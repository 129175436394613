import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';


import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule, MatRadioModule, MatTreeModule, MatListModule, MatSnackBarModule, MatIconModule, MatProgressSpinnerModule, MatSortModule, MatBadgeModule, MatTooltipModule, MatBottomSheetModule, MatDialogModule, MatTabsModule, MatAutocompleteModule, MatGridListModule, MatTableModule, MatButtonModule, MatInputModule, MatNativeDateModule, MatSelectModule, MatCardModule, MatExpansionModule, MatProgressBarModule, MatCheckboxModule, MatButtonToggleModule, MatToolbarModule, MatMenuModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
//import { SelectionModel } from '@angular/cdk/collections';
import { HttpModule } from "@angular/http";
import { WebCamModule } from 'ack-angular-webcam';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { CommonheaderComponent } from './layout/commonheader/commonheader.component';
import { FooterComponent } from './layout/footer/footer.component';
import { UserloginComponent } from './userlogin/userlogin.component';
// import { JwPaginationComponent } from 'jw-angular-pagination';
import { NgxPaginationModule } from 'ngx-pagination';

import { AuthGuard } from './guards';

import { AlertService, AuthenticationService, RedirectService } from './services';
import { JwtInterceptor, ErrorInterceptor, LoaderInterceptor } from './helpers';
import { AlertComponent } from './directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TopMenuComponent } from './layout/top-menu/top-menu.component';
// import { EmployeeSkillMappingModule } from './employee-skill-mapping/employee-skill-mapping.module';

import { customFilter } from './pipe/training_pipe';
import { EmployeeSkillMappingComponent } from './employee-skill-mapping/employee-skill-mapping.component';
import { EmployeeSkillListComponent } from './employee-skill-list/employee-skill-list.component';
import { EmployeeSkillViewComponent } from './employee-skill-view/employee-skill-view.component';
import { EmployeeSkillApproveComponent } from './employee-skill-approve/employee-skill-approve.component'

//For Front end session
import { UserIdleModule } from 'angular-user-idle';
import { EmployeeJobSearchComponent } from './employee-job-search/employee-job-search.component';
import { EmployeeJobViewComponent } from './employee-job-view/employee-job-view.component';
import { Popup } from './employee-job-view/employee-job-view.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { EmployeeSearchComponent } from './employee-search/employee-search.component';
import { EmployeeViewPopupComponent } from './employee-view-popup/employee-view-popup';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { AboutInformationComponent } from './about-information/about-information.component';
import { CareerLadderInformationComponent } from './career-ladder-information/career-ladder-information.component';
import { RoleInformationComponent } from './role-information/role-information.component';
import { RowComponent } from './employee-skill-mapping/dynamic-row/row.component';
import { SkillList } from './employee-skill-mapping/dynamic-row/row.component';
import { WarningPopupModal } from './employee-skill-mapping/dynamic-row/row.component';
import { EmployeeSkillMappingService } from './services/employee_skill_mapping.service';
import { PopupModalComponent } from "./popup-modal/popup-modal";
import { LoaderService } from "./shared/loader.subject";
import { TopmenuService } from "./shared/top-menu.subject";
import { PushNotificationService } from "./services/push_notification.service";
import {CookieService} from 'angular2-cookie/core';

// import { NgCircleProgressModule } from 'ng-circle-progress';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
//import { ChartsModule } from 'ng2-charts/ng2-charts';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';

//for push notification
// import { ToasterModule } from 'angular5-toaster';
import { InternalJobCreationComponent } from './internal-job-creation/internal-job-creation.component';
import { InternalJobPostComponent } from './internal-job-post/internal-job-post.component';
import { InternalJobEditComponent } from './internal-job-edit/internal-job-edit.component';
import { ApplyJobComponent } from './apply-job/apply-job.component';
import { AppliedJobListComponent } from './applied-job-list/applied-job-list.component';
import { InternalJobViewComponent } from './internal-job-view/internal-job-view.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { RrfCreationComponent } from './rrf-creation/rrf-creation.component';
import { RrfEditModal, RrfListComponent } from './rrf-list/rrf-list.component';
import { RrfViewComponent } from './rrf-view/rrf-view.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RrfRmgActivityComponent } from './rrf-rmg-activity/rrf-rmg-activity.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { InterviewScheduleComponent } from './interview-schedule/interview-schedule.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { IofListComponent } from './iof-list/iof-list.component';
import { TechnicalIofComponent } from './technical-iof/technical-iof.component';
import { HrRrfApproveListComponent } from './hr-rrf-approve-list/hr-rrf-approve-list.component';
import { HrRrfApproveComponent } from './hr-rrf-approve/hr-rrf-approve.component';
import { TechnicalIofViewComponent } from './technical-iof-view/technical-iof-view.component';
import { HrRrfApproveViewComponent } from './hr-rrf-approve-view/hr-rrf-approve-view.component';
import { RrfReportComponent } from './rrf-report/rrf-report.component';
import { CandidateLoginComponent } from './candidate-login/candidate-login.component';
import { CandidateFileUploadComponent } from './candidate-file-upload/candidate-file-upload.component';
import { CandidateCemModal, CandidateDocumentSendbackComponent, CandidateOfferModal, RrfCandidateApproveComponent } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateApproveModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { OfferLetterPreviewModal } from './rrf-rmg-activity/rrf-rmg-activity.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SkillListComponent } from './skill-list/skill-list.component';
import { EditSkill } from './skill-list/skill-list.component';
import { InterviewerProfileValidationComponent } from './interviewer-profile-validation/interviewer-profile-validation.component';
import { InterviewerProfileValidationViewComponent } from './interviewer-profile-validation-view/interviewer-profile-validation-view.component';
import { RrfInterviewerActivityComponent } from './rrf-interviewer-activity/rrf-interviewer-activity.component';
import { RejectPopup } from './rrf-interviewer-activity/rrf-interviewer-activity.component';
import { InterviewScheduleHrComponent } from './interview-schedule-hr/interview-schedule-hr.component';
import { CandidateCTCApproveModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateIndicativeOfferModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateAcceptModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateHRApprovalModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateOfferLetterModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { CandidateOfferLetterPreviewModal } from './rrf-candidate-approve/rrf-candidate-approve.component';
import { SupportComponent } from './support/support.component';
import { FaqComponent } from './faq/faq.component';
import { QuizComponent } from './quiz/quiz.component';
import { RegisterComponent } from './register/register.component';
import { ResultComponent } from './result/result.component';
import { RrfemailComponent } from './rrfemail/rrfemail.component';
import { StartComponent } from './start/start.component';
import { PricingComponent } from './pricing/pricing.component';
import { QuizService } from './services/quiz.service';
import { ApiService } from './services/api.service';
import { NavbarComponent } from './navbar/navbar.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { QuizinstructionComponent } from './quizinstruction/quizinstruction.component';
import { QuizResultComponent } from './quiz-result/quiz-result.component';
import { JoblistComponent } from './joblist/joblist.component';
import { MyspaceComponent } from './myspace/myspace.component';
import { SearchPipe } from './pipe/search.pipe';
import { HomepageComponent } from './homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { QuizreportComponent } from './quizreport/quizreport.component';

import { OrganizationFitmentReportComponent } from './organization-fitment-report/organization-fitment-report.component';
import { MyProfileDetailsComponent } from './my-profile-details/my-profile-details.component';
import { ReferenceDocumentsComponent } from './reference-documents/reference-documents.component';
import { EeeQuizComponent } from './eee-quiz/eee-quiz.component';
import { EeeQuizStartComponent } from './eee-quiz-start/eee-quiz-start.component';
import { EeeQuizinstructionComponent } from './eee-quizinstruction/eee-quizinstruction.component';
import { EeeQuizViewComponent } from './eee-quiz-view/eee-quiz-view.component';
import { EeeQuizResultComponent } from './eee-quiz-result/eee-quiz-result.component';
import { EeeQuizstart2Component } from './eee-quizstart2/eee-quizstart2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResumeBuilderComponent } from './resume-builder/resume-builder.component';
import { ResumePipePipe } from './pipe/resume-pipe.pipe';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// Import angular-fusioncharts
import { FusionChartsModule } from 'angular-fusioncharts';



// Load FusionCharts
import * as FusionCharts from 'fusioncharts';
// Load Charts module
import * as charts from "fusioncharts/fusioncharts.charts";


// Load fusion theme
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as Widgets from "fusioncharts/fusioncharts.widgets";
import * as Gantt from  "fusioncharts/fusioncharts.gantt";
import * as Ocean from 'fusioncharts/themes/fusioncharts.theme.ocean';
import * as Fint from 'fusioncharts/themes/fusioncharts.theme.fint';
import * as Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import * as Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';
 import { AnalyticsQuizDataComponent } from './analytics-quiz-data/analytics-quiz-data.component';
 import { EeequizreviewComponent } from './eeequizreview/eeequizreview.component';
import { JobListManagerComponent } from './job-list-manager/job-list-manager.component';
import { InternalAssessmentEmployeeComponent } from './internal-assessment-employee/internal-assessment-employee.component';
import { InternalAddEmployeeComponent } from './internal-add-employee/internal-add-employee.component';
import { CemCandidateApproveComponent } from './cem-candidate-approve/cem-candidate-approve.component';
import { CemComponent } from './cem/cem.component';
import { CemScreen2Component } from './cem/cem-screen2/cem-screen2.component';
import { CemCandidateListComponent } from './cem/cem-candidate-list/cem-candidate-list.component';
import { ExternalAssessmentEmployeeComponent } from './external-assessment-employee/external-assessment-employee.component';
import { ExternalAddEmployeeComponent } from './external-add-employee/external-add-employee.component';
import { TooltipModule} from 'ngx-bootstrap/tooltip';
import { FileUploadLoginComponent } from './file-upload-login/file-upload-login.component';
import { DeliveryStructureComponent } from './delivery-structure/delivery-structure.component';

// import { JobListManagerComponent } from './job-list-manager/job-list-manager.component';

// // Add dependencies to FusionChartsModul
 FusionChartsModule.fcRoot(FusionCharts, charts, Widgets, Gantt, FusionTheme,Ocean,Fint,Candy);


const appRoutes: Routes = [
  {
    path: 'homepage',
    component: HomepageComponent
  },
  {
    path: 'resume_builder',
    component: ResumeBuilderComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'master_dashboard',
    component: DashboardComponent
  },
  {
    path: 'login',
    component: UserloginComponent
  },
  {
    path: 'employee_skill',
    component: EmployeeSkillMappingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee_skill_view',
    component: EmployeeSkillViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee_skill_approve_list',
    component: EmployeeSkillListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee_skill_approve',
    component: EmployeeSkillApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee_job_mapping',
    component: EmployeeJobSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manager_job_mapping',
    component: EmployeeJobSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rmg_job_mapping',
    component: EmployeeJobSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'employee_search',
    component: EmployeeSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my_profile',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'internal_job_creation',
    component: InternalJobCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'internal_job_list',
    component: InternalJobPostComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'internal_job_edit',
    component: InternalJobEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'internal_job_view',
    component: InternalJobEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applied_jobs',
    component: AppliedJobListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_creation',
    component: RrfCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_edit/:rrf_id',
    component: RrfCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_request_list',
    component: RrfListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_approve_list',
    component: RrfListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_rmg_list',
    component: RrfListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_view',
    component: RrfViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_approve',
    component: RrfViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'iof_list',
    component: IofListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hr_rrf_approve_list',
    component: HrRrfApproveListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_report',
    component: RrfReportComponent,
    canActivate: [AuthGuard]
  },
  {
   path: 'rrf_candidate_approve_hr',
    component: RrfCandidateApproveComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'rrf_candidate_approve_buh',
    component: RrfCandidateApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_candidate_approve_rmg',
    component: RrfCandidateApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_candidate_approve_cem',
    component: RrfCandidateApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_candidate_approve_hrOps',
     component: RrfCandidateApproveComponent,
     canActivate: [AuthGuard]
   },
  {
    path: 'candidate_login',
    component: CandidateLoginComponent,
  },
  {
    path: 'candidate_file_upload/:id',
    component: CandidateFileUploadComponent,
  },
  {
    path: 'candidate_upload_login',
    component: FileUploadLoginComponent
  },
  {
    path: 'eee_quizreview',
    component: EeequizreviewComponent,
    
  },
  
  {
    path: 'skill_list',
    component: SkillListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_profile_validation',
    component: InterviewerProfileValidationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rrf_profile_validation_view',
    component: InterviewerProfileValidationViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path: 'recruitment_job_list',
    component: JobListManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rmg_job_list',
    component: JobListManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'quiz',
    component: QuizComponent

  },
  {
    path: 'start',
    component: StartComponent

  },
  {
    path: 'result',
    component: ResultComponent

  },
  {
    path:'aboutus',
    component:AboutusComponent
    
  },
  {
    path: 'quiz_instruction',
    component: QuizinstructionComponent, data: { title: 'welcome', toolbar: false }

  },
  {
    path: 'quiz_result',
    component: QuizResultComponent, data: { title: 'welcome', toolbar: false }

  },
  {
    path: 'rrf_dashboard',
    component: RrfemailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'joblist',
    component: JoblistComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'myspace',
    component: MyspaceComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'analytics-quiz',
    component:QuizreportComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'fitment_analytics',
    component:OrganizationFitmentReportComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'internal_assessment',
    component:InternalAssessmentEmployeeComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'external_assessment',
    component:ExternalAssessmentEmployeeComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'external_add_employee',
    component:ExternalAddEmployeeComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'internal_add_employee',
    component: InternalAddEmployeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'profile_details',
    component:MyProfileDetailsComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'reference_documents',
    component:ReferenceDocumentsComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'eee_quiz',
    component:EeeQuizComponent
  },
  {
    path:'eee_quiz_start',
    component:EeeQuizStartComponent
   
  },
  {
    path:'eee_quiz_start2',
    component:EeeQuizstart2Component
   
  },
  {
    path:'eee_quiz_instruction',
    component:EeeQuizinstructionComponent
   
  },
  {
    path:'eee_quiz_view',
    component:EeeQuizViewComponent
   
  },
  {
    path:'eee_quiz_result',
    component:EeeQuizResultComponent
   
  },
  {
    path:'analytics-quiz-data',
    component:AnalyticsQuizDataComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'cem-candidate-list',
    component:CemCandidateListComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'cem',
    component:CemComponent,
    canActivate : [AuthGuard]
  },
  {
    path:'cem-screen2',
    component:CemScreen2Component,
  },
  {
    path:'delivery-structure',
    component:DeliveryStructureComponent,
    canActivate : [AuthGuard]
  },
  { path: '**', redirectTo: '/homepage' },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserloginComponent,
    AlertComponent,
    CommonheaderComponent,
    TopMenuComponent,
    customFilter,
    SearchPipe,
    EmployeeSkillListComponent,
    EmployeeSkillViewComponent,
    EmployeeSkillApproveComponent,
    EmployeeJobSearchComponent,
    EmployeeJobViewComponent,
    Popup,
    CandidateApproveModal,
    EmployeeViewPopupComponent,
    EmployeeDetailComponent,
    EmployeeSearchComponent,
    HomeComponent,
    AboutInformationComponent,
    CareerLadderInformationComponent,
    RoleInformationComponent,
    RowComponent,
    SkillList,
    PopupModalComponent,
    EmployeeSkillMappingComponent,
    InternalJobCreationComponent,
    InternalJobPostComponent,
    InternalJobEditComponent,
    ApplyJobComponent,
    AppliedJobListComponent,
    InternalJobViewComponent,
    RrfCreationComponent,
    RrfListComponent,
    RrfEditModal,
    RrfViewComponent,
    RrfRmgActivityComponent,
    InterviewScheduleComponent,
    IofListComponent,
    TechnicalIofComponent,
    HrRrfApproveListComponent,
    HrRrfApproveComponent,
    TechnicalIofViewComponent,
    HrRrfApproveViewComponent,
    RrfReportComponent,
    CandidateLoginComponent,
    CandidateFileUploadComponent,
    RrfCandidateApproveComponent,
    OfferLetterPreviewModal,
    SkillListComponent,
    WarningPopupModal,
    EditSkill,
    InterviewerProfileValidationComponent,
    InterviewerProfileValidationViewComponent,
    RrfInterviewerActivityComponent,
    RejectPopup,
    InterviewScheduleHrComponent,
    CandidateCTCApproveModal,
    CandidateIndicativeOfferModal,
    CandidateAcceptModal,
    CandidateHRApprovalModal,
    CandidateOfferLetterModal,
    CandidateOfferLetterPreviewModal,
    CandidateOfferModal,
    CandidateCemModal,
    SupportComponent,
    FaqComponent,
    QuizComponent,
    RegisterComponent,
    ResultComponent,
    RrfemailComponent,
    StartComponent,
    NavbarComponent,
    PricingComponent,
    QuizinstructionComponent,
    QuizResultComponent,
    JobListManagerComponent,
    MyspaceComponent,
    JoblistComponent,
    HomepageComponent,
    AboutusComponent,
    ContactusComponent,
    QuizreportComponent,
    OrganizationFitmentReportComponent,
    MyProfileDetailsComponent,
    ReferenceDocumentsComponent,
    EeeQuizComponent,
    EeeQuizStartComponent,
    EeeQuizinstructionComponent,
    EeeQuizViewComponent,
    EeeQuizResultComponent,
    EeeQuizstart2Component,
    DashboardComponent,
    ResumeBuilderComponent,
    ResumePipePipe,
    AnalyticsQuizDataComponent,
    EeequizreviewComponent,
    InternalAssessmentEmployeeComponent,
    InternalAddEmployeeComponent,
    CemCandidateApproveComponent,
    CemComponent,
    CemScreen2Component,
    CemCandidateListComponent,
    ExternalAssessmentEmployeeComponent,
    ExternalAddEmployeeComponent,
    FileUploadLoginComponent,
    CandidateDocumentSendbackComponent,
    DeliveryStructureComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    FusionChartsModule,
    
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    HttpModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatRadioModule,
    MatTreeModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatPaginatorModule,
    MatStepperModule,
    MatAutocompleteModule,
    HttpClientModule,
    NgbModule,
 
    MatBottomSheetModule,
    MatListModule,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSortModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatSnackBarModule,
    // ToasterModule,
    ScrollDispatchModule,
    NgSelectModule,
    NgxChartsModule,
  //  ChartsModule,
    AngularMultiSelectModule,
    MatSelectInfiniteScrollModule,
    MaterialFileInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PdfViewerModule,
    TimePickerModule,
    NgxPaginationModule,
    TooltipModule.forRoot(),
    WebCamModule,
    NgCircleProgressModule.forRoot({
      space: -5
    }),
    // NgMultiSelectDropDownModule.forRoot(),
    FlatpickrModule.forRoot(),
    UserIdleModule.forRoot({ idle: 900, timeout: 1, ping: 1 })
  ],
  entryComponents: [OfferLetterPreviewModal, CandidateApproveModal, Popup, HrRrfApproveViewComponent, TechnicalIofViewComponent, HrRrfApproveComponent, TechnicalIofComponent, InterviewScheduleComponent, InternalJobViewComponent, ApplyJobComponent, EmployeeDetailComponent, EmployeeViewPopupComponent, SkillList, PopupModalComponent, WarningPopupModal, EditSkill, RejectPopup, InterviewScheduleHrComponent, CandidateCTCApproveModal, CandidateIndicativeOfferModal, CandidateAcceptModal, CandidateHRApprovalModal, CandidateOfferLetterModal, CandidateOfferLetterPreviewModal,CandidateCemModal, CandidateOfferModal, RrfEditModal, CandidateDocumentSendbackComponent],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    RedirectService,
    EmployeeSkillMappingService,
    LoaderService,
    PushNotificationService,
    TopmenuService,
    QuizService,
    ApiService,
    CookieService,
    // {provide: 'rootVar', useValue: 'hello'},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    //fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private push_notification_service: PushNotificationService) {
    // Consume events: Change
    this.push_notification_service.consumeEvenOnSkillApprove();
    this.push_notification_service.consumeEvenOnSkillSubmit();
  }
}
