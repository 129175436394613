import { HttpClient, HttpParams, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BACK_END_URL } from '../shared/app.globals';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class EmployeeSkillMappingService {
    constructor(private http: HttpClient) { }
      sendBaseEncode(base,passcode){
        var body = {
          base_encode: base,
          pass_code:passcode,
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/send_base_encode/`, body);
      }
      postFormData(form_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_img/` , form_data);
    }
    getUnit(parent_id: String) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_units/` + parent_id);
    }
    getRMList(unit_id: String) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rm/` + unit_id);
    }
    getCompetency(sub_sbu_id: String, selected_competency) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_competency/` + sub_sbu_id, selected_competency);
    }
    getSkill(com_str_map_id: String, already_selected_skill) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_skill/` + com_str_map_id, already_selected_skill);
    }
    saveCompetencyMapping(save_data) {
        console.log(save_data);
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_competency_mapping/`, save_data);
    }
    getCompetencyMapping(employee_id, type, experience_type) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_competency_mapping/` + employee_id + '/' + type + '/' + experience_type);
    }
    getEmployeeDetail(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_employee_detail/` + employee_id);
    }
    getMappingDetail(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_mapping_detail/` + employee_id);
    }
    // getclosedposition() {
    //     return this.http.get(BACK_END_URL + `employee_skill_mapping/status_test/`);
    // }
    getEmployeeSkillMappingList(search_value) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_employee_mapping_list/`, search_value);
    }
    getProficiency() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_proficiency/`);
    }
    saveManagerProficiency(mapping_data, employee_id, manager_id, current_role) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_manger_proficiency/` + employee_id + "/" + manager_id, { mapping_data, current_role });
    }
    getBands() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_bands/`);
    }
    getRoles(unit_id, band_name) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_roles/` + unit_id + "/" + band_name);
    }
    getJobCodes(role_name, band_name) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_job_codes/` + role_name + "/" + band_name);
    }
    getJobDetail(job_master_id, employee_id, type) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_job_detail/` + job_master_id + "/" + employee_id + "/" + type);
    }
    getproficiencyDecription(mapping_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_proficiency_list/` + mapping_id);
    }
    getEmployeeList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_employee_list/`);
    }
    getReporteeList(manager_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_reportee_list/` + manager_id);
    }
    getReporteeSkillMappingList(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_reportee_mapping_list/` + employee_id);
    }
    getSkillList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_skill_list/`)
    }
    getRole(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_role/` + employee_id)
    }
    getRoleList(filtered_value) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_role_list/` + filtered_value)
    }
    getFilteredEmployeeList(filtered_value) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_filtered_employee_list/` + filtered_value);
    }
    creatNewInternalJob(mapping_data, employee_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/create_new_internal_job/` + employee_id, mapping_data);
    }
    getJobPost() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_internal_job/`);
    }
    getJobPosts() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_open_internal_jobs/`);
    }
    getJobPostDetail(job_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_internal_job_detail/` + job_id);
    }
    updateJobPostDetail(job_post_id, status, employee_status) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_internal_job_detail/` + job_post_id + "/" + status, employee_status);
    }
    applyJob(job_post_id, employee_id, manager_agreed) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/apply_job/` + job_post_id + "/" + employee_id + "/" + manager_agreed);
    }
    applyJobCheck(job_post_id, employee_id, employee_fitment_score) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/check_apply_job/` + job_post_id + "/" + employee_id + "/" + employee_fitment_score);
    }
    getAppliedJobPost(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_applied_job_post/` + employee_id);
    }
    getRRFCreationData() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_creation_data/`);
    }
    getProject(customer_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_project/` + customer_id);
    }
    getSubWorkLocation(work_location_name) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_sub_work_locations/` + work_location_name);
    }
    creatNewRRF(mapping_data, employee_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/create_new_rrf/` + employee_id, mapping_data);
    }
    creatNewExtenalRRF(mapping_data, employee_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/create_new_external_rrf/` + employee_id, mapping_data);
    }
    getRRFList(type, employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_list/` + type + '/' + employee_id);
    }
    getRRFDetail(rrf_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_detail/` + rrf_id);
    }
    updateRRFStatus(rrf_data, rrf_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_rrf_status/` + rrf_id, rrf_data);
    }
    getRRFEditDetail(rrf_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_edit_data/` + rrf_id);
    }
    getAssociateResume(emp_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_associate_resume/` + emp_id);
    }
    deleteAssociateResume(emp_data) {
        var body={
        data:emp_data,
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/delete_resume/`, body);
    }
    associateResume(mapping_data, employee_id,employee_name,employee_no) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/associate_resume/` + employee_id + '/' + employee_name  + '/'+ employee_no , mapping_data );
    }
    getRRFListWithPeriod(type, employee_id, fromdate, todate) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_list_manager/` + type + '/' + employee_id+ '/' + fromdate+ '/' + todate);
    }
    uploadCandidate(rrf_id, save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_candidate/` + rrf_id, save_data);
    }
    scheduleInterview(save_data, rrf_id, candidate_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/schedule_interview/` + rrf_id + '/' + candidate_id, save_data);
    }
    getIOFPendingList(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_iof_pending_list/` + employee_id);
    }
    getfinalIOFPendingList(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_final_iof_pending_list/` + employee_id);
    }
    getHrIOFPendingList(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_hr_iof_pending_list/` + employee_id);
    }
    saveIOFDetail(save_data, rrf_id, candidate_id, schedule_id, status) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_iof_detail/` + rrf_id + '/' + candidate_id + '/' + schedule_id + '/' + status, save_data);
    }
    getHrApprovePendingList(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_hr_approve_pending_list/`+ employee_id);
    }
    saveHRIOFDetail(save_data, rrf_id, candidate_id, status) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_hr_iof_detail/` + rrf_id + '/' + candidate_id + '/' + status, save_data);
    }
    getIOFDetail(schedule_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_iof_detail/` + schedule_id);
    }
    candidateDuplicateCheck(rrf_id, pan_number) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/candidate_duplicate_check/` + rrf_id + '/' + pan_number);
    }
    getRRFReportList(filter_data) {
        if (filter_data.export)
            return this.http.post(BACK_END_URL + `employee_skill_mapping/get_rrf_report_list/`, filter_data, { responseType: 'blob' })
        else
            return this.http.post(BACK_END_URL + `employee_skill_mapping/get_rrf_report_list/`, filter_data)
    }
    uploadCandidateDocuments(save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_candidate_document/`, save_data);
    }
    checkCandidateDocuments(candidate_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/check_candidate_document/`, { candidate_id });
    }
    getRRFCandidateApproveList(view_type,employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_candidate_approve_list/` + view_type+ '/' + employee_id);
    }
    saveRRFCandidateApprove(candidate_id, status, approver_id,type,probation_data) {
      var body={
            "probation_data":probation_data
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_rrf_candidate_approve/` + candidate_id + '/' + status + '/' + approver_id + '/' + type , probation_data);
    }
    generateOfferLetter(candidate_id, form_data,probation_period) {
        form_data.probation_period = probation_period
        return this.http.post(BACK_END_URL + `employee_skill_mapping/generate_offer_letter/` + candidate_id, form_data);
    }
    uploadOfferLetter(candidate_id, form_data,probation_period,id) {
        form_data.probation_period = probation_period
        form_data.recruiter_id = id;
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_offer_letter/` + candidate_id + '/' + id, form_data);
    }
    getOrganizationSkillList(filter_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_organization_skill_list/`, filter_data);
    }
    addNewCompetency(sub_sbu, competency_name) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/add_new_competency/` + sub_sbu + '/' + competency_name);
    }
    updateCandidate(rrf_id, rrf_cmd) {
        var body={
            id:rrf_id,
            command:rrf_cmd
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_candidate/`, body);
    }
    getSkillDetails(competency_id,skill_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_skill_detail/` + competency_id + '/' + skill_id);
    }
    deleteSkillDetails(competency_id,skill_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/delete_skill_detail/` + competency_id + '/' + skill_id);
    }
    updateSkillDetails(save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_skill_detail/`, save_data);
    }
    saveSkillDetails(save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_skill_detail/`, save_data);
    }
    getRRFListForInterviewer(type, employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_list_for_interviewer/` + type + '/' + employee_id);
    }
    updateRRFStatusForCandidate(rrf_data, rrf_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_rrf_status_for_candidate/` + rrf_id, rrf_data);
    }
    scheduleInterviewHR(save_data, rrf_id, candidate_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/schedule_interview_hr/` + rrf_id + '/' + candidate_id, save_data);
    }
    getRecruiterList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_recruiter_list/`);
    }
    candidateFileUploadTrigger(rrf_id,candidate_id,update_object){
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_file_upload_trigger/`+ rrf_id + '/' + candidate_id, update_object);
    }
    getCandidateDetail(candidate_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_candidate_detail/`+ candidate_id);
    }
    CandidateDetail(candidate_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/candidate_detail/`+ candidate_id);
    }
    candidateCTCApproval(save_data, candidate_id,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_ctc_approval/` + candidate_id+ '/' + approver_id, save_data);
    }

    generateIndicativeOfferLetter(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/generate_indicative_offer_letter/` + candidate_id+ '/' + approver_id, form_data);
    }

    uploadIndicativeOfferLetter(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_indicative_offer_letter/` + candidate_id+ '/' + approver_id, form_data);
    }

    candidateLOIAcceptance(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_loi_acceptance/` + candidate_id+ '/' + approver_id, form_data);
    }

    candidateMissingDocs(candidate_id, form_data:any) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_missing_docs/` + candidate_id, form_data);
    }

    saveCandidateApprovalData(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_candidate_approval_data/` + candidate_id+ '/' + approver_id, form_data);
    }

    releaseOfferLetter(candidate_id,approver_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/release_offer_letter/` + candidate_id+ '/' + approver_id);
    }

    generateOfferLetterstatus(candidate_id,approver_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/generate_offer_letter_status/` + candidate_id+ '/' + approver_id);
    }

    cemDocStatus(id,doc_list,status,rejected_docs) {
        var body ={
            doc_list :doc_list,
            status:status,
            candidate_id:id,
            rejected_docs:rejected_docs
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/cem_doc_status/` ,body);
    }

    updateRRFStatusBulk(rrf_data,emp_id) {
        
        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_rrf_status_bulk/` + emp_id, rrf_data);
    }

    getTreeDetail() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_tree_detail`);
    }
    getSkillProficiency(skill_id, experience) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_skill_proficiency/` + skill_id + '/' + experience);
    }
    getRoleswithPracticeAndUnit(unit_id, practice, sub_practice, band_name) {
      
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_roles_with_practice/` + unit_id +  "/" + practice + "/" + sub_practice + "/" + band_name);
    }
    getCompetencyList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_competency_list/`)
    }
    getEmployeeFitmentList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_employee_competency_list/`)
    }
    getEmployeeFitmentListExport(form_data) {
        if (form_data.export)
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_employee_competency_list_export/`,form_data, { responseType: 'blob' });
        else
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_employee_competency_list_export/`,form_data);

    }
    getRoleswithUnitPractice(unit_id, employee_id, practice_id,sub_practice_id) {
      
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_roles_with_unit_practice/` + unit_id +  "/" + employee_id + "/" + practice_id + "/" +sub_practice_id);
    }

    getEmployeeDetails(employee_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_employee_details/` +employee_id)
    }
    getCandidate_password_details(candidate_password){
        console.log("candidate_password",candidate_password);
        var body = {
          candidate_password:candidate_password,
        }
        return this.http.post(BACK_END_URL + 'employee_skill_mapping/assessment_taken_mail', body);
        }
    quiz_sendmail(name,password,count)
    {
    var body = {
      name: name,
      password: password,
      count: count
    }
      return this.http.post(BACK_END_URL + 'employee_skill_mapping/sendquiz_mail', body);
    

  }

    // getResumeDetails(form_data) {
    //     return this.http.post('https://ht.ihtfast.com:8085/resumeparser/extractapifile',form_data);
    // }
    getRoleswithPracticeAndSubPractice(unit_id, practice_id,sub_practice_id) {
      
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_roles_with_practice_subpractice/` + unit_id +  "/" + practice_id + "/" +sub_practice_id);
    }

    customerInterviewFeedback(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/customer_interview_feedback/` + candidate_id+ '/' + approver_id, form_data);
    }
    
    candidateOfferAcceptance(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_offer_acceptance/` + candidate_id+ '/' + approver_id, form_data);
    }

    getRRFCandidateApproveListwithFilter(view_type,employee_id,filter_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_rrf_candidate_approve_list_with_status/` + view_type+ '/' + employee_id, filter_data)
     }

     candidateCEMDetails(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_cem_details/` + candidate_id+ '/' + approver_id, form_data);
    }
    sendBgvOJFDetails(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/send_bgv_ojf_details/` + candidate_id+ '/' + approver_id, form_data);
    }
    sendWelcomeMail(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/send_welcome_mail/` + candidate_id+ '/' + approver_id, form_data);
    }
    getCEMDataExport(form_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/get_cem_data_export/`,form_data, { responseType: 'blob' })
    }

    sendCPIFIOFDetails(candidate_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/send_cpif_iof_to_hr/` + candidate_id);
    }

    sendOfferDetails(candidate_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/send_offer_to_hr/` + candidate_id);
    }

    getIdealUnit(parent_id: String) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_ideal_units/` + parent_id);
    }
    saveOjfDetails(candidate_id, form_data,approver_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/save_ojf_details/` + candidate_id+ '/' + approver_id, form_data);
    }
    getCompetencyMappingExport() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_competency_mapping_export/`, { responseType: 'blob' });
    }

    getoverallskillmatrixExport() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/skill_details_list/`, { responseType: 'blob' });
    }
    sendCandidateBulkUpload( form_data) {
        return this.http.post(BACK_END_URL + `upload/api/Candidate/CsvFile`, form_data);
        // return this.http.post('https://ihtfast.com:3695/upload/api/Candidate/CsvFile',form_data);
    }
    sendEmployeeBulkUpload( form_data) {
        return this.http.post(BACK_END_URL + `uploads/api/emp/CsvFile`, form_data);
        // return this.http.post('https://ihtfast.com:3695/upload/api/Candidate/CsvFile',form_data);
    }
    sendclientmail( mailid,filepath) {
        var body={
            mailid:mailid,
            filepath:filepath
        }
        return this.http.post(BACK_END_URL +`employee_skill_mapping/send_clientmail_report/`, body);
        // return this.http.post('https://ihtfast.com:3695/upload/api/Candidate/CsvFile',form_data);
    }

    sendRRfBulkUpload(form_data) {
        // return this.http.post(BACK_END_URL + `uploads/api/User/CsvFile`, form_data);
        return this.http.post(BACK_END_URL + `uploads/api/emp/CsvFile`, form_data);
    }

    closeRR( postValue) {  
        return this.http.post(BACK_END_URL +`employee_skill_mapping/close_rrf/`, postValue);
    }

    skipAssessment (postvalue) {
        return this.http.post(BACK_END_URL +`employee_skill_mapping/skip_assessment/`, postvalue);
    }

    clientFeedback(value) {
        return this.http.post(BACK_END_URL +`employee_skill_mapping/client_feedback/`, value);
    }

    job_details(sourcedata) {
        var body={
            sourcedata:sourcedata
        }
        return this.http.post(BACK_END_URL + `employee_skill_mapping/job_info/`,body);
    }
    getInternalEmpRRFDetail(rrf_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/internal_emp_rrf_detail/` + rrf_id);
    }
      getAllEmployeeDetails() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_all_employee_details/`);
    }
    getInternalEmployeeDetail(rrf_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/internal_employee_detail/` + rrf_id);
    }
    generatePasscode(rrf_id,save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/generate_passcode/` + rrf_id,save_data);
    }
    deleteInternalEmployee(rrf_id) {
        return this.http.delete(BACK_END_URL + `employee_skill_mapping/delete_internal_employee/` + rrf_id);
    }
    getInternalEmployeeFitmentList() {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get__internal_employee_competency_list/`)
    }
    uploadInternalCandidate(save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_internal_candidate/` , save_data);
    }
    getRRFData(rrf_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_data/` + rrf_id);
    }
    sendcandidatemail( candidate,type) {
        var body={
            candidate:candidate,
            type:type
        }
        return this.http.post(BACK_END_URL +`employee_skill_mapping/send_candidatemail/`, body);
        // return this.http.post('https://ihtfast.com:3695/upload/api/Candidate/CsvFile',form_data);
    }
    technicalInterviewschedule(save_data, rrf_id, candidate_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/technical_schedule_interview/` + rrf_id + '/' + candidate_id, save_data);
    }
    finalTechnicalInterviewschedule(save_data, rrf_id, candidate_id) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/final_technical_schedule_interview/` + rrf_id + '/' + candidate_id, save_data);
    }
    pancardDuplicateCheck( pan_number) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_pancard_duplicate_check/`,pan_number);
    }
    aadharDuplicateCheck( aadhar_number) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_aadhar_duplicate_check/`,aadhar_number);
    }
    assignEmpSendMail(rrf_list,assign_rec_id,rec_id){
        var body = {
          rrf_list:rrf_list,
          assign_rec_id:assign_rec_id,
          rec_id:rec_id
        }
        return this.http.post(BACK_END_URL + 'employee_skill_mapping/assign_emp_mail', body);
        }
    updateNoOfPosition(param) {
        return this.http.post(BACK_END_URL + 'employee_skill_mapping/update_noOfPosition_rrf', param);
    }
    offerLetterAcceptance(candidate_id, form_data,approver_id,status) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/offer_letter_acceptance/` + candidate_id+ '/' + approver_id, form_data,status);
    }

    cemFinalCandidateCheckDcos(candidate_id,param) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/cem_final_candidate_check_docs/`+ candidate_id , param);
    }

    cemFinalCandidateCheck(candidate_id,param) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/cem_final_candidate_check/`+ candidate_id , param);
    }

    cemFinalCandidateCheckDetails(candidate_id,param) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/cem_final_candidate_check_details/`+ candidate_id , param);
    }

    getCandidateRRFDetail(candidate_id) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_candidate_rrf_detail/`+ candidate_id);
    }

    updateOpenClosePositions(rrf_id,open_position,close_position) {
        var body = {
            open:open_position,
            close:close_position,
          }

        return this.http.post(BACK_END_URL + `employee_skill_mapping/update_open_close_position/`+ rrf_id , body);
    }

    getExternalCandidatePasscode(id,save_data) {
        return this.http.post(BACK_END_URL+`employee_skill_mapping/generate_passcode_external/`+ id,save_data);
    }

    createExternalCandidate(id,params) {
        return this.http.post(BACK_END_URL+`employee_skill_mapping/create_external_candidate/`+id, params);
    }

    getExternalCandidateDetails(id) {
        return this.http.get(BACK_END_URL+`employee_skill_mapping/get_external_candidate_details/`+ id);
    }

    getSkillReportListTest(filter_data) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_qus_ans_list/`, { responseType: 'blob' })
    }

    getUpdatedOpenPosition(param){
        return this.http.get(BACK_END_URL +`employee_skill_mapping/update_open_count_data/`+param)
    }

    candidateFileUploadReTrigger(rrf_id,candidate_id,update_object){
        return this.http.post(BACK_END_URL + `employee_skill_mapping/candidate_file_upload_retrigger/`+ rrf_id + '/' + candidate_id, update_object);
    }

    getLOIHistory(param){
        return this.http.get(BACK_END_URL +`employee_skill_mapping/setLoiHistory/`+param)
    }

    getReHireCheck(pan_no) {
        return this.http.get(BACK_END_URL+`employee_skill_mapping/emp_pancard_duplicate_check/`+pan_no)
    }

    fileUploadLogin(body) {
        return this.http.post(BACK_END_URL +`employee_skill_mapping/candidate_upload_login`,body)
    }

    candidateDocUpdate(body) {
        return this.http.post(BACK_END_URL +`employee_skill_mapping/candidate_upload_update_doc`,body)
    }

    getCanidateDocDetails(params) {
        var body = {
            candidate_id: params
        }
        return this.http.post(BACK_END_URL +`employee_skill_mapping/get_candidate_details`, body)

    }

    updateCandidateDocuments(save_data) {
        return this.http.post(BACK_END_URL + `employee_skill_mapping/upload_candidate_document_update/`, save_data);
    }

    getTAGManagerRRList(unit) {
        return this.http.get(BACK_END_URL + `employee_skill_mapping/get_rrf_by_unit/`+unit);
    }

    getDeliveryStructure(){
        var path = './assets/json/Copy of HTL Delivery organisation new.json';
        return this.http.get(path)
    }
}