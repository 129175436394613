import { Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BACK_END_URL } from '../shared/app.globals';

@Component({
  selector: 'app-interviewer-profile-validation-view',
  templateUrl: './interviewer-profile-validation-view.component.html',
  styleUrls: ['./interviewer-profile-validation-view.component.css']
})
export class InterviewerProfileValidationViewComponent implements OnInit {
  employee_detail: any;
  rrf_detail: any;
  @Output() rrf_id: any;
  job_detail: any;
  BACK_END_URL = BACK_END_URL;
  dataSource: any = [];
  displayedColumns: string[] = ['level', 'employee_name'];
  displayedColumnsJob: string[] = ['competency_name', 'skill_name'];
  customer_project: any;
  view_type: any = "view";
  minDate = new Date();
  manager_detail: any;
  base_location: any;
  @Input() rrf_approve;
  @ViewChild('job_detail_paginator') job_detail_paginator: MatPaginator;

  constructor(private router: Router, private location: Location, private skill_service: EmployeeSkillMappingService, private route: ActivatedRoute, ) { }

  ngOnInit() {
   
    if (this.route.snapshot.params.rrf_id) {
      this.rrf_id = this.route.snapshot.params.rrf_id;
      this.skill_service.getRRFDetail(this.rrf_id)
        .subscribe(
          response => {
            console.log(response);
            this.rrf_detail = response["data"]["rrf_detail"]["rrf_detail"];
            this.employee_detail = response["data"]["employee_detail"];
            this.customer_project = response["data"]["customer_project"];
            this.manager_detail = response["data"]["manager_detail"];
            this.base_location = response["data"]["base_location"];
            this.job_detail = new MatTableDataSource(response["data"]["rrf_detail"]["job_detail"])
            setTimeout(() => {
              this.job_detail.paginator = this.job_detail_paginator;
            });
            var index = 0;
            if (this.rrf_detail["interview_panel"]){
              for (var level_object of this.rrf_detail["interview_panel"]) {
                var employee_names = '';
                index++;
                for (var employee_object of level_object) {
                  employee_names += employee_object["itemName"];
                  employee_names += ", ";
                }
                this.dataSource.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2) });
              }
            this.dataSource = new MatTableDataSource(this.dataSource);
            }
          });
    }
    if (this.route.snapshot.params.view_type) {
      this.view_type = this.route.snapshot.params.view_type;
    }
  }
  counter(i: number) {
    return new Array(i);
  }
  go_back() {
    this.location.back();
  }

}
