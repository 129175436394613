import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeSkillMappingService } from '../services/employee_skill_mapping.service';
import { PopupModalComponent } from '../popup-modal/popup-modal';
import { PushNotificationService } from "../services/push_notification.service";
import { MatDialog, MatPaginator, MatTableDataSource, MatSnackBar} from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { EmployeeViewPopupComponent } from '../employee-view-popup/employee-view-popup';
import { WarningPopupModal } from '../employee-skill-mapping/dynamic-row/row.component';

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

@Component({
  selector: 'app-employee-skill-mapping',
  templateUrl: './employee-skill-mapping.component.html',
  styleUrls: ['./employee-skill-mapping.component.css']
})
export class EmployeeSkillMappingComponent implements OnInit {
  rowId: number = 0;
  employee_detail: any;
  mapping_detail: any;
  approved_skill_list = new Map();
  updated_skill_list = {};
  updated_skill_list_error = {};
  btn_disable: boolean = true;
  hide_submit_btn: boolean = false;
  hide_view_skill: Boolean = true;
  selectedIndex: Number = 0;
  help_text_array: any = { "Add Skill": "Allows employees to add all the skills they have irrespective of their current role", "Edit Skill": "Allows employees to edit their skills", "View Skill": "Allows employees to view their skills" };
  help_text = this.help_text_array["Add Skill"];
  help_text_array1: any = { "Add Skill": "Click on ADD icon to add more skills." };
  help_text1 = this.help_text_array1["Add Skill"];
  show_skill = false;
  @Output() overall_experience_month: number = 0;
  @Output() already_selected_skill = new Array();
  @Output() rowList: Array<{ id: Number }> = [];
  @Output() selected_competency = new Array();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  activeNode;
  overall_competecny_list = new Map();
  submit_data = new Array();
  show_edit: Boolean = false;
  temp_skill = {};
  skills: any = [];
  editDetail = false;
  button_type = 'Add / Edit';
  displayedColumns: string[] = ['practice', 'compentency', 'skill', 'experience', 'worked_in_last_two_year'];
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  skillData: any = {}
  skill_set_add = {};
  skill_set_edit = {};
  skill_list_error = {};
  sub_sbu_id: any;
  employee_id:any;
  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;
  constructor(private push_notification_service: PushNotificationService, private skill_service: EmployeeSkillMappingService, private router: Router, public dialog: MatDialog,private snackBar: MatSnackBar) { this.rowList = [] }

  ngOnInit() {
    this.skill_service.getTreeDetail()
      .subscribe(
        response => {
          this.dataSource.data = response["data"];
        }
      );
    this.view_skill_show();
    this.onAddNew();
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.employee_id = jsonObj.id;
    this.skill_service.getCompetencyMapping(jsonObj.id, "a", "true")
      .subscribe(
        response => {
          this.employee_detail = response["data"]["employee_detail"];
          this.overall_experience_month = response["data"]["employee_detail"]["overall_experience_month"];
          this.mapping_detail = new MatTableDataSource(response["data"]["skill"]);
          setTimeout(() => this.mapping_detail.paginator = this.paginator);
          if (response["data"]["skill"].length > 0) {
            this.show_edit = true;
          }
        }
      );
  }

  view_skill_show() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.getCompetencyMapping(jsonObj.id, null, null)
      .subscribe(
        response => {
          if (response["data"]["skill"].length > 0) {
            this.hide_view_skill = false;
            this.hide_submit_btn = true;
            this.selectedIndex = 2;
            this.help_text = this.help_text_array["View Skill"];
          }
          for (let skill_object of response["data"]["skill"]) {
            this.skillData[skill_object.skill_id] = skill_object;
            this.already_selected_skill.push(skill_object.skill_id)
            this.approved_skill_list.set(skill_object.employee_skill_set_id, { experience_month: skill_object.experience_month, gap: skill_object.gap });
          }
          console.log(this.skillData)
        }
      );
  }

  onDeleteRow(row: any) {
    this.rowList = this.rowList.filter(rowObj => rowObj.id != row.id);
    if (this.overall_competecny_list.has(row.id))
      this.overall_competecny_list.delete(row.id);
    if (this.rowList.length == 0) {
      this.onAddNew();
    }
  }

  onAddNew() {
    this.rowId++;
    this.rowList.push({ id: this.rowId }
    );

  }
  pushCompetency(sel_competency) {
    var index = this.selected_competency.indexOf(sel_competency.old_value);
    if (index != -1)
      this.selected_competency[index] = sel_competency.new_value;
    else
      this.selected_competency.push(sel_competency.new_value);
  }
  pushCompetencyList(data) {
    if (this.overall_competecny_list.has(data.get("row_id")))
      this.overall_competecny_list.delete(data.get("row_id"));
    if (data.get("competency_list").size > 0)
      this.overall_competecny_list.set(data.get("row_id"), { "skill_list": data.get("competency_list"), "sub_sbu": data.get("sub_practice") })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(PopupModalComponent, { closeOnNavigation: true, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "yes") {
        this.SubmitData();
      }
    });
  }
  SubmitData() {
    this.hide_submit_btn = true;
    for (let com_list of this.overall_competecny_list.values()) {
      for (let [key, value] of com_list.skill_list) {
        this.submit_data.push({ "sub_sbu_id": com_list.sub_sbu, "skill_id": key, "experience": value.experience_month, "gap": value.gap })
      }
    }
    var jsonObj = JSON.parse(localStorage.currentUser);
    var data = { "band_name": jsonObj.band_name, "employee_id": jsonObj.id, "data": this.submit_data, "updated_data": this.updated_skill_list };
   // console.log(data);
    this.skill_service.saveCompetencyMapping(data)
      .subscribe(
        response => {
          this.push_notification_service.emitEventOnEmployeeSkillSubmit({ "show_employee_id": jsonObj.manager, "first_name": jsonObj.first_name });
          this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
            this.router.navigate(["employee_skill"]);
          });
        }
      );
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
 
  update_skill(index) {
    var master_id = this.mapping_detail.data[index]["employee_skill_set_id"];
    if (this.updated_skill_list_error[master_id]) {
      delete this.updated_skill_list_error[master_id]
    }
    if (Number.isNaN(Number(this.mapping_detail.data[index]["experience_month"])))
      this.updated_skill_list_error[master_id] = { "not_a_number": "error" };
    else if (Number(this.mapping_detail.data[index]["experience_month"]) == 0)
      this.updated_skill_list_error[master_id] = { "zero_error": "error" };
    else if (Number(this.mapping_detail.data[index]["experience_month"]) > this.overall_experience_month)
      this.updated_skill_list_error[master_id] = { "overall_experience": "error" };

    if (!this.mapping_detail.data[index]["gap"])
      this.updated_skill_list_error[master_id] = { "select": "error" };

    if (this.mapping_detail.data[index]["experience_month"] && this.mapping_detail.data[index]["gap"] && this.approved_skill_list.get(master_id).experience_month != this.mapping_detail.data[index]["experience_month"] || this.approved_skill_list.get(master_id).gap != this.mapping_detail.data[index]["gap"])
      this.updated_skill_list[master_id] = { "skill_id": this.mapping_detail.data[index]["skill_id"], "experience": this.mapping_detail.data[index]["experience_month"], "gap": this.mapping_detail.data[index]["gap"] };

  }

  update_json(com_skl_map_id, skill_edit_obj, type, value1, value2?) {
    var experience_month = 0;
    var year;
    var month;
    var gap = 'No';
    if (type === 'experience') {
      year = value1 ? value1 : 0;
      month = value2 ? value2 : 0
      experience_month = parseInt(year) * 12 + parseInt(month);
    } else if (type === 'gap') {
      const { checked } = value1;
      gap = checked ? 'Yes' : 'No';
      if (skill_edit_obj && skill_edit_obj.employee_skill_set_id && this.skill_set_edit[skill_edit_obj.employee_skill_set_id]) {
        this.skill_set_edit[skill_edit_obj.employee_skill_set_id]['gap'] = gap;
        this.skillData[com_skl_map_id]['gap'] = gap;

      } else if (skill_edit_obj && skill_edit_obj.employee_skill_set_id) {
        this.skill_set_edit[skill_edit_obj.employee_skill_set_id] = { skill_id: com_skl_map_id, experience: skill_edit_obj.experience_month, gap: gap }
        this.skillData[com_skl_map_id]['experience_month'] = skill_edit_obj.experience_month;
        this.skillData[com_skl_map_id]['gap'] = gap;
      } else {
        if (this.skill_set_add[com_skl_map_id]) {
          this.skill_set_add[com_skl_map_id]['gap'] = gap;
          this.skillData[com_skl_map_id]['gap'] = gap;
        }
        else {
          this.skill_set_add[com_skl_map_id] = { gap: gap };
          this.skillData[com_skl_map_id] = { skill_id: com_skl_map_id, gap: gap, employee_skill_set_status: 'Internal' }
        }

      }
    }////
    // console.log(gap);
    // console.log(this.skill_set_edit);
    // console.log(this.skill_set_add);
    if (type === 'experience') {
      if (com_skl_map_id && !experience_month && this.skill_set_add[com_skl_map_id])
        delete this.skill_set_add[com_skl_map_id]
      if (this.skill_list_error[com_skl_map_id])
        delete this.skill_list_error[com_skl_map_id]
      if (Number(experience_month) > this.overall_experience_month) {
        this.skill_list_error[com_skl_map_id] = { "overall_experience": "error" };
        this.openErrorPopup();
      }
      if (Number(month) >= 12) {
        this.skill_list_error[com_skl_map_id] = { "overall_experience": "error" };
        this.openErrorPopup();
      }
      if (com_skl_map_id && experience_month && experience_month <= this.overall_experience_month &&
        skill_edit_obj && skill_edit_obj.employee_skill_set_id) {
        gap = this.skillData[com_skl_map_id]['gap'] ? this.skillData[com_skl_map_id]['gap'] : gap
        this.skill_set_edit[skill_edit_obj.employee_skill_set_id] = { skill_id: com_skl_map_id, experience: experience_month, gap: gap }
        this.skillData[com_skl_map_id]['experience_month'] = experience_month
        this.skillData[com_skl_map_id]['gap'] = gap
        this.getProficiency(com_skl_map_id, experience_month);
      } else if (com_skl_map_id && experience_month && experience_month <= this.overall_experience_month) {
        gap = this.skillData[com_skl_map_id] && this.skillData[com_skl_map_id]['gap'] ? this.skillData[com_skl_map_id]['gap'] : gap
        this.skill_set_add[com_skl_map_id] = { experience: experience_month, gap: gap, skill_id: com_skl_map_id, sub_sbu_id: this.sub_sbu_id }
        this.skillData[com_skl_map_id] = { skill_id: com_skl_map_id, experience_month: experience_month, gap: gap, employee_skill_set_status: 'Internal' }
        this.getProficiency(com_skl_map_id, experience_month);
      }
    }
    //console.log(JSON.stringify(this.skillData[com_skl_map_id]))
  }
  getProficiency(skill_id, experience) {
    this.skill_service.getSkillProficiency(skill_id, experience)
      .subscribe(
        response => {
          if (this.skillData[skill_id])
            this.skillData[skill_id]['employee_proficiency'] = response['data']
          else
            this.skillData[skill_id] = { employee_proficiency: response['data'] }
         // console.log(this.skillData);
         // console.log(response);
        }
      );
  }
  setSunSbu(sub_sbu_id) {
    this.sub_sbu_id = sub_sbu_id;
  }
  getExperience(type, months) {
    if (months)
      return type == 'year' ? Math.floor(months / 12) : months % 12;
    else
      return '';
  }
  saveSkilSet() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    var data = { "band_name": jsonObj.band_name, "employee_id": jsonObj.id, "data": Object.values(this.skill_set_add), "updated_data": this.skill_set_edit };
    console.log(data);
    this.skill_service.saveCompetencyMapping(data)
      .subscribe(
        response => {
          this.push_notification_service.emitEventOnEmployeeSkillSubmit({ "show_employee_id": jsonObj.manager, "first_name": jsonObj.first_name });
          this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
            this.router.navigate(["employee_skill"]);
          });
        }
      );
  }
  openPopup(): void {
    const dialogRef = this.dialog.open(PopupModalComponent, { closeOnNavigation: true, disableClose: true });
    // console.log(this.skill_set_add);
    // console.log(this.skill_set_edit);
    dialogRef.afterClosed().subscribe(result => {
      if (result == "yes") {
        this.saveSkilSet();
      }
    });
  }
  openErrorPopup(): void {
    const dialogRef = this.dialog.open(WarningPopupModal, { closeOnNavigation: true, disableClose: true });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result == "yes") {
        
      }
    });
  }
openSavedMsgBar(){
  this.snackBar.open('Your skill details saved successfully.', '', {
    duration: 4000,
  });
}
  check_disable_submit() {
    if ((Object.keys(this.skill_set_add).length || Object.keys(this.skill_set_edit).length) && Object.keys(this.skill_list_error).length == 0)
      return false;
    else
      return true;
  }

  check_disable() {
    if ((Object.keys(this.updated_skill_list).length && Object.keys(this.updated_skill_list_error).length == 0) || this.overall_competecny_list.size)
      return false;
    else
      return true;
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }
  change_tab(event) {
    this.help_text = this.help_text_array[event.tab.textLabel];
    if (event.tab.textLabel == "View Skill") {
      this.hide_submit_btn = true
    } else {
      this.hide_submit_btn = false;
    }
  }
  getSkills(compentency_id) {
    this.show_skill = true;
    this.skill_service.getSkill(compentency_id, [])
      .subscribe(
        response => {
          console.log(response)
          this.skills = response
        }
      );
  }
  changeType() {
    this.editDetail = !this.editDetail;
    this.button_type = this.button_type == 'View' ? 'Add / Edit' : 'View';
  }
  openEmployeeSkill(employee_id) {
    this.dialog.open(EmployeeViewPopupComponent, { closeOnNavigation: true, width: '90vw', height: '80vh', maxWidth: '90vw', maxHeight: '80vh', autoFocus: false, data: employee_id, hasBackdrop: true,disableClose: true });
  }
  applyFilter(value){
    console.log(value)
  }
}


export interface skillObject {
  [key: string]: skillObjectInner
}
export interface skillObjectInner {
  [key: string]: any
}
