import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";


@Component({
  selector: 'app-eee-quiz-result',
  templateUrl: './eee-quiz-result.component.html',
  styleUrls: ['./eee-quiz-result.component.css']
})
export class EeeQuizResultComponent implements OnInit {
  quiz_score:any;
  total_score:any;
  name:string;
  count:number;
  total_length:number;
  movies:[];
  skill_count:string;
  candidate_password:any;
    constructor(private route:ActivatedRoute,private router:Router,private quizservice:QuizService,private skill_service: EmployeeSkillMappingService) { }
  
    ngOnInit() {
      this.name=localStorage.getItem('name');
      this.candidate_password=localStorage.getItem('password');
      
      this.quizservice.quiz_result(this.name,this.candidate_password).subscribe(
        (data: any) => {
          console.log('has',data.count)
          console.log(Object.values(data));
          if(data.count!=0)
          {
            
            this.quiz_score=data.count[0].count;
            if(this.quiz_score=='')
            {
              this.quiz_score=0
            }
            console.log('has',this.quiz_score)
            var totalAmount = 0;
            this.quizservice.getSkill(this.name,this.candidate_password).subscribe(
              (data: any) => {
                
               // console.log('you have height',data)
                this.movies=data
                this.skill_count =data.length;
                if(this.skill_count=="2")
                {
                  
                    this.total_length=30+30;
                  this.total_score= Math.round((parseInt(this.quiz_score)/this.total_length)*100)
                  
                }
                else{
                  if(this.skill_count=="1")
                  {
                    this.total_length=60;
                    console.log(this.quiz_score,this.total_length)
                    this.total_score= Math.round((parseInt(this.quiz_score)/this.total_length)*100)
                    console.log(this.total_score)
                  }
                  if(this.skill_count=="3")
                  {
                    this.total_length=30+30+30;
                    this.total_score= Math.round((parseInt(this.quiz_score)/this.total_length)*100)
                  }
                  if(this.skill_count=="4")
                  {
                    this.total_length=30+30+30+30;
                    this.total_score= Math.round((parseInt(this.quiz_score)/this.total_length)*100)
                  }
                  if(this.skill_count=="5")
                  {
                    this.total_length=30+30+30+30+30;
                    this.total_score= Math.round((parseInt(this.quiz_score)/this.total_length)*100)
                  }
           
                  
                 
            
                }
                 
                
              })

          }
          else if(data.count==0)
          {
            this.quiz_score=0;
            this.total_score=0;
            console.log('has',this.quiz_score)
            let totalAmount = 0;
          }
          
          
         
         
       });

       setTimeout(() => {
        this.router.navigate(['/homepage']);
       }, 5000)
    
    }
    save_button()
    {
      this.router.navigate(['/quiz_result'], {});
    }
    send_mail(count)
    {
      //alert(count);
      var value=count;
      this.skill_service.quiz_sendmail(this.name,this.candidate_password,value).subscribe(
        (data: any) => {
          console.log('hasasss',data)
          
        });
  
    }
  
  }
  