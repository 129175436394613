import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiBase:string = 'http://10.21.1.133:5000';
  constructor(private http:HttpClient ) { }
  rfGetdata(): Observable<any[]>
  {
    return this.http.get<any[]>(this.apiBase)

  }
  upload(data)
  {
    return this.http.post<any>(this.apiBase,data);

  }
}
