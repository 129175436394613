import { Component, OnInit } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
@Component({
  selector: 'app-analytics-quiz-data',
  templateUrl: './analytics-quiz-data.component.html',
  styleUrls: ['./analytics-quiz-data.component.css']
})
export class AnalyticsQuizDataComponent implements OnInit {
  practice:any;
  sub_practice_list = [];
  rrf_list = [];
  candidate_list = [];
  candidates=[];
  rrf_role: string;
  skill_list = [];
  collection:any;
  skill_values: string;
  rrf_role_cutoff: number;
  skill_1_cut_off: number;
  skill_2_cut_off: number;
  skill_3_cut_off: number;
  sub_practice:any;
  rrf_quizcandidate_list=[];
  rrf_graphCandidate_results:boolean;
  total_candidates:any;
  cleared_candidates:any;
  cleared_candidates_percent:any;
  role_name:any;
  rrf_code:any;
  mapping_detail:any;
  Object = Object;
  minimum_fitment_score:any;
  rrf_role_list=[];
  rrf_role_value:any;
  rrf_values=[];
  skill_name:any;
  constructor(private quizService: QuizService,private skill_service: EmployeeSkillMappingService) { }

  ngOnInit() {
    this.quizService.get_dashboarddeat().subscribe((data: any) => {
      //console.log(data)
      this.collection = data.data;
      this.rrf_code='';
    })
    this.skill_name='Autosar';
  }

  get_details(value) {
    this.quizService.getsubpractice(value).subscribe((data: any) => {

      this.sub_practice_list = data.data[0]
      console.log(this.sub_practice_list)
    })

  }

  get_rrf_code(rrf_role)
  {
    this.rrf_values=[];
    this.rrf_list.forEach(group => {
      if(group.role==rrf_role)
      this.rrf_values.push({
         name: group.rrf_code
       });
 
     })
  //console.log('rrf_valuees',this.rrf_values)
  }

  Options_pratice_change(sub_practice, practice) {
  
    this.rrf_role_list=[];
    this.rrf_list=[]
    this.rrf_values=[]
    this.quizService.get_rrf_code_quiz(sub_practice, practice).subscribe((data: any) => {
     
      data.rrf_code_list.forEach(group => {
        if(group.role!="FALSE")
        {
          this.rrf_role_list.push({
            name: group.role
          });
        }
        
   
       })
       this.rrf_role_list=this.rrf_role_list.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
      
      this.rrf_list = data.rrf_code_list;


    })
   

  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
   // this.calculateFitment(event);
  }
  getcandidate_graph(rrf_code, practice, sub_practice) {
   
    this.rrf_quizcandidate_list=[];
    this.rrf_graphCandidate_results = true;
    this.quizService.get_quiz_candidate_graph(rrf_code, practice, sub_practice).subscribe((data: any) => {
      this.rrf_quizcandidate_list = data.result;
      var totalquestions = 60;
      var passpercetange = 24;
      for (let i = 0; i < this.rrf_quizcandidate_list.length; i++) {

        //this.quiz_bar_chart.push({ label: this.rrf_quizcandidate_list[i].name, value: this.rrf_quizcandidate_list[i].count });
        var index = 0;
        if (this.rrf_quizcandidate_list[i].count >= passpercetange) {
          index = 0;
        } else {
          index = 1;
        }
        
      }
      })

  }

  get_table_details(rrf_master_id) {
    this.rrf_graphCandidate_results=true
    this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((data: any) => {
      //console.log(data)
    //  candidates=[];
      this.candidates=data;
      var candidate_count=0;
      this.candidate_list=[];
      

      this.rrf_list.forEach(obj => {
       
        if(obj.rrf_code==rrf_master_id){
          this.rrf_code=obj.rrf_code;
          if(obj.role!='FALSE'){
            this.role_name=obj.role;
          }else{
            this.role_name="-";
          }
          if(obj.minimum_fitment_score!='' && obj.minimum_fitment_score!='FALSE'){
            this.minimum_fitment_score=obj.minimum_fitment_score;
          }else{
            this.minimum_fitment_score=0;
          }
          
          var jsonObj = JSON.parse(localStorage.currentUser);
          this.mapping_detail=[];
        this.skill_service.getJobDetail(obj.job_code, jsonObj.id, '')
        .subscribe(
          response => {
            this.mapping_detail = response["data"]["job_detail"];

            this.mapping_detail.forEach(obj2 => {
              
            if(obj2.skill_name=='Autosar' || obj2.skill_name=='V&V'){
              this.skill_name=obj2.skill_name;
              
            }else{
              this.skill_name='Autosar';
            }
             
            })
          }
        );
        }
    });
    
    
    this.candidates.forEach(obj1 => {
       
      var percent=(obj1.count/Number(60));
      percent=percent*100;
      obj1.percent=Math.round(percent);
      if(percent>=Number(this.minimum_fitment_score)){
        candidate_count=candidate_count+1;
      }

      this.candidate_list.push(obj1);
  });
      // this.collection=data.data;
      // var average_graph = []
      // data.forEach(element => {
      //   var index = average_graph.findIndex(x => {
      //     return x.label == element.skill_name[0]['_id']
      //   }); 
      //   if (index >= 0) {
      //     average_graph[index]['value'] += element['count'];
      //   } else {
      //     average_graph.push({ label: element.skill_name[0]['_id'], value: element['count'] });
      //   }
      // });
      var total_candidates=data.length;
      this.total_candidates=total_candidates;
      
      this.cleared_candidates=candidate_count;
     // console.log(this.cleared_candidates)
      if(Number(this.cleared_candidates)!=0){
        //console.log("Inside")
        var candidate_percent=Number(this.cleared_candidates)/Number(this.total_candidates)
        candidate_percent=Math.round(candidate_percent*100);
  
        this.cleared_candidates_percent=candidate_percent;
      }else{
       // console.log("Inside0")
        this.cleared_candidates_percent=0;
      }
      
    
    })
    
  }

calculateFitment(event){
  var value = event.target.value;
  this.minimum_fitment_score=value;
  var candidate_count=0;

    this.candidates.forEach(obj1 => {
      var percent=(obj1.count/Number(60));
      percent=percent*100;
      obj1.percent=Math.round(percent);
      if(percent >= Number(this.minimum_fitment_score)){
        candidate_count=candidate_count+1;
      }

    });

    this.cleared_candidates=candidate_count;
    if(Number(this.cleared_candidates)!=0){
      var candidate_percent=Number(this.cleared_candidates)/Number(this.total_candidates)
      candidate_percent=Math.round(candidate_percent*100);

      this.cleared_candidates_percent=candidate_percent;
    }else{
      this.cleared_candidates_percent=0;
    }
  }

}
