import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmployeeSkillMappingService } from '../services';
import { QuizService } from '../services/quiz.service';

@Component({
  selector: 'app-file-upload-login',
  templateUrl: './file-upload-login.component.html',
  styleUrls: ['./file-upload-login.component.css']
})
export class FileUploadLoginComponent implements OnInit {

  // start_login: Boolean;
  email:any;
  pass_word: any;
  

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private quizService : EmployeeSkillMappingService,private router : Router,private dialog: MatDialog) { }

  ngOnInit() {
    // this.start_login  = false;
   
  }
  

  register_login(templateRef : TemplateRef<any>)
  {
    // alert(this.email);
    console.log(this.email, this.pass_word)
    var temp = {
      username : this.email,
      password: this.pass_word
    }
    this.quizService.fileUploadLogin(temp).subscribe((data: any) => 
    {
      console.log('has',data.id, data)
      if(data.result == true)
      {
        this.router.navigate(['/candidate_file_upload/'+data.id]);
      }
      else{
        this.dialog.open(templateRef);
      }
    })
  }
}
