import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-rrf-report',
  templateUrl: './rrf-report.component.html',
  styleUrls: ['./rrf-report.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})
export class RrfReportComponent implements OnInit {
  rrf_list_data: any;
  form_data: any;
  expandedElement = null;
  employee_data: any;
  view_type = "employee";
  minDate = new Date('2017-08-01');
  filtered_employee_list: any = {};
  Object = Object;
  sub_practices: any = [];
  practices: any = [];
  units: any = [];
 // roles:any = [];
  status_list: any = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected','Internal Fulfillment Approved', 'Internal Fulfillment Rejected','Recruiter Accepted', 'Closed'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['rrf_id', 'requested_by', 'unit', 'role', 'no_position', 'submitted_on', 'status'];
  constructor(private router: Router, private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, public redirect: RedirectService) {
  }
  ngOnInit() {
    this.form_data = new FormGroup({
      unit: new FormControl(""),
      practice: new FormControl(""),
      sub_practice: new FormControl(""),
      requested_by: new FormControl(""),
      from_date: new FormControl(""),
      to_date: new FormControl(""),
      status: new FormControl(""),
     // roles: new FormControl("")
    });
    this.form_data.controls.unit.valueChanges.subscribe(value => {
      this.skill_service.getUnit(value)
        .subscribe(
          response => {
            this.form_data.controls["practice"].setValue(null);
            this.form_data.controls["sub_practice"].setValue(null);
            this.sub_practices = [];
            this.practices = response;
          }
        )
    });
    this.form_data.controls.practice.valueChanges.subscribe(value => {
      this.skill_service.getUnit(value)
        .subscribe(
          response => {
            this.form_data.controls["sub_practice"].setValue(null);
            this.sub_practices = response
          }
        );
    });
    // this.form_data.controls.sub_practice.valueChanges.subscribe(value => {
    //   var jsonObj = JSON.parse(localStorage.currentUser);
    //   this.skill_service.getRoleswithUnitPractice(this.form_data.controls["unit"].value,jsonObj.id,this.form_data.controls["practice"].value,value)
    //     .subscribe(
    //       response => {
    //  this.roles = response["data"]["role_list"];
    //       }
    //     )
     
    // })
    this.form_data.controls.from_date.valueChanges.subscribe(value => {
      this.form_data.controls["to_date"].setValue(null);
    });
    this.skill_service.getUnit("0")
      .subscribe(
        response => {
          this.units = response
        }
      );
    this.onSubmit();
  }
  update_employee_list(event) {
    var value = event.target.value;
    const filterValue = value.toLowerCase();
    if (filterValue) {
      this.skill_service.getFilteredEmployeeList(filterValue)
        .subscribe(
          response => {
            this.filtered_employee_list = response["data"];
          }
        );
    } else {
      this.filtered_employee_list = {};
    }
  }
  onSubmit() {
    this.form_data.value["export"] = false;
    this.skill_service.getRRFReportList(this.form_data.value)
      .subscribe(
        response => {
         // console.log("Inside")
          this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
        }
      );
  }
  onExport() {
    this.form_data.value["export"] = true;
    this.skill_service.getRRFReportList(this.form_data.value)
      .subscribe(
        response => {
          FileSaver.saveAs(response, 'rrf_report.xlsx');
        }
      );
  }
  onskillExport() {
    this.form_data.value["export"] = true;
    this.skill_service.getoverallskillmatrixExport()
      .subscribe(
        response => {
          FileSaver.saveAs(response, 'rrf_report.xlsx');
        }
      );
  }
  
  redirectPage(page_link, rrf_id) {
    this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  redirectToViewPage(rrf_id){
    var jsonObj = JSON.parse(localStorage.currentUser);

  //   if ( this.view_type == "rmg" && jsonObj.id!=5629)
  //   this.redirect.change_page_with_data("/rrf_approve", { rrf_id, view_type: this.view_type });
  // else
    this.redirect.change_page_with_data("/rrf_view", { rrf_id });

  }

  fitmentReport(){
    this.router.navigate(['/fitment_analytics']);
  }
  resumeSearch(){
    this.router.navigate(['/resume_builder']);
  }

  skillSearch(){
    this.router.navigate(['/employee_search']);
  }

  analyticsReport(){
    this.router.navigate(['/analytics-quiz']);
  }

  analyticsReportData(){
    this.router.navigate(['/analytics-quiz-data']);
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }
  displayFn(employee): string | undefined {
    return employee ? employee.employee_name : undefined;
  }
}


