import { Component, OnInit, Input, ViewChild, Output, TemplateRef } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { InterviewScheduleComponent } from '../interview-schedule/interview-schedule.component'
@Component({
  selector: 'app-internal-add-employee',
  templateUrl: './internal-add-employee.component.html',
  styleUrls: ['./internal-add-employee.component.css']
})
export class InternalAddEmployeeComponent implements OnInit {
  rrf_detail: any;
  @Output() rrf_id: any;
  roles: any;
  units: any;
  rrf_master_id: any
  sub_practices: any;
  searchName: any;
  showboolean: any
  practices: any;
  mapping_data: any;
  mapping_data_array: any;
  jsonObj: any;
  employee_data: any = [];
  employee_competency_array: any = [];
  select_emp_array: any = [];
  interview_panel: any = [];
  schedule_data: any = [];
  passcode_array: any = [];
  index = 0;
  // filtered_role_list: any = [];
  // Object = Object;
  formdata: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // displayedColumns: string[] = ['select', 'employee', 'unit', 'band', 'practice', 'sub_practice', 'created_on', 'role', 'fitment_score'];
  displayedColumns: string[] = ['select', 'employee', 'Email']
  employeeDisplayedColumns: string[] = ['employee', 'passcode', 'action']
  displayedColumnsData: string[] = ['schedule_time'];
  total_count: any;
  constructor(private router: Router, private location: Location, public dialog: MatDialog, private skill_service: EmployeeSkillMappingService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.formdata = new FormGroup({
      interview_schedule: new FormArray([]),
      // name: ['', Validators.required],
    });

    if (this.route.snapshot.params.rrf_id) {
      this.rrf_id = this.route.snapshot.params.rrf_id;
      console.log("this.rrf_id", this.rrf_id);
      this.skill_service.getInternalEmpRRFDetail(this.rrf_id)
        .subscribe(
          response => {
            console.log("response", response);
            this.rrf_detail = response["data"]["rrf_detail"]["rrf_detail"];

          });
      this.skill_service.getInternalEmployeeDetail(this.rrf_id)
        .subscribe(
          response => {
            console.log("response", response);
            this.select_emp_array = response['data']
          });
    }
    this.get_employee_list()
  }

  // openInterviewSchedule(status) {
  //   console.log("schedule_data", this.select_emp_array);
  //   const dialogRef = this.dialog.open(InterviewScheduleComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: { employee_detail: this.select_emp_array, status }, hasBackdrop: true, disableClose: true });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       // this.candidate_detail = new MatTableDataSource(result.reverse())
  //       // setTimeout(() => {
  //       //   this.candidate_detail.paginator = this.candidate_paginator;
  //       // });
  //     }
  //   });
  // }
  get_employee_list() {
    // this.skill_service.getInternalEmployeeFitmentList()
    //   .subscribe(
    //     response => {
    //       this.employee_data = response["data"]["employee_data"];
    //       console.log("adasd",this.employee_data)
    //       this.employee_competency_array = response["data"]["employee_competency_list"]
    //       console.log("asdsasdasd",this.employee_competency_array)
    //       console.log("this.employee_data", response["data"])
    //       var mapping_data_test = []
    //       if (this.employee_competency_array && this.employee_data) {
    //         console.log("dsfsdfsdfsdf")
    //         this.employee_data.forEach(function (emp_details) {
    //           response["data"]["employee_competency_list"].forEach(function (emp_comp_details) {
    //             if (emp_comp_details.employee_id == emp_details.emp_details.id) {
    //               var obj = {}
    //               obj['band_name'] = emp_details.emp_details.band_name;
    //               obj['mail_id'] = emp_details.emp_details.work_email_address;
    //               obj['employee_name'] = emp_details.emp_details.employee_name;
    //               obj['practice'] = emp_details.emp_details.practice;
    //               obj['sub_practice'] = emp_details.emp_details.sub_practice;
    //               obj['unit'] = emp_details.emp_details.unit;
    //               obj['fitment_score'] = emp_comp_details.fitment_score;
    //               obj['created_on'] = emp_comp_details.created_on;
    //               obj['current_role'] = emp_comp_details.current_role;
    //               obj['checked'] = false;
    //               mapping_data_test.push(obj)
    //             }
    //           })

    //         })
    //       }
    //       this.mapping_data = new MatTableDataSource(mapping_data_test);
    //       this.mapping_data_array = mapping_data_test
    //       console.log("this.mapping_data", this.mapping_data)
    //       this.mapping_data.paginator = this.paginator;
    //       this.total_count = mapping_data_test.length;
    //     }
    //   );

      this.skill_service.getAllEmployeeDetails()
      .subscribe(
        response => {
          console.log("data",response['data']['employees'])
          var mapping_data_test = []
          mapping_data_test = response['data']['employees']
          this.mapping_data = new MatTableDataSource(mapping_data_test);
                 this.mapping_data_array = mapping_data_test
                console.log("this.mapping_data", this.mapping_data)
                this.mapping_data.paginator = this.paginator;
                this.total_count = mapping_data_test.length;
          //console.log(response["data"]["managers"])
        });
  }

  applyFilter(filterValue: string) {
    console.log("filterValue", filterValue);
    if (filterValue != '') {
      this.showboolean = true
      this.mapping_data.filter = filterValue.trim().toLowerCase();
    } else {
      this.showboolean = false
    }
  }

  addEmployee(candidate_details) {
    console.log("asdasda",candidate_details);
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    if(candidate_details.gender == 'm'){
      var emp_gender = "Male"
    }else{
      var emp_gender = "Female"
    }
    this.skill_service.getInternalEmployeeDetail(rrf_master_id)
      .subscribe(
        response => {
          this.passcode_array = [];
          console.log("response", response);
          this.select_emp_array = response['data']
         var emp_details = this.select_emp_array.filter(data => candidate_details.itemName == data.candidate_name );
        console.log("emp_details",emp_details,emp_details.length)
         if(emp_details.length != 0){
           console.log("ifcondition")
             if (confirm('Employee already added ')) {
                this.searchName = '';
                this.showboolean = false
                this.get_employee_list()
              } else {
                this.searchName = '';
                this.showboolean = false
                this.get_employee_list()
              }
         }else{
           console.log("elsecondition")
           this.jsonObj = JSON.parse(localStorage.currentUser);
               var uploaded_by = this.jsonObj.id
                var obj = {}
                var emp_array = []
                obj['candidate_name'] = candidate_details.itemName;
                obj['candidate_email'] = candidate_details.Email_id;
                obj['candidate_phone_number'] = '9876543210';
                obj['candidate_current_company'] = 'Hinduja Tech';
                obj['candidate_skill_set'] = 'MEAN STACK';
                obj['candidate_total_years_of_experience'] = '3';
                obj['candidate_relevant_years_of_experience'] = '3';
                obj['candidate_current_ctc'] = '30k';
                obj['candidate_expected_ctc'] = '30k';
                obj['candidate_notice_period'] = '15 days';
                obj['candidate_education'] = 'BE';
                obj['candidate_current_location'] = 'Chennai';
                obj['candidate_preferred_location'] = 'Chennai';
                obj['resume'] = 'uploaded_resume/1619765125575.doc';
                obj['rrf_master_id'] = rrf_master_id;
                obj['candidate_gender'] = 'MALE';
                obj['uploaded_by'] = uploaded_by;
                obj['uploaded_on'] = new Date();
                obj['status'] = 'Uploaded';
                console.log("obj", obj);
                emp_array.push(obj)
                console.log("emp_array", emp_array);
                if (emp_array) {
                  this.skill_service.uploadInternalCandidate(emp_array)
                    .subscribe(
                      response => {
                        console.log("response", response);
                        if (response["data"] == true) {
                          this.showboolean = false;
                          this.searchName = '';
                          console.log("rrf_master_id", rrf_master_id);
                          this.skill_service.getInternalEmployeeDetail(rrf_master_id)
                            .subscribe(
                              response => {
                                console.log("response", response);
                                this.select_emp_array = response['data']
                              });
                        }
                      });
                }
              }
              this.get_employee_list()
        })
  }

  GeneratePasscode() {
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    this.skill_service.getInternalEmployeeDetail(rrf_master_id)
      .subscribe(
        response => {
          this.passcode_array = [];
          console.log("response", response);
          this.select_emp_array = response['data']
          if (this.select_emp_array.length != 0) {
            this.select_emp_array.forEach(data => {
              if (data.candidate_password) {
                console.log("data", data)
              } else
                this.passcode_array.push(data)

            })
            console.log("this.passcode_array", this.passcode_array);
            console.log("rrf_master_id", rrf_master_id);

            this.skill_service.generatePasscode(rrf_master_id, this.passcode_array)
              .subscribe(
                response => {
                  if (response['data'] == true) {
                    this.skill_service.getInternalEmployeeDetail(rrf_master_id)
                      .subscribe(
                        response => {
                          this.select_emp_array = response['data']
                        });
                  }
                })
          }
        });
  }

  cmd_button(templateRef: TemplateRef<any>, id, cmd) {
    this.dialog.open(templateRef);
  }

  go_back() {
    this.location.back();
  }

  removeEmployee(emp_id) {
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    console.log("emp_id", emp_id);
    this.skill_service.deleteInternalEmployee(emp_id)
      .subscribe(
        response => {
          console.log("response", response);
          this.skill_service.getInternalEmployeeDetail(rrf_master_id)
            .subscribe(
              response => {
                this.select_emp_array = response['data']
              });
        });
  }

}
