import { Component, OnInit,ViewChild,TemplateRef, } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Observable, Subscription } from 'rxjs/Rx';
import 'rxjs/add/observable/interval';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {PaginationInstance} from "ngx-pagination";
import {TimerObservable} from "rxjs/observable/TimerObservable";



import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import{ Quizcollection} from '../quizcollection'



@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
  public config: PaginationInstance = {
      id: 'custom',
      itemsPerPage: 1,
      currentPage: 1,
      collection:[]
  };
  
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  collection:Quizcollection[] = [];
  date:Date;
  date_set:any;
  closeResult = '';
  sub: Subscription;
  countDown;
  count;
  count_status;
  count_sec;
  progress =18;
  param;
  param_result;
  total_count;
  submitanswer:Boolean=false;
  selectedIndex;
  isFavorite: Boolean = false;
  bodyClasses:string = "current";
  candidate_role:string;
  candidate_name:string;
  developer_option:any[];
  dropdowncount: number = 0;
  public quizForm: FormGroup ;
  movies:any=[];
  question_answer:number;
  unaswered_questions: number[] = [];

  data_total_set:any;
  quiz_count:number=0;
  skill_count:string;
  quiz_skill_data: Boolean = false;
  page_value:string;
  option_value:string;
  quiz_optionhide: Boolean = false;
  quiz_optionanswer:any=[];
  option_name:string
  initial_optionname:string;
  movies_drpdown:Boolean=false
  change_dropset:string;
  start_quiztime
  candidate_password:any;
  assessmentTiming: Quizcollection[];

  
  
  constructor(private route: ActivatedRoute,private router: Router, public quizService: QuizService,private fb: FormBuilder,private modalService: NgbModal) {
    
    this.route.queryParamMap
    .map(params => params.get('page'))
    .subscribe(//page =>
      
      // this.config.currentPage = page
       




       );


   }
   progressBar = document.querySelector('.progress-bar');
  intervalId;
  
  ngOnInit() {
   // this.candidate_role=localStorage.getItem('role');
   //alert(this.config.currentPage);
   this.candidate_name=localStorage.getItem('name');
   this.candidate_password=localStorage.getItem('password');
   this.router.navigate(['/quiz'], { queryParams: { page: this.config.currentPage } });
   
 
    this.quizForm = this.fb.group({
      selected: [{value: ''}, [Validators.required]]
    });
   // this.myTimer();
  
      this.quizService.getQuestions(this.candidate_name,this.candidate_password).subscribe(
        (data: any) => {
        this.collection = data.data;
        this.assessmentTiming = data.assessment.time;
        this.config.collection = this.collection;
        this.config.collection[0].className = "Visited";
 
          //this.movies=data.skills;
          console.log(this.collection)
          this.candidate_role=data.role.role;
          
          this.data_total_set=this.collection.length;
         
           this.date = new Date();
          let hours =this.date.getHours();
          let minutes = this.date.getMinutes();
          let seconds= this.date.getSeconds();
          this.date_set= hours + ":"+ minutes + ":" + seconds
          this.quiztimer();
        }
      );
      this.quizService.getSkill(this.candidate_name ,this.candidate_password).subscribe(
        (data: any) => {
          
         // console.log('you have height',data)
          this.movies=data
          console.log('you have height',this.movies[0].skill_name)
          this.option_name=this.movies[0].skill_name;
          this.initial_optionname=this.option_name

          this.skill_count =data.length;
          if(this.skill_count=="2")
          {
            //this.option_name="MATLAB";
            // this.movies =[  
            //   {skill_name:'MATLAB'},  
            //   {skill_name:'Embedded C/C++'},  
              
            // ] 
      
          }
          else{
            this.quizService.getQuestions(this.candidate_name,this.candidate_password).subscribe(
              (data: any) => {
                this.movies=data.skills;
                this.option_name=this.movies[0].skill_name
                

              });
           
      
          }
           
          
        })
       
      

      
      

    
   
  }
  
  pageChange(newPage: number,config) {
    
    if(config.collection[newPage-1].className != "Answered")
    {
      config.collection[newPage-1].className = "Visited";
    }
  
    config.currentPage = newPage;
    
    this.router.navigate(['/quiz'], { queryParams: { page: newPage } });
    this.param=newPage;
    
    
    if(this.param==this.data_total_set)
    {
     // alert(this.data_total_set)
      this.submitanswer =true
    }
    else{
      this.submitanswer =false
    }
    if(config.collection[newPage-1].className!='Visited' && config.collection[newPage-1].className!='Unattended')
    {
      //alert(config.collection[newPage-1].questionName)
        this.quizService.getpageoption(this.config.currentPage,this.candidate_name,this.candidate_password,config.collection[newPage-1].questionName,config.collection[newPage-1].topicId).subscribe(
        (data: any)=>
        {
          console.log('your have been software',data)
          if(data.data!=0)
          {
            this.quiz_optionhide = true;
            this.quiz_optionanswer=data.data;
          
          }
          else
          {
            this.quiz_optionhide = false;
            this.quiz_optionanswer=data.count;
  
          }
      });

    }
    else
    {
      this.quiz_optionhide = false;
    }


    
	}
  
  
  quiztimer() {
    this.progress = this.progress - 1;
    this.count = 60;
    this.start_quiztime=this.assessmentTiming
    this.countDown = Observable.timer(0, 1000)
      .subscribe(x => {
        this.count = this.count - 1;
      });

    this.sub = Observable.interval(500)
      .subscribe(x => {
        console.log(this.count);
        if (this.count === 0) {
          //this.countDown.unsubscribe();
          this.count=60
          this.start_quiztime=this.start_quiztime -1;
          
          if(this.start_quiztime === 0)
          {
            this.router.navigate(['/quiz_result'], {});
          }
        }
      });
  }
  
  timer_restart()
  {
    //alert('has')
   // this.count_status.unsubscribe();
  this.countDown.unsubscribe();
  this.dropdown_quiztimer();

    

  }
  dropdown_quiztimer() {
    this.progress = this.progress - 1;
    this.count = 60;
    this.start_quiztime=this.assessmentTiming
    this.countDown = Observable.timer(0, 1000)
      .subscribe(x => {
        this.count = this.count - 1;
      });

    this.sub = Observable.interval(500)
      .subscribe(x => {
        //console.log(this.count);
        if (this.count === 0) {
          //this.countDown.unsubscribe();
          this.count=60
          this.start_quiztime=this.start_quiztime -1;
          
          if(this.start_quiztime === 0)
          {
            this.router.navigate(['/quiz_result'], {});
          }
        }
      });
  }
  
  // myTimer() {
    
  //   this.count = 18;
  //   this.countDown = Observable.timer(0, 60000)
  //     .subscribe(x => {
  //       const getDownloadProgress = () => {
  //       if (this.progress <= 18) {
  //           console.log('inside if', this.progress);
  //           //alert(this.count)
  //           if(this.count==0)
  //           {
  //             alert('has data have benn saved')
  //             this.router.navigate(['/quiz_result'], {});

  //           }
  //         }
  //         else {

  //           clearInterval(this.intervalId);
  //         }
  //       }
  //       this.intervalId = setInterval(getDownloadProgress, 60000);
  //     });
  //     this.sub = Observable.interval(500)
  //     .subscribe(x => {
  //       console.log(this.count);
  //       if (this.count === 0) {
  //         this.countDown.unsubscribe();
  //       }
  //     });
  // }
  

  Answer(qID, choice) {
    this.quizService.qns[1][this.quizService.qnProgress].userAnswer = choice;
    //console.log(' this.quizService.answer[choice])', this.quizService.answer[choice])
    //console.log(' this.quizService.qns[1][this.quizService.qnProgress].answer[]', this.quizService.qns[1][this.quizService.qnProgress].answer['0'])
    if(this.quizService.qns[1][this.quizService.qnProgress].answer['0'] == this.quizService.answer[choice]){
      this.quizService.correctAnswerCount++
    }
    localStorage.setItem('qns', JSON.stringify(this.quizService.qns));
    this.quizService.qnProgress++;
    localStorage.setItem('qnProgress', this.quizService.qnProgress.toString());
    if(this.quizService.qnProgress == 9){
      this.quizService.button = 'Complete'
    }
    if (this.quizService.qnProgress == 10) {
      clearInterval(this.quizService.timer);
      this.router.navigate(['/result']);
    }
  }
  Previous(qID, choice) {
    this.quizService.qns[1][this.quizService.qnProgress].userAnswer = choice;
    const savedData = JSON.parse(localStorage.getItem('qns'));
    this.quizService.qnProgress--;
    this.quizService.qns[1][this.quizService.qnProgress] = savedData[1][this.quizService.qnProgress];
    if(this.quizService.qns[1][this.quizService.qnProgress].answer['0'] == this.quizService.answer[choice]){
      this.quizService.correctAnswerCount--
    }
    if (this.quizService.qnProgress == 10) {
      clearInterval(this.quizService.timer);
      this.router.navigate(['/result']);
    }
  }
  onAnswerChange(value){
    this.quizService.userAnswer = value;
 }
 save_button()
  {
    
    let last:any = this.movies[this.movies.length-1];
    if(this.movies.length!=2)
    {
      this.router.navigate(['/quiz_result'], {});
    }
    else{
      
      if(this.quiz_count!=2)
      {
        this.quiz_skill_data =true;

      }
      if(last.skill_name==this.option_name)
      {
        
        this.router.navigate(['/quiz_result'], {});
      }
      if(this.quiz_count==this.movies.length)
      {
        //alert(this.movies.length)
        this.router.navigate(['/quiz_result'], {});

      }
  }
}
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


dropdown_change(values)
{
  this.quiz_count++;
  this.submitanswer =false;
  this.quiz_skill_data = false;
  
  this.quizService.valueChange(values,this.candidate_name,this.candidate_password).subscribe(data=>
  {
    this.quiz_optionhide = false;
    this.config.currentPage=1
    this.option_name=values;
    this.collection =data.data;
    this.config.collection=this.collection;
    this.config.collection[0].className = "Visited";
    this.data_total_set=this.config.collection.length;
    this.change_dropset=this.data_total_set
    this.timer_restart();

  });
    
  }
  onSelect(option: any, questionName, isChecked, item) {
    this.question_answer=parseInt(this.change_dropset) + parseInt(this.data_total_set);
    console.log('hjshdjsa',this.question_answer, item)
    //this.question_answer.push(parseInt(this.config.currentPage));
    item.className = "Answered";
    this.movies_drpdown=true
 
      this.quizService.getAnswers(option,questionName,this.candidate_name,this.candidate_password,this.config.currentPage,this.data_total_set,item.topicId, item.img_path).subscribe(data=>
      {
        console.log('data',data);
        
        
      });
          
    //this.pageChange(this.config.currentPage,item)
   
    let newpage=this.config.currentPage +1;
    this.config.currentPage=newpage
    if(this.config.currentPage==this.data_total_set)
    {
      
      this.submitanswer =true
    }
    else{
      this.submitanswer =false
      let route_extend=this.data_total_set+1;
     // alert(this.config.currentPage)
     
      if(this.config.currentPage==route_extend)
      {
        //alert('has')
        let last:any = this.movies[this.movies.length-1];
        if(this.movies.length!=2)
        {
          this.router.navigate(['/quiz_result'], {});
        }
        else{
          if(this.quiz_count!=2)
          {
            this.quiz_skill_data =true;
    
          }
          if(last.skill_name==this.option_name)
          {
            
            this.router.navigate(['/quiz_result'], {});
          }
      }
       // this.router.navigate(['/quiz_result'], {});
      }
      else
      {
        //alert('gsas')
        this.router.navigate(['/quiz'], { queryParams: { page: this.config.currentPage } });
 
        

      }
      
    }
    
    
}
showanswer(item,data)
  {
    
    this.quizService.getpageoption(this.config.currentPage,this.candidate_name,this.candidate_password,this.config.collection[this.config.currentPage-1].questionName
      ,this.config.collection[this.config.currentPage-1].topicId).subscribe(
      (data: any)=>
      {
        console.log('answer',data)
        if(data.data!=0)
        {
          this.quiz_optionhide = true;
          this.quiz_optionanswer=data.data;
        
        }
        else
        {
          this.quiz_optionhide = false;
          this.quiz_optionanswer=data.count;

        }
    });

    
  }

}
