import { Component, OnInit } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { RedirectService } from "../services/redirect";
import { Router,ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchPipe } from '.././pipe/search.pipe'


@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css'],
  
})
export class JoblistComponent implements OnInit {
  config: any;
  save_jobs:boolean;
  selectedIds = [];
  emp_id:string;
  showsave_jobs:boolean=false;
  job_location_arr = [];
  selected_role:any;
  showCheckbox = false;
  constructor(private route: ActivatedRoute,private quizService : QuizService,private router : Router,private fb: FormBuilder,public redirect: RedirectService) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10};
    this.route.queryParamMap
    .map(params => params.get('page'))
    .subscribe(page =>
    this.config.currentPage = page



       );
   }
  job_list:any=[];
  firstDate :Date;
  job_checked:string;
  job_search:string;
  job_location:string;
  page = 1;
  pageSize =10;
  job_roles = [];

  ngOnInit() {
    this.job_location_arr = ['Chennai', 'Bangalore', 'Pune', 'Koeln'];
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.emp_id=jsonObj.employee_number;
    console.log(jsonObj, this.emp_id);

    
  //  this.emp_name = jsonObj.employee_number+" "+jsonObj.last_name;
  //  this.employee_id=jsonObj.id;
    
    this.quizService.getjoblist(this.emp_id).subscribe(
      (data : any) =>{
        console.log(data)
        if(data!='')
        {
          this.save_jobs=data.res;
          this.job_list=data.data;
          this.showCheckbox=data.res;
        }
    });

    // get job_role list
    this.quizService.get_unsavedjobs().subscribe(
      (data : any) =>{
        var flags = [], output = [], l = data.data.length, i;
        for( i=0; i<l; i++) {
            if( flags[data.data[i].role]) continue;
            flags[data.data[i].role] = true;
            this.job_roles.push(data.data[i].role);
        }
      console.log(l,output,flags, data.data)
      });
  }
  getCheckedValue(checked,value, id)
  {
    console.log(checked);

  //  alert(checked)
    if(checked==true)
    {
    //  alert(value);
    // this.selected_role = id;
    this.quizService.get_checkedjoblist(value).subscribe(data=>{
      console.log(data)
      this.save_jobs=false;
      this.job_list=data;
    })

    }else {
      // this.quizService.getjoblist(this.emp_id).subscribe( 
        // this.showsave_jobs=false;
        // this.getJobList();
        this.quizService.get_unsavedjobs().subscribe(
          (data : any) =>{
          console.log(data)
          if(data!='')
          {
            this.save_jobs=false;
            this.job_list=data.data;
          }
        });

    }
    
  }
  redirectPage(page_link, rrf_id) {
    
  this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  searchfilter(x:string)
  {
   // alert(x);
    this.job_list.filter = x.trim().toLowerCase();
  }
  change_joblocation(checked,value, id)
  {
    if(checked==true)
    {
      // alert(value);
    this.quizService.get_joblocation(value).subscribe(data=>{
      
      this.job_list=data;
    })

  }else {
    // this.quizService.getjoblist(this.emp_id).subscribe(
       //  this.job_list=data;
      this.quizService.get_unsavedjobs().subscribe(
        (data : any) =>{
          console.log(data)
          if(data!='')
          {
            this.save_jobs=false;
            this.job_list=data.data;
          }
        });

    }
    
  }
  saveJobs(values)
  {
    console.log(values)
    if(values===true)
    {
      this.quizService.getjoblist(this.emp_id).subscribe(
        (data : any) =>{
          console.log(data)
          if(data!='')
          {
            // this.save_jobs=data.res;
            this.job_list=data.data;
            this.showCheckbox=true;
          }
        });
      
    }
    else{
      this.showsave_jobs=false;
      this.quizService.get_unsavedjobs().subscribe(
        (data : any) =>{
          console.log(data)
          this.save_jobs=data.res;
         this.job_list=data.data;
         this.showCheckbox=false;

        });
      

    }
    
  }
  rrf_checkbox(event,id)
  {
    // this.emp_id='16790';
    this.showsave_jobs=true;
    
      this.selectedIds.push({id: id, checked: event.target.checked});
      

 // console.log('Selected Ids ', this.selectedIds);
  //this.saveJobs('true',this.selectedIds,this.emp_id)
  }
  updatedsavejobs()
  {
    
    this.quizService.savedJobslist(this.selectedIds,this.emp_id).subscribe(
      (data : any) =>{
        console.log(data)
  
      });


  }
  
}
