import { Component, OnInit } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatDialog } from '@angular/material/dialog';
import { ApplyJobComponent } from "../apply-job/apply-job.component";

@Component({
  selector: 'app-myspace',
  templateUrl: './myspace.component.html',
  styleUrls: ['./myspace.component.css']
})
export class MyspaceComponent implements OnInit {
message:string;
notificationValues: any;
panelOpenState = false;
manager_name:string;
dataSource: Object;
width = 200;
height = 300;
type = "doughnut2d";
dataFormat = "json";
  
  data = [
    
      
        {
          name:'Pending',
          value:20
        },
        {
          name:'Added Skills',
          value:50
        },
        {
          name:'Approved Skills',
          value:10
        },
        {
          name:'Pending Approvals',
          value:40
        }
        
      ];
      single: any[];
  view:any[]  = [700, 400];
  radius:any=  "90%";
   innerRadius:any= "70%";

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  job_list:any=[];
  profile_pic: string;
  show: string = '';
  emp_name:string;
  emp_img:string;
  employee_number:string;
  work_email_address:string;
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  
  chart_list:any=[];
  month_name:string;
  employee_id:any;
  

  constructor(private quizService : QuizService,private router: Router,public redirect: RedirectService,private skill_service: EmployeeSkillMappingService, public dialog: MatDialog) { }

  ngOnInit() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    
   this.emp_name = jsonObj.first_name+" "+jsonObj.last_name;
   this.employee_id=jsonObj.id;
   this.manager_name=jsonObj.manager_nme;
   this.employee_number=jsonObj.employee_number
   this.work_email_address=jsonObj.work_email_address;
   this.emp_img =  jsonObj.first_name.charAt(0)+jsonObj.last_name.charAt(0);
  //  this.quizService.employee_lookup(this.employee_number,this.emp_name,this.work_email_address).subscribe((data : any) =>
  //    {
  //      console.log(data)

  //  })
   this.getmychart_data()

   //console.log(this.emp_img);
    if (jsonObj.employee_photo) {
      this.profile_pic = "assets/employee_pics/" + jsonObj.employee_photo;
    } else {
      this.profile_pic = "assets/employee_pics/profile_picture.jpg";
    }

    // this.quizService.get_unsavedjobs().subscribe(
    //   (data : any) =>{
    //     console.log('gherr',data)
    //    this.job_list=data.data;
    //          });
    this.job_list = [];
  //   this.skill_service.getJobPost().subscribe((data: any) => {
  //   console.log (data.data.internal_job_list, 'IJP list');
  //   this.job_list = data.data.internal_job_list.filter(element => element.status == 'IJP Open');
  //   console.log(this.job_list);

  // }) 
    
   

  }

  // openDialog(job_master_id, job_post): void {
  //   var str=job_post.split(":");
  //   var job_post_id = str[1].replace(/[{()}]/g, '');
  //   job_post_id=job_post_id.trim()
  //   var jsonObj = JSON.parse(localStorage.currentUser);
  //   var employee_id = jsonObj.id
  //   this.skill_service.getJobDetail(job_master_id, employee_id, job_post_id.trim())
  //     .subscribe(
  //       response => {
  //         var mapping_detail = response["data"];
  //         this.dialog.open(ApplyJobComponent,
  //           {
  //             width: '90vw', height: '90vh', data: { mapping_detail, job_post_id }, closeOnNavigation: true, autoFocus: false, hasBackdrop: true, disableClose: true
  //           });
  //       }
  //     );

  // }

  openDialog(job_master_id, job_post): void {
    console.log(job_master_id, job_post)
   
    var jsonObj = JSON.parse(localStorage.currentUser);
    var employee_id = jsonObj.id
    this.skill_service.getJobDetail(job_master_id, employee_id, job_post)
      .subscribe(
        response => {
          console.log(response["data"])
          var mapping_detail = response["data"];
          console.log(mapping_detail)
          this.dialog.open(ApplyJobComponent,
            {
              width: '90vw', height: '90vh', data: { mapping_detail, job_post }, closeOnNavigation: true, autoFocus: false, hasBackdrop: true, disableClose: true
            });
        }
      );

  }
  change_page(page_link) {

    this.router.navigate([page_link]);
}

  onResize(event) {
    this.view = [event.target.innerWidth / 0.15, 200];
}
redirectPage(page_link, rrf_id) {
    
  this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  getmychart_data()
  {
   //let employee_id = "16790";
   console.log("asds",this.employee_id)
   this.quizService.getChartdata(this.employee_id).subscribe(data=>
    {
     console.log('chartdetails',data)
      if(data.data!='')
      {
        this.message='empty';
        this.chart_list=data.data;
        this.chart_list.splice(0,1);
        this.month_name=data.month;
        //this.message='';
      this.notificationValues = data.skilldeatils;
      console.log(this.message, this.notificationValues)
      const skillStatData = {
        chart: {
          // caption: "Recommended Portfolio Split",
          showLabels :'0 ',
          showValues: '0 ',
          theme: "fusion"
        },
        data: this.chart_list
      };
      
      this.dataSource = skillStatData;

      }
      else{
       // this.chart_list ="0";
        this.message='Please Add Your Skill';
        console.log('yore are chart',this.message)
            
      }
    })
  }

  

}
