import { Component, OnInit,ViewChild,TemplateRef, } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Observable, Subscription } from 'rxjs/Rx';
import 'rxjs/add/observable/interval';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {PaginationInstance} from "ngx-pagination";
import {TimerObservable} from "rxjs/observable/TimerObservable";
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import{ Quizcollection} from '../quizcollection'
import { EeequizreviewComponent } from '../eeequizreview/eeequizreview.component';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";



@Component({
  selector: 'app-eee-quiz-view',
  templateUrl: './eee-quiz-view.component.html',
  styleUrls: ['./eee-quiz-view.component.css']
})
export class EeeQuizViewComponent implements OnInit {
  next_button  =false;
  checkedList=[];

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 1,
    currentPage: 1,
    collection:[]
};
message: string = "Are you sure to end the quiz?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"

@ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
collection:Quizcollection[] = [];
date:Date;
date_set:any;
closeResult = '';
sub: Subscription;
countDown;
count;
count_status;


count_sec;
progress =100;
param;
param_result;
total_count;
submitanswer:Boolean=false;
alertfiftenminutes:Boolean=false;
isFavorite: Boolean = false;
bodyClasses:string = "current";
candidate_role:string;
candidate_name:string;
developer_option:any[];
dropdowncount: number = 0;
public quizForm: FormGroup ;
movies:any=[];
question_answer:number;
unaswered_questions: number[] = [];
is_submit = false;
data_total_set:any;
quiz_count:number=0;
skill_count:string;
quiz_skill_data: Boolean = false;
page_value:string;
option_value:string;
quiz_optionhide: Boolean = false;
quiz_optionanswer:any=[];
option_name:string
initial_optionname:string;
movies_drpdown:Boolean=false
change_dropset:string;
start_quiztime
candidate_password:any;
moviesLength: any;
selectedIndex = 0;
tabSwitchCount:any = 0;
  assessmentTiming: any;


constructor(private route: ActivatedRoute,private router: Router,private skill_service: EmployeeSkillMappingService, public quizService: QuizService,private fb: FormBuilder,private modalService: NgbModal,private dialog: MatDialog) {
  console.log(this.route.snapshot.routeConfig.path);
 



// window.onblur = function() {
//   var flag = confirm("Please don't leave!  Click OK if you really want to leave.  I hope you click cancel and stay with me.");
//   if (flag) {
//     console.log(flag);
//       window.onblur = undefined;
//       alert("Ok you can leave now.  **sob**");
//       //The user is leaving.  You can do a little cleanup here if you need to.
//   }
// }

// $(window).on("blur focus", function(e) {
//   var prevType = $(this).data("prevType");

//   if (prevType != e.type) {   //  reduce double fire issues
//       switch (e.type) {
//           case "blur":
//               $('div').text("Blured");
//               break;
//           case "focus":
//               $('div').text("Focused");
//               break;
//       }
//   }

//   $(this).data("prevType", e.type);
// })


 }
 progressBar = document.querySelector('.progress-bar');
intervalId;

ngOnInit() {
  
 // this.candidate_role=localStorage.getItem('role');
 //alert(this.config.currentPage);
 this.visibilityChangeCallback();
 this.candidate_name=localStorage.getItem('name');
 this.candidate_password=localStorage.getItem('password');
 this.router.navigate(['/eee_quiz_view'], { queryParams: { page: this.config.currentPage } });
 
 this.quizService.getQuestions(this.candidate_name,this.candidate_password).subscribe(
  (data: any) => {
  this.assessmentTiming = data.assessment.time;
  console.log(this.assessmentTiming)
  })

  this.quizForm = this.fb.group({
    selected: [{value: ''}, [Validators.required]]
  });
 // this.myTimer();

    // this.quizService.geteee_Questions(this.candidate_name,this.candidate_password).subscribe(
    //   (data: any) => {
    //     console.log('filterssss',data)
    //   this.collection = data.data;
    //   this.config.collection = this.collection;
    //   this.config.collection[0].className = "Visited";

    //     //this.movies=data.skills;
    //     console.log(this.collection)
    //     this.candidate_role=data.role.role;
        
    //     this.data_total_set=this.collection.length;
       
    //      this.date = new Date();
    //     let hours =this.date.getHours();
    //     let minutes = this.date.getMinutes();
    //     let seconds= this.date.getSeconds();
    //     this.date_set= hours + ":"+ minutes + ":" + seconds;
    //     this.quiztimer();
    //   }
    // );
    // this.quizService.getSkill(this.candidate_name,this.candidate_password).subscribe(
    //   (data: any) => {
    //     console.log(data, data.length)
    //     this.movies=data;
    //     this.moviesLength = data.length;
      
        
    //     this.skill_count =data.length;
    //    // alert(this.skill_count)
    //     if(this.skill_count=="2")
    //     {
          
    //       this.start_quiztime=2 * 18;
         
    //     }
    //     // else{


    //     //   //this.timing=60;
 
         
    //     //   this.timing=18
    //     // }
    //     else{
    //       if(this.skill_count=="1")
    //       {
           
    //         this.start_quiztime=35
            
    //       }
    //       if(this.skill_count=="3")
    //       {
            
    //         this.start_quiztime=3*18
            
    //       }
    //       if(this.skill_count=="4")
    //       {
            
    //         this.start_quiztime=4 *18
           
    //       }
    //       if(this.skill_count=="5")
    //       {
          
    //         this.start_quiztime=5 *18
           
    //       }
   
          
         
    
    //     }
       
    //   })

    this.quizService.geteee_Questions(this.candidate_name,this.candidate_password).subscribe(
      (data: any) => {
        console.log('filterssss',data)
      this.collection = data.data;
      this.config.collection = this.collection;
      this.config.collection[0].className = "Visited";

        //this.movies=data.skills;
        console.log(this.collection)
        this.candidate_role=data.role.role;
        
        this.data_total_set=this.collection.length;
       
         this.date = new Date();
        let hours =this.date.getHours();
        let minutes = this.date.getMinutes();
        let seconds= this.date.getSeconds();
        this.date_set= hours + ":"+ minutes + ":" + seconds;
        this.quiztimer();
      }
    );
    this.quizService.getSkill(this.candidate_name,this.candidate_password).subscribe(
      (data: any) => {
        console.log(data, data.length)
        this.movies=data;
        this.moviesLength = data.length;
      
        
        this.skill_count =data.length;
       // alert(this.skill_count)
        if(this.skill_count=="2")
        {
          
          this.start_quiztime=2 * 18;
         
        }
        // else{


        //   //this.timing=60;
 
         
        //   this.timing=18
        // }
        else{
          if(this.skill_count=="1")
          {
           
            this.start_quiztime=this.assessmentTiming;
            console.log(this.start_quiztime, this.assessmentTiming)
            
          }
          if(this.skill_count=="3")
          {
            
            this.start_quiztime=3*18
            
          }
          if(this.skill_count=="4")
          {
            
            this.start_quiztime=4 *18
           
          }
          if(this.skill_count=="5")
          {
          
            this.start_quiztime=5 *18
           
          }
        }
       
      })  
  
}

visibilityChangeCallback(){
  // var path = this.route.snapshot.routeConfig.path;
  if( window.location.pathname == '/eee_quiz_view' ) {
    // this.route.queryParamMap.map(params => params.get('page')).subscribe(   
    // );
    //  check tab changing 
    var count = 0;
    document.addEventListener("visibilitychange", function() {
      if (document.hidden) {
        if(count == 0 && window.location.pathname != '/homepage') {
          if (confirm("Please don't leave! Click OK if you really want to leave. If you try again your assessment will be terminated") == true) {
            count =+ 1;
          } else {
            count =+1;
          }
        } else if(window.location.pathname != '/homepage') {
          document.location.href = '/eee_quiz_result';
        }
      }
      else {
        console.log("SHOWN");
      }
    });
    console.log('count2',count,this.tabSwitchCount);
  } else {
    console.log(window.location.pathname);
  }
}

pageChange(newPage: number,config) {
 
  
  if(config.collection[newPage-1].className != "Answered")
  {
    config.collection[newPage-1].className = "Visited";
  }

  config.currentPage = newPage;
  
  
  this.param=newPage;
  
  
  if(this.param==this.data_total_set)
  {
    
    this.submitanswer =true
    this.next_button=false;
 
  }
  else{
    this.submitanswer =false
    this.next_button=false;
    this.router.navigate(['/eee_quiz_view'], { queryParams: { page: newPage } });
   // alert('has')
  }
  if(config.collection[newPage-1].className!='Visited' && config.collection[newPage-1].className!='Unattended')
  {
    //alert(config.collection[newPage-1].questionName)
      this.quizService.getpageoption(this.config.currentPage,this.candidate_name,this.candidate_password,config.collection[newPage-1].questionName,config.collection[newPage-1].topicId).subscribe(
      (data: any)=>
      {
        console.log('your have been software',data)
        if(data.data!=0)
        {
          this.quiz_optionhide = true;
          this.quiz_optionanswer=data.data;
        
        }
        else
        {
          this.quiz_optionhide = false;
          this.quiz_optionanswer=data.count;

        }
    });

  }
  else
  {
    this.quiz_optionhide = false;
  }


  
}


quiztimer() {
 // this.progress = this.progress - 1;
 
 
  //this.start_quiztime=35
  
  this.count = 60;
  this.countDown = Observable.timer(0, 1000)
    .subscribe(x => {
      this.count = this.count - 1;
      
    });

  this.sub = Observable.interval(500)
    .subscribe(x => {
     // console.log(this.count);
      if (this.count === 0) {
        //this.countDown.unsubscribe();
      
        this.start_quiztime=this.start_quiztime -1;
        this.count=59;
        this.progress = this.progress - 3;
        
        if(this.start_quiztime === 0)
        {
          this.router.navigate(['/eee_quiz_result'], {});
        }
        console.log(this.start_quiztime)
        if(this.start_quiztime === 15)
        {
          
          this.alertfiftenminutes=true;
         setTimeout(() => this.alertfiftenminutes = false, 9000)
        }

      }
    });
}
showOptions(event,answer,item)
{
  item.className = "Answered";

}
onCheckboxChange(option: any, questionName,question_option, isChecked, item,i,item_options,event) {
 console.log("option",option);
 
  if(event.checked==true) {
    this.checkedList.push(option);
  } 
  else {
    for(let i=0 ; i < item.length; i++) {
      if(this.checkedList[i] == option) {
        this.checkedList.splice(i,1);
     }
   }
 }
 var count_checked=this.checkedList.length
  if(count_checked==2 || count_checked==3)
  {
    this.next_button=true;
    
  
     localStorage.setItem('item_status', item.className);
     localStorage.setItem('item_name', item.questionName);
  }
  else
  {

    this.next_button=true;
    
     localStorage.setItem('item_status', item.className);
     localStorage.setItem('item_name', item.questionName);
  }
  
 }

 next_button_click(values, path)
 {
   
   if(this.config.collection[this.config.currentPage-1].className = "Unattended")
   {
    this.config.collection[this.config.currentPage-1].className="Answered";
   }
   
   
  var questionName=localStorage.getItem('item_name');
  

   if(this.checkedList.length == 1){
    var option =  this.checkedList.toString();
   }else{
    var option =  this.checkedList.join("(,)");
   }
   console.log("optioninside",option);
   this.quizService.getAnswers(option,questionName,this.candidate_name,this.candidate_password,this.config.currentPage,this.data_total_set,this.config.collection[this.config.currentPage-1].topicId, path).subscribe(data=>
    {
    });
    this.checkedList = [];
   
   
   this.next_button=false;
  

   if(this.data_total_set!=this.config.currentPage)
   {
    let newpage=this.config.currentPage +1;
    this.config.currentPage=newpage
   
   // alert(this.config.collection[this.config.currentPage-1].className)
  
   }
   else{
     
    this.config.collection[this.config.currentPage-1].className="Answered";

   }
  

  
    
  
  // item_list.className = "Answered";


 }


timer_restart()
{
  //alert('has')
 // this.count_status.unsubscribe();
this.countDown.unsubscribe();
this.dropdown_quiztimer();

  

}
dropdown_quiztimer() {
  this.progress = this.progress - 1;
  this.count = 60;
  this.start_quiztime=35
  this.countDown = Observable.timer(0, 1000)
    .subscribe(x => {
      this.count = this.count - 1;
    });

  this.sub = Observable.interval(500)
    .subscribe(x => {
      //console.log(this.count);
      if (this.count === 0) {
        //this.countDown.unsubscribe();
       
        this.start_quiztime=this.start_quiztime -1;
        this.count=60
        
        if(this.start_quiztime === 0)
        {
          this.router.navigate(['/eee_quiz_result'], {});
        }
        console.log(this.start_quiztime)
        if(this.start_quiztime === 15)
        {
           console.log('ggg',this.start_quiztime)
          this.alertfiftenminutes=true;
         // setTimeout(() => this.alertfiftenminutes = false, 3000)
        }
      }
    //  alert(this.start_quiztime)
    console.log(this.start_quiztime)
      
    });
}

// myTimer() {
  
//   this.count = 18;
//   this.countDown = Observable.timer(0, 60000)
//     .subscribe(x => {
//       const getDownloadProgress = () => {
//       if (this.progress <= 18) {
//           console.log('inside if', this.progress);
//           //alert(this.count)
//           if(this.count==0)
//           {
//             alert('has data have benn saved')
//             this.router.navigate(['/quiz_result'], {});

//           }
//         }
//         else {

//           clearInterval(this.intervalId);
//         }
//       }
//       this.intervalId = setInterval(getDownloadProgress, 60000);
//     });
//     this.sub = Observable.interval(500)
//     .subscribe(x => {
//       console.log(this.count);
//       if (this.count === 0) {
//         this.countDown.unsubscribe();
//       }
//     });
// }


Answer(qID, choice) {
  this.quizService.qns[1][this.quizService.qnProgress].userAnswer = choice;
  //console.log(' this.quizService.answer[choice])', this.quizService.answer[choice])
  //console.log(' this.quizService.qns[1][this.quizService.qnProgress].answer[]', this.quizService.qns[1][this.quizService.qnProgress].answer['0'])
  if(this.quizService.qns[1][this.quizService.qnProgress].answer['0'] == this.quizService.answer[choice]){
    this.quizService.correctAnswerCount++
  }
  localStorage.setItem('qns', JSON.stringify(this.quizService.qns));
  this.quizService.qnProgress++;
  localStorage.setItem('qnProgress', this.quizService.qnProgress.toString());
  if(this.quizService.qnProgress == 9){
    this.quizService.button = 'Complete'
  }
  if (this.quizService.qnProgress == 10) {
    clearInterval(this.quizService.timer);
    this.router.navigate(['/result']);
  }
}
Previous(qID, choice) {
  this.quizService.qns[1][this.quizService.qnProgress].userAnswer = choice;
  const savedData = JSON.parse(localStorage.getItem('qns'));
  this.quizService.qnProgress--;
  this.quizService.qns[1][this.quizService.qnProgress] = savedData[1][this.quizService.qnProgress];
  if(this.quizService.qns[1][this.quizService.qnProgress].answer['0'] == this.quizService.answer[choice]){
    this.quizService.correctAnswerCount--
  }
  if (this.quizService.qnProgress == 10) {
    clearInterval(this.quizService.timer);
    this.router.navigate(['/result']);
  }
}
onAnswerChange(value){
  this.quizService.userAnswer = value;
}
save_button(templateRef : TemplateRef<any>)
{
  this.tabSwitchCount = 1;
  let last:any = this.movies[this.movies.length-1];
  if(this.movies.length!=2)
  {
    this.dialog.open(templateRef);
  //  this.router.navigate(['/eee_quiz_result'], {});
  }
  else{
    
    if(this.quiz_count!=2)
    {

      this.quiz_skill_data =true;

    }
    if(last.skill_name==this.option_name)
    {
      this.dialog.open(templateRef);
     // this.router.navigate(['/eee_quiz_result'], {});
    }
    if(this.quiz_count==this.movies.length)
    {
      this.dialog.open(templateRef);
      //alert(this.movies.length)
     // this.router.navigate(['/eee_quiz_result'], {});

    }
}
}
// submit(templateRef : TemplateRef<any>)
// {
// this.dialog.closeAll();
// // setTimeout(_=>{
//   this.router.navigate(['/eee_quiz_result'], {});
// // })
  

// }
submit(templateRef : TemplateRef<any>)
{
  this.quizService.getCandidate_password(this.candidate_password).subscribe(data=>
    {
      console.log('filtersssss',data);
    });
  this.skill_service.getCandidate_password_details(this.candidate_password).subscribe(data1=>
    {
      console.log('filtersssss',data1);
      // var result = data[0].
      //this.timer_restart();
    });
this.dialog.closeAll();
// setTimeout(_=>{
  this.router.navigate(['/eee_quiz_result'], {});
// })
}
submitNO()
{
  this.dialog.closeAll();

}
open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


dropdown_change(values)
{
this.quiz_count++;
this.submitanswer =false;
this.quiz_skill_data = false;

this.quizService.valueChange(values,this.candidate_name,this.candidate_password).subscribe(data=>
{
  console.log('filtersssss',data);
  this.quiz_optionhide = false;
  this.config.currentPage=1
  this.option_name=values;
  this.collection =data.data;
  this.config.collection=this.collection;
  this.config.collection[0].className = "Visited";
  this.data_total_set=this.config.collection.length;
  this.change_dropset=this.data_total_set;
  //this.timer_restart();

});
  
}
onSelect(option: any, questionName,question_option, isChecked, item,i,item_options, path) {
 console.log(item.topicId)

 const values = ['a', 'b','c','d'];
 
const merged = values.reduce((obj, key, index) => ({ ...obj, [key]: item_options[index] }), {});
//console.log(merged)
var key = Object.keys(merged)[Object.values(merged).indexOf(option)]
this.question_answer=parseInt(this.change_dropset) + parseInt(this.data_total_set);
  //this.question_answer.push(parseInt(this.config.currentPage));
 
  this.movies_drpdown=true;
    this.quizService.getAnswers(option,questionName,this.candidate_name,this.candidate_password,this.config.currentPage,this.data_total_set,item.topicId, path).subscribe(data=>
    {
      console.log('data',data);
      
      
    });
        
  //this.pageChange(this.config.currentPage,item)
 
  
 // alert(this.data_total_set)
 if(this.config.currentPage)
 {
   if(this.data_total_set!=this.config.currentPage)
   {
    let newpage=this.config.currentPage +1;
    this.config.currentPage=newpage
    item.className = "Answered";
  
   }
   else{
   // item.className = "Answered";
    let newpage=this.config.currentPage;
    this.config.currentPage=newpage
    
    

   }
  
}
}
showanswer(item,data)
{
  
  this.quizService.getpageoption(this.config.currentPage,this.candidate_name,this.candidate_password,this.config.collection[this.config.currentPage-1].questionName
    ,this.config.collection[this.config.currentPage-1].topicId).subscribe(
    (data: any)=>
    {
      console.log('answer',data)
      if(data.data!=0)
      {
        this.quiz_optionhide = true;
        this.quiz_optionanswer=data.data;
      
      }
      else
      {
        this.quiz_optionhide = false;
        this.quiz_optionanswer=data.count;

      }
  });

  
}
// review_question()
// {
  // const url = this.router.serializeUrl(
  //   this.router.createUrlTree(['//eee_quizreview'])
  // );
  // this.router.navigate(['/eee_quizreview'], {});
  // window.open(url, '_blank','noreferrer');
 
  
// }
review_question()
{
  // const url = this.router.serializeUrl(
  //   this.router.createUrlTree(['//eee_quizreview'])
  // );

  // window.open(url, '_blank','noreferrer');
  //this.router.navigate(['/eee_quizreview'], {});

  this.dialog.open(EeequizreviewComponent,
    {
      width: '90vw', height: '90vh', closeOnNavigation: true, autoFocus: false, hasBackdrop: true, disableClose: true
    });
  
}

selectTab(index: number): void {
  console.log(index);
  this.selectedIndex = index;
}

onTabChanged($event, X) {
  console.log($event.index, 'event');
  this.selectedIndex = $event.index;
  console.log(this.movies[$event.index], this.movies[$event.index]);
  this.dropdown_change(this.movies[$event.index].topicId);
}

}
