import { Component, OnInit,ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort, MatSnackBar } from '@angular/material';
import { FileValidator } from 'ngx-material-file-input';
@Component({
  selector: 'app-my-profile-details',
  templateUrl: './my-profile-details.component.html',
  styleUrls: ['./my-profile-details.component.css']
})
export class MyProfileDetailsComponent implements OnInit {

  employee_detail:any;
  firstFormGroup: FormGroup;
  maxSize: number = 2097152;
  extentions: string[] =['.doc', '.docx']
  urlValue: boolean = false;
  BACK_END_URL:any = 'http://localhost:3695/';
  file_url:any 
  file_url1:any
  file_data:any
  file_data1:any
  resume_length:any
  template_url:any
  constructor(private skill_service: EmployeeSkillMappingService,private _snackBar: MatSnackBar,  public dialog: MatDialog) { }

  ngOnInit() {
    this.firstFormGroup = new FormGroup({
      associate_resume: new FormControl("", [FileValidator.maxContentSize(this.maxSize) ]),
      resume_1: new FormControl(""),
      resume_2: new FormControl("")
    })
    this.template_url = 'associate_resume/template_resume.doc'
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log("jsonObj",jsonObj);
    this.skill_service.getAssociateResume(jsonObj.id)
        .subscribe(
          response => {
            console.log("response",response)
            console.log("sdfsdfsdf",Object.keys(response['data']).length)
             this.resume_length = Object.keys(response['data']).length
            if(response['data'][0]['emp_id'] == jsonObj.id){
              console.log("response['data'][0]",response['data'][0])
              if(response['data'][0]['resume_url']){
                console.log("response['data'][0]",response['data'][0])
                this.file_url = response['data'][0]['resume_url']
                this.file_data =  response['data'][0]
              }
             if(response['data'][1]['resume_url']){
              this.file_url1 = response['data'][1]['resume_url']
              this.file_data1 =  response['data'][1]
             }
console.log("sfsfsfsd")
this.urlValue = true
            }
          }
        );
    this.skill_service.getEmployeeDetails(jsonObj.id)
    .subscribe(
      response => {
        this.employee_detail = response["data"]["employee_detail"];
      }
    );
  }

  onSubmit(){
    console.log("this.firstFormGroup.value.associate_resume.files[0]",this.firstFormGroup.value.associate_resume.files[0])
    const myArr = this.firstFormGroup.value.associate_resume.files[0]['name'].split('.').pop();
    console.log("myArr",myArr);
    if(myArr == 'docx' || myArr == 'doc'){
      console.log("inside docxs")
      const formData = new FormData();
    // var jsonObj = localStorage.getItem('currentUser')
    // var x = localStorage.getItem("currentUser");
    // console.log("jsonObj",x);  
    var jsonObj = JSON.parse(localStorage.currentUser);
      var id = jsonObj.id;
      var emp_no = jsonObj.employee_number;
      var emp_name = jsonObj.first_name + "_" + jsonObj.last_name
console.log("isadisdid",id,emp_no)
    if (this.firstFormGroup.value.associate_resume) {
      formData.append("file", this.firstFormGroup.value.associate_resume.files[0]);

    }
    this.skill_service.associateResume(formData, id,emp_name,emp_no)
        .subscribe(
          response => {
            if(response['message'] == true){
              this.urlValue = true
              this.firstFormGroup.value.associate_resume.files[0] = ''
              var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.getAssociateResume(jsonObj.id)
        .subscribe(
          response => {
            console.log("response",response)
            this.resume_length = Object.keys(response['data']).length
            if(response['data'][0]['emp_id'] == jsonObj.id){
              console.log("response['data'][0]",response['data'][0])
              if(response['data'][0]['resume_url']){
                console.log("response['data'][0]",response['data'][0])
                this.file_url = response['data'][0]['resume_url']
                this.file_data =  response['data'][0]
              }
             if(response['data'][1]['resume_url']){
              this.file_url1 = response['data'][1]['resume_url']
              this.file_data1 =  response['data'][1]
             }
            }
          }
        );
            }
            console.log("response",response)
          }
        );
    }else{
      this._snackBar.open('sorry, upload docx or doc format Resume', '', {
        duration: 5000,
      });
    }
    
   }

   deleteResume(data,resume){
     console.log("datadeleteAssociateResume",data);
     this.skill_service.deleteAssociateResume(data)
     .subscribe(
       response => {
         console.log("response",response)
        if(response['data'] == true){
          console.log("insideasdasd")
          var jsonObj = JSON.parse(localStorage.currentUser);
          this.skill_service.getAssociateResume(jsonObj.id)
              .subscribe(
                response => {
                  console.log("response",response)
                  this.resume_length = Object.keys(response['data']).length
                //   if(response['data'][0]['emp_id'] == jsonObj.id){
                //     console.log("response['data'][0]",response['data'][0])
                //     if(response['data'][0]['resume_url']){
                //       console.log("response['data'][0]",response['data'][0])
                //       this.file_url = response['data'][0]['resume_url']
                //       this.file_data =  response['data'][0]
                //     }
                //    if(response['data'][1]['resume_url']){
                //     this.file_url1 = response['data'][1]['resume_url']
                //     this.file_data1 =  response['data'][1]
                //    }
                //   }
                }
              );
          if(resume == 'resume1'){
            console.log("insidesdsd")
            this.file_url = false
          }
          else if(resume == 'resume2'){
            this.file_url1 = false
          }
        }
         console.log("response",response)
       })
   }
   
}
