import { Component, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  start_login: Boolean;
  email:any;
  count:number;
  candidate_details:string;
  candidate_role:string;
  name:string;
  candidate_password:any;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private quizService : QuizService,private router : Router,private dialog: MatDialog) { }

  ngOnInit() {
    this.start_login  = false;
   
  }

  OnSubmit(name:string,email:string){
    this.quizService.insertParticipant(name,email).subscribe(
      (data : any) =>{
        
        localStorage.setItem('participant',JSON.stringify(data));
        this.router.navigate(['/start']);
      }
    );
  }
  changed(event)
  {
    this.start_login = true;
    
  }
  register_login(templateRef : TemplateRef<any>)
  {
    //alert(this.email);
    this.quizService.candidateLogin(this.email).subscribe((data: any) => 
    {
      console.log('has',data)
      if(data!=0)
      {
       // alert('has')
        this.count=data.count;
      
      this.candidate_details = data.name;
      this.candidate_role =data.role
      this.candidate_password=this.email;
      //alert(this.count)

      //this.candidate_details=data.data.candidate_documents;
    localStorage.setItem('name',this.candidate_details);
    localStorage.setItem('role',this.candidate_role);
    localStorage.setItem('password',this.candidate_password);

    this.name=localStorage.getItem('name');
    
    if(this.count!=0)
    {
     // alert('has')
      this.router.navigate(['/quiz_instruction']);
    }
    else{
      //alert('your ppassword dobt match');
      this.dialog.open(templateRef);
      
    }

      }
      else
      {
        //alert('your password dont match')
        this.dialog.open(templateRef);
      }
      

    

      
     // console.log('you have got data',this.count,this.candidate_details,this.candidate_role);
    })
    
  
  }
 

}
