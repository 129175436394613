import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray,FormBuilder } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { ActivatedRoute } from '@angular/router';
import { BACK_END_URL } from '../shared/app.globals';
import { forEach } from 'node_modules_old/@angular/router/src/utils/collection';
import { C } from 'node_modules_old/@angular/cdk/keycodes';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-candidate-file-upload',
  templateUrl: './candidate-file-upload.component.html',
  styleUrls: ['./candidate-file-upload.component.css']
})
export class CandidateFileUploadComponent implements OnInit {
  dynamicArray: any = [];  
  educationdynamicArray: any = []; 
  newDynamic: any = {};  
  newEduDocs: any = {};
  form_data: any;
  myGroup:FormGroup;
  dependentDetails: any = [];
  maxSize: number = 5242880;
  candidate_id: any;
  BACK_END_URL = BACK_END_URL;
  check_already_upload: Boolean = false;
  accepted_file_exetension = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];
  accepted_photo_exetension = ['png', 'jpg', 'jpeg'];
  accepted_cpif_exetension = ['doc', 'docx','pdf'];
  relation_list: any ;
  selectedMaritalStatus:any;
  isDisable = false;
  selectedValue: any;
  empty = new Array(10).fill(null);
  tenth: any = '10';
  age = '';
  files: File[] = [];
  selectedRadioOption: string;
  disable_btn = true;
  pastEmp_data: any ;
  candidateDocCheck: any;
  checkgh = true;
  candidateDocPath: any;

  constructor(private route: ActivatedRoute, private skill_service: EmployeeSkillMappingService,private fb :FormBuilder,public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log("insidecandidatefileupload",this.route.snapshot);
    this.newDynamic = {name: "", relationship: "",gender:"",age:"",dob:""};  
    this.dynamicArray.push(this.newDynamic);  
    this.newEduDocs = {qualification: "", yearOfPassing: "", percentage: "", documentAttached: "" };
    this.educationdynamicArray.push(this.newEduDocs);

    if (this.route.snapshot.params.id) {
      this.candidate_id = this.route.snapshot.params.id;
      this.getCandiateUpdateDetails(this.candidate_id);

      this.skill_service.checkCandidateDocuments(this.candidate_id)
        .subscribe(
          response => {
            console.log(response,'check candidate docs')
            if (response["data"] == true)
              this.check_already_upload = true;
          }
        );
    }
    this.form_data = new FormGroup({
      payslips: new FormControl("", [Validators.required, FileValidator.maxContentSize(this.maxSize)]),
      payslips1: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      payslips2: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      bank_statement: new FormControl("", [FileValidator.maxContentSize(this.maxSize)]),
      // degree_certificates: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      others: new FormControl("", [FileValidator.maxContentSize(this.maxSize)]),
      experience_letter: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      degree_certificates: new FormArray([new FormGroup({ qualification: new FormControl("",[Validators.required]), yearOfPassing: new FormControl("",[Validators.required, Validators.maxLength(4), Validators.minLength(4)]), percentage: new FormControl("",[Validators.required,Validators.minLength(2),Validators.maxLength(4)]), documentAttached: new FormControl("") })]),
      candidate_photo: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      cpif: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      cFirst: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      pan_card: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      aadhar_card: new FormControl("", [FileValidator.maxContentSize(this.maxSize), Validators.required]),
      pan_number: new FormControl("", [Validators.required, Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]),
      aadhar_number: new FormControl("", [Validators.required, Validators.pattern('^[2-9]{1}[0-9]{11}$')]),
      marital_status: new FormControl("", [Validators.required]),
      dependent_details: new FormArray([new FormGroup({ name: new FormControl("",[Validators.pattern("^[a-zA-Z]+$"),Validators.maxLength(30)]), relationship: new FormControl(""), gender: new FormControl(""), dob: new FormControl(""), age: new FormControl("",[Validators.pattern("^[0-9]+$"),Validators.maxLength(2)]) })]),
      employement_letter: new FormControl("", [Validators.required])
    });
    this.form_data.disable();

    this.form_data.controls.payslips.valueChanges.subscribe(value => {
      if (value) {
        this.form_data.controls.payslips.setValidators(null);
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.payslips.setValue(null, { emitEvent: false });
          }
        }
      } else {
        this.form_data.controls.payslips.setValidators([Validators.required]);
      }
    });

    this.form_data.controls.payslips1.valueChanges.subscribe(value => {
      if (value) {
        this.form_data.controls.payslips1.setValidators(null);
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.payslips1.setValue(null, { emitEvent: false });
          }
        }
      } else {
        this.form_data.controls.payslips1.setValidators([Validators.required]);
      }
    });

    this.form_data.controls.payslips2.valueChanges.subscribe(value => {
      if (value) {
        this.form_data.controls.payslips2.setValidators(null);
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.payslips2.setValue(null, { emitEvent: false });
          }
        }
      } else {
        this.form_data.controls.payslips2.setValidators([Validators.required]);
        
      }
    });
    this.form_data.controls.bank_statement.valueChanges.subscribe(value => {
      if (value) {
        this.form_data.controls.payslips.setValidators(null);
        this.form_data.controls.payslips1.setValidators(null);
        this.form_data.controls.payslips2.setValidators(null);
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.bank_statement.setValue(null, { emitEvent: false });
          }
        }
      }
      else {
        this.form_data.controls.payslips.setValidators([Validators.required]);
        this.form_data.controls.payslips1.setValidators([Validators.required]);
        this.form_data.controls.payslips2.setValidators([Validators.required]);
      }
    });
    // this.form_data.controls.degree_certificates.valueChanges.subscribe(value => {
    //   if (value) {
    //     for (let file of value.files) {
    //       var uploaded_file_type = file.name.split('.').pop();
    //       if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
    //         this.form_data.controls.degree_certificates.setValue(null, { emitEvent: false });
    //       }
    //     }
    //   }
    //   this.form_data.controls.payslips.updateValueAndValidity();
    //   this.form_data.controls.bank_statement.updateValueAndValidity();
    // });
    this.form_data.controls.others.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.others.setValue(null, { emitEvent: false });
          }
        }
      }
    });
    this.form_data.controls.experience_letter.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.experience_letter.setValue(null, { emitEvent: false });
          }
        }
      }
    });
    this.form_data.controls.candidate_photo.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_photo_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.candidate_photo.setValue(null, { emitEvent: false });
          }
        }
      }
    });
    this.form_data.controls.cpif.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_cpif_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.cpif.setValue(null, { emitEvent: false });
          }
        }
      }
    });

    this.form_data.controls.cFirst.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_cpif_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.cFirst.setValue(null, { emitEvent: false });
          }
        }
      }
    });

    this.form_data.controls.pan_card.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.pan_card.setValue(null, { emitEvent: false });
          }
        }
      }
    });

    this.form_data.controls.aadhar_card.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.form_data.controls.aadhar_card.setValue(null, { emitEvent: false });
          }
        }
      }
    });

    // this.form_data.controls.employement_letter.valueChanges.subscribe((value: any) => {
    //   console.log('wty')
    //   if (value) {
    //     for (let file of value.files) {
    //       var uploaded_file_type = file.name.split('.').pop();
    //       if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
    //         this.form_data.controls.employement_letter.setValue(null, { emitEvent: false });
    //       }
    //     }
    //   }
      // if (!Array.isArray(files)) {
      //   this.files = [files];
      // } else {
      //   this.files = files;
      // }
      // this.form_data.controls.employement_letter.setValue(null, { emitEvent: false });
      // console.log(this.files, this.form_data.controls.employement_letter )
    // })

    this.form_data.controls.marital_status.valueChanges.subscribe(value => {
      if(this.dynamicArray.length != 1) {
        for(var x=0;x<=(this.dynamicArray.length);x++) {
        this.deleteRow(0)
        }
      } else {
        console.log('else part')
      }

      if (value=='Single') {
        this.relation_list = ['Father', 'Mother'];
        this.form_data.controls["dependent_details"].setValue([{name: "", relationship: "Father", gender: "", age: "",dob:""} ]);
      }else if (value=='Married') {
        console.log('inside married')
        this.relation_list = ['Spouse', 'Son', 'Daughter'];
        this.form_data.controls["dependent_details"].setValue([{name: "", relationship: "Spouse", gender: "", age: "",dob:""} ]);
      }else if (value=='Widow') {
        console.log('inside widow')
        this.relation_list = ['Father', 'Mother','Son', 'Daughter'];
        this.form_data.controls["dependent_details"].setValue([{name: "", relationship: "Father", gender: "", age: "",dob:""} ]);
      }else if (value=='Divorced') {
        this.relation_list = ['Father', 'Mother', 'Son', 'Daughter'];
        this.form_data.controls["dependent_details"].setValue([{name: "", relationship: "Father", gender: "", age: "",dob:""} ]);
      }
    });

    this.addEducationalDetails();
  }

  getCandiateUpdateDetails(id){
    this.skill_service.getCanidateDocDetails(this.candidate_id)
        .subscribe(
          response => {
            this.candidateDocCheck = response['result'][0].candidate_documents.candidate_documents_bool;
            this.candidateDocPath = response['result'][0].candidate_documents;
            console.log(response,'check c', this.candidateDocCheck);
            this.selectedRadioOption == 'no';
            // this.disableFields();
            this.form_data.enable();
          })
  }

  enablingForm() {
    this.form_data.enable();
  }

  // onSelectedFileMultiple(event) {
  //   console.log(event,'event')
  //   if (event.target.files.length > 0) {
  //     var files = [];
  //     files = event.target.files;
  //     console.log(event.target.files,this.form_data.controls['employement_letter'], files)
  //     this.form_data.controls['employement_letter'].setValue(files);
  //     console.log(files)
  //   }
  // }


  // onSelectedFileMultiple(event){
  //   console.log(event,'event')
  //   for(let i = 0; i < event.target.files.length; i++){
  //     console.log(event.target.files)
  //     let file = event.target.files[i];
  //     this.files.push(file);
  //     console.log(this.files);
  //     this.form_data.controls['employement_letter'].setValue(this.files);
  //   }
  // }

  addRow(index) {  
    console.log(this.dynamicArray.length)
    
    if(this.selectedMaritalStatus == 'Married' && this.dynamicArray.length < 2) { // if married
      this.isDisable = false;
    } else if(this.selectedMaritalStatus == 'Single' && this.dynamicArray.length < 1) { // if single
      this.isDisable = false;
    } else if(this.selectedMaritalStatus == 'Widow' && this.dynamicArray.length < 3) { // if widow 
      this.isDisable = false;
    } else if (this.selectedMaritalStatus == 'Divorced' && this.dynamicArray.length < 3) {  //if divorce
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }

    console.log(this.selectedMaritalStatus,'status',this.isDisable)
    this.newDynamic = {name: "", relationship: "",gender:"",age: "",dob:""};
    this.dynamicArray.push(this.newDynamic);   

    const dynamicArray1 = this.form_data.controls.dependent_details as FormArray;
    dynamicArray1.push(this.fb.group({
      name: "",
      relationship: "",
      gender:"",
      age: "",
      dob:""
    })); 
    return true;  
  }  

  deleteRow(index) {  

      if(this.dynamicArray.length ==1) {    
          return false;  
      } else {  
          this.isDisable = false;
          this.dynamicArray.splice(index, 1);  
          console.log(this.dynamicArray,index,'arr')
          const dynamicArray1 = this.form_data.controls.dependent_details as FormArray;
          dynamicArray1.removeAt(index);
          return true;  
      }  
  }  

  addEducationalDetails() {
    console.log(this.educationdynamicArray)
    this.newEduDocs = {qualification: "", yearOfPassing: "", percentage: "", documentAttached: "" };
    this.educationdynamicArray.push(this.newEduDocs);

    const dynamicArray2 = this.form_data.controls.degree_certificates as FormArray;
    for(var i=0; i<3; i++)  {
        dynamicArray2.push(this.fb.group({
          qualification: "",
          yearOfPassing: "",
          percentage:"",
          documentAttached: ""
        })); 
    }

    this.educationdynamicArray = dynamicArray2.value;
    console.log(this.educationdynamicArray, dynamicArray2.value)
  }

  addEducationalDetailsRow() {
    console.log('add row', this.educationdynamicArray.length)
    this.newEduDocs = {qualification: "", yearOfPassing: "", percentage: "", documentAttached: "" };
    this.educationdynamicArray.push(this.newEduDocs);

    const dynamicArray2 = this.form_data.controls.degree_certificates as FormArray;
        dynamicArray2.push(this.fb.group({
          qualification: "",
          yearOfPassing: "",
          percentage:"",
          documentAttached: ""
        })); 

    this.educationdynamicArray = dynamicArray2.value;
  }

  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFileChange(pFileList: any) {
    console.log(pFileList.target.files)
    if ((pFileList.target as HTMLInputElement).files) {
      pFileList = pFileList.target.files;
    } else if (pFileList[0].name != null) {
      console.log('works');
      pFileList = pFileList;
    }
    let filename: any;
    filename = Object.keys(pFileList).map((key: any) => pFileList[key]);
    for (var key in pFileList) {
      console.log("key", key)
      // skip loop if the property is from prototype
      if (!pFileList.hasOwnProperty(key)) continue;
      console.log("pFileList[key]", pFileList[key]);
      this.files.push(pFileList[key]);
      this._snackBar.open("Uploaded Successfully !", 'Close', {
        duration: 2000,
      });
    }
    if(this.files.length > 0) {
      this.disable_btn = false;
    } else {
      this.disable_btn = true;
    }

  }
  // onFileChange(pFileList: any){
  //   console.log(pFileList.target.files)
  //   if ((pFileList.target as HTMLInputElement).files) {
  //     pFileList = pFileList.target.files;
  //   } else if(pFileList[0].name != null) {
  //     console.log('works');
  //     pFileList = pFileList;
  //   }
  //   let filename: any;
  //   filename = Object.keys(pFileList).map((key: any) => pFileList[key]);
  //   this.files.push(pFileList[0]);
  //   console.log(this.files)
  //   this._snackBar.open("Successfully upload!", 'Close', {
  //     duration: 2000,
  //   });
   
  // }

  deleteFile(f: any){
    this.files = this.files.filter(function(w: any){ return w.name != f.name });
    this._snackBar.open("Deleted Successfully !", 'Close', {
      duration: 2000,
    });
  }
  
  deleteFromArray(index: any) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  onSubmitting() {
    // this.pastEmp_data = [];
    let formData: FormData = new FormData();
    for (const file of this.files) {
      console.log(file)
        let obj={
          files : this.files
        };
        this.pastEmp_data = obj;
    }
    console.log(this.files);
   
    this.dialog.closeAll();
  }


  onSubmit() {
    console.log("asdasd",this.form_data.value,this.form_data);
    // this.skill_service.uploadCandidateDocuments(this.form_data.value.payslips)
    // .subscribe(
    //   response => {
    //     this.check_already_upload = true;
    //   }
    // );
    
    // if candidate uploads for first time

    if(this.candidateDocCheck != undefined || this.candidateDocCheck != null) {
      if(this.candidateDocCheck.educationalBoolean == 'no') {
        this.form_data.controls.degree_certificates.at(0).setValue( {qualification: "reject", yearOfPassing: "2012", percentage: "34", documentAttached: null });
      }
      if(this.candidateDocCheck.dependentBoolean == 'no') {
        this.form_data.controls['marital_status'].setValue(this.candidateDocPath['marital_status']);
        this.form_data.controls["dependent_details"].at(0).setValue({name: "reject", relationship: "Father", gender: "", age: "",dob:""});
      }
        this.form_data.controls.payslips.setValidators(null);
        this.form_data.controls.payslips.setValidators(null);
        this.form_data.controls.payslips1.setValidators(null);
        this.form_data.controls.payslips2.setValidators(null);
        this.form_data.controls.experience_letter.setValidators(null);
        this.form_data.controls.employement_letter.setValidators(null);
        this.form_data.controls.aadhar_card.setValidators(null);
        this.form_data.controls.cFirst.setValidators(null);
        this.form_data.controls.cpif.setValidators(null);
        this.form_data.controls.pan_card.setValidators(null);
        this.form_data.controls.pan_number.setValidators(null);
        this.form_data.controls.aadhar_number.setValidators(null);
        this.form_data.controls.candidate_photo.setValidators(null);
        this.form_data.controls.degree_certificates.at(1).clearValidators();

        this.form_data.controls.payslips.updateValueAndValidity();
        this.form_data.controls.payslips1.updateValueAndValidity();
        this.form_data.controls.payslips2.updateValueAndValidity();
        this.form_data.controls.experience_letter.updateValueAndValidity();
        this.form_data.controls.employement_letter.updateValueAndValidity();
        this.form_data.controls.aadhar_card.updateValueAndValidity();
        this.form_data.controls.cFirst.updateValueAndValidity();
        this.form_data.controls.cpif.updateValueAndValidity();
        this.form_data.controls.pan_card.updateValueAndValidity(); 
        this.form_data.controls.pan_number.updateValueAndValidity();
        this.form_data.controls.aadhar_number.updateValueAndValidity();
        this.form_data.controls.candidate_photo.updateValueAndValidity();


      // } else if (this.selectedRadioOption == 'no') {
      //   this.form_data.get('payslips').markAsTouched();
      //   this.form_data.get('payslips1').markAsTouched();
      //   this.form_data.get('payslips2').markAsTouched();
      //   this.form_data.get('bank_statement').markAsTouched();
      //   this.form_data.get('experience_letter').markAsTouched();
      //   this.form_data.get('candidate_photo').markAsTouched();
      //   this.form_data.get('cpif').markAsTouched();
      //   this.form_data.get('cFirst').markAsTouched();
      //   this.form_data.get('pan_card').markAsTouched();
      //   this.form_data.get('aadhar_card').markAsTouched(); 
      //   this.form_data.controls.degree_certificates.at(0).get('documentAttached').markAsTouched();
      //   this.form_data.controls.degree_certificates.at(1).get('documentAttached').markAsTouched();
      //   this.form_data.controls.degree_certificates.at(2).get('documentAttached').markAsTouched();
      // }
    } else {
      if(this.selectedRadioOption == 'yes') {
        this.form_data.controls.payslips.setValidators(null);
        this.form_data.controls.payslips.setValidators(null);
        this.form_data.controls.payslips1.setValidators(null);
        this.form_data.controls.payslips2.setValidators(null);
        this.form_data.controls.experience_letter.setValidators(null);
        this.form_data.controls.employement_letter.setValidators(null);
  
        this.form_data.controls.payslips.updateValueAndValidity();
        this.form_data.controls.payslips1.updateValueAndValidity();
        this.form_data.controls.payslips2.updateValueAndValidity();
        this.form_data.controls.experience_letter.updateValueAndValidity();
        this.form_data.controls.employement_letter.updateValueAndValidity();
      } else if (this.selectedRadioOption == 'no') {
        this.form_data.get('payslips').markAsTouched();
        this.form_data.get('payslips1').markAsTouched();
        this.form_data.get('payslips2').markAsTouched();
        this.form_data.get('bank_statement').markAsTouched();
        this.form_data.get('experience_letter').markAsTouched();
        this.form_data.get('candidate_photo').markAsTouched();
        this.form_data.get('cpif').markAsTouched();
        this.form_data.get('cFirst').markAsTouched();
        this.form_data.get('pan_card').markAsTouched();
        this.form_data.get('aadhar_card').markAsTouched(); 
        this.form_data.controls.degree_certificates.at(0).get('documentAttached').markAsTouched();
        this.form_data.controls.degree_certificates.at(1).get('documentAttached').markAsTouched();
        this.form_data.controls.degree_certificates.at(2).get('documentAttached').markAsTouched();
      }
    }    

    if (this.form_data.status != 'INVALID') {
      var formData = new FormData();
      const formDataArr = new FormData();
      Object.keys(this.form_data.value).forEach((key) => {
        console.log("sadsf",key);
        if(key == 'degree_certificates'){
          if (this.form_data.value[key]!=''){
            console.log("sdad",this.form_data.value[key])
            this.form_data.value[key].forEach((x,i) => {
              // console.log(x.getRawValue(),'xxxxxxx',i)
              Object.keys(x).forEach((y) => {
                console.log(this.form_data.value[key][i][y]);
                if( this.form_data.value[key][i][y] !=null ){
                  if (this.form_data.value[key][i][y]["files"] != undefined){
                    for (var j = 0; j < this.form_data.value[key][i][y]["files"].length; j++) {
                      // console.log("asasdewe",y + "[]", this.form_data.value[key][i][y]["files"][j])
                      // console.log("sadasdas",x.qualification)
                      formData.append("Marksheet_" + x.qualification + "[]", this.form_data.value[key][i][y]["files"][j]);
                    }
                  }else{
                    
                      formData.append(y, JSON.stringify(this.form_data.value[key][i][y]));
                    
                  }
                }
                // console.log(y,'yyyyy',this.form_data.value[key][i],this.form_data.value[key][i][y]);
                
                  // console.log(formDataArr,formData, 'dere')
              })
            })
            formData.append(key, JSON.stringify(this.form_data.value[key]));

            console.log(formDataArr,formData, 'dere')

          }
        }else if(key != 'degree_certificates'){
          console.log(this.form_data.value)
          if (this.form_data.value[key]!=''){
            console.log(this.form_data.value[key])
            if (this.form_data.value[key]["files"]!=undefined){
              for (var i = 0; i < this.form_data.value[key]["files"].length; i++) {
                console.log("asasdewe",key + "[]", this.form_data.value[key]["files"][i])
                formData.append(key + "[]", this.form_data.value[key]["files"][i]);
              }
            }else if(this.form_data.value[key]=='Single' || this.form_data.value[key]=='Married' || this.form_data.value[key]=='Widow' || this.form_data.value[key]=='Divorced'){
              formData.append("marital_status", this.form_data.value[key]);
            }else{
              formData.append(key, JSON.stringify(this.form_data.value[key]));
            }
          }
        }     
      });
      console.log()
      formData.append("candidate_id", this.candidate_id);
      // formData.append("employement_letter", this.formData)
      if(this.candidateDocCheck == undefined || this.candidateDocCheck == null) {
        this.skill_service.uploadCandidateDocuments(formData)
        .subscribe(
          response => {
            this.check_already_upload = true;
          }
        );
      } else if(this.candidateDocCheck != undefined || this.candidateDocCheck != null) {
        this.skill_service.updateCandidateDocuments(formData)
        .subscribe(
          response => {
            this.check_already_upload = true;
          }
        );
      }
       
    } else {
      console.log("invalid")
    }
  }

  changeTextToUppercase(field) {
    const obj = {};
    obj[field] = this.form_data.controls[field].value.toUpperCase();
    this.form_data.patchValue(obj);
  }

  ageCalculator(item,i) {
    console.log(item,i);
    let timeDiff = Math.abs(Date.now() - item.getTime());
    let agecalculated = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    console.log(agecalculated,this.form_data.controls["dependent_details"],this.form_data.controls["dependent_details"].value,this.form_data.controls["dependent_details"].value[i]);
    this.form_data.controls["dependent_details"].at(i).patchValue({ age: agecalculated});
    return true;
  }

  onBlur(templateRef: TemplateRef<any>, value) {
    console.log("sddfdsf", this.form_data.value.pan_number, this.form_data)
    // var pan_number = this.form_data.value.pan_number
    if (this.form_data.controls.pan_number.status == 'VALID') {
      var parms = {
        'pan_number': this.form_data.value.pan_number
      }
      this.skill_service.pancardDuplicateCheck(parms)
        .subscribe(response => {
            console.log("asdasd", response)
            if (response['data'] == true) {
              this.dialog.open(templateRef, { data: response['rrf_code'] });
              this.form_data.controls.pan_number.setValue("");
            } else {
              console.log("else")
            }
          }
        )
    } else{
      console.log('invLID')
    }
  }

  onBlurAadhar(templateRef: TemplateRef<any>, value) {
    console.log("sddfdsf", this.form_data.value.aadhar_number, this.form_data)
    // var pan_number = this.form_data.value.pan_number
    if (this.form_data.controls.aadhar_number.status == 'VALID') {
      var parms = {
        'aadhar_number': this.form_data.value.aadhar_number
      }
      this.skill_service.aadharDuplicateCheck(parms)
        .subscribe(response => {
            console.log("asdasd", response)
            if (response['data'] == true) {
              this.dialog.open(templateRef, { data: response['rrf_code'] });
              this.form_data.controls.aadhar_number.setValue("");
            } else {
              console.log("else")
            }
          }
        )
    } else{
      console.log('invLID')
    }
  }

  uploadMultiFile(templateRef: TemplateRef<any>) {
    // this.dialog.open(templateRef)
    const dialogRef = this.dialog.open(templateRef, {disableClose: false, width:'500px', height:'400x' });
    dialogRef.afterClosed().subscribe(result => {
      console.log('selected valu', this.pastEmp_data);
      this.form_data.controls.employement_letter.setValue(this.pastEmp_data);
    })
  }
}