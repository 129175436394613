import { Component, OnInit, Output } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { PushNotificationService } from "../services/push_notification.service";

@Component({
  selector: 'app-employee-skill-approve',
  templateUrl: './employee-skill-approve.component.html',
  styleUrls: ['./employee-skill-approve.component.css']
})
export class EmployeeSkillApproveComponent implements OnInit {
  mapping_data = new Array();
  @Output() employee_detail: any;
  proficiencies: any;
  approve_data = {};
  employee_id: any;
  roles: any;
  role_error: Boolean = false;
  current_role: any;
  units:any;
  practices:any;
  proficiency_error: Boolean = false;
  formdata: any;
  sub_practices:any;
  constructor(private push_notification_service: PushNotificationService, private skill_service: EmployeeSkillMappingService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.route.snapshot.params.employee_id) {
      this.employee_id = this.route.snapshot.params.employee_id;
    }
            
    this.formdata = new FormGroup({
      unit: new FormControl(),
      practice: new FormControl(),
      sub_practice: new FormControl(),
      role: new FormControl()
    });
    this.skill_service.getCompetencyMapping(this.employee_id, "m", null)
      .subscribe(
        response => {
          this.mapping_data = response["data"]["skill"];
          this.employee_detail = response["data"]["employee_detail"];
          this.current_role = response["data"]["current_role"];
         // console.log(this.employee_detail.band_name)
          for (var skill_value of this.mapping_data) {
            if(skill_value.strucure){
              this.approve_data[skill_value.employee_skill_set_id] = skill_value.employee_proficiency_id;
            }else{
              this.approve_data[skill_value.employee_skill_set_id] = "";
            }
            
            
          }
        }
      )
    // this.skill_service.getRole(this.employee_id)
    //   .subscribe(
    //     response => {
    //       this.roles = response["data"]["role_list"];
        
    //     }
    //   )
    this.skill_service.getProficiency()
      .subscribe(
        response => {
          this.proficiencies = response["data"];
        }
      );
      this.skill_service.getUnit("0")
      .subscribe(
        response => {
          this.units = response;
        });
        this.formdata.controls.unit.valueChanges.subscribe(value => {
          this.skill_service.getUnit(value)
            .subscribe(
              response => {
               
               this.practices = response;
              }
            )
         
        })
        this.formdata.controls.practice.valueChanges.subscribe(value => {
          this.skill_service.getUnit(value)
            .subscribe(
              response => {
               
                this.sub_practices = response
              }
            );
        });
        this.formdata.controls.sub_practice.valueChanges.subscribe(value => {
          this.skill_service.getRoleswithUnitPractice(this.formdata.controls["unit"].value,this.employee_id,this.formdata.controls["practice"].value,value)
            .subscribe(
              response => {
         this.roles = response["data"]["role_list"];
              }
            )
         
        })
  }
  clearAllSelected() {
    this.roles = [];
    this.formdata.controls["role"].setValue(null);
   // this.get_roles();
  }
  
  update_approve_list(ess_id, value) {
    if (this.approve_data[ess_id])
      delete this.approve_data[ess_id]
    this.approve_data[ess_id] = value;
  }
  check_disable() {
    if (Object.keys(this.approve_data).length == this.mapping_data.length)
      return false;
    else
      return true;
  }
  submit_data(current_role) {
    this.proficiency_error=false;
    for (let key in this.approve_data) {
      let value = this.approve_data[key];
      if(value==''){
        this.proficiency_error=true;
      }
    }
    if (current_role && !this.proficiency_error) {
      this.role_error = false;
      this.proficiency_error=false;
      var jsonObj = JSON.parse(localStorage.currentUser);
       this.skill_service.saveManagerProficiency(this.approve_data, this.employee_id, jsonObj.id, current_role)
         .subscribe(
           response => {
             this.push_notification_service.emitEventOnEmployeeSkillApprove({ "show_employee_id": this.employee_id, "first_name": jsonObj.first_name })
             this.router.navigate(['/employee_skill_approve_list']);
           }
         )
     }else if (current_role==undefined && this.proficiency_error){
      this.role_error = true;
      this.proficiency_error=true;
    } else if (current_role==undefined){
      this.role_error = true;
    }else if (this.proficiency_error){
      this.proficiency_error=true;
      this.role_error = false;
    }

  }

}
