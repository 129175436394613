import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatDialog, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeSkillMappingService } from '../services';
import html2canvas from 'html2canvas';
import { QuizService } from '../services/quiz.service';
import { BACK_END_URL } from '../shared/app.globals';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-external-add-employee',
  templateUrl: './external-add-employee.component.html',
  styleUrls: ['./external-add-employee.component.css']
})
export class ExternalAddEmployeeComponent implements OnInit {

  rrf_detail: any;
  @Output() rrf_id: any;
  roles: any;
  units: any;
  rrf_master_id: any
  sub_practices: any;
  searchName: any;
  showboolean: any
  practices: any;
  mapping_data: any;
  mapping_data_array: any;
  jsonObj: any;
  employee_data: any = [];
  employee_competency_array: any = [];
  select_emp_array: any = [];
  interview_panel: any = [];
  schedule_data: any = [];
  passcode_array: any = [];
  index = 0;
  singleSkillQA:any;
  formdata: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // displayedColumns: string[] = ['select', 'employee', 'unit', 'band', 'practice', 'sub_practice', 'created_on', 'role', 'fitment_score'];
  displayedColumns: string[] = ['select', 'employee', 'Email']
  employeeDisplayedColumns: string[] = ['employee', 'passcode', 'action', 'report']
  displayedtableColumns: string[] = ['skill_name','skill_mark','percentage'];
  singleSkillDisplayedColumns: string[] = ['position','question','correct_answer','employee_answer'];
  displayedColumnsData: string[] = ['schedule_time'];
  total_count: any;
  firstFormGroup: FormGroup;
  thirdtype: string;
  type_chart: string;
  dataFormat: string;
  width: number;
  height: number;
  candidate_image_path: any;
  emp_details: any;
  emp_count: any;
  emp_label_count: any[];
  emp_label_barchart: any[];
  week_assessment_count: any;
  ttl_count_data: any;
  single_candidate_percentage_chart: Object;
  emp_all_mark_barchart: any;
  singleDataSource:any;
  topicName: any;
  percentageValue: number;
  showReport: boolean;


  constructor(private router: Router, public dialog: MatDialog, private skill_service: EmployeeSkillMappingService, private route: ActivatedRoute, private quizService: QuizService, private location: Location) { }

  ngOnInit() {

    console.log('external-add');

    this.firstFormGroup = new FormGroup({
      first_name: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [Validators.required]),
      email_id: new FormControl("", [Validators.required]),
      phone_number: new FormControl("", [Validators.required])
    })

    if (this.route.snapshot.params.rrf_id) {
      this.rrf_id = this.route.snapshot.params.rrf_id;
      console.log("this.rrf_id", this.rrf_id);
      this.skill_service.getExternalCandidateDetails(this.rrf_id)
        .subscribe(
          response => {
            console.log("response", response);
            this.rrf_detail = response["data"]["rrf_detail"]["rrf_detail"];

          });
      this.skill_service.getExternalCandidateDetails(this.rrf_id)
        .subscribe(
          response => {
            console.log("response", response);
            this.select_emp_array = response['data']
          });
    }
    this.get_employee_list()
  }

  // openInterviewSchedule(status) {
  //   console.log("schedule_data", this.select_emp_array);
  //   const dialogRef = this.dialog.open(InterviewScheduleComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: { employee_detail: this.select_emp_array, status }, hasBackdrop: true, disableClose: true });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       // this.candidate_detail = new MatTableDataSource(result.reverse())
  //       // setTimeout(() => {
  //       //   this.candidate_detail.paginator = this.candidate_paginator;
  //       // });
  //     }
  //   });
  // }
  get_employee_list() {
    // this.skill_service.getInternalEmployeeFitmentList()
    //   .subscribe(
    //     response => {
    //       this.employee_data = response["data"]["employee_data"];
    //       console.log("adasd",this.employee_data)
    //       this.employee_competency_array = response["data"]["employee_competency_list"]
    //       console.log("asdsasdasd",this.employee_competency_array)
    //       console.log("this.employee_data", response["data"])
    //       var mapping_data_test = []
    //       if (this.employee_competency_array && this.employee_data) {
    //         console.log("dsfsdfsdfsdf")
    //         this.employee_data.forEach(function (emp_details) {
    //           response["data"]["employee_competency_list"].forEach(function (emp_comp_details) {
    //             if (emp_comp_details.employee_id == emp_details.emp_details.id) {
    //               var obj = {}
    //               obj['band_name'] = emp_details.emp_details.band_name;
    //               obj['mail_id'] = emp_details.emp_details.work_email_address;
    //               obj['employee_name'] = emp_details.emp_details.employee_name;
    //               obj['practice'] = emp_details.emp_details.practice;
    //               obj['sub_practice'] = emp_details.emp_details.sub_practice;
    //               obj['unit'] = emp_details.emp_details.unit;
    //               obj['fitment_score'] = emp_comp_details.fitment_score;
    //               obj['created_on'] = emp_comp_details.created_on;
    //               obj['current_role'] = emp_comp_details.current_role;
    //               obj['checked'] = false;
    //               mapping_data_test.push(obj)
    //             }
    //           })

    //         })
    //       }
    //       this.mapping_data = new MatTableDataSource(mapping_data_test);
    //       this.mapping_data_array = mapping_data_test
    //       console.log("this.mapping_data", this.mapping_data)
    //       this.mapping_data.paginator = this.paginator;
    //       this.total_count = mapping_data_test.length;
    //     }
    //   );

      this.skill_service.getAllEmployeeDetails()
      .subscribe(
        response => {
          console.log("data",response['data']['employees'])
          var mapping_data_test = []
          mapping_data_test = response['data']['employees']
          this.mapping_data = new MatTableDataSource(mapping_data_test);
                 this.mapping_data_array = mapping_data_test
                console.log("this.mapping_data", this.mapping_data)
                this.mapping_data.paginator = this.paginator;
                this.total_count = mapping_data_test.length;
          //console.log(response["data"]["managers"])
        });
  }

  applyFilter(filterValue: string) {
    console.log("filterValue", filterValue);
    if (filterValue != '') {
      this.showboolean = true
      this.mapping_data.filter = filterValue.trim().toLowerCase();
    } else {
      this.showboolean = false
    }
  }

  addEmployee() {
    console.log("asdasda");
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    // if(candidate_details.gender == 'm'){
    //   var emp_gender = "Male"
    // }else{
    //   var emp_gender = "Female"
    // }
    this.skill_service.getExternalCandidateDetails(rrf_master_id)
      .subscribe(
        response => {
          this.passcode_array = [];
          console.log("response", response);
          this.select_emp_array = response['data'];
          this.firstFormGroup.reset();
        //  var emp_details = this.select_emp_array.filter(data => candidate_details.itemName == data.candidate_name );
        // console.log("emp_details",emp_details,emp_details.length)
        //  if(emp_details.length != 0){
        //    console.log("ifcondition")
        //      if (confirm('Employee already added ')) {
        //         this.searchName = '';
        //         this.showboolean = false
        //         this.get_employee_list()
        //       } else {
        //         this.searchName = '';
        //         this.showboolean = false
        //         this.get_employee_list()
        //       }
        //  }else{
        //    console.log("elsecondition")
        //    this.jsonObj = JSON.parse(localStorage.currentUser);
        //        var uploaded_by = this.jsonObj.id
        //         var obj = {}
        //         var emp_array = []
        //         obj['candidate_name'] = candidate_details.itemName;
        //         obj['candidate_email'] = candidate_details.Email_id;
        //         obj['candidate_phone_number'] = '9876543210';
        //         obj['candidate_current_company'] = 'Hinduja Tech';
        //         obj['candidate_skill_set'] = 'MEAN STACK';
        //         obj['candidate_total_years_of_experience'] = '3';
        //         obj['candidate_relevant_years_of_experience'] = '3';
        //         obj['candidate_current_ctc'] = '30k';
        //         obj['candidate_expected_ctc'] = '30k';
        //         obj['candidate_notice_period'] = '15 days';
        //         obj['candidate_education'] = 'BE';
        //         obj['candidate_current_location'] = 'Chennai';
        //         obj['candidate_preferred_location'] = 'Chennai';
        //         obj['resume'] = 'uploaded_resume/1619765125575.doc';
        //         obj['rrf_master_id'] = rrf_master_id;
        //         obj['candidate_gender'] = 'MALE';
        //         obj['uploaded_by'] = uploaded_by;
        //         obj['uploaded_on'] = new Date();
        //         obj['status'] = 'Uploaded';
        //         console.log("obj", obj);
        //         emp_array.push(obj)
        //         console.log("emp_array", emp_array);
        //         if (emp_array) {
        //           this.skill_service.uploadInternalCandidate(emp_array)
        //             .subscribe(
        //               response => {
        //                 console.log("response", response);
        //                 if (response["data"] == true) {
        //                   this.showboolean = false;
        //                   this.searchName = '';
        //                   console.log("rrf_master_id", rrf_master_id);
        //                   this.skill_service.getInternalEmployeeDetail(rrf_master_id)
        //                     .subscribe(
        //                       response => {
        //                         console.log("response", response);
        //                         this.select_emp_array = response['data']
        //                       });
        //                 }
        //               });
        //         }
        //       }
        //       this.get_employee_list()
        })
  }

  onExport() {
    this.skill_service.getSkillReportListTest({})
      .subscribe(
        response => {
          FileSaver.saveAs(response, 'skill_report.xlsx');
        }
      );
  }

  GeneratePasscode() {
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    this.skill_service.getExternalCandidateDetails(rrf_master_id)
      .subscribe(
        response => {
          this.passcode_array = [];
          console.log("response", response);
          this.select_emp_array = response['data']
          if (this.select_emp_array.length != 0) {
            this.select_emp_array.forEach(data => {
              if (data.candidate_password) {
                console.log("data", data)
              } else
                this.passcode_array.push(data)

            })
            console.log("this.passcode_array", this.passcode_array);
            console.log("rrf_master_id", rrf_master_id);

            this.skill_service.getExternalCandidatePasscode(rrf_master_id,this.passcode_array)
              .subscribe(
                response => {
                  console.log(response,'pascoderesponse');
                  if (response['data'] == true) {
                    this.skill_service.getExternalCandidateDetails(rrf_master_id)
                      .subscribe(
                        response => {
                          this.select_emp_array = response['data']
                        });
                  }
                })
          }
        });
  }

  cmd_button(templateRef: TemplateRef<any>, id, cmd) {
    this.dialog.open(templateRef);
  }

  go_back() {
    this.location.back();
  }

  removeEmployee(emp_id) {
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    console.log("emp_id", emp_id);
    this.skill_service.deleteInternalEmployee(emp_id)
      .subscribe(
        response => {
          console.log("response", response);
          this.skill_service.getExternalCandidateDetails(rrf_master_id)
            .subscribe(
              response => {
                this.select_emp_array = response['data']
              });
        });
  }

  onSubmit() {
    var rrf_master_id = this.route.snapshot.params.rrf_id;
    console.log(this.firstFormGroup.value);
    var param = {
      candidate_name: this.firstFormGroup.value.first_name,
      candidate_last_name: this.firstFormGroup.value.last_name,
      candidate_email: this.firstFormGroup.value.email_id,
      candidate_phone_number: this.firstFormGroup.value.phone_number
      // candidate_password: 'gjhgg'
    }
    this.skill_service.createExternalCandidate(rrf_master_id, param)
      .subscribe(
        response => {
          console.log(response);
          this.addEmployee();
        });
  }

  cmd_button_single(templateRef : TemplateRef<any>,  candidate){
    console.log("asdasdasdas",candidate)
     console.log("week");
    
      this.quizService.get_external_employeeQA(candidate._id).subscribe((data: any) => {
        console.log("sdsdasd",data, data['data']['length'] )
        if(data['data']['length'] != 0) {
          this.showReport = true;
        } else {
          this.showReport = false;
        }
        this.week_assessment_count = data.data[1].page_length;
        this.topicName = data.data[1].topicId ;
        console.log(this.week_assessment_count, this.topicName)
        this.emp_count = 0
        if(data['data']){
          var emp_ans_dump = []
          data['data'].forEach((data) =>{
            if(data.questionName != 'Question'){
              emp_ans_dump.push(data)
            }
            if(data.marks == "1"){
              this.emp_count = this.emp_count + 1
              console.log("dsdfsdcount",this.emp_count)
            }
            console.log("asdsadfs",emp_ans_dump)
          })
          console.log("asdsad",emp_ans_dump)
          this.singleSkillQA = new MatTableDataSource(emp_ans_dump);
        }
        
      })
      setTimeout(() => {
      
      this.thirdtype = "doughnut2d";
      this.type_chart = "column2d";
      this.dataFormat = "json";
      this.width = 492;
      this.height = 300;
      if(candidate.candidate_image_path){
        this.candidate_image_path = BACK_END_URL + candidate.candidate_image_path
      }
      this.emp_details = candidate;
      console.log("aSas",this.candidate_image_path, candidate, this.emp_details );
      // this.emp_count = candidate.count
      this.dialog.open(templateRef);
       this.emp_label_count = []
       this.emp_label_barchart = []
      //  this.week_assessment_count = 60
       console.log("this.emp_count",this.emp_count)
       this.emp_label_count.push({"label":this.topicName,"value":this.emp_count})
       this.emp_label_barchart.push({"label":this.topicName,"value":this.emp_count})
        this.emp_label_barchart.push({"label":"Total Questions","value":this.week_assessment_count})
        this.ttl_count_data = this.week_assessment_count;
        var incorrect = this.ttl_count_data - this.emp_count;
        this.percentageValue = this.emp_count/this.ttl_count_data;
        console.log("adasdasdas",this.week_assessment_count, incorrect)
        const percantage_Source = {
          chart: {
            caption: "SUMMARY",
            // "subCaption": "Overall Skill Test Mark",
            "xAxisName": "Skill Name",
            "yAxisName": "Mark",
            "numberSuffix": "",
            "bgColor": "#EEEEEE",
            "theme": "fusion",
            // "theme": "Candy",
            "borderColor": "#00264d",
  "borderthickness":"2",
              "toolTipBgColor": "#666666",
              "toolTipBorderColor": "#666666",
              "toolTipColor": "000000",
              "toolTipBgAlpha": "80",
              "showToolTipShadow": "1",
              "exportEnabled": "1",
              "showBorder": "1",
              "pieRadius": "70"
        }, data: [{label: 'Incorrect', value: incorrect},
          {label: 'Correct', value: this.emp_count}]
      
        };
        this.single_candidate_percentage_chart = percantage_Source;
        
        const overall_barchart = {
          chart: {
              "caption": "MARKS SCORED",
              "subCaption": "Individual Skill Test Mark",
              "xAxisName": "Skill Name & Total Questions",
              "yAxisName": "Mark",
              "numberSuffix": "",
              "theme": "Candy",
              "borderColor": "#00264d",
              "borderthickness":"2",
                          "toolTipBgColor": "#666666",
                          "toolTipBorderColor": "#666666",
                          "toolTipColor": "000000",
                          "toolTipBgAlpha": "80",
                          "showToolTipShadow": "1",
                          "exportEnabled": "1",
                          "showBorder": "1",
          },
          data: this.emp_label_barchart
      }
      this.emp_all_mark_barchart = overall_barchart
      this.singleDataSource = new MatTableDataSource(this.emp_label_count);
    },1000);
  }

  convertToPDF(nam){
    var data = document.getElementById('contentToConvert');
    // html2canvas(data,{allowTaint: true,useCORS: true,scale: 0.8}).then(canvas => {
    //   var imgData = canvas.toDataURL('image/png');
    //   var imgWidth = 210; 
    //   var pageHeight = 295;  
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   var doc = new jsPDF('p', 'mm');
    //   var position = 10; // give some top padding to first page
      
    //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;
      
    //   while (heightLeft >= 0) {
    //     position += heightLeft - imgHeight; // top padding for other pages
    //     doc.addPage();
    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save( 'file.pdf');
    // })
    html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
      const image = { type: 'jpeg', quality: 0.98 };
      const margin = [0.3, 0.3];
      const filename = 'myfile.pdf';
  
      var imgWidth = 8.5;
      var pageHeight = 11;
  
      var innerPageWidth = imgWidth - margin[0] * 2;
      var innerPageHeight = pageHeight - margin[1] * 2;
  
      // Calculate the number of pages.
      var pxFullHeight = canvas.height;
      var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      var nPages = Math.ceil(pxFullHeight / pxPageHeight);
  
      // Define pageHeight separately so it can be trimmed on the final page.
      var pageHeight = innerPageHeight;
  
      // Create a one-page canvas to split up the full image.
      var pageCanvas = document.createElement('canvas');
      var pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;
  
      // Initialize the PDF.
      var pdf = new jsPDF('p', 'in', [8.5, 11]);
  
      for (var page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }
  
        // Display the page.
        var w = pageCanvas.width;
        var h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
  
        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
      }
  
      pdf.save(nam+'.pdf');
    }) 
   
  }

}
