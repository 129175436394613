import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MatSort } from '@angular/material';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-interviewer-profile-validation',
  templateUrl: './interviewer-profile-validation.component.html',
  styleUrls: ['./interviewer-profile-validation.component.css']
})
export class InterviewerProfileValidationComponent implements OnInit {
  rrf_list_data: any;
  employee_data: any;
  view_type = "employee";
  employee_id:String;
  arr_pes_chennai:boolean;
  arr_dts_chennai:boolean;
  arr_ena_chennai:boolean;
  arr_pes_pune:boolean;
  arr_dts_bangalore:boolean;
  arr_others:boolean;
  is_manager:boolean;
  form_data:any;
  status_list: any ;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['rrf_number','job_title', 'practice', 'experience', 'fitment_score', 'location', 'vacancies', 'job_code', 'requested_by', 'submitted_on', 'status'];
  
  constructor(  private router: Router, private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, public redirect: RedirectService) {

  }
  ngOnInit() {
    var jsonObj = JSON.parse(localStorage.currentUser);
     if (this.router.url == "/rrf_profile_validation") {
      this.view_type = "interviewer";
      //this.displayedColumns.splice(1, 0, "requested_by");
 } else {
      this.view_type = "rmg";
     // this.displayedColumns.splice(1, 0, "requested_by");
    }
  
        
    this.skill_service.getRRFListForInterviewer(this.view_type, jsonObj.id)
      .subscribe(
        response => {
          this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
          this.employee_id=jsonObj.id.toString();
          this.arr_pes_chennai=jsonObj.is_pes_ch;
          this.arr_dts_chennai=jsonObj.is_dts_ch;
          this.arr_ena_chennai=jsonObj.is_ena_ch;
          this.arr_pes_pune=jsonObj.is_pes_pu;
          this.arr_dts_bangalore=jsonObj.is_dts_ban;
          this.arr_others=jsonObj.is_others;
          this.is_manager=jsonObj.is_manager;
          this.rrf_list_data.sort = this.sort;  
        }
      );
  }

  onSubmit() {
    this.form_data.value["export"] = false;
    this.skill_service.getRRFReportList(this.form_data.value)
      .subscribe(
        response => {
          this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
        }
      );
  }

  redirectPage(page_link, rrf_id) {
    if (page_link == "/rrf_approve")
      this.redirect.change_page_with_data(page_link, { rrf_id, view_type: this.view_type });
    else
      this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  applyFilter(filterValue: string) {
    this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
