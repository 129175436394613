import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {  MatDialog } from '@angular/material';


import { QuizService } from '../services/quiz.service';


@Component({
  selector: 'app-eee-quizstart2',
  templateUrl: './eee-quizstart2.component.html',
  styleUrls: ['./eee-quizstart2.component.css']
})
export class EeeQuizstart2Component implements OnInit {
  showMainContent: Boolean = true;
  
  
  quiz_instruction: Boolean = false;
  name:string;
  skill_count:string;
  section:string;
  timing:number;
  line_hide:Boolean=false
  candidate_password:any;
  

  constructor(private router : Router,public quizService: QuizService, private dialog: MatDialog) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    this.candidate_password=localStorage.getItem('password');
    this.quizService.getSkill(this.name,this.candidate_password).subscribe(
      (data: any) => {
        console.log(data,'data-jxkh')
        this.skill_count =data.length;
       // alert(this.skill_count)
        if(this.skill_count=="2")
        {
           this.line_hide=true;
          this.section='2';
          this.timing=54;
        }
        else{


          this.line_hide=false;
          this.section='';
          //this.timing=60;
 
         
          this.timing=36
        }
         
        
      })
     
    

  }

  StartQuiz() {
    this.router.navigate(['/eee_quiz_view']);
  }
  
 
 Start_quiz()
 {
  this.quizService.chatAnswers(this.name,this.candidate_password).subscribe(data=>
    {
    });
   console.log('has')
  this.router.navigate(['/eee_quiz_view']);

 }
 Show_previous()
 {
  this.router.navigate(['/eee_quiz_start']);
 }

 save_button(templateRef : TemplateRef<any>) 
 {
    this.dialog.open(templateRef,{
      width: '30vw', height: '27vh', closeOnNavigation: true, autoFocus: false, hasBackdrop: true, disableClose: true
    });
 }

}
