import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MatSort, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { FileValidator } from 'node_modules_old/ngx-material-file-input';
import * as XLSX from 'xlsx';

export interface closeValue {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-rrf-list',
  templateUrl: './rrf-list.component.html',
  styleUrls: ['./rrf-list.component.css']
})
export class RrfListComponent implements OnInit {
  rrf_list_data: any;
  employee_data: any;
  view_type = "employee";
  employee_id:String;
  arr_pes_chennai:boolean;
  arr_dts_chennai:boolean;
  arr_ena_chennai:boolean;
  arr_pes_pune:boolean;
  arr_dts_bangalore:boolean;
  arr_others:boolean;
  is_manager:boolean;
  form_data:any;
  status_list: any ;
  rrf_count:any;
  jsonObj: any;
  showToggle = true;
  formData = new FormData();
  firstFormGroup: FormGroup;
  maxSize: number = 2097152;
  isBUH = false;
  isCOO = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedState: any;
  displayedColumns: string[] = ['rrf_code','job_title', 'practice', 'experience', 'fitment_score', 'location', 'vacancies', 'job_code', 'requested_by', 'submitted_on', 'status', 'position_change', 'edit_rrf','close_rr'];
  foods: closeValue[] = [
    {value: 'Internally_Fulfilled', viewValue: 'Internally Fulfilled'},
    {value: 'Externally_Fulfilled', viewValue: 'Externally Fulfilled'},
    {value: 'Cancelled_by_Client', viewValue: 'Cancelled by Client'},
    {value: 'Unable_to_Fulfill', viewValue: 'Unable to Fulfill'},
    {value: 'No_longer_relevant', viewValue: 'No Longer Relevant'}    
  ];
 
  constructor( private router: Router, private location: Location, private skill_service: EmployeeSkillMappingService, private _snackBar: MatSnackBar,  public dialog: MatDialog, public redirect: RedirectService) {

  }
  ngOnInit() {
    this.selectedState='request';
    this.jsonObj = JSON.parse(localStorage.currentUser);
    
    if (this.router.url == "/rrf_request_list") {
      this.view_type = "employee";
      this.status_list = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected', 'Recruiter Approved', 'Recruiter Rejected','Internal Fulfillment Approved', 'Internal Fulfillment Rejected', 'Closed'];
    } else if (this.router.url == "/rrf_approve_list") {
      this.view_type = "manager";
      this.displayedColumns.splice(1, 0, "requested_by");
      this.status_list = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected', 'Recruiter Approved', 'Recruiter Rejected','Internal Fulfillment Approved', 'Internal Fulfillment Rejected', 'Closed'];
    } else {
      this.view_type = "rmg";
      this.showToggle = false;
      this.displayedColumns.splice(1, 0, "requested_by");
      if( this.jsonObj.id.toString()=='2779'){
        // if(jsonObj.id.toString()=='5629' || jsonObj.id.toString()=='2779'){
        this.status_list = ['RM Approved','RMG Approved', 'Recruiter Accepted', 'Internal Fulfillment Approved', 'Closed'];
      }else{
        this.status_list = ['RMG Approved', 'Recruiter Accepted', 'Internal Fulfillment Approved', 'Closed'];
      }
    }
  
    if(this.jsonObj.id.toString()=='6329' || this.jsonObj.id.toString()=='3723' || this.jsonObj.id.toString()=='6732' ||this.jsonObj.id.toString()=='779') {
      this.isBUH = true;
    } else {
      this.isBUH = false;
    } 

    if(this.jsonObj.id.toString()=='33' ) {
      this.isCOO = true;
    } else {
      this.isCOO = false;
    } 

    this.firstFormGroup = new FormGroup({
      customer_job_description: new FormControl("", [FileValidator.maxContentSize(this.maxSize)])
     
    });
        
    this.rrfListTableValue()
  }

  rrfListTableValue() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    var rrf_list_value;
    this.skill_service.getRRFList(this.view_type, jsonObj.id)
    .subscribe(
      response => {
        this.rrf_count=response["data"]["rrf_counts"];        
        var temp = [];
        response["data"]["rrf_list"].forEach(item => {
        if(item.external_employee_status != undefined) {
          if(item.external_employee_status == 'No' && item.internal_employee_status == 'No') {
            temp.push(item);
          }   
        } else if(item.external_employee_status == undefined) {
          if( item.internal_employee_status == 'No') {
            temp.push(item);
          } 
        }
        });
        console.log(response["data"]["rrf_list"],temp,'list-comp')
        if(this.view_type == "manager") {
          rrf_list_value = temp.filter(element => element.status == 'Submitted' || element.status == 'RM Approved');
        } else if (this.jsonObj.id.toString()=='2779' && this.view_type == "rmg"  ) {
          rrf_list_value = temp.filter(element => element.status == 'RM Approved' || element.status == 'RMG Approved'); 
        } else if (this.jsonObj.id.toString()!='2779' && this.view_type == "rmg"  ) {
          rrf_list_value = temp.filter(element => element.status == 'RMG Approved' || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved'); 
        } else {
          rrf_list_value = temp;
        }
        console.log('rr_list', rrf_list_value, temp);
        this.rrf_list_data = new MatTableDataSource(rrf_list_value);

        this.employee_data = response["data"]["employee_detail"];
        this.rrf_list_data.paginator = this.paginator;
        this.employee_id=jsonObj.id.toString();
        this.arr_pes_chennai=jsonObj.is_pes_ch;
        this.arr_dts_chennai=jsonObj.is_dts_ch;
        this.arr_ena_chennai=jsonObj.is_ena_ch;
        this.arr_pes_pune=jsonObj.is_pes_pu;
        this.arr_dts_bangalore=jsonObj.is_dts_ban;
        this.arr_others=jsonObj.is_others;
        this.is_manager=jsonObj.is_manager;
        this.rrf_list_data.sort = this.sort;       
       }
    );
  }

  onSubmit() {
    this.form_data.value["export"] = false;
    this.skill_service.getRRFReportList(this.form_data.value)
      .subscribe(
        response => {
          this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
        }
      );
  }

  openPositionModal(item,id,rrf_code,status) {
    const dialogRef = this.dialog.open(RrfEditModal, { maxHeight: '90vh', closeOnNavigation: true, disableClose: true, data: {item:item, id: id,rrf_code:rrf_code,status:status} });
    dialogRef.afterClosed().subscribe(result => {
      this.rrfListTableValue();
    })
  }

   redirectPage(page_link, rrf_id) {

  if (page_link == "/rrf_approve")
       this.redirect.change_page_with_data(page_link, { rrf_id, view_type: this.view_type });
   else
     this.redirect.change_page_with_data(page_link, { rrf_id });
  }
 applyFilter(filterValue: string) {
     this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }
  change_page()
  { 
    this.router.navigate(['/rrf_creation']);
  }
  create_rrf()
  { 
    this.router.navigate(['/rrf_creation']);
  }
  approve_rrf()
  { 
    this.router.navigate(['/rrf_approve_list']);
  }
  approve_skill()
  { 
    this.router.navigate(['/employee_skill_approve_list']);
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
  go_back() {
    this.location.back();
  }
  // view rrf list on button click
  onRRButtonClick() {
    // this.skill_service.getRRFList(this.view_type, this.jsonObj.id)
    //   .subscribe(
    //     response => {
    //       this.rrf_count=response["data"]["rrf_counts"];
    //       this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
    //       this.employee_data = response["data"]["employee_detail"];
    //       this.rrf_list_data.paginator = this.paginator;
    //       this.employee_id=this.jsonObj.id.toString();
    //       this.arr_pes_chennai=this.jsonObj.is_pes_ch;
    //       this.arr_dts_chennai=this.jsonObj.is_dts_ch;
    //       this.arr_ena_chennai=this.jsonObj.is_ena_ch;
    //       this.arr_pes_pune=this.jsonObj.is_pes_pu;
    //       this.arr_dts_bangalore=this.jsonObj.is_dts_ban;
    //       this.arr_others=this.jsonObj.is_others;
    //       this.is_manager=this.jsonObj.is_manager;
    //       this.rrf_list_data.sort = this.sort;        }
    //   );
    this.rrfListTableValue();
    this.router.navigate(['/rrf_request_list']);

  }

  save_button(templateRef : TemplateRef<any>)
  {
    this.dialog.open(templateRef);
  }
  upload(file){
    this.formData = new FormData();
    this.formData.append("file", file);
  }

  onRRBulkSubmit() {
    this.skill_service.sendRRfBulkUpload(this.formData)
    .subscribe(
      response => {
        console.log("sdfsdfdf",response)
        // if(response[0].status != '') {
        //   this.rrfListTableValue();
        //   this.router.navigate(['/rrf_request_list']);
        //   this.openSnackBar();
        //   console.log("response",response)
        //   this.downloadxlxsFile(response)
        // }
      }
    );
  }
  downloadxlxsFile(data){
    console.log("downloaddata",data)
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
  XLSX.writeFile(workbook,'bulk-rrfupload-reference-data.xlsx');
  }
  openSnackBar() {
    this._snackBar.open('RR Uploaded Successfully', '', {
      duration: 5000,
    });
  }


  onChange(value, element) {
    console.log(value, element);
    let postValue = {rrfCode: element.rrf_code, role: element.role, close_reason: value, closed_by : this.jsonObj.id, closed_by_emp_id: this.jsonObj.employee_number};
    this.skill_service.closeRR(postValue)
    .subscribe( response=> {
      console.log(response);
      this._snackBar.open('RR Closed Successfully', '', {
        duration: 5000,
      });
      this.rrfListTableValue();
      this.router.navigate(['/rrf_request_list']);
    });
  }
  
  onEditClick(id) {
    console.log(id);
    this.router.navigate(['/rrf_edit', id]);
  }

  onToggleChange($event) {
    console.log($event.value);
    if(this.view_type == "rmg") {
      if($event.value == 'request') {
        this.  rrfListTableValue(); 
      } else if ($event.value == 'waiting') {
        this.router.navigate(['/rmg_job_list']);
      } 
    } else {
      if($event.value == 'request') {
        this.  rrfListTableValue(); 
      } else if ($event.value != 'request') {
        this.router.navigate(['/recruitment_job_list']);
      } 
    } 
  }

}



@Component({
  selector: 'rrf-edit-position',
  templateUrl: 'rrf-edit-position.html',
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class RrfEditModal {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RrfEditModal>,private skill_service: EmployeeSkillMappingService, ) { };
  src: any;
  no_of_position: any;
  rrf_code:any;
  status:any;
  ngOnInit() {
    console.log(this.data)
    this.no_of_position = this.data.item;
    this.rrf_code = this.data.rrf_code;
    this.status = this.data.status;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    var temp = {
      no_of_position : this.no_of_position,
      id: this.data.id,
      status:this.status

    }
    console.log(this.no_of_position, temp);

    this.skill_service.updateNoOfPosition(temp)
    .subscribe(
      response => {
      console.log(response)
      if(response['data'] == true) {
        this.dialogRef.close();
      }
    }
    )
  }

}
