import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { EmployeeSkillMappingService } from 'src/app/services/employee_skill_mapping.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cem-screen2',
  templateUrl: './cem-screen2.component.html',
  styleUrls: ['./cem-screen2.component.css']
})
export class CemScreen2Component implements OnInit {

  form_data : FormGroup;
  finalStatus: any;
  candidate_id: any;

  constructor(private location: Location, private skill_service: EmployeeSkillMappingService, private router: Router) { }

  ngOnInit() {

    this.candidate_id = JSON.parse(localStorage.getItem("candidate_detail_array"));
    
    if(this.candidate_id.cem_final_candidate_check_details == null) {
        this.form_data = new FormGroup({
          final_status: new FormControl(""),
          naukri_update_date: new FormControl(""),
          naukri_active_date: new FormControl(""),
          expected_ctc: new FormControl(""),
          holding_offer: new FormControl(""),
          preferred_location: new FormControl(""),    
          cem_remark2: new FormControl("")
        })
    } else if(this.candidate_id.cem_final_candidate_check_details != null) {
        this.form_data = new FormGroup({
          final_status: new FormControl(this.candidate_id.cem_final_candidate_check_details.final_status),
          naukri_update_date: new FormControl(this.candidate_id.cem_final_candidate_check_details.naukri_update_date),
          naukri_active_date: new FormControl(this.candidate_id.cem_final_candidate_check_details.naukri_active_date),
          expected_ctc: new FormControl(this.candidate_id.cem_final_candidate_check_details.expected_ctc),
          holding_offer: new FormControl(this.candidate_id.cem_final_candidate_check_details.holding_offer),
          preferred_location: new FormControl(this.candidate_id.cem_final_candidate_check_details.preferred_location),    
          cem_remark2: new FormControl(this.candidate_id.cem_final_candidate_check_details.cem_remark2)
        })
    }
  }

  onSubmit() {
  
    var id = JSON.parse(localStorage.getItem("candidate_detail_array"));
    // var data_form = JSON.parse(localStorage.getItem("cem_screen1"));
    console.log(JSON.parse(localStorage.getItem("cem_screen1")));
    console.log(this.form_data.value);
    // let merged = {...data_form, ...this.form_data.value};
    // console.log(merged,id,'merged')
    // this.form_data.value["candidate_id"] = id['_id'] 
    console.log(this.form_data.value,'merged1')
   
    this.skill_service.cemFinalCandidateCheckDetails(id['_id'] ,this.form_data.value)
      .subscribe(result => {
        console.log(result);
    this.router.navigate(['/cem-candidate-list']);
    })
  }

  go_back() {
    this.router.navigate(['/cem']);
  }

}
