import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from 'node_modules_old/@angular/material/dialog';

import { QuizService } from '../services/quiz.service';

@Component({
  selector: 'app-eee-quiz-start',
  templateUrl: './eee-quiz-start.component.html',
  styleUrls: ['./eee-quiz-start.component.css']
})
export class EeeQuizStartComponent implements OnInit {
  showMainContent: Boolean = true;
  
  start_quiz: Boolean = false;
  quiz_instruction: Boolean = false;
  name:string;
  skill_count:string;
  section:string;
  total_length:number;
  timing:number;
  line_hide:Boolean=false
  candidate_password:any;
  noQuestionperSkill:Number
  assessmentTiming: any;
  noOfQuestion: any;
  

  constructor(private router : Router,public quizService: QuizService) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    this.candidate_password=localStorage.getItem('password');
    this.quizService.getQuestions(this.name,this.candidate_password).subscribe(
      (data: any) => {
        console.log(data)
      this.assessmentTiming = data.assessment.time;
      this.noOfQuestion = data.assessment.no_of_qus
    
    this.quizService.getSkill(this.name,this.candidate_password).subscribe(
      (data: any) => {
        
        this.skill_count =data.length;
     
        if(this.skill_count=="2")
        {
          this.noQuestionperSkill=30
           this.line_hide=true;
          this.section='2';
          this.timing=2 * 18;
          this.total_length=30+30;
        }
     
        
        // else{


        //   //this.timing=60;
 
         
        //   this.timing=18
        // }
        else{
          if(this.skill_count=="1")
          {
            this.noQuestionperSkill=this.noOfQuestion;

            this.line_hide=false;
          this.section='';
          this.timing=this.assessmentTiming;
          this.total_length=this.assessmentTiming;
           
          }
          if(this.skill_count=="3")
          {
            this.noQuestionperSkill=30
           this.line_hide=true;
          this.section='3';
            this.total_length=3 * 30;
            this.timing=3*18
            
          }
          if(this.skill_count=="4")
          {
            this.noQuestionperSkill=30
            this.line_hide=true;
            this.section='4';
          
            this.total_length=4 * 30;
            this.timing=4 *18
           
          }
          if(this.skill_count=="5")
          {
            this.noQuestionperSkill=30
            this.line_hide=true;
            this.section='5';
            
            this.total_length=5 * 30;
            this.timing=5 *18
            
          }
   
          
         
    
        }
       
        
         
        
      })
    })
    

  }

  Showprevious() {
    this.router.navigate(['/eee_quiz_instruction']);
  }

 Show_Hide_Button()
 {
  
  this.router.navigate(['/eee_quiz_start2']);

 }
 
 Show_previous()
 {
  this.router.navigate(['/eee_quiz_instruction']);
 }

}
