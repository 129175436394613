import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { HrRrfApproveViewComponent } from 'src/app/hr-rrf-approve-view/hr-rrf-approve-view.component';
import { EmployeeSkillMappingService } from 'src/app/services';
import { TechnicalIofViewComponent } from 'src/app/technical-iof-view/technical-iof-view.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-cem-candidate-list',
  templateUrl: './cem-candidate-list.component.html',
  styleUrls: ['./cem-candidate-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})

export class CemCandidateListComponent implements OnInit {
  rrf_list_data: any;
  view_type: any = 'CEM';
  employee_data: any;
  expandedElement = null;
  show = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[] = ['sbu','recruiter', 'unit', 'band', 'location', 'rrf_id', 'requested_by', 'candidate_name', 'skill', 'contact_number', 'candidate_source', 'candidate_expected_ctc', 'grand_ctc', 'offer_released_on', 'candidate_doj', 'candidate_remarks', 'last_working_date', 'candidate_email', 'bgv','rm', 'hr_evaluation_result', 'status','action'];
  excel_data: any;
  // displayedColumns: string[] = ['sbu', ];

  constructor(private skill_service: EmployeeSkillMappingService,public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    localStorage.removeItem('candidate_detail_array');
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.getRRFCandidateApproveList(this.view_type, jsonObj.id)
      .subscribe(
        response => {
          if(this.view_type == "CEM"){
            var data = response["data"]["rrf_candidate_approve_list"]
            console.log("sdasdsdasd",data,response["data"]["rrf_candidate_approve_list"])
            // filter list by status 
            var newArray = data.filter(function (el) {
              if(el.candidate_detail['status'] == 'Offer Letter Accepted' || el.candidate_detail['status']  == 'Resignation Mail Shared' || el.candidate_detail['status'] == 'LWD Acceptance Shared' || el.candidate_detail['status'] == 'Resignation Acceptance Shared') {
                return el
              }
            });
            this.excel_data = newArray;
            this.rrf_list_data = new MatTableDataSource(newArray);
            console.log("asdasdas",newArray,this.excel_data)
            this.employee_data = response["data"]["employee_detail"];
            this.rrf_list_data.paginator = this.paginator;
          }          
        }
      );
  }

  onClick(id) {
    console.log(id)
    localStorage.setItem('candidate_detail_array', JSON.stringify(id));
    this.router.navigate(['/cem']);
  }

  applyFilter(filterValue: string) {
    this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }

  // ExportTOExcel() {
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rrf_list_data);
  //   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //   XLSX.writeFile(workbook, 'bulk-candidateupload-reference-data.xlsx');
  // }

  ExportTOExcel($event) {

    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.excel_data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // wb.Sheets.Sheet1.A.s = { font: { bold: true } };
    console.log(wb.Sheets.Sheet1)
    let range = XLSX.utils.decode_range(ws["!ref"]);

    XLSX.utils.book_append_sheet(wb, ws, 'CEM Report');

    /* save to file */
    XLSX.writeFile(wb, 'CEM Report ' + new Date() + '.xlsx');
  }

}
