import { Component, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MatSnackBar } from '@angular/material';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BACK_END_URL, FRONT_END_URL } from '../shared/app.globals';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { TechnicalIofViewComponent } from '../technical-iof-view/technical-iof-view.component';
import { HrRrfApproveViewComponent } from '../hr-rrf-approve-view/hr-rrf-approve-view.component';
@Component({
  selector: 'app-rrf-candidate-approve',
  templateUrl: './rrf-candidate-approve.component.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})
export class RrfCandidateApproveComponent implements OnInit {
  rrf_list_data: any;
  employee_data: any;
  BACK_END_URL = BACK_END_URL;
  view_type = "HR";
  expandedElement = null;
  files: any = [];
  tempHistory:any = []
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['rrf_id', 'requested_by', 'technical_iof_detail', 'hr_iof_detail', 'unit', 'band', 'role', 'candidate_name', 'status', 'action', 'revert', 'history'];
  constructor(private router: Router, private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, public redirect: RedirectService, private bottomSheet: MatBottomSheet) {

  }
  ngOnInit() {
    
    if (this.router.url == "/rrf_candidate_approve_buh") {
      this.view_type = "BUH";
    } else if (this.router.url == "/rrf_candidate_approve_rmg") {
      this.view_type = "RMG";
    } else if (this.router.url == "/rrf_candidate_approve_hr") {
      this.view_type = "HR";
    }
    else if (this.router.url == "/rrf_candidate_approve_cem") {
      this.view_type = "CEM";
    }
    else if (this.router.url == "/rrf_candidate_approve_hrOps") {
      this.view_type = "HROps";
    }
    this.getRRFCandidateList();
  }

  historyPopUp(ele, templateRef: TemplateRef<any>) {
    console.log(ele)
    this.tempHistory = [];
    this.tempHistory = ele.candidate_detail.loi_history[0].split(",");
    this.dialog.open(templateRef, {data:this.tempHistory,disableClose: false, width:'300px', height:'275px' });
    console.log(ele);
  }

  revertOffer(candidate_id, templateRef: TemplateRef<any>) {
    console.log('revert', candidate_id);
    this.dialog.open(templateRef, {data: candidate_id,disableClose: true});
  }

  getRevertOffer(data) {
    this.skill_service.getLOIHistory(data).subscribe((res:any) => {
      console.log(res);
      this.getRRFCandidateList();
    })
  }

  technicalOpenIOF(iof_detail, type) {
    console.log(iof_detail);
    this.dialog.open(TechnicalIofViewComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: iof_detail, hasBackdrop: true, disableClose: true });
  }
  hrOpenIOF(iof_detail, type) {
    console.log(iof_detail.hr_iof_detail);
    this.dialog.open(HrRrfApproveViewComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: iof_detail.hr_iof_detail, hasBackdrop: true, disableClose: true });
  }
  open_competency_popup(candidate_id,rrf_id) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateApproveModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type, rrf_id }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getRRFCandidateList();
    });
  }
  generate_indicative_offer_popup(candidate_id) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateIndicativeOfferModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getRRFCandidateList();
    });
  }

  candidate_acceptance_popup(candidate_id) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateAcceptModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getRRFCandidateList();
    });
  }

  hr_approval_popup(candidate_id) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateHRApprovalModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getRRFCandidateList();
    });
  }

  generate_CEM_popup(candidate_id) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateCemModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.getRRFCandidateList();
    });
  }

  open_ctc_approval_popup(candidate_id) {
    let candidate_detail = new Map();
    this.skill_service.getCandidateDetail(candidate_id)
      .subscribe(
        response => {
          console.log("asddgyystasd", response);
          candidate_detail.set("candidate_data", response["data"]);
          candidate_detail.set("candidate_id", candidate_id);
          candidate_detail.set("view_type", this.view_type);
          // candidate_detail.set("rrf_details", response["data"][]);
          this.bottomSheet.dismiss();
          const bottomSheetRef = this.bottomSheet.open(CandidateCTCApproveModal, { closeOnNavigation: true, data: candidate_detail, disableClose: true })
          bottomSheetRef.afterDismissed().subscribe(result => {
            this.getRRFCandidateList();
          })
        }
      );
  }

  generate_offer_letter_popup(candidate_id, rec_type, templateRef: TemplateRef<any>,rrf_code) {
    console.log(templateRef,'templateRef');
    this.dialog.closeAll();
    this.skill_service.getUpdatedOpenPosition(rrf_code).subscribe((res:any) => {
      console.log(res);
      if(res.result == true) {
          const dialogRef = this.dialog.open(templateRef,{
            width: '30vw', height: '27vh', closeOnNavigation: true, autoFocus: false, hasBackdrop: true,disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            this.getRRFCandidateList();
          });
      } else if(res.result == false) {
        const dialogRef = this.dialog.open(CandidateOfferLetterModal, {
           closeOnNavigation: true, data: { candidate_id, view_type: this.view_type, rec: rec_type, templateRef }, hasBackdrop: true, disableClose: true 
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getRRFCandidateList();
        });
      }
    })   
  }

  offer_acceptance_popup(candidate_id, rrf_id, open, closed) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(CandidateOfferModal, { closeOnNavigation: true, data: { candidate_id, view_type: this.view_type, rrf_id, open , closed }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
      this.getRRFCandidateList();
    });
  }

  getRRFCandidateList() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log("sdasdas", jsonObj)
    console.log("sdasdas", this.view_type)
    this.employee_data = [];
    this.skill_service.getRRFCandidateApproveList(this.view_type, jsonObj.id)
      .subscribe(
        response => {
          console.log(response)
          if (this.view_type == "CEM") {
            var data = response["data"]["rrf_candidate_approve_list"]
            console.log("sdasdsdasd", data, response["data"]["rrf_candidate_approve_list"])
            var newArray = data.filter(function (el) {
              return el.candidate_detail['status'] == 'HR Approved'
            });
            console.log("asdasdas", newArray)
            this.employee_data = response["data"]["employee_detail"];
            this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_candidate_approve_list"]);
            this.rrf_list_data.paginator = this.paginator;
          } else {
            this.employee_data = response["data"]["employee_detail"];
            this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_candidate_approve_list"]);
            this.rrf_list_data.paginator = this.paginator;
          }
          console.log(this.employee_data)
        }
      );
  }
  applyFilter(filterValue: string) {
    this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
@Component({
  selector: 'candidate-approve-modal',
  templateUrl: 'candidate-approve-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateApproveModal {
  constructor(private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateApproveModal>,private snackBar: MatSnackBar) { };
  reject_reason: any;
  FRONT_END_URL = FRONT_END_URL;

  ngOnInit() {
    console.log(this.data)
    this.reject_reason = new FormControl('', [Validators.required]);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  candidate_feedback(type) {
    console.log("type", type)
    if (type == "Approved") {
      this.reject_reason.setValidators(null);
    } else {
      this.reject_reason.setValidators([Validators.required]);
    }
    this.reject_reason.updateValueAndValidity();
    var status = '';
    if (this.reject_reason.status == "VALID") {
      console.log("ainside valid", type, this.data.view_type)
      var jsonObj = JSON.parse(localStorage.currentUser);
      if (this.data.view_type == 'RMG') {
        if (type == "Approved") {
          // status = "Documents Verified Recruiter";
          status = "Documents Verified";
        } else {
          status = "Candidate Documents Rejected";
        }
      } else if(this.data.view_type == 'HROps') {
        if (type == "Approved") {
          status = "Documents Verified HR Operations";
        } else {
          status = "Candidate Documents Rejected HROps";
        }
      } else {
        status = this.data.view_type + " " + type;
      }

      this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
        .subscribe(
          response => {
            this.dialogRef.close();
          }
        );
    }
  }

  
  sendUploadLink(url,id) {
    var update_object = {};
    update_object["url"] = url;
   
    const dialogRef = this.dialog.open(CandidateDocumentSendbackComponent, { closeOnNavigation: true, data: this.data.candidate_id, hasBackdrop: true, disableClose: true,width:'490px', height:'575px' });
    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
      // this.getRRFCandidateList();
    });
  }

}

@Component({
  selector: 'candidate-ctc-approve-modal',
  templateUrl: 'candidate-ctc-approve-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateCTCApproveModal {
  candidate_data: any;
  candidate_id: any;
  view_type: any;
  form_data: any;
  reject_reason: any;
  probation_period: any;
  probation_notice_period: any;
  reject_bool = false;
  constructor(private skill_service: EmployeeSkillMappingService, private bottomSheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public candidate_detail: any) { };
  ngOnInit() {

    this.candidate_data = this.candidate_detail.get("candidate_data");
    this.candidate_id = this.candidate_detail.get("candidate_id");
    this.view_type = this.candidate_detail.get("view_type");
    var result = this.candidate_data['rrf_detail']['rrf_detail']['unit']
    console.log("dedewf", result)
    if (result == '1') {
      this.probation_period = '3'
    } else {
      this.probation_period = '1'
    }
    this.form_data = new FormGroup({
      candidate_total_years_of_experience: new FormControl(this.candidate_data.candidate_data.candidate_total_years_of_experience, [Validators.required]),
      candidate_relevant_years_of_experience: new FormControl(this.candidate_data.candidate_data.candidate_relevant_years_of_experience, [Validators.required]),
      candidate_current_ctc: new FormControl(this.candidate_data.candidate_data.candidate_current_ctc, [Validators.required, Validators.minLength(6), Validators.pattern('^[0-9]*$')]),
      candidate_expected_ctc: new FormControl(this.candidate_data.candidate_data.candidate_expected_ctc, [Validators.required, Validators.minLength(6), Validators.pattern('^[0-9]*$')]),
      candidate_notice_period: new FormControl(this.candidate_data.candidate_data.candidate_notice_period, [Validators.required, Validators.pattern('^[0-9]*$')]),
      candidate_current_location: new FormControl(this.candidate_data.candidate_data.candidate_current_location, [Validators.required]),
      candidate_preferred_location: new FormControl(this.candidate_data.candidate_data.candidate_preferred_location, [Validators.required]),
      probation_notice_period: new FormControl(this.probation_period, [Validators.required]),
    });



    if (this.view_type == 'BUH') {
      this.form_data.get('candidate_total_years_of_experience').disable({ onlySelf: true });
      this.form_data.get('candidate_relevant_years_of_experience').disable({ onlySelf: true });
      this.form_data.get('candidate_current_ctc').disable({ onlySelf: true });
      this.form_data.get('candidate_expected_ctc').disable({ onlySelf: true });
      this.form_data.get('candidate_notice_period').disable({ onlySelf: true });
      this.form_data.get('candidate_current_location').disable({ onlySelf: true });
      this.form_data.get('candidate_preferred_location').disable({ onlySelf: true });
      if (result == '1') {
        this.form_data.get('probation_notice_period').disable({ onlySelf: true });
      }
      // probation_notice_period: new FormControl(this.probation_period, [Validators.required]),
      this.reject_reason = new FormControl('', [Validators.required]);
    }
  }

  closeDialog() {
    this.bottomSheetRef.dismiss();
  }

  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isNumberForTotalExp(event) {
    const pattern = /^\d*\.?\d*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  candidate_ctc_approval(type) {
    if(this.form_data.status == "VALID") {
      var jsonObj = JSON.parse(localStorage.currentUser);
      this.skill_service.candidateCTCApproval(this.form_data.value, this.candidate_id, jsonObj.id)
        .subscribe(
          response => {
            this.bottomSheetRef.dismiss();
          }
        );
    } else {
      console.log('invalid')
    }
  }

  candidate_feedback(type) {
    if (type == "Approved") {
      this.reject_reason.setValidators(null);
    } else {
      this.reject_bool = true;
      this.reject_reason.setValidators([Validators.required]);
    }

    // this.reject_reason.updateValueAndValidity();
    // var status = '';
    // if (this.reject_reason.status == "VALID") {
    //   var jsonObj = JSON.parse(localStorage.currentUser);

    //   status = "Practice Head " + type;

    //   this.skill_service.saveRRFCandidateApprove(this.candidate_id, status, jsonObj.id,type)
    //     .subscribe(
    //       response => {
    //         this.bottomSheetRef.dismiss();
    //       }
    //     );
    // }

    this.skill_service.CandidateDetail(this.candidate_id).subscribe(
      response => {
        console.log("adasdasd", response)
        if (type == 'Approved') {
          if (response['data']['sub_band'] == 'AT2' || response['data']['sub_band'] == 'DC') {
            this.reject_reason.updateValueAndValidity();
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var data_type = []
              data_type.push({ "probation_notice_period": this.form_data.value.probation_notice_period })
              data_type.push({ "reject_resason": "" })
              var jsonObj = JSON.parse(localStorage.currentUser);

              status = "Recruitment Head Approval Pending";

              this.skill_service.saveRRFCandidateApprove(this.candidate_id, status, jsonObj.id, type, data_type)
                .subscribe(
                  response => {
                    this.bottomSheetRef.dismiss();
                  }
                );
            }

          }
          else {
            var data_type = []
            data_type.push({ "probation_notice_period": this.form_data.value.probation_notice_period })
            data_type.push({ "reject_resason": "" })
            this.reject_reason.updateValueAndValidity();
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var jsonObj = JSON.parse(localStorage.currentUser);

              status = "Practice Head " + type;

              this.skill_service.saveRRFCandidateApprove(this.candidate_id, status, jsonObj.id, type, data_type)
                .subscribe(
                  response => {
                    this.bottomSheetRef.dismiss();
                  }
                );
            }
          }
        } else {
          this.reject_reason.updateValueAndValidity();
          var status = '';
          console.log("dwdqwdwq", this.reject_reason.value)
          console.log("dwdqwdwq", this.form_data.value.probation_notice_period)
          if (this.reject_reason.status == "VALID" && this.form_data.value.probation_notice_period != "") {
            var jsonObj = JSON.parse(localStorage.currentUser);
            var data_type = []
            // data_type['reject_resason'] = this.reject_reason.value
            // data_type['probation_notice_period'] = this.form_data.value.probation_notice_period

            data_type.push({ "probation_notice_period": this.form_data.value.probation_notice_period })
            data_type.push({ "reject_resason": this.reject_reason.value })
            // console.log("asdasdas",data);
            status = "Practice Head " + type;

            this.skill_service.saveRRFCandidateApprove(this.candidate_id, status, jsonObj.id, type, data_type)
              .subscribe(
                response => {
                  this.bottomSheetRef.dismiss();
                }
              );
          }
        }
      }
    );
  }

}

@Component({
  selector: 'candidate-indicative-offer-modal',
  templateUrl: 'candidate-indicative-offer-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateIndicativeOfferModal {
  constructor(private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateIndicativeOfferModal>) { };
  reject_reason: any;
  offer_letter_form_data: any;
  band_list: any = ['A', 'L', 'M', 'E', 'AC1', 'AT2', 'DC'];
  sub_band_list: any = ['AT2', 'A1', 'A2', 'L1', 'L2', 'M1', 'M2', 'M3', 'E1', 'E2', 'AC1', 'DC'];
  candidate_detail: any;
  dependent_detail_array: any = [];
  dependent_list: any = [];
  designation_list: any = [];
  formData = new FormData();

  ngOnInit() {
    this.offer_letter_form_data = new FormGroup({
      grand_ctc: new FormControl("", [Validators.required, Validators.minLength(6)]),
      band: new FormControl("", [Validators.required]),
      sub_band: new FormControl("", [Validators.required]),
      designation: new FormControl("", [Validators.required]),
      no_of_dependent: new FormControl("", [Validators.required]),
      dependent_detail: new FormArray([])
    });
    this.skill_service.getCandidateDetail(this.data.candidate_id)
      .subscribe(
        response => {
          this.candidate_detail = response["data"]["candidate_data"];
          this.designation_list = response["data"]["designation_list"];
          this.dependent_list = this.candidate_detail.candidate_documents.dependent_details;
          this.offer_letter_form_data.controls["no_of_dependent"].setValue(this.candidate_detail.candidate_documents.dependent_details.length);
          this.counter();
        }
      );


  }
  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  counter() {
    this.offer_letter_form_data.setControl('dependent_detail', new FormArray([]));
    let control = <FormArray>this.offer_letter_form_data.controls.dependent_detail;
    var i = this.offer_letter_form_data.controls["no_of_dependent"].value ? this.offer_letter_form_data.controls["no_of_dependent"].value : 1;
    this.dependent_detail_array = Array.from(Array(Number(i))).map((x, y) => y);
    this.dependent_detail_array.forEach(x => {
      control.push(this.patchValues(x));
    });

  }
  patchValues(index) {
    var relation = '';
    var age = '';
    if (this.dependent_list.length > 0) {
      relation = this.dependent_list[index]['relationship'];
      age = this.dependent_list[index]['age'];
    }
    return new FormGroup({
      age: new FormControl(age),
      relation: new FormControl(relation)

    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  generate_indicative_offer(templateRef: TemplateRef<any>) {
    
    
    // this.dialog.closeAll();
    if(this.offer_letter_form_data.status != 'INVALID') {

      const dialogRef = this.dialog.open(templateRef, { closeOnNavigation: true,  hasBackdrop: true,disableClose: false });

    //   console.log('valid')
    //   var jsonObj = JSON.parse(localStorage.currentUser);
    //   this.skill_service.generateIndicativeOfferLetter(this.data.candidate_id, this.offer_letter_form_data.value, jsonObj.id)
    //     .subscribe(
    //       response => {
    //         this.dialogRef.close();
    //       }
    //     );
    } else {
      console.log('invalid')
    }
   
  }
  upload(file){
    this.formData = new FormData();
    this.formData.append("file", file);
  }
  onRRBulkSubmit(){
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log(this.offer_letter_form_data.value, this.data.candidate_id, jsonObj.id, this.formData)
    // this.offer_letter_form_data.value['files'] = this.formData ;
    // this.offer_letter_form_data.addControl('file',this.formData);
    this.offer_letter_form_data['recruiter_id'] = jsonObj.id;
    this.formData.append("obj_data",JSON.stringify(this.offer_letter_form_data.value))
    console.log(this.offer_letter_form_data)

      this.skill_service.uploadIndicativeOfferLetter(this.data.candidate_id, this.formData, jsonObj.id )
        .subscribe(
          response => {
            console.log(response);
            // this.dialogRef.close();
                this.dialog.closeAll();

          }
        );
  }
}

@Component({
  selector: 'candidate-accept-modal',
  templateUrl: 'candidate-accept-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateAcceptModal {
  constructor(private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateApproveModal>) { };
  candidate_remarks: any;
  loi_status: any;
  form_data: any;
  maxSize: number = 2097152;
  accepted_file_exetension = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];
  file_url: any = '';
  ngOnInit() {
    this.form_data = new FormGroup({
      candidate_remarks: new FormControl('', [Validators.required]),
      loi_status: new FormControl('', [Validators.required]),
      acceptance_mail_copy: new FormControl("", [Validators.required, FileValidator.maxContentSize(this.maxSize)])
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  candidate_acceptance() {
    this.form_data.get('acceptance_mail_copy').markAsTouched();
    var jsonObj = JSON.parse(localStorage.currentUser);
    let file = this.form_data.value.acceptance_mail_copy.files[0];
    let fileName = file.name;
    const formData = new FormData();
    if (this.form_data.value.acceptance_mail_copy) {
      formData.append("file", this.form_data.value.acceptance_mail_copy.files[0]);
    }


    Object.keys(this.form_data.value).forEach((key) => {
      if (key == 'acceptance_mail_copy') {
        formData.append(key, fileName);
      } else {
        formData.append(key, this.form_data.value[key]);
      }

    });

    this.skill_service.candidateLOIAcceptance(this.data.candidate_id, formData, jsonObj.id)
      .subscribe(
        response => {
          this.dialogRef.close();
        }
      );
  }

}

@Component({
  selector: 'candidate-hr-approval-modal',
  templateUrl: 'candidate-hr-approval-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateHRApprovalModal {
  constructor(private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateIndicativeOfferModal>) { };
  form_data: any;
  candidate_detail: any;
  designation_list: any = [];
  salutation: any = ['Mr.', 'Ms.', 'Mrs.'];
  minDate = new Date();
  band_list: any = ['A', 'L', 'M', 'E', 'AC1', 'AT2', 'DC'];
  sub_band_list: any = ['AT2', 'A1', 'A2', 'L1', 'L2', 'M1', 'M2', 'M3', 'E1', 'E2', 'AC1', 'DC'];
  option_list: any = ['Yes', 'No'];
  rrf_detail: any;
  manager_name: any;
  rrf_field_value: any = {};
  joining_bonus_exist: boolean = false;
  dependent_detail_array: any = [];
  dependent_list: any = [];
  reject_reason: any;
  reject_bool = false;
  ngOnInit() {

    this.form_data = new FormGroup({
      ojf_ref_no: new FormControl("", [Validators.required]),
      rrf_no: new FormControl("", [Validators.required]),
      salutation: new FormControl("", [Validators.required]),
      candidate_name: new FormControl("", [Validators.required]),
      candidate_last_name: new FormControl("", [Validators.required]),
      candidate_dob: new FormControl("", [Validators.required]),
      candidate_education: new FormControl("", [Validators.required]),
      candidate_total_years_of_experience: new FormControl("", [Validators.required]),
      candidate_relevant_years_of_experience: new FormControl("", [Validators.required]),
      candidate_email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      candidate_address: new FormControl("", [Validators.required]),
      candidate_address1: new FormControl("", [Validators.required]),
      candidate_address2: new FormControl("", [Validators.required]),
      candidate_phone_number: new FormControl("", [Validators.required]),
      indicative_offer_on: new FormControl("", [Validators.required]),
      candidate_doj: new FormControl("", [Validators.required]),
      reporting_manager: new FormControl("", [Validators.required]),
      candidate_current_ctc: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$')]),
      candidate_current_company: new FormControl("", [Validators.required]),
      candidate_skill_set: new FormControl("", [Validators.required]),
      band: new FormControl("", [Validators.required]),
      sub_band: new FormControl("", [Validators.required]),
      designation: new FormControl("", [Validators.required]),
      candidate_current_location: new FormControl("", [Validators.required]),
      htl_location: new FormControl("", [Validators.required]),
      customer_name: new FormControl(""),
      candidate_source: new FormControl("", [Validators.required]),
      candidate_source_reference: new FormControl("", [Validators.required]),
      grand_ctc: new FormControl("", [Validators.required]),
      joining_bonus: new FormControl("", [Validators.required]),
      joining_bonus_amount: new FormControl(""),
      relocation_amount: new FormControl("", [Validators.required]),
      notice_reimbursement: new FormControl("", [Validators.required]),
      onetime_bonus: new FormControl("", [Validators.required]),
      contract_start_date: new FormControl(""),
      contract_end_date: new FormControl(""),
      // service_agreement: new FormControl("", [Validators.required]),
      dependent1: new FormControl(""),
      dependent2: new FormControl(""),
      dependent3: new FormControl("")
    });
    this.reject_reason = new FormControl("", [Validators.required])
    this.skill_service.getCandidateDetail(this.data.candidate_id)
      .subscribe(
        response => {

          this.candidate_detail = response["data"]["candidate_data"];
          this.designation_list = response["data"]["designation_list"];
          this.rrf_detail = response["data"]["rrf_detail"]["rrf_detail"];
          this.manager_name = response["data"]["manager_name"];

          if (this.candidate_detail.candidate_gender == 'Male') {
            this.form_data.controls.salutation.setValue("Mr.");
          } else if (this.candidate_detail.candidate_gender == 'Female') {
            this.form_data.controls.salutation.setValue("Ms.");
          }
          var rrf_code = this.rrf_detail.rrf_code.replace(/\D/g, '');
          if (this.rrf_detail.unit == '1' || this.rrf_detail.unit == '2') {
            this.form_data.controls.rrf_no.setValue("RRF/Delivery/" + rrf_code + "-1");
          } else {
            this.form_data.controls.rrf_no.setValue("RRF/SSU/" + rrf_code + "-1");
          }
          var month_names = ["Jan", "Feb", "Mar",
            "Apr", "May", "Jun",
            "Jul", "Aug", "Sep",
            "Oct", "Nov", "Dec"];

          var date = new Date();
          var month = date.getMonth();
          var year = date.getFullYear();
          var monthYear = month_names[month] + year.toString().slice(-2);

          if (this.rrf_detail.unit == '1') {
            this.form_data.controls.ojf_ref_no.setValue("HTL/" + monthYear + "/Delivery-PES/53726");
          } else if (this.rrf_detail.unit == '2') {
            this.form_data.controls.ojf_ref_no.setValue("HTL/" + monthYear + "/Delivery-DTS/53726");
          } else {
            this.form_data.controls.ojf_ref_no.setValue("HTL/" + monthYear + "/SSU-Enabling Function/53726");
          }
          this.form_data.controls.candidate_name.setValue(this.candidate_detail.candidate_name);
          this.form_data.controls.candidate_last_name.setValue(this.candidate_detail.candidate_last_name);
          this.form_data.controls.candidate_education.setValue(this.candidate_detail.candidate_education);
          this.form_data.controls.candidate_total_years_of_experience.setValue(this.candidate_detail.candidate_total_years_of_experience);
          this.form_data.controls.candidate_relevant_years_of_experience.setValue(this.candidate_detail.candidate_relevant_years_of_experience);
          this.form_data.controls.candidate_email.setValue(this.candidate_detail.candidate_email);
          this.form_data.controls.candidate_phone_number.setValue(this.candidate_detail.candidate_phone_number);
          this.form_data.controls.indicative_offer_on.setValue(this.candidate_detail.indicative_offer_on);
          this.form_data.controls.reporting_manager.setValue(this.manager_name);
          this.form_data.controls.candidate_current_ctc.setValue(this.candidate_detail.candidate_current_ctc);
          this.form_data.controls.candidate_current_company.setValue(this.candidate_detail.candidate_current_company);
          this.form_data.controls.candidate_skill_set.setValue(this.candidate_detail.candidate_skill_set);
          this.form_data.controls.band.setValue(this.candidate_detail.band);
          this.form_data.controls.sub_band.setValue(this.candidate_detail.sub_band);
          this.form_data.controls.designation.setValue(this.candidate_detail.designation);
          this.form_data.controls.candidate_current_location.setValue(this.candidate_detail.candidate_current_location);
          this.form_data.controls.htl_location.setValue(this.rrf_detail.base_location);
          this.form_data.controls.candidate_source.setValue(this.candidate_detail.candidate_source);
          if (this.candidate_detail.candidate_source != 'Employee Referral') {
            this.form_data.controls.candidate_source_reference.setValue(this.candidate_detail.candidate_source_reference);
          } else {
            this.form_data.controls.candidate_source_reference.setValue(this.candidate_detail.employee_reference.employee_name);
          }
          this.form_data.controls.grand_ctc.setValue(this.candidate_detail.grand_ctc);
          this.form_data.controls.candidate_dob.setValue(this.candidate_detail.candidate_dob);
          this.form_data.controls.candidate_doj.setValue(this.candidate_detail.candidate_doj);
          this.form_data.controls.joining_bonus.setValue(this.candidate_detail.joining_bonus);
          this.form_data.controls.joining_bonus_amount.setValue(this.candidate_detail.joining_bonus_amount);
          this.form_data.controls.relocation_amount.setValue(this.candidate_detail.relocation_amount);
          this.form_data.controls.notice_reimbursement.setValue(this.candidate_detail.notice_reimbursement);
          this.form_data.controls.onetime_bonus.setValue(this.candidate_detail.onetime_bonus);
          this.form_data.controls.contract_start_date.setValue(this.candidate_detail.contract_start_date);
          this.form_data.controls.contract_end_date.setValue(this.candidate_detail.contract_end_date);
          // this.form_data.controls.service_agreement.setValue(this.candidate_detail.service_agreement);
          this.form_data.controls.candidate_address.setValue(this.candidate_detail.candidate_address);
          this.form_data.controls.candidate_address1.setValue(this.candidate_detail.candidate_address1);
          this.form_data.controls.candidate_address2.setValue(this.candidate_detail.candidate_address2);
          this.form_data.controls.candidate_last_name.setValue(this.candidate_detail.candidate_last_name);

          this.skill_service.getRRFEditDetail(this.rrf_detail._id)
            .subscribe(
              response => {
                this.rrf_field_value["customer"] = response["data"]["customer"];
              });
          this.form_data.controls.customer_name.setValue(this.rrf_detail.customer_name);

          this.dependent_list = this.candidate_detail.candidate_documents.dependent_details;
          var i = this.candidate_detail.candidate_documents.dependent_details.length;
          console.log(this.dependent_list.length, i,this.candidate_detail.candidate_documents.marital_status )
          this.dependent_detail_array = Array.from(Array(Number(i))).map((x, y) => y);
          this.dependent_detail_array.forEach(x => {
            var relation = this.dependent_list[x]['relationship'];
            var age = this.dependent_list[x]['age'];
            console.log(this.dependent_list[x]['age'],this.dependent_list[x]['relationship'] )
            if (this.candidate_detail.candidate_documents.marital_status == 'Single') {
              if (this.dependent_list.length == 1) {
                if (relation == 'Father' || relation == 'Mother') {
                  this.form_data.controls.dependent1.setValue(age);
                }
              } else if (this.dependent_list.length == 2) {
                if (relation == 'Father') {
                  this.form_data.controls.dependent1.setValue(age);
                } else if (relation == 'Mother') {
                  this.form_data.controls.dependent2.setValue(age);
                }
              }
            } else {
              if (this.dependent_list.length == 4) {
                if (relation == 'Spouse') {
                  this.form_data.controls.dependent1.setValue(age);
                } else if (relation == 'Son') {
                  this.form_data.controls.dependent2.setValue(age);
                } else if (relation == 'Daughter') {
                  this.form_data.controls.dependent3.setValue(age);
                }

              } else if (this.dependent_list.length == 3) {
                if (relation == 'Spouse') {
                  this.form_data.controls.dependent1.setValue(age);
                } else if (relation == 'Son' || relation == 'Daughter') {
                  this.form_data.controls.dependent2.setValue(age);
                }
              } else if (this.dependent_list.length == 2) {
                if (relation == 'Spouse' || relation == 'Son' || relation == 'Daughter') {
                  this.form_data.controls.dependent1.setValue(age);
                }
              }
            }

          });

          if (this.candidate_detail.status == 'Recruitment Head Approval Pending' || this.candidate_detail.status == 'Recruitment Head Approved') {

            this.form_data.get('salutation').disable({ onlySelf: true });
            this.form_data.get('rrf_no').disable({ onlySelf: true })
            this.form_data.get('candidate_name').disable({ onlySelf: true })
            this.form_data.get('candidate_last_name').disable({ onlySelf: true })
            this.form_data.get('candidate_education').disable({ onlySelf: true })
            this.form_data.get('candidate_total_years_of_experience').disable({ onlySelf: true })
            this.form_data.get('candidate_relevant_years_of_experience').disable({ onlySelf: true })
            this.form_data.get('candidate_email').disable({ onlySelf: true })
            this.form_data.get('candidate_phone_number').disable({ onlySelf: true })
            this.form_data.get('indicative_offer_on').disable({ onlySelf: true })
            this.form_data.get('reporting_manager').disable({ onlySelf: true })
            this.form_data.get('candidate_current_ctc').disable({ onlySelf: true })
            this.form_data.get('candidate_current_company').disable({ onlySelf: true })
            this.form_data.get('candidate_skill_set').disable({ onlySelf: true })
            this.form_data.get('band').disable({ onlySelf: true })
            this.form_data.get('sub_band').disable({ onlySelf: true })
            this.form_data.get('designation').disable({ onlySelf: true })
            this.form_data.get('candidate_current_location').disable({ onlySelf: true })
            this.form_data.get('htl_location').disable({ onlySelf: true })
            this.form_data.get('candidate_source').disable({ onlySelf: true })
            this.form_data.get('candidate_source_reference').disable({ onlySelf: true })
            this.form_data.get('grand_ctc').disable({ onlySelf: true })
            this.form_data.get('candidate_dob').disable({ onlySelf: true })
            this.form_data.get('candidate_doj').disable({ onlySelf: true })
            this.form_data.get('joining_bonus').disable({ onlySelf: true })
            this.form_data.get('joining_bonus_amount').disable({ onlySelf: true })
            this.form_data.get('relocation_amount').disable({ onlySelf: true })
            this.form_data.get('notice_reimbursement').disable({ onlySelf: true })
            this.form_data.get('onetime_bonus').disable({ onlySelf: true })
            this.form_data.get('contract_start_date').disable({ onlySelf: true })
            this.form_data.get('contract_end_date').disable({ onlySelf: true })
            // this.form_data.get('service_agreement').disable({ onlySelf: true })
            this.form_data.get('candidate_address').disable({ onlySelf: true })
            this.form_data.get('candidate_address1').disable({ onlySelf: true })
            this.form_data.get('candidate_address2').disable({ onlySelf: true })
            this.form_data.get('dependent1').disable({ onlySelf: true })
            this.form_data.get('dependent2').disable({ onlySelf: true })
            this.form_data.get('dependent3').disable({ onlySelf: true })
            this.form_data.get('customer_name').disable({ onlySelf: true })
            this.form_data.get('ojf_ref_no').disable({ onlySelf: true })
          }

        });



    this.form_data.controls.joining_bonus.valueChanges.subscribe(value => {
      if (value == 'Yes') {
        this.joining_bonus_exist = true;
        this.form_data.controls.joining_bonus_amount.setValidators([Validators.required]);
      } else if (value == 'No') {
        this.form_data.controls.joining_bonus_amount.setValidators(null);
        this.joining_bonus_exist = false;
      }
    });


  }
  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isNumberForTotalExp(event) {
    const pattern = /^\d*\.?\d*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  recruitment_head_approval() {
    console.log("inside log", this.form_data.value)
    var jsonObj = JSON.parse(localStorage.currentUser);
    if (this.form_data.valid) {
      this.skill_service.saveCandidateApprovalData(this.data.candidate_id, this.form_data.value, jsonObj.id)
        .subscribe(
          response => {
            this.dialogRef.close();
          }
        );
    }

  }

  candidate_feedback(type) {
    if (type == "Approved") {
      this.reject_reason.setValidators(null);
    } else {
      this.reject_bool = true;
      this.reject_reason.setValidators([Validators.required]);
    }
    this.skill_service.CandidateDetail(this.data.candidate_id).subscribe(
      response => {
        console.log("asdsed", response['data'])
        if (type == 'Approved') {
          if (response['data']['band'] == 'E' || response['data']['band'] == 'M') {
            console.log("insidesdfsfsdf")
            this.reject_reason.updateValueAndValidity();
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var jsonObj = JSON.parse(localStorage.currentUser);

              status = "Recruitment Head Approved";

              this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
                .subscribe(
                  response => {
                    this.dialogRef.close();
                  }
                );
            }

          }
          else {
            this.reject_reason.updateValueAndValidity();
            console.log("insideelse")
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var jsonObj = JSON.parse(localStorage.currentUser);


              status = "Tag Head Approved";

              this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
                .subscribe(
                  response => {
                    this.dialogRef.close();
                  }
                );
            }
          }
        } else {
          // this.reject_reason.updateValueAndValidity();
          // var status = '';
          // if (this.reject_reason.status == "VALID") {
          //   var jsonObj = JSON.parse(localStorage.currentUser);

          //   status = "Recruitment Head Rejected";

          //   this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type,"")
          //     .subscribe(
          //       response => {
          //         this.dialogRef.close();
          //       }
          //     );
          // }
          if (response['data']['band'] == 'E' || response['data']['band'] == 'M') {
            this.reject_reason.updateValueAndValidity();
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var jsonObj = JSON.parse(localStorage.currentUser);
              status = "Recruitment Head Rejected";
              this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
                .subscribe(
                  response => {
                    this.dialogRef.close();
                  }
                );
            }

          }
          else {
            this.reject_reason.updateValueAndValidity();
            var status = '';
            if (this.reject_reason.status == "VALID") {
              var jsonObj = JSON.parse(localStorage.currentUser);
              status = "Tag Head Rejected";
              this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
                .subscribe(
                  response => {
                    this.dialogRef.close();
                  }
                );
            }
          }
        }
      }
    );
  }

  candidate_feedback_hr(type) {
    console.log("asawqasdad",type)
    if (type == "Approved") {
      this.reject_reason.setValidators(null);
    } else {
      this.reject_reason.setValidators([Validators.required]);
    }
    this.skill_service.CandidateDetail(this.data.candidate_id).subscribe(
      response => {
        console.log("asdsed", response['data'])
        if (type == 'Approved') {
          console.log("insidesdfsfsdf")
          this.reject_reason.updateValueAndValidity();
          var status = '';
          if (this.reject_reason.status == "VALID") {
            var jsonObj = JSON.parse(localStorage.currentUser);
            status = "HR Approved";
            this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
              .subscribe(
                response => {
                  this.dialogRef.close();
                }
              );
          }
        }
        else {
          this.reject_reason.updateValueAndValidity();
          console.log("insideelse")
          var status = '';
          if (this.reject_reason.status == "VALID") {
            var jsonObj = JSON.parse(localStorage.currentUser);
            status = "HR Rejected";
            this.skill_service.saveRRFCandidateApprove(this.data.candidate_id, status, jsonObj.id, type, "")
              .subscribe(
                response => {
                  this.dialogRef.close();
                }
              );
          }
        }
      }
    );
  }
}

@Component({
  selector: 'candidate-offer-letter-modal',
  templateUrl: 'candidate-offer-letter-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateOfferLetterModal {
  constructor(private skill_service: EmployeeSkillMappingService, private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateIndicativeOfferModal>, public dialog: MatDialog) { };
  reject_reason: any;
  offer_letter_form_data: any;
  band_list: any = ['A', 'L', 'M', 'E', 'AC1', 'AT2', 'DC'];
  sub_band_list: any = ['AT2', 'A1', 'A2', 'L1', 'L2', 'M1', 'M2', 'M3', 'E1', 'E2', 'AC1', 'DC'];
  candidate_detail: any;
  dependent_detail_array: any = [];
  dependent_list: any = [];
  designation_list: any = [];
  rrf_detail: any;
  probation_period; any;
  minDate = new Date();
  view_type: any;
  formData = new FormData();
  ngOnInit() {
    this.view_type = this.data.view_type
    this.offer_letter_form_data = new FormGroup({
      grand_ctc: new FormControl("", [Validators.required]),
      band: new FormControl("", [Validators.required]),
      sub_band: new FormControl("", [Validators.required]),
      designation: new FormControl("", [Validators.required]),
      candidate_address: new FormControl("", [Validators.required]),
      candidate_address1: new FormControl("", [Validators.required]),
      candidate_address2: new FormControl("", [Validators.required]),
      candidate_doj: new FormControl("", [Validators.required]),
      base_location: new FormControl("", [Validators.required]),
      no_of_dependent: new FormControl("", [Validators.required]),
      dependent_detail: new FormArray([])
    });
    this.get_candidate_fn()


  }
  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  counter() {
    this.offer_letter_form_data.setControl('dependent_detail', new FormArray([]));
    let control = <FormArray>this.offer_letter_form_data.controls.dependent_detail;
    var i = this.offer_letter_form_data.controls["no_of_dependent"].value ? this.offer_letter_form_data.controls["no_of_dependent"].value : 1;
    this.dependent_detail_array = Array.from(Array(Number(i))).map((x, y) => y);
    this.dependent_detail_array.forEach(x => {
      control.push(this.patchValues(x));
    });

  }
  patchValues(index) {
    var relation = '';
    var age = '';
    if (this.dependent_list.length > 0) {
      relation = this.dependent_list[index]['relationship'];
      age = this.dependent_list[index]['age'];

    }
    return new FormGroup({
      age: new FormControl(age),
      relation: new FormControl(relation)

    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  generate_offer_letter(templateRef: TemplateRef<any>) {
    console.log("generateoffer",this.data);
    if(this.offer_letter_form_data.valid) {
      // get_candidate_rrf_detail

      // temp comment

      // this.skill_service.getCandidateRRFDetail(this.data.candidate_id)
      // .subscribe(
      //   response => {
      //     console.log("ssdewerfddf",response);
      //     // if(response['count_bool']) {
      //       this.skill_service.generateOfferLetter(this.data.candidate_id, this.offer_letter_form_data.value, this.probation_period)
      //       .subscribe(
      //         response => {
      //           // this.dialogRef.close();
      //           const dialogRef = this.dialog.open(CandidateOfferLetterPreviewModal, { maxHeight: '90vh', closeOnNavigation: true, data: { filename: response["data"], candidate_id: this.data.candidate_id, view_type: this.data.view_type, rec: this.data.rec }, hasBackdrop: true, disableClose: true });
      //           dialogRef.afterClosed().subscribe(result => {
      //             console.log(dialogRef.componentInstance.closeMessage);
      //             if(dialogRef.componentInstance.closeMessage == 'true') {
      //               console.log('dont close')
      //             } else {
      //             this.dialogRef.close();
      //               // window.location.reload();
      //               // this.get_candidate_fn()
      //               // 
      //               // this.router.navigate(['/rrf_candidate_approve_rmg']);
      //               // 
      //               //  generate_offer_letter_status()
      //               console.log("conoellog");
      //             }
      //           });
      //         }
      //       );
          
      //     // } else if (!response['count_bool']) {
      //     //   // console.log(templateRef)
      //     //   this.dialog.open(this.data.templateRef);
      //     // }
      //   })

      // temp comment

      const dialogRef = this.dialog.open(templateRef, { closeOnNavigation: true,  hasBackdrop: true, disableClose: false });
        dialogRef.afterClosed().subscribe(result => {
                    console.log(dialogRef.componentInstance.closeMessage);
                    if(dialogRef.componentInstance.closeMessage == 'true') {
                      console.log('dont close')
                    } else {
                    this.dialogRef.close();
                      // window.location.reload();
                      // this.get_candidate_fn()
                      // 
                      // this.router.navigate(['/rrf_candidate_approve_rmg']);
                      // 
                      //  generate_offer_letter_status()
                      console.log("conoellog");
                    }
                  })
      } else {
        console.log('invalid')
      }
    
  }

  // temprory coding
  release_offer_letter() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.releaseOfferLetter(this.data.candidate_id, jsonObj.id)
      .subscribe(
        response => {
          this.dialogRef.close();
          if (this.data.view_type == "BUH") {
            this.router.navigate(['/rrf_candidate_approve_buh']);
          } else if (this.data.view_type == "RMG") {
            this.router.navigate(['/rrf_candidate_approve_rmg']);
          } else if (this.data.view_type == "HR") {
            this.router.navigate(['/rrf_candidate_approve_hr']);
          } else if (this.data.view_type == "CEM") {
            this.router.navigate(['/rrf_candidate_approve_cem']);
          } else if (this.data.view_type == "HROps") {
            this.router.navigate(['/rrf_candidate_approve_hrOps']);
          }
        }); 

  }

  // temprory coding

  get_candidate_fn() {
    this.candidate_detail = [];
    this.skill_service.getCandidateDetail(this.data.candidate_id)
      .subscribe(
        response => {
          this.candidate_detail = response["data"]["candidate_data"];
          this.probation_period = this.candidate_detail.probation_notice_period
          console.log("candidate_detail", this.candidate_detail)
          this.designation_list = response["data"]["designation_list"];
          this.rrf_detail = response["data"]["rrf_detail"]["rrf_detail"];
          console.log("adsgahsfdty", this.rrf_detail)
          this.dependent_list = this.candidate_detail.candidate_documents.dependent_details;

          this.offer_letter_form_data.controls.grand_ctc.setValue(this.candidate_detail.grand_ctc);
          this.offer_letter_form_data.controls.band.setValue(this.candidate_detail.band);
          this.offer_letter_form_data.controls.sub_band.setValue(this.candidate_detail.sub_band);
          this.offer_letter_form_data.controls.designation.setValue(this.candidate_detail.designation);
          this.offer_letter_form_data.controls.candidate_address.setValue(this.candidate_detail.candidate_address);
          this.offer_letter_form_data.controls.candidate_address1.setValue(this.candidate_detail.candidate_address1);
          this.offer_letter_form_data.controls.candidate_address2.setValue(this.candidate_detail.candidate_address2);
          this.offer_letter_form_data.controls.candidate_doj.setValue(this.candidate_detail.candidate_doj);
          this.offer_letter_form_data.controls.base_location.setValue(this.rrf_detail.base_location);
          this.offer_letter_form_data.controls["no_of_dependent"].setValue(this.candidate_detail.candidate_documents.dependent_details.length);
          this.counter();
        }
      );
  }
  
  upload(file){
    this.formData = new FormData();
    this.formData.append("file", file);
  }
  onRRBulkSubmit(){
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log(this.offer_letter_form_data.value, this.data.candidate_id, jsonObj.id, this.formData)
    this.formData.append("obj_data",JSON.stringify(this.offer_letter_form_data.value));
    // this.formData['obj_data']['recruiter_id'] = jsonObj.id;
    console.log(this.offer_letter_form_data)

      this.skill_service.uploadOfferLetter(this.data.candidate_id, this.formData, this.probation_period,jsonObj.id)
        .subscribe(
          response => {
            console.log(response);
            this.dialog.closeAll();
          }
        );
  }
}




@Component({
  selector: 'candidate-offer-letter-preview',
  templateUrl: 'candidate-offer-letter-preview.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateOfferLetterPreviewModal {
  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private skill_service: EmployeeSkillMappingService, public dialogRef: MatDialogRef<CandidateOfferLetterPreviewModal>) { };
  src: any;
  candidate_id: any;
  file_name: any;
  BACK_END_URL = BACK_END_URL;
  view_type: any;
  closeMessage: string = "False"; 
  ngOnInit() {
    console.log("asdasd", this.data)
    this.candidate_id = this.data.candidate_id;
    if (this.data.rec == '') {
      this.view_type = this.data.view_type;
    } else {
      this.view_type = this.data.ass_rec;
    }

    this.file_name = this.data.filename.toString();
  }
  release_offer_letter() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.releaseOfferLetter(this.data.candidate_id, jsonObj.id)
      .subscribe(
        response => {
          this.dialogRef.close();
          if (this.data.view_type == "BUH") {
            this.router.navigate(['/rrf_candidate_approve_buh']);
          } else if (this.data.view_type == "RMG") {
            this.router.navigate(['/rrf_candidate_approve_rmg']);
          } else if (this.data.view_type == "HR") {
            this.router.navigate(['/rrf_candidate_approve_hr']);
          } else if (this.data.view_type == "CEM") {
            this.router.navigate(['/rrf_candidate_approve_cem']);
          } else if (this.data.view_type == "HROps") {
            this.router.navigate(['/rrf_candidate_approve_hrOps']);
          }
        });

  }

  generate_offer_letter_status() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.skill_service.generateOfferLetterstatus(this.data.candidate_id, jsonObj.id)
      .subscribe(
        response => {
          this.dialogRef.close();
          if (this.data.view_type == "BUH") {
            this.router.navigate(['/rrf_candidate_approve_buh']);
          } else if (this.data.view_type == "RMG") {
            this.router.navigate(['/rrf_candidate_approve_rmg']);
          } else if (this.data.view_type == "CEM") {
            console.log("generateofferletterstatus")
            this.router.navigate(['/rrf_candidate_approve_cem']);
          } else if (this.data.view_type == "HR") {
            this.router.navigate(['/rrf_candidate_approve_hr']);
          } else if (this.data.view_type == "HROps") {
            this.router.navigate(['/rrf_candidate_approve_hrOps']);
          }
        });

  }

  closeDialog(item) {
    console.log("test")
    this.closeMessage = item;
    console.log(this.closeMessage);
    // this.router.navigate(['/rrf_candidate_approve_cem']);
    this.dialogRef.close();

  }

}
@Component({
  selector: 'candidate-cem-modal',
  templateUrl: 'candidate-cem-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateCemModal {
  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private skill_service: EmployeeSkillMappingService, public dialogRef: MatDialogRef<CandidateCemModal>) { };
  src: any;
  candidate_id: any;
  candidate_remarks: any;
  file_name: any;
  form_data: any
  BACK_END_URL = BACK_END_URL;
  candidate_name: any;
  cpif_educational: any;
  cpif_experience: any;
  IOF_form1: any;
  IOF_form2: any;
  Business_approval: any;
  Technical_interview: any;
  HR_interview: any;
  current_ctc: any;
  Delivery_cost: any;
  indicative_offer: any;
  indicative_offer_split: any;
  aadhar_pan: any;
  approval_mail_ph: any;
  mark_sheet10: any;
  mark_sheet12: any;
  diploma: any;
  degree_certificates_ug: any;
  degree_certificates_pg: any;
  cem_array: any;
  candidate_data: any;
  view_type: any;
  cem_action: any = ['Yes', 'No', 'NA'];
  maxSize: number = 2097152;
  accepted_file_exetension = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];
  file_url: any = '';
  files = [];
  ngOnInit() {
    // if(this.data.view_type == "BUH"){
    this.view_type = this.data.view_type
    this.candidate_id = this.data.candidate_id;
    this.form_data = new FormGroup({
      // candidate_name: new FormControl('', [Validators.required]),
      cpif_educational: new FormControl('', [Validators.required]),
      IOF_form1: new FormControl('', [Validators.required]),
      cpif_experience: new FormControl('', [Validators.required]),
      IOF_form2: new FormControl('', [Validators.required]),
      Business_approval: new FormControl('', [Validators.required]),
      Technical_interview: new FormControl('', [Validators.required]),
      HR_interview: new FormControl('', [Validators.required]),
      current_ctc: new FormControl('', [Validators.required]),
      Delivery_cost: new FormControl('', [Validators.required]),
      indicative_offer: new FormControl('', [Validators.required]),
      indicative_offer_split: new FormControl('', [Validators.required]),
      aadhar_pan: new FormControl('', [Validators.required]),
      approval_mail_ph: new FormControl('', [Validators.required]),
      mark_sheet10: new FormControl('', [Validators.required]),
      mark_sheet12: new FormControl('', [Validators.required]),
      diploma: new FormControl('', [Validators.required]),
      degree_certificates_ug: new FormControl('', [Validators.required]),
      degree_certificates_pg: new FormControl('', [Validators.required])
    });
    if (this.data.view_type == "RMG") {
      this.candidate_data = this.data.candidate_id;
      console.log("asdadwqdqw", this.candidate_data)
      

      // this.form_data.controls.candidate_name.setValue(this.candidate_data['cem_document_status']['candidate_name']); 
      this.form_data.controls.cpif_educational.setValue(this.candidate_data['cem_document_status']['cpif_educational']);
      this.form_data.controls.IOF_form1.setValue(this.candidate_data['cem_document_status']['IOF_form1']);
      this.form_data.controls.cpif_experience.setValue(this.candidate_data['cem_document_status']['cpif_experience']);
      this.form_data.controls.IOF_form2.setValue(this.candidate_data['cem_document_status']['IOF_form2']);
      this.form_data.controls.Business_approval.setValue(this.candidate_data['cem_document_status']['Business_approval']);
      this.form_data.controls.Technical_interview.setValue(this.candidate_data['cem_document_status']['Technical_interview']);
      this.form_data.controls.HR_interview.setValue(this.candidate_data['cem_document_status']['HR_interview']);
      this.form_data.controls.current_ctc.setValue(this.candidate_data['cem_document_status']['current_ctc']);
      this.form_data.controls.Delivery_cost.setValue(this.candidate_data['cem_document_status']['Delivery_cost']);
      this.form_data.controls.indicative_offer.setValue(this.candidate_data['cem_document_status']['indicative_offer']);
      this.form_data.controls.indicative_offer_split.setValue(this.candidate_data['cem_document_status']['indicative_offer_split']);
      this.form_data.controls.aadhar_pan.setValue(this.candidate_data['cem_document_status']['aadhar_pan']);
      this.form_data.controls.approval_mail_ph.setValue(this.candidate_data['cem_document_status']['approval_mail_ph']);
      this.form_data.controls.mark_sheet10.setValue(this.candidate_data['cem_document_status']['mark_sheet10']);
      this.form_data.controls.mark_sheet12.setValue(this.candidate_data['cem_document_status']['mark_sheet12']);
      this.form_data.controls.diploma.setValue(this.candidate_data['cem_document_status']['diploma']);
      this.form_data.controls.degree_certificates_ug.setValue(this.candidate_data['cem_document_status']['degree_certificates_ug']);
      this.form_data.controls.degree_certificates_pg.setValue(this.candidate_data['cem_document_status']['degree_certificates_pg']);
      // this.form_data.controls.missing_file.setValue('');

      // this.form_data.addControl('missing_file',[Validators.required, FileValidator.maxContentSize(this.maxSize)]);

      // this.form_data.controls.acceptance_mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)])
      // this.form_data = new FormGroup({
      //   missing_file: new FormControl("", [Validators.required, FileValidator.maxContentSize(this.maxSize)])
      // })
    }
  }

  onFileChange(pFileList: any) {
    console.log(pFileList.target.files)
    if ((pFileList.target as HTMLInputElement).files) {
      pFileList = pFileList.target.files;
    } else if (pFileList[0].name != null) {
      console.log('works');
      pFileList = pFileList;
    }
    let filename: any;
    filename = Object.keys(pFileList).map((key: any) => pFileList[key]);
    for (var key in pFileList) {
      console.log("key", key)
      // skip loop if the property is from prototype
      if (!pFileList.hasOwnProperty(key)) continue;
      console.log("pFileList[key]", pFileList[key]);
      this.files.push(pFileList[key]);
      // this._snackBar.open("Successfully upload!", 'Close', {
      //   duration: 2000,
      // });
    }
  }

  

  rec_submit_fn() {
    console.log("dqweqwew", this.files);
    // this.form_data.get('missing_file').markAsTouched();
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('file', file) // file.name is optional
    }
    // var jsonObj = JSON.parse(localStorage.currentUser);
    // let file = this.form_data.value.missing_file.files;
    // let fileName = file.name;
    // const formData = new FormData();
    // if (this.form_data.value.missing_file) {
    //   formData.append("file", this.form_data.value.missing_file.files);
    // }
    // Object.keys(this.form_data.value).forEach((key) => {
    //   if (key == 'missing_file') {
    //     formData.append(key, fileName);
    //   } else {
    //     formData.append(key, this.form_data.value[key]);
    //   }

    // });
    console.log("formdata", formData)
    this.skill_service.candidateMissingDocs(this.candidate_data['_id'], formData)
      .subscribe(
        response => {
          this.dialogRef.close();
        }
      );
  }

  cem_submit_fn(data) {
    if (this.form_data.valid) {
      console.log("asdasd", this.form_data.value)

      var data1 = this.form_data.value
      this.cem_array = [];
      for (const key in data1) {
        console.log("sdfsdf", key, data1[key])
        if (data1[key] == 'NO') {
          this.cem_array.push(key)
        }
      }
      console.log("sdasd", this.cem_array);
      if (this.cem_array == '') {
        var status = 'Compliance Verified'
        this.skill_service.cemDocStatus(this.data.candidate_id, this.form_data.value, status, [])
          .subscribe(response => {
            console.log("dsfsdfsdf", response)
            this.dialogRef.close();
          })
      } else {
        var status = 'Insufficiant Document'
        this.skill_service.cemDocStatus(this.data.candidate_id, this.form_data.value, status, this.cem_array)
          .subscribe(response => {
            console.log("dsfsdfsdf", response)
            this.dialogRef.close();
          })
      }

    } else {
      console.log('invalid,',this.form_data.value)
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}



@Component({
  selector: 'candidate-offer-modal',
  templateUrl: 'candidate-offer-modal.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles: ['.back_button{display: flex;justify-content: space-between;}textarea {resize: none;}']
})
export class CandidateOfferModal {
  constructor(private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateApproveModal>) { };
  candidate_remarks: any;
  loi_status: any;
  form_data: any;
  maxSize: number = 2097152;
  accepted_file_exetension = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];
  file_url: any = '';
  ngOnInit() {
    this.form_data = new FormGroup({
      candidate_remarks: new FormControl('', [Validators.required]),
      loi_status: new FormControl('', [Validators.required]),
      acceptance_mail_copy: new FormControl("", [Validators.required, FileValidator.maxContentSize(this.maxSize)])
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  candidate_offer_letter_acceptance() {
    this.form_data.get('acceptance_mail_copy').markAsTouched();

    console.log("adsd",this.form_data.value, this.data)
    var jsonObj = JSON.parse(localStorage.currentUser);
    let file = this.form_data.value.acceptance_mail_copy.files[0];
    let fileName = file.name;
    const formData = new FormData();
    if (this.form_data.value.acceptance_mail_copy) {
      formData.append("file", this.form_data.value.acceptance_mail_copy.files[0]);
    }


    Object.keys(this.form_data.value).forEach((key) => {
      if (key == 'acceptance_mail_copy') {
        formData.append(key, fileName);
      } else {
        formData.append(key, this.form_data.value[key]);
      }

    });

    var openCount: any;
    var closedCount: any;

    if(this.form_data.value.loi_status=='Offer Letter Accepted') {
      openCount = (parseInt(this.data.open)) - 1;
      closedCount = (parseInt(this.data.closed)) + 1;
      console.log(closedCount, openCount)
    }
    
    if(this.form_data.status == "VALID") {

       // update open & close position count //

       this.skill_service.updateOpenClosePositions(this.data.rrf_id,openCount,closedCount)
       .subscribe((response)=> {
         console.log(response)
       })

      this.skill_service.offerLetterAcceptance(this.data.candidate_id, formData, jsonObj.id,this.form_data.value.loi_status)
      .subscribe(
        response => {
          this.dialogRef.close();
        }
      );
     
    } else {
      console.log('invalid')
    }
   
  }

}

@Component({
  selector: 'app-candidate-document-sendback',
  templateUrl: './candidate-document-sendback.component.html',
  styleUrls: ['./rrf-candidate-approve.component.css'],
  styles:['.mat-radio-button {margin: 0 5px;}, .mat-dialog-content {max-height: 75vh;}']
})
export class CandidateDocumentSendbackComponent implements OnInit {
  form_data: FormGroup;

  constructor(private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CandidateDocumentSendbackComponent>, private snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log(this.data)
    this.form_data = new FormGroup({
      // Marksheet_10: new FormControl('', [Validators.required]),
      // Marksheet_12: new FormControl('', [Validators.required]),
      // Marksheet_UG: new FormControl('', [Validators.required]),
      // Marksheet_PG: new FormControl('', [Validators.required]),
      dependentBoolean: new FormControl('', [Validators.required]),
      educationalBoolean: new FormControl('', [Validators.required]),
      experience_letter: new FormControl('', [Validators.required]),
      cpif: new FormControl('', [Validators.required]),
      cFirst: new FormControl('', [Validators.required]),
      payslips: new FormControl('', [Validators.required]),
      employement_letter: new FormControl('', [Validators.required]),
      pan_card: new FormControl('', [Validators.required]),
      aadhar_card: new FormControl('', [Validators.required]),
      candidate_photo: new FormControl('',[Validators.required])      
    })
  }

  sendBack_link(){
    var data = this.form_data.value;
    if(this.form_data.status == 'VALID') {
      data['_id'] = this.data;
      console.log('valid', this.form_data.value, data, this.data);
      this.skill_service.candidateDocUpdate(data).subscribe((response:any) => {
        console.log(response);
        if(response.status == 201) {
          this.closeDialog();
          this.snackBar.open('Candidate File Upload triggered Successfully', '', {
            duration: 3000,
          });
        } else {
          this.closeDialog();
          this.snackBar.open('OOps! Something went wrong', '', {
            duration: 3000,
          });
        }
      })
    } else{
      console.log('invalid', this.form_data.value);
    }
     
  }

  closeDialog() {
    this.dialogRef.close();
  }

}