import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService } from '../services';


@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css'],
})
export class UserloginComponent implements OnInit {
  formdata;
  loginForm: FormGroup;
  returnUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }
  ngOnInit() {
    this.formdata = new FormGroup({
      user_name: new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(5)
      ])),
      password: new FormControl("", [Validators.required, Validators.maxLength(20), Validators.minLength(5)])
    });
    if (localStorage.currentUser) {
      var jsonObj = JSON.parse(localStorage.currentUser);
    
   var emp_name = jsonObj.first_name+" "+jsonObj.last_name;
   var employee_id=jsonObj.employee_number;
   console.log(emp_name,employee_id)
      this.router.navigate(['/myspace']);
    } else {
      this.authenticationService.logout();
    }
    console.log(this.route.snapshot.queryParams['returnUrl'],'returnurl')
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/myspace';
    this.returnUrl = '/myspace';
  }
  passwordvalidation(formcontrol) {
    if (formcontrol.value.length < 5 || formcontrol.value.length > 20) {
      return { "password": true };
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onClickSubmit() {
    if (this.formdata.valid) {
      (<HTMLInputElement>document.getElementById("submit_btn")).disabled = true;
    
      this.authenticationService.login(this.formdata.value.user_name, this.formdata.value.password)
        .subscribe(
          data => {
            console.log("insidelogin")
           // console.log(this.returnUrl);
            this.router.navigate([this.returnUrl]);
          },
          error => {
            if(error == "Username or password is incorrect") {
              (<HTMLInputElement>document.getElementById("submit_btn")).disabled = false;
              this.alertService.error(error);
            } else {
              (<HTMLInputElement>document.getElementById("submit_btn")).disabled = false;
              this.alertService.error('Something Went Wrong!');
            }
            console.log("asddsa",error)
          });
    }

  }

}
