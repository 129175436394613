import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';


@Component({
  selector: 'app-quizinstruction',
  templateUrl: './quizinstruction.component.html',
  styleUrls: ['./quizinstruction.component.css']
})
export class QuizinstructionComponent implements OnInit {
  showMainContent: Boolean = true;
  
  start_quiz: Boolean = false;
  name:string;
  role:string;
  skill_count:string;
  timing:number;
  total_length:number;
  candidate_password:any;
  noOfQuestion: any;
  assessmentTiming: any;

  
  constructor(private router : Router,public quizService: QuizService) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    
   this.candidate_password=localStorage.getItem('password');
   console.log(this.candidate_password)
    //this.role=localStorage.getItem('role');
    this.quizService.getQuestions(this.name,this.candidate_password).subscribe(
      (data: any) => {
        console.log("datafref",data)
        this.role=data.role.role;
        this.noOfQuestion = data.assessment.no_of_qus;
        this.assessmentTiming = data.assessment.time;
      })

    this.quizService.getSkill(this.name,this.candidate_password).subscribe(
      (data: any) => {
        console.log("jhgsdahasgd",data)
        this.skill_count =data.length;
       // alert(this.skill_count)
        if(this.skill_count=="2")
        {
          this.timing=36;
          this.total_length=30+30;
        }
        // else{


        //   //this.timing=60;
 
         
        //   this.timing=18
        // }
        else{
          if(this.skill_count=="1")
          {
            this.total_length=this.noOfQuestion;
            this.timing=this.assessmentTiming
          }
          if(this.skill_count=="3")
          {
            this.total_length=30+30+30;
            this.timing=54
          }
          if(this.skill_count=="4")
          {
            this.total_length=30+30+30+30;
            this.timing=54
          }
          if(this.skill_count=="5")
          {
            this.total_length=30+30+30+30+30;
          }
   
          
         
    
        }
       
         
        
      })
  }
Show_Hide_Button()
{
  this.router.navigate(['/start']);
}


}
