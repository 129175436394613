import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { NgForm } from '@angular/forms'
// import { MatDialog, MatSort } from '@angular/material';
import * as FusionCharts from "fusioncharts";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { pipe } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import { takeWhile, debounceTime, startWith } from 'rxjs/operators'
import { CookieService } from 'angular2-cookie';
import { isUndefined } from 'node_modules_old/@syncfusion/ej2-base';
import { toNumber } from 'node_modules_old/ngx-bootstrap/timepicker/timepicker.utils';
import { MatPaginator, MatTableDataSource, MatDialog, MatSort, MatSnackBar } from '@angular/material';
import { BACK_END_URL } from '../shared/app.globals';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-quizreport',
  templateUrl: './quizreport.component.html',
  styleUrls: ['./quizreport.component.css']
})
export class QuizreportComponent implements OnInit {
  dataSourceDualYAxis: Object
  fusion_timedataSource: any;
  fusiontime_type: string;
  scrollcombidy2d = "line"
  timeseriesdataSource: Object;
  clientdataSource: Object;
  clientmarksSource: Object;
  htmarksSource: Object;
  markdataSource: Object;
  dropdown_filter = []
  events: string[] = [];
  selectedStartDate: any;
  selectedEndDate: any;
  candidate_image_path:any;
  startDate = new Date();
  newdashboarddata = [];
  pratcicedatainfo = []
  demo_newarray = []
  singleArray = []
  multiSkillArray = []
  endDate;
  client_marksheet = [];
  @ViewChild(MatSort) sort: MatSort;
  timeseriestype = "msline";
  practice: any;
  linearray = [];
  sub_practice_list = [];
  modalfitment_list = [];
  rrf_list = [];
  candidate_list = [];
  candidate_mail_list = [];
  candidate_list_updated = [];
  candidate_list_reset = [];
  range: string;
  type1: string;
  limit: string;
  mailValue:string;
  resetValue: boolean = true;
  candidates = [];
  newdashboardskill = [];
  rrf_role: string;
  skill_list = [];
  collection: any;
  skill_values: string;
  skill_values_check: any;
  rrf_role_cutoff: number;
  skill_1_cut_off: number;
  skill_2_cut_off: number;
  skill_3_cut_off: number;
  emp_name:any;
  emp_count:any;
  emp_details:any;
  sub_practice: any;
  rrf_quizcandidate_list = [];
  rrf_graphCandidate_results: boolean;
  total_candidates: any;
  cleared_candidates: any;
  new_cleared_candidates: any;
  cleared_candidates_percent: any;
  role_name: any;
  rrf_code = '';
  mapping_detail: any;
  table_marks: Object;
  rrValues: boolean;
  Object = Object;
  checkbox_array = [];
  minimum_fitment_score: any = '';
  internal_status:any;
  rrf_role_list = [];
  rrf_role_lists = [];
  rrf_role_value: any;
  periodValue: any;
  dropdownskill;
  chart_categgory_list = []
  rrf_values = [];
  skill_name: any;
  passno: any;
  fail_value: any
  quiz_bar_chart = [];
  rr_report = [];
  dashobj = {};
  dasharray = [];
  dasharray_list_update = [];
  dasharray_mail_list = [];
  dasharray_list_reset = [];
  arrayCount: number;
  dashresults: any;
  singleSkillQA:any;
  minimumpasspercentage;
  thirdtype = "doughnut2d";
  //linechart_type = "area2d";
  linechart_type = "column2d";
  funneltype = "bar2d";
  angulartype = "angulargauge";
  multitype = "msbar3d";
  lineColor = '#ffffff';
  dualLineChartColor = '#ffffff'
  emp_all_mark_barchart:any;
  singleDataSource:any;
  funnel_width = 600;
  myControl;
  skill_proficiency_name: any;
  options: any[] = [
    { value: 'Q4', months: ['Jan', 'Mar'] },
    { value: 'Q1', months: ['Apr', 'Jun'] },
    { value: 'Q2', months: ['Jul', 'Sep'] },
    { value: 'Q3', months: ['Oct', 'Dec'] }
  ];
  year = new Date().getFullYear()
  topictype = "column3d";
  skiil_type = "bar2d";
  gauge_bar_chart = [];
  testing_chart = [];
  type_chart = "column2d";
  width = 492;
  funnel_height = 420
  width_adjancentgraph = 690;
  widthadjancentgraph = 1015;
  width_piechart = 490;
  height = 300;
  height_type = 500;
  assesementChartHeight = 360;
  type = "doughnut2d";
  gauge_type = "doughnut2d";
  Type = "angulargauge";
  gaugetype = "thermometer";
  filter_category = []
  week_assessment_count:any;
  // sub_practice_list = [];
  dashboard_chart = [];
  // rrf_values=[]
  candidate_percentage_piechart = [{
    label: 'Pass',
    value: 0

  },
  {
    label: 'Fail',
    value: 0

  }]
  threed_chart = [{
    label: 'Pass',
    value: 0
  },
  {
    label: 'Fail',
    value: 0

  }]

  dataFormat = "json";
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  graph_chart = [];
  percentage_value;
  marks_value
  //view: any[] = [500, 250];
  dataSource: Object;
  multidataSource: Object;
  gaugedataSource: Object;
  areadataSource: Object;
  speedometerdataSource: Object;

  gauge_dataSource: Object;
  skill_averagegraph: Object;
  topic_averagegraph: Object;
  candidate_percentage_chart: Object;
  single_candidate_percentage_chart: Object;
  rrf_data_source: Object;
  // rrf_quizcandidate_list = [];
  datalist: Object;
  skill_topicgraph = []
  speedtype = "angulargauge";

  showXAxisLabel = true;

  showYAxisLabel = true;
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  dropdownaverage_array = []

  data_gaugeSource: Object
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  graph_list = []
  selectedFood1: string;
  selectedValue: string;
  class: string;
  Candidate_results: boolean = false
  // rrf_graphCandidate_results: boolean = false
  skill_dropdown = [];
  // rrf_role_list=[];
  // rrf_role_value;
  skill_cutoflist = [];
  quiz_result = [];
  fitmentscore: number;
  arrLabel = [];
  datasetArr = [];
  arrDataActual = [];
  selectedFood2: string;
  view: any[] = [400, 250];
  data = [
  ]
  data_list_array: Object;
  rr_list_array: Object;
  dataSource123: any;
  type123: string;
  width123: string;
  height123: string;
  dataSource456: any;
  type456: string;
  width456: string;
  height456: string;
  toggleSelected: string;
  isSticky: boolean = false;
  clientChart: string;
  assesmentChart: string;
  clientmarks = [];
  client_valuesdata = [];
  labelValues = [];
  chartDataset = [];
  markChartInput = [];
  percentChartInput = [];
  array_push = []
  emp_label_count = [];
  emp_label_barchart = [];
  assesmentMarkdataset: Object;
  assesmentPercentagedataset: Object;
  selected_rrf_id: any;
  currentUser: any;
  dynamicTable: any;
  headerName: any;
  yAxisValue: number;
  mark_percent_value = "mark"
  mark_percent_skillName = "";
  array_of_array = [];
  iteratemark: number;
  ttl_count_data:any;
  displayedColumns: string[] = ['skill_name','skill_mark','percentage'];
  singleSkillDisplayedColumns: string[] = ['position','question','correct_answer','employee_answer'];
  back_end_url: string;
  units: any;
  practices: any;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }

  constructor(private quizService: QuizService, private skill_service: EmployeeSkillMappingService, private dialog: MatDialog, private snackBar: MatSnackBar, private cookieService: CookieService) {
  }


  ngOnInit() {
    this.back_end_url = BACK_END_URL;

    this.skill_service.getUnit('0').subscribe(response => {
      console.log(response)
      this.units = response;
      this.practice = [];
      this.sub_practice_list = [];
      this.rrf_role_lists =[];
      this.rrf_values = [];
      this.periodValue = null;
    })

    this.quizService.get_dashboarddeat().subscribe((data: any) => {
      this.collection = data.data;
      this.rrf_code = '';
    })
    this.skill_1_cut_off = 22;
    this.skill_name = 'Autosar';
    this.toggleSelected = 'skill';
    this.assesmentChart = 'mark';
    this.clientChart = 'mark';
    this.dropdownskill = 'All';
    // this.dropdownskill_graph('All');

    // preload the page with saved user perference
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.skill_service.getEmployeeDetails(this.currentUser.id).subscribe((data: any) => {
      console.log(data);
      console.log("sadasdasd",data.data.employee_detail.ideal_practice)
      this.practice = data.data.employee_detail.ideal_practice;
      // this.get_details(data.data.employee_detail.ideal_practice);
      this.sub_practice == '';
    });
    this.skill_service.getRRFCreationData()
    .subscribe(
      response => {
        console.log(response);
        this.units = response['data'].unit;
      })
    let cookieValue = [];
    cookieValue.push(this.cookieService.getObject(this.currentUser.employee_number));
    const allCookies: {} = this.cookieService.getAll();
    console.log(cookieValue, 'cookie');
    console.log(allCookies);
    if (cookieValue.length != 0 || cookieValue != undefined) {
      this.periodValue = cookieValue[0].periodValue;
      this.practice = cookieValue[0].parctice;
      this.get_details(cookieValue[0].parctice);
      this.sub_practice = cookieValue[0].sub_practice;
      console.log("this.sub_practice",this.sub_practice);
      this.onPeriodDropdownchange(cookieValue[0].periodValue, cookieValue[0].sub_practice, cookieValue[0].parctice);
      this.get_fitmentdetailsgraph(cookieValue[0].sub_practice);
    }

  }

  post_unit_details(evt) {
    this.skill_service.getUnit(evt.value)
    .subscribe(
      response => {
        console.log("addsad", response)
        // this.firstFormGroup.controls["sub_practice"].setValue(null);
        // this.firstFormGroup.controls["band"].setValue(null);
        // this.firstFormGroup.controls["role"].setValue(null);
        this.practices = response;
        this.sub_practice_list = [];
        this.rrf_role_lists =[];
        this.rrf_values = [];
        this.periodValue = null;
      }
    );
  }

  newdashboardfilter(rrf_master_id) {
    console.log("rrf_master_id",rrf_master_id);
    this.skill_service.getRRFData(rrf_master_id).subscribe((setdata: any) => {
          console.log("data", setdata)
          this.minimum_fitment_score = setdata.data[0].minimum_fitment_score
          this.internal_status = setdata.data[0].internal_employee_status 
          console.log("sdd",this.internal_status)
        })
    this.newdashboardskill = [];
    console.log("rrf_master_id")
    this.quizService.get_data_newdashboard(rrf_master_id).subscribe((data: any) => {
      console.log("get_data_newdashboard", data)

      this.newdashboardskill = (data.newdashboarddata);
      console.log("beforeif", this.newdashboardskill, this.newdashboardskill.length);
      
      if (this.newdashboardskill.length == 1) {

        //this.skill_values_check = this.newdashboardskill.length
        this.dropdownskill = this.newdashboardskill[0].skill_name;
        this.mark_percent_skillName = this.newdashboardskill[0].skill_name;
        //this.dropdownskill_graph(this.newdashboardskill[0].skill_name);
        this.yAxisValue = 60;
        this.get_table_details(rrf_master_id)
        this.get_table_detailsNew(rrf_master_id)
        this.get_dash_table_value(rrf_master_id)


      } else {
        this.newdashboardskill.sort((a, b) => a.skill_name.localeCompare(b.skill_name));
        this.mark_percent_skillName = "All"
        //this.skill_values_check = this.newdashboardskill.length
        console.log("this.newdashboardskill.length: ", this.newdashboardskill.length);
        this.dropdownskill = 'All';
        this.yAxisValue = this.newdashboardskill.length * 30;
        console.log("this.yAxisValue", this.yAxisValue)
        this.get_table_details(rrf_master_id)
        this.get_table_detailsNew(rrf_master_id)
        this.get_dash_table_value(rrf_master_id)

      }
      console.log('works', this.newdashboardskill.length, this.dropdownskill);
    })

  }

  //get chart details for quiz
  get_table_details(rrf_master_id) {
    console.log("rrf_master_id",rrf_master_id);
    console.log("this.mark_percent_skillName", this.mark_percent_skillName);
    console.log("mark_percent_value", this.mark_percent_value);
    console.log("tableworks", rrf_master_id)
    console.log("this.newdashboardskill.length",this.newdashboardskill.length);
   // this.newdashboarddata = []

    // this.dashboard_chart = [];
    this.selected_rrf_id = rrf_master_id;
    this.graph_chart = [];
    this.rrf_graphCandidate_results = true
    this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((data: any) => {
      console.log('1 works',data);
      this.total_candidates = data.length;
      console.log("this.total_candidates",this.total_candidates);
    })
    var data_topic_chart = [];
    var category = []
    var category_list;
    this.quizService.getdashboard_graph(rrf_master_id).subscribe((data: any) => {
      console.log("has result", data.length, data, data.data.length)

      if (data.length != 0 && data.data.length != undefined) {
        console.log("keertahan");

        this.table_marks = data.skill;
        for (var i = 0; i < this.quiz_bar_chart.length; i++) {

          if (this.rrf_quizcandidate_list[i].label != '') {


            var no = i + 1
            if (no < 10) {
              var value = '000' + no.toString()
            }
            else {
              var value = '00' + no.toString()
            }

            category.push({ 'label': value })


          }

        }
        this.labelValues = category;

        if (this.newdashboardskill.length == 1) {
          this.yAxisValue = 60;
          //  this.timeseriesdataSource = {};
          // this.markdataSource = {};
         this.singleSkillFun(rrf_master_id)
        }
        else if(this.newdashboardskill.length > 1){
          //  this.timeseriesdataSource = {};
          // this.markdataSource = {};
          this.multipleSkillFun(rrf_master_id)
      }
        this.chart_categgory_list = category

      } else {
        console.log('2 works');
        this.timeseriesdataSource = {};
      }
    })
    
  }

  singleSkillFun(rrf_master_id){
    console.log("test")
    
      if (this.mark_percent_value == "mark") {
        console.log("insidemark")
        this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
          this.total_candidates = arrayData.length
         console.log("arrayData",arrayData);
         console.log("this.total_candidates",this.total_candidates,typeof this.total_candidates);
        
          var filter_array = [];
          this.singleArray = [];
          
          //this.client_valuesdata = []
          //var clientno=0
          this.iteratemark = 0;
          arrayData.forEach(obj => {
            
            this.singleArray.push({ value: obj.count, tooltext: " "+obj.name+" $dataValue" })
            this.iteratemark += obj.count;
          })
          console.log("skill_nameskill_nametyt", this.singleArray);
          console.log("this.iteratemark",  this.iteratemark);
        console.log("markaverage", this.iteratemark)
        var markaverage = Math.round(this.iteratemark/this.total_candidates)
        console.log("markaverage",markaverage);
        var markarray = [];
        markarray.push(
          {
            startvalue: markaverage,
            color: "#8cbb2c",
            trendValueFontBold: "1",
            thickness: "2",
            displayvalue: "Average : " + markaverage,
            valueOnRight: '1',
            dashed: "1",
            dashLen: "4",
            dashGap: "2",
            parentyaxis: "S"
          })
        console.log(markarray, markaverage, this.iteratemark, 'markavg')
        let markchartData = []
         markchartData = this.singleArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
        console.log(markchartData, 'markchartData');
        if (markchartData.length > 0) {
          console.log("After assigned all : ", this.yAxisValue, this.newdashboardskill.length)
          const markData = {
            chart: {
              caption: "Assessment Results",
              subCaption: 0,
              xAxisname: "Candidates",
              yAxisName: "Marks",
              xAxisNameFontColor:"#082542",
              // "#00264d",
              yAxisNameFontColor:"#082542",
              // "#00264d",
              yAxisMaxValue: this.yAxisValue,
              "labelDisplay": "rotate",
              "slantLabel": "1",
              "trendValueBorderColor": "#1b3088",
              "trendValueBorderAlpha": "80",
              "trendValueBorderPadding": "4",
              "trendValueBorderRadius": "5",
              "trendValueBorderThickness": "2",
              "interactiveLegend": "0",
              theme: "fusion",
              drawcrossline: "1",
              "paletteColors": this.lineColor,
              //Anchor Cosmetics
              "anchorRadius": "6",
              "anchorBorderThickness": "2",
              "anchorBorderColor": "#127fcb",
              "anchorSides": "3",
              "anchorBgColor": "#d3f7ff",
            },
            data: markchartData,
            "trendlines": [
              {
                line: markarray
              }
            ]
          };
          console.log( markchartData, 'mark')
          this.timeseriesdataSource = markData;
          return this.timeseriesdataSource
          
        } else {
          this.timeseriesdataSource = {};
        }
      })
      } else {
        this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
          this.total_candidates = arrayData.length
         console.log("arrayData",arrayData);
         console.log("this.total_candidates",this.total_candidates,typeof this.total_candidates);
        
          var filter_array = [];
          this.singleArray = [];
          
          //this.client_valuesdata = []
          //var clientno=0
          this.iteratemark = 0;
          arrayData.forEach(obj => {
            
            this.singleArray.push({ value: Math.round((obj.count/60)*100), tooltext: " "+obj.name+" $dataValue" })
            this.iteratemark += obj.count;
          })
          console.log("skill_nameskill_nametyt", this.singleArray);
          console.log("this.iteratemark",  this.iteratemark);
        console.log("this.iteratemark",this.iteratemark);
        var avergae_no = Math.round((this.iteratemark /(60*this.total_candidates)) * 100)
        console.log("this.newdashboardskill", this.newdashboardskill)
        console.log(avergae_no, 'average',)
        var line_array = [];
        //var linearray=[];
        line_array.push(
          {
            startvalue: avergae_no,
            color: "#8cbb2c",
            trendValueFontBold: "1",
            thickness: "2",
            displayvalue: "Percentage : " + avergae_no +"%",
            valueOnRight: '1',
            dashed: "1",
            dashLen: "4",
            dashGap: "2",
            parentyaxis: "S"
          })
        console.log(line_array)
  
        let percentagechartData = this.singleArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
        console.log(percentagechartData);
        if (percentagechartData.length > 0) {
          const percentageData = {
            chart: {
              caption: "Assessment Results",
              subCaption: 0,
              xAxisname: "Candidates",
              yAxisName: "Percentage",
              yAxisMaxValue: 100,
              "labelDisplay": "rotate",
              "slantLabel": "1",
              "trendValueBorderColor": "#1b3088",
              "trendValueBorderAlpha": "80",
              "trendValueBorderPadding": "4",
              "trendValueBorderRadius": "5",
              "trendValueBorderThickness": "2",
              "formatNumber": "0",
              "interactiveLegend": "0",
              theme: "fusion",
              drawcrossline: "1",
              "paletteColors": this.lineColor,
              //Anchor Cosmetics
              "anchorRadius": "6",
              "anchorBorderThickness": "2",
              "anchorBorderColor": "#127fcb",
              "anchorSides": "3",
              "anchorBgColor": "#d3f7ff",
  
            },
            data: percentagechartData,
            trendlines: [
              {
                line: line_array
              }
            ]
          };
          this.markdataSource = percentageData;
          console.log(this.markdataSource, 'percentage')
        } else {
          this.markdataSource = {};
        }
      })
      }
    
    
  }

  multipleSkillFun(rrf_master_id){
    if (this.mark_percent_value == "mark") {
      
      if(this.mark_percent_skillName == "" || this.mark_percent_skillName == "All"){
        this.yAxisValue = this.newdashboardskill.length * 30;
        this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
          this.total_candidates = arrayData.length
          console.log("arrayData",arrayData)
          this.multiSkillArray = [];
          this.markChartInput = [];
          this.iteratemark = 0;
          arrayData.forEach(obj => {
           
                this.multiSkillArray.push({ value: obj.count, tooltext: " "+obj.name+" $dataValue" })
                console.log("skill_nameskill_name", typeof obj.count);
                this.iteratemark += obj.count;
          })
          // this.markChartInput = this.multiSkillArray
          // this.iteratemark = this.iteratemark
          console.log("this.multiSkillArray",this.multiSkillArray);
          console.log("this.iteratemark",this.iteratemark);
          console.log("this.total_candidates",this.total_candidates);
          var markaverage = Math.round(this.iteratemark / this.total_candidates)
          var markarray = [];
          markarray.push(
            {
              startvalue: markaverage,
              color: "#8cbb2c",
              trendValueFontBold: "1",
              thickness: "2",
              displayvalue: "Average : " + markaverage,
              valueOnRight: '1',
              dashed: "1",
              dashLen: "4",
              dashGap: "2",
              parentyaxis: "S"
            })
          console.log(markarray, markaverage, this.iteratemark, 'markavg')
          let markchartData = this.multiSkillArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
          console.log(markchartData, 'markchartData');
          if (markchartData.length > 0) {
            console.log("After assigned all : ", this.yAxisValue, this.newdashboardskill.length)
            const markData = {
              chart: {
                caption: "Assessment Results",
                subCaption: 0,
                xAxisname: "Candidates",
                yAxisName: "Marks",
                 xAxisNameFontColor:"#082542",
                //  "#00264d",
                 yAxisNameFontColor:"#082542",
                //  "#00264d",
                yAxisMaxValue: this.yAxisValue,
                "labelDisplay": "rotate",
                "slantLabel": "1",
                "trendValueBorderColor": "#1b3088",
                "trendValueBorderAlpha": "80",
                "trendValueBorderPadding": "4",
                "trendValueBorderRadius": "5",
                "trendValueBorderThickness": "2",
                "interactiveLegend": "0",
                theme: "fusion",
                drawcrossline: "1",
                "paletteColors": this.lineColor,
                //Anchor Cosmetics
                "anchorRadius": "6",
                "anchorBorderThickness": "2",
                "anchorBorderColor": "#127fcb",
                "anchorSides": "3",
                "anchorBgColor": "#d3f7ff",
              },
              data: markchartData,
              "trendlines": [
                {
                  line: markarray
                }
              ]
            };
            this.timeseriesdataSource = markData;
            console.log(this.timeseriesdataSource, markchartData, 'mark')
          } else {
            this.timeseriesdataSource = {};
          }
        })
      }else{
        this.yAxisValue = 30;
         this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
      console.log("arrayData",arrayData)
      this.total_candidates = arrayData.length
      this.multiSkillArray = [];
      this.iteratemark = 0;
      arrayData.forEach(obj => {
        obj.skill_name.forEach(obj11 => {
          if (obj11._id == this.mark_percent_skillName) {
            this.multiSkillArray.push({ value: obj11.count, tooltext: " "+obj.name+" $dataValue" })
            console.log("skill_nameskill_name", typeof obj11.count);
            this.iteratemark += obj11.count;
          }

        })
      })
      console.log("this.multiSkillArray",this.multiSkillArray);
      console.log("this.iteratemark",this.iteratemark);
      var markaverage = Math.round(this.iteratemark / this.total_candidates)
      var markarray = [];
      markarray.push(
        {
          startvalue: markaverage,
          color: "#8cbb2c",
          trendValueFontBold: "1",
          thickness: "2",
          displayvalue: "Average : " + markaverage,
          valueOnRight: '1',
          dashed: "1",
          dashLen: "4",
          dashGap: "2",
          parentyaxis: "S"
        })
      console.log(markarray, markaverage, this.iteratemark, 'markavg')
      let markchartData = this.multiSkillArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
      console.log(markchartData, 'markchartData');
      if (markchartData.length > 0) {
        console.log("After assigned all : ", this.yAxisValue, this.newdashboardskill.length)
        const markData = {
          chart: {
            caption: "Assessment Results",
            subCaption: 0,
            xAxisname: "Candidates",
            yAxisName: "Marks",
            xAxisNameFontColor:"#082542",
            // "#00264d",
            yAxisNameFontColor:"#082542",
            // "#00264d",
            yAxisMaxValue: this.yAxisValue,
            "labelDisplay": "rotate",
            "slantLabel": "1",
            "trendValueBorderColor": "#1b3088",
            "trendValueBorderAlpha": "80",
            "trendValueBorderPadding": "4",
            "trendValueBorderRadius": "5",
            "trendValueBorderThickness": "2",
            "interactiveLegend": "0",
            theme: "fusion",
            drawcrossline: "1",
            "paletteColors": this.lineColor,
            //Anchor Cosmetics
            "anchorRadius": "6",
            "anchorBorderThickness": "2",
            "anchorBorderColor": "#127fcb",
            "anchorSides": "3",
            "anchorBgColor": "#d3f7ff",
          },
          data: markchartData,
          "trendlines": [
            {
              line: markarray
            }
          ]
        };
        this.timeseriesdataSource = markData;
        console.log(this.timeseriesdataSource, markchartData, 'mark')
      } else {
        this.timeseriesdataSource = {};
      }
    })
      }
    }else{
      console.log("inside percentage")
      if(this.mark_percent_skillName == "" || this.mark_percent_skillName == "All"){
        this.yAxisValue = this.newdashboardskill.length * 30;
        this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
          this.total_candidates = arrayData.length
          console.log("arrayData",arrayData)
          this.multiSkillArray = [];
          this.markChartInput = [];
          this.iteratemark = 0;
          arrayData.forEach(obj => {
           
                this.multiSkillArray.push({ value: Math.round((obj.count/(this.newdashboardskill.length*30))*100), tooltext: " "+obj.name+" $dataValue" })
                console.log("skill_nameskill_name", typeof obj.count);
                this.iteratemark += obj.count;
          })
          console.log("this.multiSkillArray",this.multiSkillArray);
          console.log("this.iteratemark",this.iteratemark);
          console.log("this.total_candidates",this.total_candidates);
          var avg_cal = (this.newdashboardskill.length*this.total_candidates)*30
          console.log("avg_cal",avg_cal)
          var avergae_no = Math.round((this.iteratemark /avg_cal) * 100)
          console.log("this.newdashboardskill", this.newdashboardskill)
          console.log(avergae_no, 'average',)
          var line_array = [];
          //var linearray=[];
          line_array.push(
            {
              startvalue: avergae_no,
              color: "#8cbb2c",
              trendValueFontBold: "1",
              thickness: "2",
              displayvalue: "Percentage : " + avergae_no +"%",
              valueOnRight: '1',
              dashed: "1",
              dashLen: "4",
              dashGap: "2",
              parentyaxis: "S"
            })
          console.log(line_array)
    
          let percentagechartData = this.multiSkillArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
          console.log(percentagechartData);
          if (percentagechartData.length > 0) {
            const percentageData = {
              chart: {
                caption: "Assessment Results",
                subCaption: 0,
                xAxisname: "Candidates",
                yAxisName: "Percentage",
                yAxisMaxValue: 100,
                "labelDisplay": "rotate",
                "slantLabel": "1",
                "trendValueBorderColor": "#1b3088",
                "trendValueBorderAlpha": "80",
                "trendValueBorderPadding": "4",
                "trendValueBorderRadius": "5",
                "trendValueBorderThickness": "2",
                "formatNumber": "0",
                "interactiveLegend": "0",
                theme: "fusion",
                drawcrossline: "1",
                "paletteColors": this.lineColor,
                //Anchor Cosmetics
                "anchorRadius": "6",
                "anchorBorderThickness": "2",
                "anchorBorderColor": "#127fcb",
                "anchorSides": "3",
                "anchorBgColor": "#d3f7ff",
    
              },
              data: percentagechartData,
              trendlines: [
                {
                  line: line_array
                }
              ]
            };
            this.markdataSource = percentageData;
            console.log(this.markdataSource, 'percentage')
          } else {
            this.markdataSource = {};
          }
 
        })
      }else{
        this.yAxisValue = 30;
         this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((arrayData: any) => {
      console.log("arrayData",arrayData)
      this.total_candidates = arrayData.length
      this.multiSkillArray = [];
      this.iteratemark = 0;
      arrayData.forEach(obj => {
        obj.skill_name.forEach(obj11 => {
          if (obj11._id == this.mark_percent_skillName) {
            this.multiSkillArray.push({ value: Math.round((obj11.count/30)*100), tooltext: " "+obj.name+" $dataValue" })
            console.log("skill_nameskill_name", typeof obj11.count);
            this.iteratemark += ((obj11.count/30)*100)
          }

        })
      })
      console.log("this.multiSkillArray",this.multiSkillArray);
      console.log("this.iteratemark",this.iteratemark);
      // var avg_cal = (this.newdashboardskill.length*1)*30
      // console.log("avg_cal",avg_cal)
      // var avergae_no = Math.round((this.iteratemark /avg_cal) * 100)
      var avergae_no =  Math.round(this.iteratemark / this.total_candidates)
      console.log("this.newdashboardskill", this.newdashboardskill)
      console.log(avergae_no, 'average',)
      var line_array = [];
      //var linearray=[];
      line_array.push(
        {
          startvalue: avergae_no,
          color: "#8cbb2c",
          trendValueFontBold: "1",
          thickness: "2",
          displayvalue: "Percentage : " + avergae_no +"%",
          valueOnRight: '1',
          dashed: "1",
          dashLen: "4",
          dashGap: "2",
          parentyaxis: "S"
        })
      console.log(line_array)

      let percentagechartData = this.multiSkillArray.map((o, i) => ({ ...o, ...this.labelValues[i] }));
      console.log(percentagechartData);
      if (percentagechartData.length > 0) {
        const percentageData = {
          chart: {
            caption: "Assessment Results",
            subCaption: 0,
            xAxisname: "Candidates",
            yAxisName: "Percentage",
            yAxisMaxValue: 100,
            "labelDisplay": "rotate",
            "slantLabel": "1",
            "trendValueBorderColor": "#1b3088",
            "trendValueBorderAlpha": "80",
            "trendValueBorderPadding": "4",
            "trendValueBorderRadius": "5",
            "trendValueBorderThickness": "2",
            "formatNumber": "0",
            "interactiveLegend": "0",
            theme: "fusion",
            drawcrossline: "1",
            "paletteColors": this.lineColor,
            //Anchor Cosmetics
            "anchorRadius": "6",
            "anchorBorderThickness": "2",
            "anchorBorderColor": "#127fcb",
            "anchorSides": "3",
            "anchorBgColor": "#d3f7ff",

          },
          data: percentagechartData,
          trendlines: [
            {
              line: line_array
            }
          ]
        };
        this.markdataSource = percentageData;
        console.log(this.markdataSource, 'percentage')
      } else {
        this.markdataSource = {};
      }
    })
      }
    }

  }

  exportChart(e) {
    // FusionCharts.batchExport({
    //   exportFormat: "pdf"
    // });
    FusionCharts.batchExport({
      "charts": [{
          "id": "new-chart-1",
      }],
      "exportFileName": "batchExport",
      "exportFormats": "pdf",
      "exportAtClientSide": "1"
  })
  }

// generateSingleSkillPdf(singleCandidate){
// console.log("singleCandidate",singleCandidate);
// var obj = {}
// obj["Skill Name"] = singleCandidate.skill_name[0]._id
// obj["Name"] = singleCandidate.name
// obj["Marks"] = singleCandidate.count
// obj["Percent"] = singleCandidate.percent + "%"
// console.log("obj",obj);
//     var doc = new jsPDF();
//     var col = ["Details", "Values"];
//     var rows = [];

//     for(var key in obj){
//         var temp = [key, obj[key]];
//         rows.push(temp);
//     }

//     doc.autoTable(col, rows);

//     doc.save('SingleSkill.pdf');
// }
cmd_button_single(templateRef : TemplateRef<any>, candidate){
  console.log("asdasdasdas",candidate)
  if(candidate.assessment_type != 'normal'){
   console.log("week")
    this.quizService.get_employeeQA(candidate._id).subscribe((data: any) => {
      console.log("sdsdasd",data)

      if(data['data']){
        var emp_ans_dump = []
        data['data'].forEach((data) =>{
          if(data.questionName != 'Question'){
            emp_ans_dump.push(data)
          }
          console.log("asdsadfs",emp_ans_dump)
        })
        console.log("asdsad",emp_ans_dump)
        this.singleSkillQA = new MatTableDataSource(emp_ans_dump);
      }
      
    })
    this.thirdtype = "doughnut2d";
    this.type_chart = "column2d";
    this.dataFormat = "json";
    this.width = 492;
    this.height = 300;
    if(candidate.candidate_image_path){
  this.candidate_image_path = BACK_END_URL + candidate.candidate_image_path
  console.log("aSas",this.candidate_image_path);
    }
    this.emp_details = candidate;
    this.emp_count = candidate.count
    this.dialog.open(templateRef);
    var emp_loop = candidate.skill_name
     this.emp_label_count = []
     this.emp_label_barchart = []
      emp_loop.forEach(data =>{
        if(data._id != ""){
          this.week_assessment_count = data.count
          this.emp_label_count.push({"label":data._id,"value":this.emp_count,"ttl_qus":this.week_assessment_count})
          this.emp_label_barchart.push({"label":data._id,"value":this.emp_count})
        }
      })
      console.log("adasdasdas",this.week_assessment_count)
      // this.emp_label_count.push({"label":"Total Questions","value":this.week_assessment_count})
      // console.log("adsasds",this.emp_label_count)
      this.emp_label_barchart.push({"label":"Total Questions","value":this.week_assessment_count})
      this.ttl_count_data = this.week_assessment_count
      var incorrect = this.ttl_count_data - this.emp_count
      const percantage_Source = {
        // chart: {
        //    caption: "SUMMARY OF ATTEMPTS",
        //   subcaption: 0,
        //   enablesmartlabels: 0,
        //   showlabels: 0,
        //   numbersuffix: "",
        //   usedataplotcolorforlabels: 0,
        //   plottooltext: 0,
        //   "valueFontColor": "#e5e4e4",
        //   "bgColor": "#00264d",rgb(255, 255, 255)
        //   containerBackgroundOpacity: '0',
        //   "canvasBgAlpha": "0",
        //   "baseFontColor": "#ffffff",
        //   "toolTipBgColor": "#666666",
        //   "toolTipBorderColor": "#666666",
        //   "toolTipColor": "000000",
        //   "toolTipBgAlpha": "80",
        //   "showToolTipShadow": "1",
        //   "exportEnabled": "1",
        //   "ExportedFileName": "Overall pass or fail percentage",
        //   theme: "fusion"
        // },
       
        chart: {
          caption: "SUMMARY",
          // "subCaption": "Overall Skill Test Mark",
          "xAxisName": "Skill Name",
          "yAxisName": "Mark",
          "numberSuffix": "",
          "bgColor": "#EEEEEE",
          "theme": "fusion",
          // "theme": "Candy",
          "borderColor": "#00264d",
"borderthickness":"2",
            "toolTipBgColor": "#666666",
            "toolTipBorderColor": "#666666",
            "toolTipColor": "000000",
            "toolTipBgAlpha": "80",
            "showToolTipShadow": "1",
            "exportEnabled": "1",
            "showBorder": "1",
            "pieRadius": "70"
      }, data: [{label: 'Incorrect', value: incorrect},
        {label: 'Correct', value: this.emp_count}]
    
      };
      console.log("csdsjhjhksad",incorrect,this.emp_count)
      this.single_candidate_percentage_chart = percantage_Source;
      
      const overall_barchart = {
        chart: {
            "caption": "MARKS SCORED",
            "subCaption": "Individual Skill Test Mark",
            "xAxisName": "Skill Name & Total Questions",
            "yAxisName": "Mark",
            "numberSuffix": "",
            "theme": "Candy",
            "borderColor": "#00264d",
            "borderthickness":"2",
                        "toolTipBgColor": "#666666",
                        "toolTipBorderColor": "#666666",
                        "toolTipColor": "000000",
                        "toolTipBgAlpha": "80",
                        "showToolTipShadow": "1",
                        "exportEnabled": "1",
                        "showBorder": "1",
        },
        data: this.emp_label_barchart
    }
    this.emp_all_mark_barchart = overall_barchart
    console.log("tetxffdgerer",this.emp_label_barchart)
    this.singleDataSource = new MatTableDataSource(this.emp_label_count);
    console.log("asdasd",this.emp_label_count)
  }else{
    this.quizService.get_employeeQA(candidate._id).subscribe((data: any) => {
      console.log("sdsdasd",data)
      if(data['data']){
        var emp_ans_dump = []
        data['data'].forEach((data) =>{
          if(data.questionName != 'Question'){
            emp_ans_dump.push(data)
          }
          console.log("asdsadfs",emp_ans_dump)
        })
        console.log("asdsad",emp_ans_dump)
        this.singleSkillQA = new MatTableDataSource(emp_ans_dump);
      }
      
    })
    this.thirdtype = "doughnut2d";
    this.type_chart = "column2d";
    this.dataFormat = "json";
    this.width = 492;
    this.height = 300;
    if(candidate.candidate_image_path){
  this.candidate_image_path = BACK_END_URL + candidate.candidate_image_path
  console.log("aSas",this.candidate_image_path);
    }
    this.emp_details = candidate;
    this.emp_count = candidate.count
    this.dialog.open(templateRef);
    var emp_loop = candidate.skill_name
     this.emp_label_count = []
     this.emp_label_barchart = []
      emp_loop.forEach(data =>{
        if(data._id != ""){
          this.emp_label_count.push({"label":data._id,"value":this.emp_count,"ttl_qus":60})
          this.emp_label_barchart.push({"label":data._id,"value":this.emp_count})
        }
      })
      // this.emp_label_count.push({"label":"Total Questions","value":60})
      // console.log("adsasds",this.emp_label_count)
      this.emp_label_barchart.push({"label":"Total Questions","value":60})
      this.ttl_count_data = 60
      var incorrect = this.ttl_count_data - this.emp_count
      const percantage_Source = {
        // chart: {
        //    caption: "SUMMARY OF ATTEMPTS",
        //   subcaption: 0,
        //   enablesmartlabels: 0,
        //   showlabels: 0,
        //   numbersuffix: "",
        //   usedataplotcolorforlabels: 0,
        //   plottooltext: 0,
        //   "valueFontColor": "#e5e4e4",
        //   "bgColor": "#00264d",rgb(255, 255, 255)
        //   containerBackgroundOpacity: '0',
        //   "canvasBgAlpha": "0",
        //   "baseFontColor": "#ffffff",
        //   "toolTipBgColor": "#666666",
        //   "toolTipBorderColor": "#666666",
        //   "toolTipColor": "000000",
        //   "toolTipBgAlpha": "80",
        //   "showToolTipShadow": "1",
        //   "exportEnabled": "1",
        //   "ExportedFileName": "Overall pass or fail percentage",
        //   theme: "fusion"
        // },
       
        chart: {
          caption: "SUMMARY",
          // "subCaption": "Overall Skill Test Mark",
          "xAxisName": "Skill Name",
          "yAxisName": "Mark",
          "numberSuffix": "",
          "bgColor": "#EEEEEE",
          "theme": "fusion",
          // "theme": "Candy",
          "borderColor": "#00264d",
"borderthickness":"2",
            "toolTipBgColor": "#666666",
            "toolTipBorderColor": "#666666",
            "toolTipColor": "000000",
            "toolTipBgAlpha": "80",
            "showToolTipShadow": "1",
            "exportEnabled": "1",
            "showBorder": "1",
            "pieRadius": "70"
      }, data: [{label: 'Incorrect', value: incorrect},
        {label: 'Correct', value: this.emp_count}]
    
      };
      console.log("incorrect",incorrect,this.emp_count)
      this.single_candidate_percentage_chart = percantage_Source;
      
      const overall_barchart = {
        chart: {
            "caption": "MARKS SCORED",
            "subCaption": "Individual Skill Test Mark",
            "xAxisName": "Skill Name & Total Questions",
            "yAxisName": "Mark",
            "numberSuffix": "",
            "theme": "Candy",
            "borderColor": "#00264d",
            "borderthickness":"2",
                        "toolTipBgColor": "#666666",
                        "toolTipBorderColor": "#666666",
                        "toolTipColor": "000000",
                        "toolTipBgAlpha": "80",
                        "showToolTipShadow": "1",
                        "exportEnabled": "1",
                        "showBorder": "1",
        },
        data: this.emp_label_barchart
    }
    this.emp_all_mark_barchart = overall_barchart
    console.log("this.emp_label_barchart",this.emp_label_barchart)
    console.log("adasdasd",this.emp_label_count)
    this.singleDataSource = new MatTableDataSource(this.emp_label_count);
  }
 

}
cmd_button_multi(templateRef : TemplateRef<any>, candidate)
{
  console.log("sdas",candidate)
  this.thirdtype = "doughnut2d";
  this.type_chart = "column2d";
  this.dataFormat = "json";
  this.width = 492;
  this.height = 300;
  this.emp_details = candidate;
  this.emp_count = candidate.count
  this.dialog.open(templateRef);
  var emp_loop = candidate.skillName
  this.emp_label_count = []
    emp_loop.forEach(data =>{
      this.emp_label_count.push({"label":data.name,"value": data.count})
    })
    console.log("adsasds",this.emp_label_count)
  if(candidate.skillName.length != 1){
     
    var ttl_count = candidate.skillName.length * 30
    var incorrect = ttl_count - this.emp_count
    
    const percantage_Source = {
      // chart: {
      //    caption: "SUMMARY OF ATTEMPTS",
      //   subcaption: 0,
      //   enablesmartlabels: 0,
      //   showlabels: 0,
      //   numbersuffix: "",
      //   usedataplotcolorforlabels: 0,
      //   plottooltext: 0,
      //   "valueFontColor": "#e5e4e4",
      //   "bgColor": "#00264d",rgb(255, 255, 255)
      //   containerBackgroundOpacity: '0',
      //   "canvasBgAlpha": "0",
      //   "baseFontColor": "#ffffff",
      //   "toolTipBgColor": "#666666",
      //   "toolTipBorderColor": "#666666",
      //   "toolTipColor": "000000",
      //   "toolTipBgAlpha": "80",
      //   "showToolTipShadow": "1",
      //   "exportEnabled": "1",
      //   "ExportedFileName": "Overall pass or fail percentage",
      //   theme: "fusion"
      // },
     
      chart: {
        caption: "SUMMARY",
        "subCaption": "Overall Skill Test Mark",
        "xAxisName": "Skill Name",
        "yAxisName": "Mark",
        "numberSuffix": "",
        "bgColor": "#EEEEEE",
        "theme": "fusion"
    }, data: [{label: 'Incorrect', value: incorrect},
      {label: 'Correct', value: this.emp_count}]
  
    };
    this.candidate_percentage_chart = percantage_Source;
    const overall_barchart = {
        chart: {
            "caption": "MARKS SCORED",
            "subCaption": "Individual Skill Test Mark",
            "xAxisName": "Skill Name & Total Questions",
            "yAxisName": "Mark",
            "numberSuffix": "",
            "theme": "Candy"
        },
        data: this.emp_label_count
    }
    this.emp_all_mark_barchart = overall_barchart
  }

 
}
convertToPDF(nam){
  var data = document.getElementById('contentToConvert');
  // html2canvas(data,{allowTaint: true,useCORS: true,scale: 0.8}).then(canvas => {
  //   var imgData = canvas.toDataURL('image/png');
  //   var imgWidth = 210; 
  //   var pageHeight = 295;  
  //   var imgHeight = canvas.height * imgWidth / canvas.width;
  //   var heightLeft = imgHeight;
  //   var doc = new jsPDF('p', 'mm');
  //   var position = 10; // give some top padding to first page
    
  //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;
    
  //   while (heightLeft >= 0) {
  //     position += heightLeft - imgHeight; // top padding for other pages
  //     doc.addPage();
  //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }
  //   doc.save( 'file.pdf');
  // })
  html2canvas(data, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
    const image = { type: 'jpeg', quality: 0.98 };
    const margin = [0.3, 0.3];
    const filename = 'myfile.pdf';

    var imgWidth = 8.5;
    var pageHeight = 11;

    var innerPageWidth = imgWidth - margin[0] * 2;
    var innerPageHeight = pageHeight - margin[1] * 2;

    // Calculate the number of pages.
    var pxFullHeight = canvas.height;
    var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    // Define pageHeight separately so it can be trimmed on the final page.
    var pageHeight = innerPageHeight;

    // Create a one-page canvas to split up the full image.
    var pageCanvas = document.createElement('canvas');
    var pageCtx = pageCanvas.getContext('2d');
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Initialize the PDF.
    var pdf = new jsPDF('p', 'in', [8.5, 11]);

    for (var page = 0; page < nPages; page++) {
      // Trim the final page to reduce file size.
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight;
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
      }

      // Display the page.
      var w = pageCanvas.width;
      var h = pageCanvas.height;
      pageCtx.fillStyle = 'white';
      pageCtx.fillRect(0, 0, w, h);
      pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

      // Add the page to the PDF.
      if (page > 0) pdf.addPage();
      var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
      pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
    }

    pdf.save(nam+'.pdf');
  })
  // html2canvas(data,{allowTaint: true,useCORS: true,scale: 0.8}).then(canvas => {  
  //   // Few necessary setting options  
  
  //   const contentDataURL = canvas.toDataURL('image/png')  
  //   var margin = 2;
  //   var imgWidth = 210 - 2*margin; 
  //   var pageHeight = 295;  
  //   var imgHeight = canvas.height * imgWidth / canvas.width;
  //   var heightLeft = imgHeight;
  
  //   var doc = new jsPDF('p', 'mm');
  //   var position = 10;
  //   var font: { 'padding-bottom: 40%; padding-top:30%' };
  //   doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
  
  //   heightLeft -= pageHeight;
  
  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight;
  //     doc.addPage();
  //     doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight-10);
  //     heightLeft -= pageHeight;
  //   }
  //   doc.save( 'file.pdf');
  
  // });  
 
}
// convertToPDF(){
//   var data = document.getElementById('contentToConvert');

//   html2canvas(data,{allowTaint: true,useCORS: true}).then(canvas => {
//   // Few necessary setting options
//   // canvas.useCORS = true
//   var imgWidth = 208;
//   var pageHeight = 295;
//   var imgHeight = canvas.height * imgWidth / canvas.width;
//   var heightLeft = imgHeight;
   
//   const contentDataURL = canvas.toDataURL('image/png')
//   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
//   var position = 0;
//   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//   pdf.save('new-file.pdf'); // Generated PDF
//   });
  
// }

generateMultiSkillPdf(multiSkill){
console.log("multiSkill",multiSkill);
var obj = {}
obj["Name"] = multiSkill.name
//  multiSkill.skillName.forEach(skillData => {
//    var mul_skill = skillData.name
// obj[mul_skill] = skillData.count
//  })

var array = multiSkill.skillName
var mk = ""
console.log("array",array);
for(var i=0; i<array.length; i++ ){
  console.log("array[i].count",typeof array[0].count)
  mk = ""
  if(array[i].count <= 9){
    mk = "0"+array[i].count
  }
  else{
    mk = array[i].count
  }
  obj[array[i].name + " Mark"] = mk
}

obj["Total Marks"] = multiSkill.count

console.log("obj",obj);
    var doc = new jsPDF();
    var col = ["Details", "Values"];
    var rows = [];
    for(var key in obj){
        var temp = [key, obj[key]];
        rows.push(temp);
    }
    //doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
    //doc.rect(5, 5, 200, 285, "S").line(5, 45, 205, 45, "S");assets/img/HindujaTech.jpg
    doc.rect(5, 5, 200, 285, "S")
   // var myImage = "assets/img/HindujaTech.jpg";
    //  var img = new Image();
    //  img.src = bannerImage;
   // doc.addImage(myImage, 'JPEG', 300, 200);
    doc.autoTable(col, rows);
    doc.save('MultiSkill.pdf');
}

  dropdownskill_graph(dropdownskill) {
    console.log('dropdown function works', dropdownskill)
    console.log("this.selected_rrf_id",this.selected_rrf_id);
    this.mark_percent_skillName = dropdownskill;
    if (dropdownskill != 'All') {
      this.get_table_details(this.selected_rrf_id);
    }
    else if (dropdownskill == 'All') {
      this.get_table_details(this.selected_rrf_id);
      this.yAxisValue = this.newdashboardskill.length * 30;
      console.log('dropdown value', dropdownskill)
    }
  }

  downskill_graph(dropdownskill) {
    console.log("dropdownskill",dropdownskill);
    this.mark_percent_skillName = dropdownskill;
    if (dropdownskill == 'All') {
      this.get_table_details(this.selected_rrf_id);
      console.log('dropdown-value = all')
    }

  }

  // getting  rrf details chart and fitmentscore graph

  Options_pratice_change(sub_practice, practice) {
    this.arrDataActual = []
    console.log('works')
    this.rrf_role_list = [];
    this.rrf_list = []
    this.rrf_values = []
    this.dynamicTable = []
    this.quizService.get_practice_graph(sub_practice, practice).subscribe((data: any) => {
      this.data = data.total_result;
      console.log(this.data[0].value)
      const gaugedata = {
        chart: {
          caption: "Resource Requisition Raised ",
          lowerlimit: "0",
          upperlimit: "100",
          showvalue: "1",
          numbersuffix: 0,
          "slantLabel": "1",
          theme: "fusion",
          showtooltip: "1",
          "exportEnabled": "1",
          "ExportedFileName": "Total count of rrf raised"
        },
        colorrange: {
          color: [
            {
              minvalue: "0",
              maxvalue: "50",
              code: "#62B58F"
            },
            {
              minvalue: "50",
              maxvalue: "75",
              code: "#62B58F"
            },
            {
              minvalue: "75",
              maxvalue: "100",
              code: "#62B58F"
            }
          ]
        },
        dials: {
          dial: [
            {
              value: this.data[0].value
            }
          ]
        }
      };
      this.gaugedataSource = gaugedata

      this.graph_list = data.rrf_code_list
      console.log('rrf_deatils', this.graph_list)
      for (let i = 0; i < this.graph_list.length; i++) {

        this.arrDataActual.push({ label: this.graph_list[i]._id, value: this.graph_list[i].count });
      }
      console.log('rrf_deatilsss', this.arrDataActual, 'value', data.total_result[0].value)
      this.arrDataActual.push({ label: "Total requisition raised", value: data.total_result[0].value })
      let sortOrder = ['Submitted', 'RM Approved', 'RMG Approved', 'RM Rejected', 'RMG Rejected', 'Recruiter Accepted', 'Closed'];
      this.arrDataActual.sort((a, b) => {
        return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
      });
      // this.reuse_praticegraphinfo(this.arrDataActual)

    })
    this.quizService.get_rrf_code_quiz(sub_practice, practice).subscribe((data: any) => {
      console.log(data, 'rrf_lift_value');
      data.rrf_code_list.forEach(group => {
        if (group.role != "") {
          this.rrf_role_list.push({ name: group.role, rrf_code: group.rrf_code });
          this.rrf_list.push({ name: group.rrf_code, role: group.role })
        }
      })
      this.rrf_role_list = this.rrf_role_list.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
      //   this.rrf_list = data.rrf_code_list;
      this.dynamicTable = this.rrf_role_list;
      this.headerName = 'RRF Role';
    })
  }

  //sendmailfun
  mailsendchange(mailtype, limit) {
    console.log("mailtype", mailtype, limit)
  }

checkedValueSingleSkill(value,event){
console.log("value",value)
console.log("event",event)
console.log("candidate_list",this.candidate_list);
  value['isActive'] = event
console.log("candidate_list",this.candidate_list);
}

checkedValueMultiSkill(value,event){
console.log("value",value)
console.log("event",event)
console.log("dasharray",this.dasharray);
  value['isActive'] = event
console.log("dasharray",this.dasharray);
}

sendSingleFilterValue(mailtype,rrf_code) {
    console.log(" this.candidate_list", this.candidate_list)
    var array_list = []
    this.candidate_list.forEach(element => {
  if(element.isActive == true ){
       array_list.push(element)
  }
});
console.log("this.candidate_list",array_list,rrf_code);
    this.skill_service.sendcandidatemail(array_list, mailtype).subscribe((data: any) => {
      console.log("data", data)
       if(data.mail_type == "true"){
        this.snackBar.open('Mail to the Recruiter Sent Successfully ', '', {
          duration: 3000,
        });
         console.log(this.range,this.limit)
    this.mailValue = ''
    this.range = '';
    this.limit = '';
    this.newdashboardfilter(rrf_code)
      }
    })
}

sendMultiFilterValue(mailtype,rrf_code) {
  console.log("this.dasharray",this.dasharray);
     var array_list_data = []
    this.dasharray.forEach(element => {
  if(element.isActive == true ){
       array_list_data.push(element)
  }
});
    console.log(" this.candidate_list", array_list_data)
    this.skill_service.sendcandidatemail(array_list_data, mailtype).subscribe((data: any) => {
      console.log("data", data)
      if(data.mail_type == "true"){
         console.log(this.range,this.limit)
         this.snackBar.open('Mail to the Recruiter Sent Successfully ', '', {
          duration: 3000,
        });
    this.mailValue = ''
    this.range = '';
    this.limit = '';
    this.newdashboardfilter(rrf_code)
      }
    })
}
  //getting sub pratcice name
get_details(value) {
    console.log("value", value)
    this.practice = value;
    this.arrDataActual = [];
    this.dynamicTable = [];
    this.sub_practice == '';
    this.quizService.getsubpractice(value).subscribe((data: any) => {
      console.log(data)
      this.sub_practice_list = data.data[0];
      this.rrf_role_lists =[];
      this.rrf_values = [];
      this.periodValue = null;
      this.headerName = 'Sub Practice';
      this.dynamicTable = data.data[0];
      this.pratcicedatainfo = data.rrfdetails
      var total_count = 0;

      for (let i = 0; i < this.pratcicedatainfo.length; i++) {

        total_count += this.pratcicedatainfo[i].count;

        this.arrDataActual.push({ label: this.pratcicedatainfo[i]._id, value: this.pratcicedatainfo[i].count });
      }

      this.arrDataActual.push({ label: "Total requisition raised", value: total_count })
      let sortOrder = ['Submitted', 'RM Approved', 'RMG Approved', 'RM Rejected', 'RMG Rejected', 'Recruiter Accepted', 'Closed'];
      this.arrDataActual.sort((a, b) => {
        return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
      });
      //  this.reuse_praticegraphinfo(this.arrDataActual)

    })
}

  // getting rrf role
get_rrf_code(rrf_role) {
    console.log(rrf_role)

    this.quiz_bar_chart = [];
    this.rrf_quizcandidate_list = [];

    this.rrf_values = [];
    this.dynamicTable = [];

    this.quizService.getrrf_role_list(rrf_role).subscribe((data: any) => {

      console.log(data)
      data.data.forEach(group => {

        console.log(group)
        this.rrf_values.push({ name: group.rrf_code });

      })
      // sort RRF code
      this.rrf_values.sort((a,b) => b.name.localeCompare(a.name));

      console.log(this.rrf_values);
      this.dynamicTable = this.rrf_values;
      this.headerName = 'RRF List';
    })

  }

  get_dash_table_value(rrf_master_id) {

    console.log("this.newdashboardskill", this.newdashboardskill);
    this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((data: any) => {
      this.dasharray = []
      this.dasharray_mail_list = []
      this.dasharray_list_update = []
      console.log("data", data)
      data.forEach(skilllist => {
        console.log("skilllist", skilllist);
        if (data.length != 0 || data.length != undefined) {
          this.array_of_array = []
          this.dashresults = this.newdashboardskill.filter(({ skill_name: id1 }) => !skilllist.skill_name.some(({ _id: id2 }) => id2 === id1));
          console.log("results", this.dashresults)
          if (this.dashresults.length == 0) {
            console.log("terterterte")

            this.newdashboardskill.forEach(dash => {
              this.dashobj = {}
              this.dashobj["emp_name"] = skilllist.name
              console.log()
              skilllist.skill_name.forEach(element => {
                if (dash.skill_name == element._id) {

                  this.dashobj["name"] = dash.skill_name
                  this.dashobj["count"] = element.count
                  this.array_of_array.push(this.dashobj)

                }
              })

              //this.dasharray.push({skillName:this.dashobj})
              // this.dasharray.push({skillName:this.dashobj})
            this.array_of_array.sort((a, b) => a.name.localeCompare(b.name));
            });
            
          }
          else {

            this.dashresults.forEach(data => {
              this.dashobj = {}
              this.dashobj["name"] = data.skill_name
              this.dashobj["count"] = 0
              this.array_of_array.push(this.dashobj)
            })
            // this.newdashboardskill.forEach(dash => {
            //   data[0].skill_name.forEach(element => {
            //     if (dash.skill_name == element._id) {
            //       this.dashobj = {}
            //       this.dashobj["name"] = dash.skill_name
            //       this.dashobj["count"] = element.count
            //       this.array_of_array.push(this.dashobj)
            //     }

            //   })


            // });
            skilllist.skill_name.forEach(dash =>{
              this.dashobj = {}
                    this.dashobj["name"] = dash._id
                    this.dashobj["count"] = dash.count
                    this.array_of_array.push(this.dashobj)
            })
            console.log("this.array_of_array",this.array_of_array);
            this.array_of_array.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
        this.arrayCount = 0;
        this.array_of_array.forEach(countValue => {

          console.log("countValue.count", countValue.count);
          this.arrayCount = this.arrayCount + toNumber(countValue.count)
        })
        console.log("this.array_of_array",this.array_of_array)
        console.log(" this.arrayCount", this.arrayCount)
          if(!skilllist.candidate_mail_status || skilllist.candidate_mail_status == null || skilllist.candidate_mail_status == undefined){
          this.dasharray.push({ skillName: this.array_of_array, count: this.arrayCount, name: skilllist.name,_id:skilllist._id,isActive:true })
        this.dasharray_list_update.push({ skillName: this.array_of_array, count: this.arrayCount,_id:skilllist._id, name: skilllist.name,isActive:true })
        this.dasharray_list_reset.push({ skillName: this.array_of_array, count: this.arrayCount,_id:skilllist._id, name: skilllist.name,isActive:true })
        console.log("this.dasharray",this.dasharray);
        
              } 
          else if(skilllist.candidate_mail_status == "NextRoundMail" || skilllist.candidate_mail_status == "RejectionMail"){
                this.dasharray_mail_list.push({skillName: this.array_of_array, count: this.arrayCount,_id:skilllist._id,'email':skilllist.email,'candidate_last_name':skilllist.candidate_last_name,'current_location':skilllist.current_location,'education':skilllist.education,'gender':skilllist.gender,'phone':skilllist.phone,  name: skilllist.name,isActive:true,candidate_mail_status: skilllist.candidate_mail_status});
                console.log("this.dasharray_mail_list",this.dasharray_mail_list)
              }
       
      })
    })
  }

  //getting  candidates tables
  get_table_detailsNew(rrf_master_id) {
    console.log("table2works", rrf_master_id)
    this.rrf_graphCandidate_results = true;
    // changed service
    this.skill_service.getRRFData(rrf_master_id).subscribe((setdata: any) => {
      console.log("data", setdata)
      this.minimum_fitment_score = setdata.data[0].minimum_fitment_score
    })
    console.log("this.minimum_fitment_score", this.minimum_fitment_score);
    this.candidates = []
    this.quizService.gettablequiz_rrfdetails(rrf_master_id).subscribe((data: any) => {
     
      if (data.length != 0 || data.length != undefined) {
        console.log('1 works', data);
        this.candidates = data;
        console.log(data, 'candidatelist', this.newdashboardskill)

        var candidate_count = 0;
        this.candidate_list = [];
        this.candidate_list_updated = [];
         this.candidate_mail_list = []
        this.candidates.forEach(obj1 => {
          console.log("obj1",obj1);
            obj1["isActive"] = true
          //  if(obj1["candidate_mail_status"] != undefined || obj1["candidate_mail_status"]){
          //    obj1["candidate_mail_status"] = obj1["candidate_mail_status"];
          //  }else{
          //    obj1["candidate_mail_status"] = null
          //  }
          var percent = (obj1.count / Number(60));
          percent = percent * 100;
          obj1.percent = Math.round(percent);
          if (percent >= Number(this.minimum_fitment_score)) {
            candidate_count = candidate_count + 1;
          }
             
           if(obj1.candidate_mail_status == "NextRoundMail" || obj1.candidate_mail_status == "RejectionMail"){
                this.candidate_mail_list.push(obj1);
              } 
            // else(!obj1.candidate_mail_status || obj1.candidate_mail_status == null || obj1.candidate_mail_status == undefined){
              else{
                this.candidate_list.push(obj1);
               this.candidate_list_updated.push(obj1);
               this.candidate_list_reset.push(obj1)
               console.log("this.candidate_list",this.candidate_list);
                   } 
              
        });
        
        console.log(this.table_marks)

        // if (this.rrf_code == 'RR5404') {
        //   let array3 = this.candidate_list.map((o, i) => ({ ...o, ...this.table_marks[i] }));
        //   array3[0].skill_name.push({ _id: 'new', count: 0 });
        //   this.candidate_list = array3;
        // } else {
        //   console.log('not rr5404');
        //   let array3 = this.candidate_list.map((o, i) => ({ ...o, ...this.table_marks[i] }));
        //   this.candidate_list = array3;
        //   console.log('merged array', this.candidate_list)
        // }
        //   demo_newarray
        console.log("icecream", this.candidate_list[0].skill_name);

        this.cleared_candidates = candidate_count;
        if (Number(this.cleared_candidates) != 0) {
          var candidate_percent = Number(this.cleared_candidates) 
          candidate_percent = Math.round(candidate_percent * 100);

          this.cleared_candidates_percent = Math.round(candidate_percent);
        } else {
          // console.log("Inside0")
          this.cleared_candidates_percent = 0;
        }

      } else {
        console.log('2 works');
        //this.mapping_detail=[];

      }
    })

  }

  // calculating fitmentscore
  calculateFitment(event) {
    var value = event.target.value;
      this.minimum_fitment_score = value;
    console.log("this.minimum_fitment_score",typeof this.minimum_fitment_score,this.minimum_fitment_score);
  }

  keyPress(event: any) {
    console.log("event.target.value")
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
     //this.calculateFitment(event);
  }
  on_Change(event) {
    //console.log(event.target.checked )
    this.rrf_values.forEach(element => {
      //  console.log(element)
    })

  }

  get_newCheckedValue(event, name, x, i) {
    console.log(x)
  }
  
  get_fitmentdetailsgraph(subpratice) {
    this.quizService.getfitmentgraph(subpratice).subscribe((data: any) => {
      console.log('getfitmentgraph', data)
      const areadata = {
        chart: {
          // caption:"Fitment Score (Experience Based)",
          yaxisname: "Associate Count",
          xaxisname: "Fitment %",
          "exportEnabled": "1",
          "ExportedFileName": "Fitmentscore",
          subcaption: 0,
          "valueFontColor": "#e5e4e4",
          legendposition: "Right",
          drawanchors: "0",
          showvalues: "0",
          "bgColor": "#00264d",
          plottooltext: "<b>$dataValue</b>",
          theme: "fusion",
          "baseFontColor": "#fff",
          "palettecolors": "F2726F,FFC533,62B58F"
        },
        "data": data
      };
      this.areadataSource = areadata
      this.Candidate_results = true;



    })
  }

  onTextChange(value, subpractice, practice) {

    if (value == '') {
      this.Options_pratice_change(subpractice, practice);
    }

  }
  change(inc) {
    this.year += inc
  }


checkTopSingleSkill(range, type, value) {
    console.log("range",range,type, value)
    // if(value != ''){
    //     this.mailValue = "NextRoundMail";
    // }else{
    //   this.mailValue = "";
    // }
    if(value != "0"){
this.resetValue = true;
    if (range == 'Bottom' && type == 'Number' && value != '') {
      // console.log("Inside3")
      
      this.candidate_list = this.candidate_list_updated;
      this.candidate_list.sort(this.compareValues('count'));
      var candidate_new_list = [];
      var i = 1;
      for (var obj of this.candidate_list) {
        if (Number(value) <= this.candidate_list.length) {
          if (i <= Number(value)) {
            candidate_new_list.push(obj);
            i = i + 1;
          }

        } else {
          candidate_new_list.push(obj)
        }
      }
      this.candidate_list = candidate_new_list;
    } else if (range == 'Top' && type == 'Number' && value != '') {
      //console.log("Inside2")
      this.candidate_list = this.candidate_list_updated;
      this.candidate_list.sort(this.compareValues('count', 'desc'));
      var candidate_new_list = [];
      var j = 1;
      for (var obj of this.candidate_list) {

        if (Number(value) <= this.candidate_list.length) {
          if (j <= Number(value)) {
            candidate_new_list.push(obj);
            j = j + 1;
          }

        } else {
          candidate_new_list.push(obj)
        }
      }
      this.candidate_list = candidate_new_list;

    } else if (range == 'Bottom') {
      //console.log("Inside")
      console.log("this.candidate_list_updated",this.candidate_list_updated);
      this.candidate_list = this.candidate_list_updated;
      this.candidate_list.sort(this.compareValues('count'));
      console.log("this.candidate_list",this.candidate_list);
    } else if (range == 'Top') {
      // console.log("Inside1")
      this.candidate_list = this.candidate_list_updated;
      this.candidate_list.sort(this.compareValues('count', 'desc'));
    }
    }
    


}


validateMultiValue(limit){
  console.log(typeof limit)
 if(this.dasharray.length < limit || limit == "0"){
this.limit = "";
 }
}

isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

validatesingleValue(limit){
  console.log(typeof limit,limit)
 if(this.candidate_list.length < limit || limit == "0"){
this.limit = "";
 }
}
checkTopMultiSkill(range, type, value) {
    console.log("range",range,type,typeof value,value)
    // if(value != ''){
    //     this.mailValue = "NextRoundMail";
    // }else{
    //   this.mailValue = "";
    // }
    if(value != "0"){
this.resetValue = true;
    if (range == 'Bottom' && type == 'Number' && value != '') {
      // console.log("Inside3")
      
      this.dasharray =  this.dasharray_list_update
      this.dasharray.sort(this.compareValues('count'));
      var candidate_new_list = [];
      var i = 1;
      for (var obj of this.dasharray) {
        if (Number(value) <= this.dasharray.length) {
          if (i <= Number(value)) {
            candidate_new_list.push(obj);
            i = i + 1;
          }

        } else {
          candidate_new_list.push(obj)
        }
      }
      this.dasharray = candidate_new_list;
    } else if (range == 'Top' && type == 'Number' && value != '') {
      //console.log("Inside2")
      this.dasharray = this.dasharray_list_update;
      this.dasharray.sort(this.compareValues('count', 'desc'));
      var candidate_new_list = [];
      var j = 1;
      for (var obj of this.dasharray) {

        if (Number(value) <= this.dasharray.length) {
          if (j <= Number(value)) {
            candidate_new_list.push(obj);
            j = j + 1;
          }

        } else {
          candidate_new_list.push(obj)
        }
      }
      this.dasharray = candidate_new_list;

    } else if (range == 'Bottom') {
      //console.log("Inside")
      console.log("dasharray",this.dasharray_list_update)
      this.dasharray = this.dasharray_list_update;
      this.dasharray.sort(this.compareValues('count'));
    } else if (range == 'Top') {
      // console.log("Inside1")
      this.dasharray = this.dasharray_list_update;
      this.dasharray.sort(this.compareValues('count', 'desc'));
    }
    }
    


  }
  compareValues(key, order = 'asc') {
    console.log("key",key);
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  showModal(): void {
    var $: any;
    $("#myModal").modal('show');
  }
  chartpopup(templateRef: TemplateRef<any>, sub_practice) {
    this.quizService.get_popupdetails(sub_practice).subscribe((data: any) => {
      console.log(data)

      this.modalfitment_list = data
      this.dialog.open(templateRef,);
    })


  }

  reset_filter(rrf_code) {
    console.log(" this.candidate_list_reset", this.candidate_list_reset);
    console.log(this.range,this.limit)
    this.mailValue = ''
    this.range = '';
    this.limit = '';
    this.newdashboardfilter(rrf_code)

  }

  reset_filter_multiSkill(rrf_code) {
    console.log(this.range,this.limit)
    this.mailValue = ''
    this.range = '';
    this.limit = '';
    this.newdashboardfilter(rrf_code)

  }

  add_Event(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value
  }

  // toggle change event
  onChange($event) {
    this.toggleSelected = $event.value;
  }

  onToggleChange($event) {
    console.log($event.value);
    if (this.mark_percent_skillName == "") {
      this.mark_percent_skillName = "All"
    }
    console.log("this.mark_percent_skillName", this.mark_percent_skillName);
    if ($event.value == 'histogram') {
      this.scrollcombidy2d = "column2d";
      this.timeseriestype = "mscolumn2d";
      this.lineColor = '#5d62b5';
      this.dualLineChartColor = '#5d62b5, #29c3be';
      this.get_table_details(this.selected_rrf_id);
    } else if ($event.value == 'scatter') {
      this.scrollcombidy2d = "line";
      this.timeseriestype = "msline";
      this.lineColor = '#ffffff';
      this.dualLineChartColor = '#ffffff';
      this.get_table_details(this.selected_rrf_id);
      console.log(this.selected_rrf_id, 'rrf')
    }
  }

  // onChangeAssesmentChart($event) {
  //   console.log($event.value);
  //   if($event.value == 'marks') {
  //     this.assesmentChart = 'marks';
  //   } else if($event.value == 'percentage') {
  //     this.assesmentChart = 'percentage';
  //   }
  // }

  onChangeClientChart($event) {
    if (this.mark_percent_skillName == "") {
      this.dropdownskill_graph("All")
    }
    console.log("mark_percent_skillName", this.mark_percent_skillName);
    if ($event.value == 'mark') {
      //this.clientChart = $event.value;
      this.mark_percent_value = $event.value;
      this.dropdownskill_graph(this.mark_percent_skillName)
    } else if ($event.value == 'percentage') {
      this.mark_percent_value = $event.value;
      console.log("this.mark_percent_skillName", this.mark_percent_skillName)
      this.dropdownskill_graph(this.mark_percent_skillName)
    }
  }

  // period dropdown function


  onPeriodDropdownchange(periodValue, sub_practice, practice) {
    console.log("sub_practice",sub_practice)
    console.log("practice",practice);
    if (sub_practice != undefined) {
      var months_array = [];
      var array_graph = [];
      this.arrDataActual = [];
      this.dynamicTable = [];
      this.rrf_list = [];
      this.rrf_values = [];
      var date180DaysAgo = new Date(new Date().getTime() - periodValue * 24 * 60 * 60 * 1000);
      var startdate = new Date(this.startDate);
      var startyear = date180DaysAgo.getFullYear();
      var month = date180DaysAgo.getMonth() + 1 //getMonth is zero based;
      var day = date180DaysAgo.getDate();
      var start_date = startyear + "-" + month + "-" + day;

      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1 //getMonth is zero based;
      var day = date.getDate();
      var formatted_date = year + "-" + month + "-" + day;
      //alert(this.startDate)
      console.log(formatted_date, date180DaysAgo, 'periodValue', startdate, start_date)
      this.quizService.getdatewise_graph(formatted_date, this.sub_practice, this.practice, start_date).subscribe((data: any) => {
        console.log(data)
        var total_count = 0;
        // data.rrf_code_list.forEach(element => {
        // total_count+=element.count;
        // })
        data.rrf_code_list.forEach(group => {
          total_count += group.count;

          if (group.role != "FALSE") {


            this.rrf_role_list.push({ name: group.role });
            this.rrf_values.push({ name: group.rrf_code })
          }
        })
        this.rrf_role_lists = this.rrf_role_list.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
        this.rrf_values = this.rrf_values.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
        console.log(this.rrf_values, this.rrf_role_list, "rrf list")
        console.log('total_count', total_count);

        array_graph.push({ name: 'total_count', value: total_count })
        this.selectedEndDate = formatted_date;
        this.selectedStartDate = start_date;

        this.data = array_graph;
        console.log(this.data[0].value)
        const gauge_datedata = {
          chart: {
            caption: "Resource Requisition Raised ",
            lowerlimit: "0",
            upperlimit: "100",
            showvalue: "1",
            numbersuffix: 0,
            theme: "fusion",
            showtooltip: "1",
            "exportEnabled": "1",
            "exportfilename": "export_file1",
          },
          colorrange: {
            color: [
              {
                minvalue: "0",
                maxvalue: "50",
                code: "#62B58F"
              },
              {
                minvalue: "50",
                maxvalue: "75",
                code: "#62B58F"
              },
              {
                minvalue: "75",
                maxvalue: "100",
                code: "#62B58F"
              }
            ]
          },
          dials: {
            dial: [
              {
                value: this.data[0].value
              }
            ]
          }
        };
        this.gaugedataSource = gauge_datedata

        this.graph_list = data.rrf_code_list
        this.arrDataActual = []


        for (let i = 0; i < this.graph_list.length; i++) {

          this.arrDataActual.push({ label: this.graph_list[i]._id, value: this.graph_list[i].count });
        }
        console.log('rrf_deatilsss', this.arrDataActual);
        console.log('rrf_deatilsss', this.arrDataActual, 'value', data.total_result[0].value)
        this.arrDataActual.push({ label: "Total requisition raised", value: data.total_result[0].value })
        let sortOrder = ['Submitted', 'RM Approved', 'RMG Approved', 'RM Rejected', 'RMG Rejected', 'Recruiter Accepted', 'Closed'];
        this.arrDataActual.sort((a, b) => {
          return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
        });

        console.log('sorted', this.arrDataActual)
      })
      this.quizService.get_datewiserrf_code_quiz(formatted_date, this.sub_practice, this.practice, start_date).subscribe((data: any) => {
        console.log('client', data)
        data.rrflist.forEach(group => {
          if (group.role != "FALSE") {
            this.rrf_role_list.push({ name: group.role });
          }
        })
        console.log(this.rrf_role_list)

        this.rrf_role_lists = this.rrf_role_list.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
        this.rrf_list = data.rrflist;
        console.log("this.rrf_role_lists",this.rrf_role_lists)
         this.dynamicTable = this.rrf_role_lists;
      })
      // console.log("this.rrf_role_lists",this.rrf_role_lists)
      // console.log("this.rrf_role_list",this.rrf_role_list);
      // this.dynamicTable = this.rrf_role_lists;
      this.headerName = 'RRF Role';

    }
    else {
      console.log('no end date')
    }
  }

  // save details to cookies 
  saveDetails(practiceValue: any, sub_practiceValue: any, periodValueSelected, rrf_codeValue: any, rrf_codeSelected: any) {
    var postValue = ({ parctice: practiceValue, sub_practice: sub_practiceValue, periodValue: periodValueSelected, rrf_role_value: rrf_codeValue, rrf_code: rrf_codeSelected });
    console.log(postValue);
    this.cookieService.putObject(this.currentUser.employee_number, postValue);
  }

  toArray(tilecollection: object) {
    console.log("tilecollection", tilecollection);
    return Object.keys(tilecollection).map(key => tilecollection[key]);
  }

  resetForm(form: NgForm) {
    console.log('reset works')
    form.reset()
    this.newdashboardskill.length = 0
    this.Candidate_results = false;
    this.rrf_graphCandidate_results = false;
    this.rrf_code = '';
    this.rrf_role_list = [];
    this.rrf_values = [];
    this.skill_service.getEmployeeDetails(this.currentUser.id).subscribe((data: any) => {
      console.log(data);
      // this.practice = data.data.employee_detail.ideal_practice;
      // this.get_details(data.data.employee_detail.ideal_practice);
    });

    // console.log(this.Candidate_results, this.rrf_graphCandidate_results,this.rrf_code)
  }

  getCountOfCandidate(rrf_code, score) {
    console.log("score",score);
    this.quizService.get_data_newdashboard(rrf_code).subscribe((dataVal: any) => {
      console.log("get_data_newdashboard", dataVal)

      var skillVal = (dataVal.newdashboarddata.length);
      if(skillVal == 1){
this.skill_values_check =60
      }else{
        this.skill_values_check = 30 * skillVal
      }
      console.log("this.skill_values_check",this.skill_values_check)
      console.log("this.skill_values_check",skillVal);
    this.rr_report = [];
    var ttl_candidate = 0
    console.log(this.rr_report.length, this.rr_report, 'before');
    this.quizService.getCountOfAllCandidates(rrf_code, score,this.skill_values_check).subscribe((data: any) => {
      console.log("getCountOfAllCandidates", data);
      for (let i = 0; i < data.length; i++) {
        this.rr_report.push({ label: data[i].name, value: data[i].count });
        if(data[i].name == "Assessment Cleared"){
          console.log("data[i].count",data[i].count);
          this.new_cleared_candidates =data[i].count
        }
        if(data[i].name =="Recruiter Shortlisted"){
          ttl_candidate = this.dasharray.length
        }
      }
      console.log("ttl_candidate",ttl_candidate);
      console.log("this.candidate_percentage_piechart",this.candidate_percentage_piechart[0].label);
      if (this.candidate_percentage_piechart[0].label == "Pass") {
        this.candidate_percentage_piechart[0].value = Math.round((this.new_cleared_candidates/ttl_candidate)*100)
      }
      if(this.candidate_percentage_piechart[1].label == "Fail") {
        this.candidate_percentage_piechart[1].value = Math.round(100 - ((this.new_cleared_candidates/ttl_candidate)*100) )
      }
      console.log("this.candidate_percentage_piechart[0].value",this.candidate_percentage_piechart);
       const percantage_Source = {
            chart: {
              // caption: "Overall Test Result",
              subcaption: 0,
              enablesmartlabels: 0,
              showlabels: 0,
              numbersuffix: "%",
              usedataplotcolorforlabels: 0,
              plottooltext: 0,
              "valueFontColor": "#e5e4e4",
              "bgColor": "#00264d",
              containerBackgroundOpacity: '0',
              "canvasBgAlpha": "0",
              "baseFontColor": "#ffffff",
              "toolTipBgColor": "#666666",
              "toolTipBorderColor": "#666666",
              "toolTipColor": "000000",

              "toolTipBgAlpha": "80",
              "showToolTipShadow": "1",
              "exportEnabled": "1",
              "ExportedFileName": "Overall pass or fail percentage",
              theme: "fusion"
            },
            data: this.candidate_percentage_piechart

          };
          this.candidate_percentage_chart = percantage_Source;
    });
    // } else {
    //   this.rr_report = [];
    // }
    console.log("ttl_candidate",ttl_candidate);
    console.log("this.candidate_percentage_piechart[0].value",this.candidate_percentage_piechart[0].value);
    console.log("this.candidate_percentage_piechart",this.candidate_percentage_piechart[0].label);

    const data_Source = {
      chart: {
        // caption: "Resource Requisition Status",
        "is2D": "0",
        // subcaption: 0,
        theme: "fusion",
        // showlabels: "1",
        showvalues: "1",
        // showpercentvalues: 0,
        "bgColor": "#00264d",
        "valueFontColor": "#e5e4e4",
        "toolTipColor": "000000",
        "YAxisMinValue": 0,
        "YAxisMaxValue": 20,
        "yAxisValuesStep": 1,
        "yAxisName": "Number of candidates",
        // "numDivLines":"5", 
        // decimals : 2,
        // decimals:1,
        // "sForceDecimals": "1",
        // "forceXAxisValuesDecimals": "1",

        // "YAxisMaxValue": 25,
        // "xAxisValueDecimals": "2",
        // "forceXAxisValueDecimals": "1",
        "exportEnabled": "1",
        "ExportedFileName": "RRF Status",
        "palettecolors": "C467D4,9326BF,7625B9,5E24B6,4424B1,3123AE",
        // streamlineddata: "0",
        "baseFontColor": "#fff",
        plottooltext: "$label: <b>$value</b>",
        "toolTipBorderColor": "#666666",
        "toolTipBgColor": "#efefef",
      },
      data: this.rr_report
    };
    this.rr_list_array = data_Source;
    this.Candidate_results = true;
  })
  }

}

