import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { RedirectService } from "../services/redirect";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})
export class SkillListComponent implements OnInit {
  skill_list_data: any;
  form_data: any;
  sub_practices: any = [];
  practices: any = [];
  units: any = [];
  competency_list: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['skill', 'beginner', 'practitioner', 'advance', 'expert', 'action'];
  constructor(private router: Router, private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, public redirect: RedirectService, private bottomSheet: MatBottomSheet) {
  }
  ngOnInit() {
    this.form_data = new FormGroup({
      unit: new FormControl(""),
      practice: new FormControl(""),
      sub_practice: new FormControl(""),
      competency: new FormControl(""),
      new_competency: new FormControl("", [Validators.required]),
    });
    this.form_data.controls.unit.valueChanges.subscribe(value => {
      if (value)
        this.skill_service.getUnit(value)
          .subscribe(
            response => {
              this.form_data.controls["practice"].setValue(null);
              this.form_data.controls["sub_practice"].setValue(null);
              this.form_data.controls["competency"].setValue(null);
              this.sub_practices = [];
              this.competency_list = [];
              this.practices = response; 
            }
          )
    });
    this.form_data.controls.practice.valueChanges.subscribe(value => {
      if (value)
        this.skill_service.getUnit(value)
          .subscribe(
            response => {
              this.form_data.controls["sub_practice"].setValue(null);
              this.form_data.controls["competency"].setValue(null);
              this.sub_practices = response
              this.competency_list = [];
            }
          );
    });
    this.form_data.controls.sub_practice.valueChanges.subscribe(value => {
      if (value)
        this.skill_service.getCompetency(value, [])
          .subscribe(
            response => {
              this.form_data.controls["competency"].setValue(null);
              this.competency_list = response
            }
          );
    });

    this.form_data.controls.competency.valueChanges.subscribe(value => {
      if (value)
      this.skill_service.getOrganizationSkillList(value)
      .subscribe(
        response => {
          this.skill_list_data = new MatTableDataSource(response["data"]);
          this.skill_list_data.paginator = this.paginator;
        }
      );
    });
    this.skill_service.getUnit("0")
      .subscribe(
        response => {
          this.units = response
        }
      );
  //  this.onSubmit();
  }

 

  onSubmit() {
    this.skill_service.getOrganizationSkillList(this.form_data.value)
      .subscribe(
        response => {
          this.skill_list_data = new MatTableDataSource(response["data"]);
          this.skill_list_data.paginator = this.paginator;
        }
      );
  }
  addCompetency() {
    if (this.form_data.value.new_competency)
      this.skill_service.addNewCompetency(this.form_data.value.sub_practice, this.form_data.value.new_competency)
        .subscribe(
          response => {
            this.form_data.controls["new_competency"].setValue("");
            this.competency_list = response["data"].reverse();
          }
        );
  }

  redirectPage(page_link, rrf_id) {
    this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  ngOnDestroy() {
    this.dialog.closeAll();
    this.bottomSheet.dismiss();
  }

  openDialog(page_mode,skill_id){
    var competency_id=this.form_data.controls.competency.value;
    if(page_mode!='delete'){
      let skill_data = new Map();
      this.skill_service.getSkillDetails(competency_id,skill_id)
      .subscribe(
        response => {
          skill_data.set("skills",response["data"]);
          skill_data.set("mode",page_mode);
          skill_data.set("competency_id",competency_id);
          this.bottomSheet.dismiss();
          const bottomSheetRef = this.bottomSheet.open(EditSkill, { closeOnNavigation: true, data: skill_data, disableClose: true })
        
          bottomSheetRef.afterDismissed().subscribe(result => {
           
            if(result=='update' || result=='add'){
              this.skill_service.getOrganizationSkillList(competency_id)
              .subscribe(
                response => {
                  this.skill_list_data = new MatTableDataSource(response["data"]);
                  this.skill_list_data.paginator = this.paginator;
                }     
              );
            }
            
            })
        });
    }else{
      this.skill_service.deleteSkillDetails(competency_id,skill_id)
      .subscribe(
        response => {
          this.skill_list_data = new MatTableDataSource(response["data"]);
          this.skill_list_data.paginator = this.paginator;
          });
    }
    
  }

  fitmentReport(){
    this.router.navigate(['/fitment_analytics']);
  }

}



@Component({
  selector: 'edit-skill-popup',
  templateUrl: 'edit-skill-popup.html',
  styleUrls: ['./skill-list.component.css'],
})
export class EditSkill {
  public skills: any;
  public mode: String;
  public competency_id:any;
  form_data1: any;
  skill_list_data: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private location: Location,private router: Router, private skill_service: EmployeeSkillMappingService,private bottomSheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public skill_data: any) { }
 
   ngOnInit() {
    this.skills = this.skill_data.get('skills');
    this.mode = this.skill_data.get('mode');
    this.competency_id= this.skill_data.get('competency_id');

      this.form_data1 = new FormGroup({
        skill_name: new FormControl(this.skills.skill_name, [Validators.required]),
        beginner: new FormControl(this.skills.Beginner, [Validators.required]),
        practitioner: new FormControl(this.skills.Practitioner, [Validators.required]),
        advance: new FormControl(this.skills.Advanced, [Validators.required]),
        expert: new FormControl(this.skills.Expert, [Validators.required])
      })
  }
  keyPress(event: any) {
    const pattern = /[-0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  updateSkillMapping(skill_id,competency_id){
    const formData = new FormData();
    formData.append("skill_name",this.form_data1.value.skill_name);
    formData.append("beginner",this.form_data1.value.beginner);
    formData.append("practitioner",this.form_data1.value.practitioner);
    formData.append("advance",this.form_data1.value.advance);
    formData.append("expert",this.form_data1.value.expert);
    formData.append("skill_id",skill_id);
    formData.append("competency_id",competency_id);

    this.skill_service.updateSkillDetails(formData)
    .subscribe(
      response => {
            console.log(response);

            this.bottomSheetRef.dismiss('update');
        });
  }

  saveSkillMapping(){
    const formData = new FormData();
    formData.append("skill_name",this.form_data1.value.skill_name);
    formData.append("beginner",this.form_data1.value.beginner);
    formData.append("practitioner",this.form_data1.value.practitioner);
    formData.append("advance",this.form_data1.value.advance);
    formData.append("expert",this.form_data1.value.expert);
    formData.append("competency_id",this.competency_id);

    this.skill_service.saveSkillDetails(formData)
    .subscribe(
      response => {
            console.log(response);
            
            this.bottomSheetRef.dismiss('add');
        });
  }
  
  dismissBottomSheet() {
    
  }
  closeBottomSheet() {
    this.bottomSheetRef.dismiss('cancel');
  }

}