import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-hr-rrf-approve',
  templateUrl: './hr-rrf-approve.component.html',
  styleUrls: ['./hr-rrf-approve.component.css']
})
export class HrRrfApproveComponent implements OnInit {
  formdata: any;
  overallRating: any = 0;
  hr_selected_result: any;
  constructor(private router: Router, private _snackBar: MatSnackBar, private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<HrRrfApproveComponent>) { }

  ngOnInit() {
    console.log(this.data);
    this.formdata = new FormGroup({
    
      interview_performance: new FormGroup({
        presentation: new FormControl(""),
        clarity_of_thought: new FormControl(""),
        communication: new FormControl(""),
        problem_solving_skills: new FormControl(""),
        job_stability: new FormControl(""),
        excitement_and_enthusiasm_for_the_job: new FormControl(""),
        attitude: new FormControl(""),
        team_player_and_leadership_skills: new FormControl(""),
        listening_skills: new FormControl(""),
        fitment_to_HTL_culture: new FormControl(""),
        qualification_details: new FormControl(""),
        work_experience_details: new FormControl(""),
        reason_for_job_change: new FormControl(""),
        notice_period: new FormControl("",[Validators.pattern('^[0-9]*$'), Validators.maxLength(3)]),
        others: new FormControl(""),
      }),
      evaluation_result_and_remarks: new FormGroup({
        evaluation_remarks: new FormControl(""),
        evaluation_result: new FormControl("", [Validators.required]),
        image_upload: new FormControl("",[Validators.required]),
        hr_reject_reason: new FormControl("")
        // image_upload:new FormControl( [
        //   "",
        //   [Validators.required]
        // ]),
       
      }),
      other_comments: new FormGroup({
        strength_and_weaknesses: new FormControl(""),
        reference_check_details: new FormControl(""),
        commitments_given: new FormControl("")
      })
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  onFileChange(event){
    console.log("asdasd",event.target.files)
  }
  save_iof() {
    console.log("asaxasd", this.formdata.valid)
    this.formdata.controls.evaluation_result_and_remarks.get('image_upload').markAsTouched();
    if (this.formdata.valid) {
      // var jsonObj = JSON.parse(localStorage.currentUser);
      // var employee_id = jsonObj.id;
      // this.formdata.value["submitted_by"] = employee_id;
      // if (this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Selected') {
      //   var status = 'HR Round Cleared';
      // } else {
      //   var status = 'Rejected'
      // }
      this.formdata.getRawValue();
      var jsonObj = JSON.parse(localStorage.currentUser);
      var employee_id = jsonObj.id;
      this.formdata.value["submitted_by"] = employee_id;
      const formData = new FormData();
      formData.append("file", this.formdata.value.evaluation_result_and_remarks.image_upload.files[0]);
      delete this.formdata.value.evaluation_result_and_remarks.image_upload;
      Object.keys(this.formdata.value).forEach((key) => {
        // if(key!='employee_reference'){
        //   formData.append(key, this.form_data.value[key]);
        // }else{
          formData.append(key, JSON.stringify(this.formdata.value[key]));
        // }
        
      });
      if (this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Selected') {
        console.log("inside select")
        var status = 'HR Round Cleared';
        this.skill_service.saveHRIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["hr_rrf_approve_list"]);
            });
            this.openSnackBar();
          }
        );
      }else if(this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Rejected'){
        console.log("inside Rejected")
        var status = 'HR Round Rejected';
        this.skill_service.saveHRIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
             this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["hr_rrf_approve_list"]);
             });
            this.openSnackBar();
          }
        );
      }else if(this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Reschedule'){
        console.log("inside Reschedule")
        var status = 'HR Round Reschedule';
        this.skill_service.saveHRIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
             this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["hr_rrf_approve_list"]);
             });
            this.openSnackBar();
          }
        );
      }
    }
    else{
      this._snackBar.open('please fill mandatory fields', '', {
        duration: 2000,
      });
    }
  }


  openSnackBar() {
    this._snackBar.open('Interview Observation Form Saved Successfully', '', {
      duration: 2000,
    });
  }
}
