import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cem-candidate-approve',
  templateUrl: './cem-candidate-approve.component.html',
  styleUrls: ['./cem-candidate-approve.component.css']
})
export class CemCandidateApproveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
