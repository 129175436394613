import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { TechnicalIofComponent } from '../technical-iof/technical-iof.component';
import { BACK_END_URL } from '../shared/app.globals';

@Component({
  selector: 'app-iof-list',
  templateUrl: './iof-list.component.html',
  styleUrls: ['./iof-list.component.css']
})
export class IofListComponent implements OnInit {
  rrf_list_data: any;
  filteredArr: any = [];
  view_type = "employee";
  BACK_END_URL = BACK_END_URL;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['rrf_id', 'candidate_name', 'resume','interview_round','status', 'role', 'action'];
  constructor( private skill_service: EmployeeSkillMappingService, public dialog: MatDialog) {

  }
  ngOnInit() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    // var filteredArr = [];
    this.skill_service.getIOFPendingList(jsonObj.id)
      .subscribe(
        response => {
          console.log("sdshggdytewttdfthasgvdhgas",response['data']);
         
          response['data'].forEach((element) => {
            // if(element.candidate_detail.status == 'Technical Interview 1 Scheduled'){
              if(element.candidate_detail.status == 'Skipped Technical Interview 1'){
              this.filteredArr.push(element)
            }
          })
          });
        //   filteredArr = (response['data'].filter(element => element.candidate_detail.status == 'Technical Interview 1 Schedule'))
        //   console.log("filteredArr",filteredArr);
        //   this.rrf_list_data = new MatTableDataSource(filteredArr);
        //  this.rrf_list_data.paginator = this.paginator;HR Interview Scheduled
          this.skill_service.getfinalIOFPendingList(jsonObj.id)
      .subscribe(
        response => {
          console.log(response['data']);
          response['data'].forEach((element) => {
            if(element.candidate_detail.status == 'Skipped Technical Interview'){
              this.filteredArr.push(element)
            }
          })
          
        });

        this.skill_service.getHrIOFPendingList(jsonObj.id)
        .subscribe(
          response => {
            console.log(response['data']);
            response['data'].forEach((element) => {
              if(element.candidate_detail.status == 'Skipped HR Round'){
                this.filteredArr.push(element)
              }
            })
            
          });

        setTimeout(() => {
          console.log("filteredArr",this.filteredArr);
          this.rrf_list_data = new MatTableDataSource(this.filteredArr);
          this.rrf_list_data.paginator = this.paginator;
        },500);
       
  }

  openIOFForm(rrf_object) {
    console.log("rrf_object",rrf_object);
    const dialogRef = this.dialog.open(TechnicalIofComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: rrf_object, hasBackdrop: true, disableClose: true });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result);
    //   if (result) {
    //     this.rrf_detail = result;
    //     this.candidate_detail = new MatTableDataSource(result["candidate_detail"].reverse())
    //     setTimeout(() => {
    //       this.candidate_detail.paginator = this.candidate_paginator;
    //     });
    //   }
    // });
  }
  applyFilter(filterValue: string) {
    this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }

}
