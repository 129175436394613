import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { Router } from '@angular/router';
import { RedirectService } from '../../services/redirect';
@Component({
  selector: 'app-commonheader',
  templateUrl: './commonheader.component.html',
  styleUrls: ['./commonheader.component.css']
})
export class CommonheaderComponent implements OnInit {
  profile_pic: string;
  show: string = '';
  is_manager: boolean;
  is_rmg: any;
  is_hr: boolean;
  is_LnD: boolean;
  is_manager1: boolean;
  is_rmg1: boolean;
  is_hr1: boolean;
  is_report1:boolean;
  is_interviewer:boolean;
  is_interviewer1:boolean;
  is_employee:boolean;
  employee_id:any;
  is_report:boolean;
  is_rmg_new: boolean;
  is_rmg_new1: boolean;
  is_cem: boolean;
  is_cem1: boolean;
  rmgValue = false;
  hide_myskill: boolean;
  is_panellist: boolean;
  exeptionId: boolean;
  iof_list: boolean;
  status = true;
  managerToggle = true;
  recruiterToggle = true;
  panellistToggle = true;
  hrToggle = true;
  cemToggle = true;
  hrOpsToggle = true;
  showCEMTab = false;
  is_buh = false;
  is_hrops = false;
  is_hrops1: boolean;
  tabs: any = { home: "", employee: "", manager: "", rmg: "" ,emp: "",manager_rm: "", mgr: "",emp_job:"",mgr_skill:"",interviewer:"",emp_ijp:"",rmg_ijp:"",rmg_ijpList:"",rmg_skill:"",lnd:"",lnd_skill:"",hr:"",skill_search_hr:"",rmg_cem_list:"",rmg_approval_list:"",rmg_resume:"",rmg_dashboard:"",report:"", cem:"", hrops:"", mgr_delivery:""}
  is_buh1: boolean;
  band_name: any;
  constructor(private authenticationService: AuthenticationService, private router: Router,private redirect: RedirectService) { }

  ngOnInit() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log("josonis",jsonObj)
    if(jsonObj.is_rmg==true){
      this.rmgValue=true;
    }
    //console.log(jsonObj)
    document.getElementById("user_name").innerHTML = jsonObj.first_name;
    if (jsonObj.employee_photo) {
      this.profile_pic = "assets/employee_pics/" + jsonObj.employee_photo;
    } else {
      this.profile_pic = "assets/employee_pics/profile_picture.jpg";
    }
    if(jsonObj.id == '5990' || '4959' || '4710') {
      // this.is_panellist = true;
      this.is_interviewer = true;
    }
    if(jsonObj.id == '6175' || jsonObj.employee_number == '19246') {
      // this.is_panellist = true;
      this.is_cem = true;
      this.is_cem1 = true;
    } else {
      this.is_cem1 = false;
    }
    this.is_manager1 = jsonObj.is_manager;
    this.is_rmg1 = jsonObj.is_rmg;
    this.is_hr = jsonObj.is_hr;
    this.is_hr1 = jsonObj.is_hr;
    this.is_LnD = jsonObj.is_LnD;
    this.is_interviewer1=jsonObj.is_interviewer;
    this.is_interviewer=jsonObj.is_interviewer;
    this.is_report1 = jsonObj.is_report;
    this.is_hrops = jsonObj.is_hrops;
    this.employee_id=jsonObj.id.toString();
    console.log(this.employee_id)
    this.is_employee=true;
    this.is_manager = false;
    this.is_rmg = false;
    this.is_rmg_new=false;
    this.is_report = false;
    this.band_name = jsonObj.band_name;
     if(jsonObj.id.toString()=='6594' || jsonObj.id.toString()=='6995' || jsonObj.id.toString()=='2779'){
        this.is_rmg_new1=true;
        console.log("rmg check")
        this.is_rmg1 =false;
      }
      
       //delivery manager without reportees
      if(jsonObj.id == '6902' || jsonObj.id.toString()== '7004' || jsonObj.id.toString()== '6992' || jsonObj.id.toString()== '6932' || jsonObj.id.toString()== '6375' || jsonObj.id.toString()== '6002' || jsonObj.id.toString()== '6191') {
        // this.is_panellist = true;
        this.is_manager1 = true;
      }
      if( jsonObj.id.toString()== '6002') {
        // this.is_panellist = true;
        this.recruiterToggle = true;
        this.is_rmg1 = true;
      }
      if(jsonObj.id == '6329' || jsonObj.id == '3723' || jsonObj.id == '6732' || jsonObj.id == '779' || jsonObj.id == '1193') {
        this.is_buh = true;
      } else{
        this.is_buh = false;
      }
      if(this.is_hrops == true) {
        // this.hrOpsToggle = true;
        this.is_hrops1 = true;
      } else {
        // this.hrOpsToggle = false;
        this.is_hrops1 = false;
      }
  }
  display() {
    if (this.show == '')
      this.show = "show";
    else
      this.show = '';
  }
  
  Onlogout() {
    console.log("inside of logout")
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  myProfile(){
    this.router.navigate(['/profile_details']);
  }
  referenceDocs(){
    this.router.navigate(['/reference_documents']);
  }
  change_page(page_link, active) {
    console.log('page_link',page_link,active,this.tabs[active],this.tabs)
    this.redirect.change_page(page_link);
    this.clear_tabs();
   
     this.tabs[active] = "active";
  }
  clear_tabs() {
    for (var key in this.tabs) {
      this.tabs[key] = "";
    }
  }
  update_menu(is_manager,is_employee,is_report,is_interviewer1,buh){
    this.status = false;    
    console.log(this.status, this.is_buh,buh, is_manager) 
    if(is_manager && is_employee){
      this.is_manager=true;
      this.is_report=true;
      this.is_employee=false;
      this.is_interviewer=false;
      this.recruiterToggle = false;
      this.panellistToggle = false;
      this.hrToggle = false;
      this.hrOpsToggle = false;
      this.is_hr = false;
      this.cemToggle = false;
      this.is_cem = false;
      this.is_hrops = false;
      this.is_buh1 = this.is_buh;
      //this.is_rmg=false;
    }else {
     // this.is_rmg=false;
      this.is_manager=false;
      this.is_report=false;
      this.is_employee=true;
      this.is_interviewer=is_interviewer1;
      this.is_hr = this.is_hr1;
      this.recruiterToggle = true;
      this.panellistToggle = true;
      this.hrToggle = true;
      this.hrOpsToggle = true;
      this.cemToggle = true;
      this.is_cem = this.is_cem1;
      this.is_hrops = this.is_hrops1;
      this.is_buh1 = false;
    }

  }

  update_menu_rmg(is_rmg1,is_employee){
    console.log(is_rmg1,is_employee)
    this.status = true;    
    this.managerToggle = false;
    this.hrToggle = false;
    this.hrOpsToggle = false;
    this.panellistToggle = false;
    this.cemToggle = false;
    console.log(this.status)
    var jsonObj = JSON.parse(localStorage.currentUser);
    // dhineshkumar;
    if(is_rmg1 && is_employee){
      // this.is_manager=false;
      this.is_report=true;
      this.is_employee=false;
      this.is_hr = false;
      this.is_cem = false;
      this.is_hrops = false;
      this.is_buh1 = false;
      this.hide_myskill = true;
      if(jsonObj.id.toString()=='6594' || jsonObj.id.toString()=='6995' || jsonObj.id.toString()== '6002'){
        this.is_rmg_new=true;
        this.is_rmg=false;
      }else{
        this.is_rmg_new=false;
        this.is_rmg=true;
      }
      this.router.navigate(['/rmg_job_list']);

    } else {
      // this.is_manager=false;
      this.managerToggle = true;
      this.hrToggle = true;
      this.hrOpsToggle = true;
      this.panellistToggle = true;
      this.cemToggle = true;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = this.is_cem1;
      this.is_hrops = this.is_hrops1;
      this.is_report=false;
      this.is_employee=true;
      this.hide_myskill =  false;
      this.is_buh1 = false;
      this.router.navigate(['/myspace']);

    }
  }

  update_menu_cem(cem,is_employee){
    console.log(cem,is_employee)
    this.status = true;    
    console.log(this.status)
    if(cem && is_employee){
      this.showCEMTab = true;
      this.managerToggle = false; 
      this.hrToggle = false;
      this.hrOpsToggle = false;
      this.recruiterToggle= false;
      this.is_panellist = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = true;
      this.is_hrops = false;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=false;
      this.hide_myskill =  true;
      this.router.navigate(['/cem-candidate-list']);

    } else {
      // this.is_manager=false;
      this.showCEMTab = false;
      this.managerToggle = true;
      this.recruiterToggle= true;
      this.hrToggle = true;
      this.hrOpsToggle = true;
      this.panellistToggle = true;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = this.is_cem1;
      this.is_hrops = this.is_hrops1;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=true;
      this.is_panellist = false;
      this.hide_myskill =  false;
      this.router.navigate(['/myspace']);

    }
  }

  update_menu_panellist(is_interviewer1, is_employee) {
    this.status = true;   
    console.log(this.status)
    if( is_employee && this.is_interviewer) {
      this.managerToggle = false; 
      this.hrToggle = false;
      this.hrOpsToggle = false;
      this.recruiterToggle= false;
      this.cemToggle = false;
      this.is_panellist = true;
      this.iof_list = true;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = false;
      this.is_hrops = false;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=false;
      this.hide_myskill =  true;
      this.router.navigate(['/rrf_profile_validation']);

    } else { 
      this.managerToggle = true;
      this.recruiterToggle= true;
      this.hrToggle = true;
      this.hrOpsToggle = true;
      this.cemToggle = true;
      this.is_panellist = false;
      this.iof_list = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = this.is_cem1;
      this.is_hrops = this.is_hrops1;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=true;
      this.hide_myskill =  false;
      this.router.navigate(['/myspace']);
    }
  }

  update_menu_hr(is_hr, is_employee) {
    this.status = true;   
    console.log(this.status)
    if( is_employee && is_hr) {
      console.log('if works');
      this.managerToggle = false; 
      this.panellistToggle = false;
      this.cemToggle = false;
      this.recruiterToggle= false;
      this.hrOpsToggle = false;
      this.is_panellist = true;
      this.iof_list = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = false;
      this.is_hrops = false;
      this.is_buh1 = false;
      this.is_report=true;
      this.is_employee=false;
      this.hide_myskill =  true;
      this.router.navigate(['/rmg_job_list']);

    } else { 
      console.log('else works');
      this.managerToggle = true;
      this.panellistToggle = true;
      this.recruiterToggle= true;
      this.hrOpsToggle = true;
      this.cemToggle = true;
      this.is_panellist = false;
      this.iof_list = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = this.is_cem1;
      this.is_hrops = this.is_hrops1;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=true;
      this.hide_myskill =  false;
      this.router.navigate(['/myspace']);
    }
  }

  update_menu_hrOps(ishrops,is_employee) {
    console.log('inside', this.is_hrops);    
    this.status = true;   
    console.log(this.status)
    if( is_employee && ishrops) {
      console.log('if works');
      this.managerToggle = false; 
      this.panellistToggle = false;
      this.cemToggle = false;
      this.recruiterToggle= false;
      this.hrToggle = false;
      this.is_panellist = false;
      this.iof_list = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = false;
      this.is_hr = false;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=false;
      this.is_interviewer=false;
      this.hide_myskill =  true;
      this.router.navigate(['/rrf_candidate_approve_hrOps']);
    } else { 
      console.log('else works');
      this.managerToggle = true;
      this.panellistToggle = true;
      this.recruiterToggle= true;
      this.hrToggle = true;
      this.cemToggle = true;
      this.is_panellist = false;
      this.iof_list = false;
      this.is_rmg=false;
      this.is_rmg_new=false;
      this.is_cem = this.is_cem1;
      this.is_hr = this.is_hr1;
      this.is_interviewer=this.is_interviewer1;
      this.is_buh1 = false;
      this.is_report=false;
      this.is_employee=true;
      this.hide_myskill =  false;
      this.router.navigate(['/myspace']);
    }
  }
}
