import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

//  public transform(value, key: string, term: string) {
//     return value.filter((item) => {
//       if (item.hasOwnProperty(key)) {
//         if (term) {
//           let regExp = new RegExp('\\b' + term, 'gi');
//           return regExp.test(item[key]);
//         } else {
//           return true;
//         }
//       } else {
//         return false;
//       }
//     });
//   }
transform(items: any, filter: any, isAnd: boolean): any {
  if (filter && Array.isArray(items)) {
   let filterKeys = Object.keys(filter);
    if (isAnd) {
     return items.filter(item =>
        filterKeys.reduce((memo, keyName) =>
            (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true));
    } else {
     return items.filter(item => {
      return filterKeys.some((keyName) => {
        console.log(keyName);
        return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";
      });
    });
   }
  } else {
   return items;
  }

}
}
