import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { BACK_END_URL } from '../shared/app.globals';

@Component({
  selector: 'app-technical-iof-view',
  templateUrl: './technical-iof-view.component.html',
  styleUrls: ['./technical-iof-view.component.css']
})


export class TechnicalIofViewComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TechnicalIofViewComponent>) { }
  file_url:any
  interview_schedule:any
  final_interview_schedule:any;
  BACK_END_URL = BACK_END_URL;
  ngOnInit() {
console.log("data",this.data);
console.log("sdfsdf",this.data['interview_schedule'].length)
if(this.data['interview_schedule'].length != 0){
  this.interview_schedule = this.data['interview_schedule'][0]['iof_detail']
}else{
  this.interview_schedule = []
}
if(this.data['final_interview_schedule'].length != 0){
  console.log("insideif")
  this.final_interview_schedule = this.data['final_interview_schedule'][0]['iof_detail']
}else{
  console.log("insideelse")
  this.final_interview_schedule = []
}

  }
  closeDialog() {
    this.dialogRef.close();
    // this.file_url = 'public/candidate_interview_capture_image/file_example_JPG_1MB.jpg'
  }
  // getBase64Image(img) {
  //   var canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   var dataURL = canvas.toDataURL("image/png");
  //   console.log(dataURL);
  //   return dataURL;
  // }
//   captureScreen(e){
//   {
//     e.preventDefault();
    
//     html2canvas(document.body, {
//       allowTaint: true,
//       logging:true,
//       onrendered: function(canvas) {
//       imagestring = canvas.toDataURL("image/png");
//       console.log(imagestring);
//       document.body.appendChild(canvas);
//       }
//   });
//   };
// }
  public captureScreen() {
    // html2canvas(document.getElementById('contentToConvert')).then(function (canvas) {
    //   var img = canvas.toDataURL("image/png");
    //   var imgWidth = 208;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var doc = new jsPDF('p', 'mm', 'a4');
    //   var position = 0;
    //   doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight)
    //   doc.save('Resume.pdf');
    // });
    var scaleBy = 5;
    var w = 1000;
    var h = 1000;
    var div = document.querySelector('#screen');
    var canvas = document.createElement('canvas');
    canvas.width = w * scaleBy;
    canvas.height = h * scaleBy;
    canvas.style.width = w + 'px';
    canvas.style.height = h + 'px';
    var context = canvas.getContext('2d');
    context.scale(scaleBy, scaleBy);

    
    html2canvas(document.getElementById('contentToConvert'), { allowTaint: true,
      useCORS: true}).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
  // document.body.appendChild(canvas);
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const doc = new jsPDF('p', 'mm', 'a4');
      let heightLeft = imgHeight;
      let position = 10;
      // doc.text(10, 15, "Interview Observation Form");
      doc.addImage(imgData, 'PNG', 10, 20, imgWidth - 15, imgHeight);
      heightLeft -= pageHeight;
      var d = new Date();
      var n = d.toLocaleDateString();
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 10, position, imgWidth - 15, imgHeight);
        heightLeft -= pageHeight;
      }
      // Generated PDF
      doc.save('IOF' + '.pdf');
    });
  }


}
