import { Component, OnInit,ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { QuizService } from '../services/quiz.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BACK_END_URL } from '../shared/app.globals';
@Component({
  selector: 'app-resume-builder',
  templateUrl: './resume-builder.component.html',
  styleUrls: ['./resume-builder.component.css']
})
export class ResumeBuilderComponent implements OnInit {
  mapping_data: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['candidate_name','candidate_last_name','candidate_skill_set','candidate_aadhar_number','candidate_pan_number', 'candidate_email','candidate_phone_number','file_name'];
  resume_search:string;
  BACK_END_URL = BACK_END_URL;
  total_count:any;
  constructor(public quizService: QuizService ) { }

  ngOnInit() {
    this.quizService.get_resumebuilder().subscribe(
      (data: any) => {
        
       this.mapping_data = new MatTableDataSource(data.data);
       this.mapping_data.paginator = this.paginator;
       this.total_count=data.data.length;
      })
      this.mapping_data.filterPredicate = function(data, filter: string): boolean {
       console.log(filter.length)
        return data.candidate_name.toLowerCase().includes(filter) || data.candidate_last_name.toLowerCase().includes(filter) || data.candidate_skill_set.toLowerCase().includes(filter) || data.candidate_email.toLowerCase().includes(filter) || data.candidate_phone_number.toString() === filter;
      };
  }
  applyFilter(filterValue: string) {
    
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.mapping_data.filter = filterValue;
    const numRows =  this.mapping_data.paginator.length;
    this.total_count= numRows;
    
  }

}
