import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { RedirectService } from "../services/redirect";
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormControl } from '@angular/forms';
import { FileValidator } from 'node_modules_old/ngx-material-file-input';
import * as XLSX from 'xlsx';

export interface PeriodicElement {
  position:number;
  job_title: string; 
  practice: string;
  experience: string;
  fitment_score: string;
  location: string;
  vacancies: string;
  job_code: string;
  requested_by: string;
  submitted_on: string;
  assigned_to:string;
  status: string;

}
export interface closeValue {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-job-list-manager',
  templateUrl: './job-list-manager.component.html',
  styleUrls: ['./job-list-manager.component.css']
})
export class JobListManagerComponent implements OnInit {
  rrf_list_data:any =[];
  rrf_list:any;
  employee_data: any;
  startDate=new Date();
  view_type = "employee";
  employee_id: String;
  periodValue: any;
  selectedState: any;
  arr_pes_chennai: boolean;
  arr_dts_chennai: boolean;
  arr_ena_chennai: boolean;
  arr_pes_pune: boolean;
  arr_dts_bangalore: boolean;
  arr_others: boolean;
  is_manager: boolean;
  form_data: any;
  status_list: any;
  showToggle = true;
  rmgToggle: boolean = false;
  rrf_count:any;
  firstFormGroup: FormGroup;
  maxSize: number = 2097152;
  formData = new FormData();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<PeriodicElement>(true, []);
  rrf_id_list: any;
  is_rmg1 :boolean;
  assignbool:boolean = true;
  jsonObj: any;
  checked_rrf_id:any;
  checked_emp_id:any;
  checked_rrf_id_bool:any;
  rec_id:any;
  isBUH = false;
  isCOO = false;
  cooApproval = false;
  buhApproval = false;
  displayedColumns: string[] = ['select', 'rrf_code', 'job_title', 'practice', 'experience', 'fitment_score', 'location', 'vacancies', 'job_code', 'assigned_to', 'status', 'close_rr'];
  foods: closeValue[] = [
    {value: 'Internally_Fulfilled', viewValue: 'Internally Fulfilled'},
    {value: 'Externally_Fulfilled', viewValue: 'Externally Fulfilled'},
    {value: 'Cancelled_by_Client', viewValue: 'Cancelled by Client'},
    {value: 'Unable_to_Fulfill', viewValue: 'Unable to Fulfill'},
    {value: 'No_longer_relevant', viewValue: 'No Longer Relevant'}    
  ]
  assignBtn: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute, private skill_service: EmployeeSkillMappingService, public dialog: MatDialog, private _snackBar: MatSnackBar, public redirect: RedirectService) {

  }
  ngOnInit() {
     this.jsonObj = JSON.parse(localStorage.currentUser);
     console.log("asasdasd",this.view_type, this.selectedState);
     console.log(this.jsonObj.id.toString(),this.jsonObj);
     if(this.jsonObj.id.toString()=='6329' || this.jsonObj.id.toString()=='3723' || this.jsonObj.id.toString()=='6732' ||this.jsonObj.id.toString()=='779') {
       this.isBUH = true;
       this.rmgToggle = false;
     } else {
       this.isBUH = false;
     } 
 
     if(this.jsonObj.id.toString()=='33' ) {
       this.isCOO = true;
       this.rmgToggle = false;
     } else {
       this.isCOO = false;
     }
    this.is_rmg1 = this.jsonObj.is_rmg;
    if(this.is_rmg1){

    }
    if (this.router.url == "/rrf_request_list") {
      if(this.isBUH == true) {
        this.view_type = "buh";
      } else if( this.isCOO == true) {
        this.view_type = "coo";
      } else {
        this.view_type = "employee";
      }
      this.showToggle = false;
      this.status_list = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected', 'Recruiter Approved', 'Recruiter Rejected', 'Internal Fulfillment Approved', 'Internal Fulfillment Rejected', 'Closed'];
    } else if (this.router.url == "/recruitment_job_list") { //this.router.url == "/rrf_approve_list"
      this.view_type = "manager";
      this.rmgToggle = false;
      //this.displayedColumns.splice(1, 0, "requested_by");
      this.status_list = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected', 'Recruiter Approved', 'Recruiter Rejected', 'Internal Fulfillment Approved', 'Internal Fulfillment Rejected', 'Closed'];
    } else {
      console.log("insideoninit cond")
      this.view_type = "rmg";
      this.showToggle = false;
      this.rmgToggle = true;
      // this.displayedColumns.splice(1, 0, "requested_by");
      if (this.jsonObj.id.toString() == '2779' || this.jsonObj.id.toString() == '6594' || this.jsonObj.id.toString() == '6995') {
        this.status_list = ['RM Approved', 'RMG Approved', 'Recruiter Accepted', 'Internal Fulfillment Approved', 'Closed'];
      } else {
        this.status_list = ['RMG Approved', 'Recruiter Accepted', 'Internal Fulfillment Approved', 'Closed'];
      }
      
    }

    this.firstFormGroup = new FormGroup({
      customer_job_description: new FormControl("", [FileValidator.maxContentSize(this.maxSize)])
     
    });
    console.log(this.view_type);
    let rrf_list_value;
    // if(this.view_type != 'rmg') {
    //   if(this.jsonObj.employee_number == '19246' || this.jsonObj.employee_number == '18889' || this.jsonObj.employee_number == '16497' || this.jsonObj.employee_number == '17632' || this.jsonObj.employee_number == '17089' || this.jsonObj.employee_number == '18516') {
    //     var unit_id;
    //     if(this.jsonObj.employee_number == '18516' || this.jsonObj.employee_number =='18889' || this.jsonObj.employee_number == '19246' || this.jsonObj.employee_number =='17089' ){
    //       unit_id = '1'
    //     } else if(this.jsonObj.employee_number == '16497' || this.jsonObj.employee_number =='17632' ) {
    //       unit_id = '2'
    //     } 
    //     console.log(unit_id)
    //     this.skill_service.getTAGManagerRRList(unit_id).subscribe((result:any) => {
    //       console.log(result.data);
    //       rrf_list_value = result.data;
    //       console.log(rrf_list_value, 'rr_list', this.rrf_list, );
          
    //       this.rrf_list_data = new MatTableDataSource<PeriodicElement>(rrf_list_value);
    //       this.rrf_list_data.paginator = this.paginator;
    //       this.rrf_list_data.sort = this.sort;
    //     })
  
    //   }
    // } else if(this.view_type == 'rmg'){
      this.skill_service.getRRFList(this.view_type, this.jsonObj.id)
      .subscribe(
        response => {
          console.log(this.view_type)
          console.log(response);
          var temp = [];
          response["data"]["rrf_list"].forEach(item => {
          if(item.external_employee_status != undefined) {
            if(item.external_employee_status == 'No' && item.internal_employee_status == 'No') {
              temp.push(item);
            }   
          } else if(item.external_employee_status == undefined) {
            if( item.internal_employee_status == 'No') {
              temp.push(item);
            } 
          }
          });
          console.log(temp, response["data"]["rrf_list"], 'job-list');
          this.rrf_list=temp;
          if(this.view_type == "manager") {
            rrf_list_value = this.rrf_list.filter(element =>{
              if(this.isBUH == true) {
                if(element.band == 'M1' || element.band == 'M2' || element.band == 'M3') {
                  if(element.status == 'Submitted') {
                    if(element.rm_id != this.jsonObj.id.toString()  ) {
                      console.log(element)
                      return element;
                    }
                  }
                } else {
                  if(element.status == 'Submitted' || element.status == 'RM  Accepted') {
                    console.log(element);
                    return element;
                  }
                }
              } else if(this.isCOO == true) {
                if(element.band == 'M1' || element.band == 'M2' || element.band == 'M3') {
                  if(element.status == 'Submitted' || element.status == 'RM  Accepted' || element.status == 'RM Accepted') {
                    if(element.rm_id != this.jsonObj.id.toString()  ) {
                      console.log(element)
                      return element;
                    }
                  }
                } else {
                  if(element.status == 'Submitted' || element.status == 'RM  Accepted') {
                    console.log(element);
                    return element;
                  }
                }
              } else {
                if(element.status == 'Submitted' || element.status == 'RM  Accepted') {
                  return element;
                }
              }
              
             }) 
            console.log('1')
          } else if ((this.jsonObj.id.toString()=='2779' || this.jsonObj.id.toString() == '6594' || this.jsonObj.id.toString() == '6995') && this.view_type == "rmg"  ) {
            rrf_list_value = this.rrf_list.filter(element => element.status == 'RM  Accepted' || element.status == 'RM Accepted' || element.status == 'COO Accepted'); 
            console.log('2')
          } else if ((this.jsonObj.id.toString()!='2779' || this.jsonObj.id.toString() != '6594' || this.jsonObj.id.toString() != '6995') && this.view_type == "rmg"  ) {
            rrf_list_value = this.rrf_list.filter(element => element.status == "RMG Accepted" || element.status == "RMG Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' || element.status =='RMG Approved' || element.status == 'Closed'); 
            console.log('3')
          } else {
            rrf_list_value = this.rrf_list;
            console.log('4')
          }
          console.log(rrf_list_value, 'rr_list', this.rrf_list, );
          this.rrf_list_data = new MatTableDataSource<PeriodicElement>(rrf_list_value);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
          this.rrf_count=response["data"]["rrf_counts"];
          this.employee_id = this.jsonObj.id.toString();
          this.arr_pes_chennai = this.jsonObj.is_pes_ch;
          this.arr_dts_chennai = this.jsonObj.is_dts_ch;
          this.arr_ena_chennai = this.jsonObj.is_ena_ch;
          this.arr_pes_pune = this.jsonObj.is_pes_pu;
          this.arr_dts_bangalore = this.jsonObj.is_dts_ban;
          this.arr_others = this.jsonObj.is_others;
          this.is_manager = this.jsonObj.is_manager;
          this.rrf_list_data.sort = this.sort;
        }
      );
    // }
    


  }
  onRRFrequestbutton() {
    this.rrfListTableValue();
    this.router.navigate(['/rrf_request_list']);
  }

  rrfListTableValue() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    let rrf_list_value;
    this.skill_service.getRRFList(this.view_type, jsonObj.id)
    .subscribe(
      response => { 
        console.log(response["data"],'check');
        this.rrf_count=response["data"]["rrf_counts"];
        if(this.view_type == "manager") {
          rrf_list_value = this.rrf_list.filter(element =>{
            if(this.isBUH == true) {
              if(element.band == 'M1' || element.band == 'M2' || element.band == 'M3') {
                if(element.status == 'Submitted') {
                  if(element.rm_id != this.jsonObj.id.toString()  ) {
                    console.log(element)
                    return element;
                  }
                }
              } else {
                if(element.status == 'Submitted' || element.status == 'RM Accepted') {
                  console.log(element);
                  return element;
                }
              }
            } else if(this.isCOO == true) {
              if(element.band == 'M1' || element.band == 'M2' || element.band == 'M3') {
                if(element.status == 'Submitted' ) {
                  if(element.rm_id != this.jsonObj.id.toString()  ) {
                    console.log(element)
                    return element;
                  }
                }
              } else {
                if(element.status == 'Submitted' ) {
                  console.log(element);
                  return element;
                }
              }
            } else {
              if(element.status == 'Submitted' || element.status == 'RM  Accepted') {
                return element;
              }
            }
            
           }) 
          console.log('1')
        } else if ((this.jsonObj.id.toString()=='2779' || this.jsonObj.id.toString() == '6594' || this.jsonObj.id.toString() == '6995') && this.view_type == "rmg"  ) {
          rrf_list_value = response["data"]["rrf_list"].filter(element => element.status == 'RM  Accepted' || element.status == "RMG Accepted" || element.status == 'COO Accepted'); 
        } else if ((this.jsonObj.id.toString()!='2779' || this.jsonObj.id.toString() != '6594' || this.jsonObj.id.toString() != '6995') && this.view_type == "rmg"  ) {
          rrf_list_value = response["data"]["rrf_list"].filter(element => element.status == "RMG Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' || element.status == 'Closed' ); 
        } else {
          rrf_list_value = response["data"]["rrf_list"];
        }
        console.log('rr_list', response["data"]["rrf_list"], );
        this.rrf_list_data = new MatTableDataSource(rrf_list_value);
        this.employee_data = response["data"]["employee_detail"];
        this.rrf_list_data.paginator = this.paginator;
        this.employee_id=jsonObj.id.toString();
        this.arr_pes_chennai=jsonObj.is_pes_ch;
        this.arr_dts_chennai=jsonObj.is_dts_ch;
        this.arr_ena_chennai=jsonObj.is_ena_ch;
        this.arr_pes_pune=jsonObj.is_pes_pu;
        this.arr_dts_bangalore=jsonObj.is_dts_ban;
        this.arr_others=jsonObj.is_others;
        this.is_manager=jsonObj.is_manager;
        this.rrf_list_data.sort = this.sort;       
       }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (this.rrf_list_data) {
      const numSelected = this.selection.selected.length;
      const numRows = this.rrf_list_data.length;
      return numSelected === numRows;
      
    } else {
      return false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.rrf_list.forEach(row => 
        this.selection.select(row)
        );
        
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  updateRRFDetail() {
    console.log('checkbox function',this.rrf_list_data);
    var rrf_id_obj = []
    var selected_rrf = []
    this.rrf_list_data.filteredData.forEach(function (value) {
      // console.log(value,'rrf_value')
      if (value.checked) {
        rrf_id_obj.push(value._id);
        selected_rrf.push(value.rrf_code);
      }
    });
    console.log("asdasd",selected_rrf)
    this.checked_rrf_id = selected_rrf
    if(this.checked_rrf_id.length > 0) {
      this.assignBtn = true;
    } else {
      this.assignBtn = false;
    }
    this.rrf_id_list = rrf_id_obj;
  }


  onSubmit() {
    this.form_data.value["export"] = false;
    this.skill_service.getRRFReportList(this.form_data.value)
      .subscribe(
        response => {
          this.rrf_list_data = new MatTableDataSource(response["data"]["rrf_list"]);
          this.employee_data = response["data"]["employee_detail"];
          this.rrf_list_data.paginator = this.paginator;
        }
      );
  }

  redirectToListPage()
  {
    //this.router.navigate(['/rrf_request_list']);
  }
  redirectToViewPage(rrf_id){
    var jsonObj = JSON.parse(localStorage.currentUser);

    if ( this.view_type == "rmg" && (jsonObj.id!=2779 || jsonObj.id!=6594 || jsonObj.id!=6995))
    this.redirect.change_page_with_data("/rrf_approve", { rrf_id, view_type: this.view_type });
  else
    this.redirect.change_page_with_data("/rrf_view", { rrf_id });

  }
  redirectPage(page_link, rrf_id) {

    if (page_link == "/rrf_approve")
      this.redirect.change_page_with_data(page_link, { rrf_id, view_type: this.view_type });
    else
      this.redirect.change_page_with_data(page_link, { rrf_id });
  }
  applyFilter(filterValue: string) {
    this.rrf_list_data.filter = filterValue.trim().toLowerCase();
  }
  create_rrf() {
    this.router.navigate(['/rrf_creation']);
  }
  submit_approve(type) {
    console.log(type);
    var jsonObj = JSON.parse(localStorage.currentUser);
    var employee_id = jsonObj.id;
    var update_object = {};
    var redirect_url;
    if (this.view_type == "manager") {
      if(this.buhApproval == true){
        var status = "BUH ";
      } else if(this.cooApproval == true) {
        var status = "COO ";
      } else {
        var status = "RM ";
        update_object["RM_status_changed_on"] = new Date();
      }
      update_object["manager_id"] = employee_id.toString();
      update_object["manager_status_changed_on"] = new Date();
      //redirect_url = '/rrf_approve_list';
    } else if(employee_id=='2779' || employee_id=='6594' || employee_id=='6995'){
      var status = "RMG ";
      update_object["rmg_id"] = employee_id.toString();
      update_object["rmg_status_changed_on"] = new Date();
    }else{
      var status = "Recruiter ";
      update_object["recruiter_id"] = employee_id.toString();
      update_object["recruiter_status_changed_on"] = new Date();
    }

    if (type == "approve") {
        status += "Accepted"
    } else if(type == "closed") {
      status = "Closed";
    } else {
      status += "Rejected";
    }
    console.log("adasdsad",status)
      update_object["status"] = status;
      update_object["rrf_ids"] = this.rrf_id_list;
      this.skill_service.updateRRFStatusBulk(update_object,employee_id.toString())
        .subscribe(
          response => {
            this.skill_service.getRRFList(this.view_type, jsonObj.id)
            .subscribe(
              response => {
                this.rrf_list=response["data"]["rrf_list"];
                this.rrf_list_data = new MatTableDataSource<PeriodicElement>(response["data"]["rrf_list"]);
                this.employee_data = response["data"]["employee_detail"];
                this.rrf_list_data.paginator = this.paginator;
                this.rrf_count=response["data"]["rrf_counts"];
                this.employee_id = jsonObj.id.toString();
                this.arr_pes_chennai = jsonObj.is_pes_ch;
                this.arr_dts_chennai = jsonObj.is_dts_ch;
                this.arr_ena_chennai = jsonObj.is_ena_ch;
                this.arr_pes_pune = jsonObj.is_pes_pu;
                this.arr_dts_bangalore = jsonObj.is_dts_ban;
                this.arr_others = jsonObj.is_others;
                this.is_manager = jsonObj.is_manager;
                this.rrf_list_data.sort = this.sort;
              }
            );
          }
        );
        this.reload()
       
    }
  
    reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
  save_button(templateRef : TemplateRef<any>)
  {
    this.dialog.open(templateRef);
  }
  upload(file){
    this.formData = new FormData();
    this.formData.append("file", file);
  }
  onRRBulkSubmit() {
    console.log("this.formData",this.formData)
    this.skill_service.sendRRfBulkUpload(this.formData)
    .subscribe(
      response => {
        var formData = new FormData();
        if(response[0].status != '') {
          this.rrfListTableValue();
          this.router.navigate(['/rrf_request_list']);
          this.openSnackBar();
          console.log("response",response)
        //   var mystyle = {
        //     sheetid: 'My Big Table Sheet',
        //     headers: true,
        //     column: {
        //         style:'font-size:16px'
        //     },
        //     columns: [
        //         {columnid:'rrf_id', tittle: 'rrf_id', width:400},
        //         {columnid:'status', tittle: 'status', width:400},
    
        //     ],
    
        //     row: {
        //         style: function(sheet,row,rowidx){
        //             // return 'background:'+(rowidx%2?'red':'yellow');
        //         }
        //     }
        // };
    
        // alasql('SELECT * INTO XLS("bulk-rrfupload-reference-data.xls",?) FROM ?',[mystyle, response]);
        this.downloadxlxsFile(response)
        }
      }
    );
  }
  downloadxlxsFile(data){
    console.log("downloaddata",data)
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
  XLSX.writeFile(workbook,'bulk-rrfupload-reference-data.xlsx');
  }
//   rrfDownload(){
//     var rrfList = [
//         {
//             "rrf_code": "RR0001",
//             "job_code":"240",
//             "unit": 1,
//             "practice": 10,
//             "sub_practice": 37,
//             "band": "AT1",
//             "manager_id": 1043,
//             "base_location": "chennai",
//             "customer_type": 'External',
//             "customer_name": "655",
//             "project": '4371',
//             "billable": 'yes',
//             "work_location": 'chennai',
//           //  "sub_work_location": 'madurai',
//             "interview_round": 1,
//             "start_date": 'MM-DD-YYYY',
//             "resource_request_reason": 'Addition Position',
//             "minimum_experience": 2,
//             "maximum_experience": 5,
//             "customer_job_description": 'consultent',
//            // "customer_interview": 'NO',
//             "role": 'GET-CAE',
//             //"manager_reject_reason": 'No',
//             //"manager_status_changed_on": 'MM-DD-YYYY',
//             "no_of_position": 2,
//             "status":"Submitted",
//             "minimum_fitment_score":"0",
//             "created_by":4534,
//             "deleted":false,
//             "created_on":"MM-DD-YYYY"
//         },
//     ]

//     var self = this;
//     var mystyle = {
//         sheetid: 'My Big Table Sheet',
//         headers: true,
//         column: {
//             style:'font-size:16px'
//         },
//         columns: [
//             {columnid:'rrf_code', tittle: 'rrf_code', width:400},
//             {columnid:'job_code', tittle: 'job_code', width:400},
//             {columnid:'unit', tittle: 'unit', width:400},
//             {columnid:'practice', tittle: 'practice', width:400},
//             {columnid:'sub_practice', tittle: 'sub_practice', width:400},
//             {columnid:'band', tittle: 'band', width:400},
//             {columnid:'manager_id', tittle: 'manager_id', width:400},
//             {columnid:'base_location', tittle: 'base_location', width:400},
//             {columnid:'customer_type', tittle: 'customer_type', width:400},
//             {columnid:'customer_name', tittle: 'customer_name', width:400},
//             {columnid:'project', tittle: 'project', width:400},
//             {columnid:'billable', tittle: 'billable', width:400},
//             {columnid:'work_location', tittle: 'work_location', width:400},
//             //{columnid:'sub_work_location', tittle: 'sub_work_location', width:400},
//             {columnid:'interview_round', tittle: 'interview_round', width:400},
//             {columnid:'start_date', tittle: 'start_date', width:400},
//             {columnid:'resource_request_reason', tittle: 'resource_request_reason', width:400},
//             {columnid:'minimum_experience', tittle: 'minimum_experience', width:400},
//             {columnid:'maximum_experience', tittle: 'maximum_experience', width:400},
//             {columnid:'customer_job_description', tittle: 'customer_job_description', width:400},
//            // {columnid:'customer_interview', tittle: 'customer_interview', width:400},
//             {columnid:'role', tittle: 'role', width:400},
//             //{columnid:'manager_reject_reason', tittle: 'manager_reject_reason', width:400},
//             //{columnid:'manager_status_changed_on', tittle: 'manager_status_changed_on', width:400},
//             {columnid:'no_of_position', tittle: 'no_of_position', width:400},
//             {columnid:'status', tittle: 'status', width:400},
//             {columnid:'minimum_fitment_score', tittle: 'minimum_fitment_score', width:400},
//             {columnid:'created_by', tittle: 'created_by', width:400},
//             {columnid:'deleted', tittle: 'deleted', width:400},
//             {columnid:'created_on', tittle: 'created_on', width:400},

//         ],

//         row: {
//             style: function(sheet,row,rowidx){
//                 // return 'background:'+(rowidx%2?'red':'yellow');
//             }
//         }
//     };

//     alasql('SELECT * INTO XLS("bulk-rrfsample-data.xls",?) FROM ?',[mystyle, rrfList]);
// }
  openSnackBar() {
    this._snackBar.open('RR Uploaded Successfully', '', {
      duration: 5000,
    });
  }
  onChange(value, element) {
    
    let postValue = {rrfCode: element.rrf_code, role: element.role, close_reason: value, closed_by : this.jsonObj.id, closed_by_emp_id: this.jsonObj.employee_number};
    this.skill_service.closeRR(postValue)
    .subscribe( response=> {
      console.log(response);
      this._snackBar.open('RR Closed Successfully', '', {
        duration: 5000,
      });
      if(this.is_rmg1){
        this.router.navigate(['/rmg_job_list']);
        this.rrfListTableValue();
      } else {
        this.onRRFrequestbutton();
      }
    });
   }
   onPeriodDropdownchange(periodValue) {
    var jsonObj = JSON.parse(localStorage.currentUser);
    var date180DaysAgo = new Date(new Date().getTime() - periodValue * 24 * 60 * 60 * 1000);
    var startdate = new Date(this.startDate);
    var startyear=date180DaysAgo.getFullYear();
    var month=date180DaysAgo.getMonth()+1 //getMonth is zero based;
    var day=date180DaysAgo.getDate()+1;
    var start_date=startyear+"-"+month+"-"+day;
    
    var date = new Date();
    var year=date.getFullYear();
    var month=date.getMonth()+1 //getMonth is zero based;
    var day=date.getDate();
    var formatted_date=year+"-"+month+"-"+day;
    console.log(formatted_date, start_date,'periodValue');
    this.skill_service.getRRFListWithPeriod(this.view_type, jsonObj.id,start_date,formatted_date)
    .subscribe((response: any) => {
      console.log(response);
      var rrf_list_value;
      if(this.view_type == "manager") { 
        rrf_list_value = response.data.rrf_list.filter(element => element.status == 'Submitted' || element.status == 'RM  Accepteds' );
        console.log('1')
      } else if ((this.jsonObj.id.toString()=='2779' || this.jsonObj.id.toString()=='6594' || this.jsonObj.id.toString()=='6995') && this.view_type == "rmg"  ) {
        rrf_list_value = response.data.rrf_list.filter(element => element.status == 'RM  Accepted' || element.status == 'RMG Approved' || element.status == 'COO Accepted'); 
        console.log('2')
      } else if ((this.jsonObj.id.toString()!='2779' || this.jsonObj.id.toString()!='6594' || this.jsonObj.id.toString()!='6995') && this.view_type == "rmg"  ) {
        // rrf_list_value = response["data"]["rrf_list"].filter(element => element => element.status == "RMG  Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' ||  element.status == 'RM Approved' || element.status == 'Internal Fulfillment Approved' || element.status == 'Closed'  ); 

        rrf_list_value = response.data.rrf_list.filter(element => element.status == "RMG  Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' || element.status == 'Closed'); 
        console.log('3')
      } else {
        rrf_list_value = response.data.rrf_list;
        console.log('4')
      }
      
      console.log(rrf_list_value, 'rr_list', response.data.rrf_list );
      this.rrf_list_data = new MatTableDataSource<PeriodicElement>(rrf_list_value);
      this.employee_data = response["data"]["employee_detail"];
      this.rrf_list_data.paginator = this.paginator;
      this.rrf_count=response["data"]["rrf_counts"];
      this.employee_id = this.jsonObj.id.toString();
      this.arr_pes_chennai = this.jsonObj.is_pes_ch;
      this.arr_dts_chennai = this.jsonObj.is_dts_ch;
      this.arr_ena_chennai = this.jsonObj.is_ena_ch;
      this.arr_pes_pune = this.jsonObj.is_pes_pu;
      this.arr_dts_bangalore = this.jsonObj.is_dts_ban;
      this.arr_others = this.jsonObj.is_others;
      this.is_manager = this.jsonObj.is_manager;
      this.rrf_list_data.sort = this.sort;

    })
  }

  getRRFListForBUHandCOO(view_type){
    let rrf_list_value;
    this.skill_service.getRRFList(view_type, this.employee_id)
    .subscribe(
      response => { 
        rrf_list_value = response["data"]["rrf_list"].filter((element:any) =>{
          if(this.isBUH == true) {
            console.log(element.rm_id, element.status)
            this.buhApproval = true;
            if(element.status == 'Submitted') {
              if(element.rm_id == this.jsonObj.id.toString()  ) {
                console.log(element);
                return element;
              }
            } else {
              return element;
            }
          } else if(this.isCOO == true) {
            console.log(element.rm_id, element.status)
            this.cooApproval = true;
            if(element.status == 'Submitted') {
              if(element.rm_id == this.jsonObj.id.toString()  ) {
                console.log(element)
                return element;
              }
            } else {
              return element;
            }
          }
        })
        console.log(response["data"],'check',rrf_list_value);
        // rrf_list_value = response["data"]["rrf_list"];
        console.log('rr_list', response["data"]["rrf_list"], rrf_list_value );
        this.rrf_list_data = new MatTableDataSource(rrf_list_value);
        this.rrf_list_data.paginator = this.paginator;
        this.employee_data = response["data"]["employee_detail"]
        this.rrf_list_data.sort = this.sort;       
       }
    );
  }

  onToggleChange($event) {
    console.log($event.value);
    if($event.value == 'request') {
      this.onRRFrequestbutton();
    } else if ($event.value == 'waiting') {
      this.router.navigate(['/recruitment_job_list']);
      this.rrfListTableValue();
    } else if ($event.value == 'waitingBUH') {
      this.getRRFListForBUHandCOO('BUH');
      console.log('working BUH')
    } else if ($event.value == 'waitingCOO') {
      this.getRRFListForBUHandCOO('COO');
      console.log('working COO')
    }
  }

  rmgOnToggleChange($event) {
    console.log($event.value);
    if($event.value == 'request') {
      // this.onRRFrequestbutton();
      this.assignbool = false;
      this.rrfListTableValueRmg("request")
      // this.router.navigate(['/rmg_job_list']);
    } else if ($event.value == 'waiting') {
      //   this.view_type = "rmg";
      this.assignbool = true;
      this.router.navigate(['/rmg_job_list']);
      this.rrfListTableValueRmg("waiting");
    }  
  }



  rrfListTableValueRmg(data) {
   
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.employee_id=jsonObj.id.toString();
    console.log("sadasd",this.employee_id)
    let rrf_list_value;
    this.skill_service.getRRFList(this.view_type, jsonObj.id)
    .subscribe(
      response => { 
        this.rrf_count=response["data"]["rrf_counts"];
        // if(this.view_type == "RMG") {
        //    if ((this.jsonObj.id.toString()=='2779' || this.jsonObj.id.toString() == '6594' || this.jsonObj.id.toString() == '6995') && this.view_type == "rmg"  ) {
        //   rrf_list_value = response["data"]["rrf_list"].filter(element => element.status == 'RM  Accepted' || element.status == "RMG  Accepted"); 
        // } else if ((this.jsonObj.id.toString()!='2779' || this.jsonObj.id.toString() != '6594' || this.jsonObj.id.toString() != '6995') && this.view_type == "rmg"  ) {
        //   rrf_list_value = response["data"]["rrf_list"].filter((element => element.status == "RMG  Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' && element.recruiter_assign_id_to == 'employee_id') ); 
        // }
        if(data == 'request'){
          rrf_list_value = response["data"]["rrf_list"].filter(element => element.recruiter_assign_id_to == this.employee_id && element.recruiter_assign_id_to != ''  );
        }else if(data == 'waiting'){
          rrf_list_value = response["data"]["rrf_list"].filter(element => element.status == "RMG Accepted" || element.status == 'Recruiter Accepted' || element.status == 'RecruiterApproved' || element.status == 'Closed'); 
        }
       
        console.log('rr_list', rrf_list_value );
        this.rrf_list_data = new MatTableDataSource(rrf_list_value);
        this.employee_data = response["data"]["employee_detail"];
        this.rrf_list_data.paginator = this.paginator;
       
        this.arr_pes_chennai=jsonObj.is_pes_ch;
        this.arr_dts_chennai=jsonObj.is_dts_ch;
        this.arr_ena_chennai=jsonObj.is_ena_ch;
        this.arr_pes_pune=jsonObj.is_pes_pu;
        this.arr_dts_bangalore=jsonObj.is_dts_ban;
        this.arr_others=jsonObj.is_others;
        this.is_manager=jsonObj.is_manager;
        this.rrf_list_data.sort = this.sort;       
       }
    );
  }
  
  onEmpSubmit(){
    console.log("formdata",this.firstFormGroup.value['emp_code'])
    var data = this.firstFormGroup.value['emp_code']
    // var data1 = this.firstFormGroup.value['RRF_code']
    if(data){
      this.skill_service.assignEmpSendMail(this.checked_rrf_id,data, this.rec_id).subscribe(data1=>
        {
          console.log('filtersssss',data1);
          this.rrfListTableValue();
         
        });
    }
  }


  openDialog(templateRef : TemplateRef<any>) 
  {
    var emp_data = [{"id":"16497","value":[{emp_id:"3720",emp_number:"16497",emp_name:"Priya Rengabashyam"},{emp_id:"5533",emp_number:"17417",emp_name:"Ashwini Murugan"},{emp_id:"6987",emp_number:"18527",emp_name:"Archana lokhande"}]},
    {"id":"17632","value":[{emp_id:"5835",emp_number:"17632",emp_name:"Raviteja Peyala"},{emp_id:"6357",emp_number:"17975",emp_name:"Kajol Mane"},{emp_id:"7010",emp_number:"18547",emp_name:"Tamizhvalavan"},
    {emp_id:"6734",emp_number:"18296",emp_name:"Anindita Chakraborty"}]},
    {"id":"18889","value":[{emp_id:"6667",emp_number:"18889",emp_name:"Vino Kingsly"},{emp_id:"6722",emp_number:"18286",emp_name:"Aravinthan"},
    {emp_id:"6749",emp_number:"18308",emp_name:"Thenmozhi Arumugam"}]},
    {"id":"17089","value":[{emp_id:"4997",emp_number:"17089",emp_name:"Pratap Patil"},{emp_id:"5990",emp_number:"17748",emp_name:"Sandi Sarathy"},{emp_id:"6849",emp_number:"18402",emp_name:"Trupti"},
    {emp_id:"6722",emp_number:"18286",emp_name:"Aravinthan"},{emp_id:"6865",emp_number:"18417",emp_name:"Abhishek"}]},
    {"id":"18516","value":[{emp_id:"6975",emp_number:"18516",emp_name:"Manigandan J"},{emp_id:"4452",emp_number:"16741",emp_name:"Sachin Telap"}]},
    {"id":"17840","value":[{emp_id:"6161",emp_number:"17840",emp_name:"Shaheena N"},{emp_id:"6789",emp_number:"18346",emp_name:"Nandhini Pandu"}]},
    {"id":"17848","value":[{emp_id:"6175",emp_number:"17848",emp_name:"Nivetha Venkatesh"},{emp_id:"6844",emp_number:"18397",emp_name:"Ragavendran B"},{emp_id:"6718",emp_number:"18282",emp_name:"Abinaya Senthilnathan"},
    {emp_id:"6827",emp_number:"18380",emp_name:"Clement Kevin"},{emp_id:"6754",emp_number:"18313",emp_name:"Ranjani Kumarakrishnan"}
  ]}] 
    console.log("checkedrrf",this.checked_rrf_id)
    var jsonObj = JSON.parse(localStorage.currentUser);
    var emp_number = jsonObj.employee_number
    var user_id = jsonObj.id
    if(emp_number){
      console.log("adasdsd",jsonObj.employee_number)
      emp_data.forEach((res)=>{
        if(emp_number == res.id){
          this.checked_emp_id = res['value']
          this.rec_id = user_id
        }
      })
    }
   
  
    this.firstFormGroup = new FormGroup({
      RRF_code: new FormControl(""),
      emp_code: new FormControl(""),
    });
    
     this.dialog.open(templateRef,{
       width: '50vw', height: '60vh', closeOnNavigation: true, autoFocus: false, hasBackdrop: true, disableClose: true }
     );
  }

}



