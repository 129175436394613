import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { QuizService } from '../services/quiz.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  showMainContent: Boolean = true;
  
  start_quiz: Boolean = false;
  quiz_instruction: Boolean = false;
  name:string;
  skill_count:string;
  section:string;
  timing:number;
  line_hide:Boolean=false
  candidate_password:any;
  

  constructor(private router : Router,public quizService: QuizService) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    this.candidate_password=localStorage.getItem('password');
    this.quizService.getSkill(this.name,this.candidate_password).subscribe(
      (data: any) => {
        
        this.skill_count =data.length;
       // alert(this.skill_count)
        if(this.skill_count=="2")
        {
           this.line_hide=true;
          this.section='2';
          this.timing=36;
        }
        else{


          this.line_hide=false;
          this.section='';
          //this.timing=60;
 
         
          this.timing=18
        }
         
        
      })
     
    

  }

  StartQuiz() {
    this.router.navigate(['/quiz']);
  }
  ShowHideButton() {
    this.showMainContent = true;
    this.start_quiz =false;
    this.quiz_instruction=false;

 }
 Show_Hide_Button()
 {
  
  this.showMainContent = false;
  this.start_quiz =true;
  this.quiz_instruction=true;

 }
 Start_quiz()
 {
  this.router.navigate(['/quiz']);

 }
 Show_previous()
 {
  this.router.navigate(['/quiz_instruction']);
 }


}
