import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';

@Component({
  selector: 'app-eee-quizinstruction',
  templateUrl: './eee-quizinstruction.component.html',
  styleUrls: ['./eee-quizinstruction.component.css']
})
export class EeeQuizinstructionComponent implements OnInit {
  showMainContent: Boolean = true;
  
  start_quiz: Boolean = false;
  name:string;
  role:string;
  skill_count:string;
  timing:number;
  total_length:number;
  candidate_password:any;
  noQuestionperSkill:Number
  notiming:Number
movies:[]
  noOfQuestion: any;
  assessmentTiming: any;
  
  constructor(private router : Router,public quizService: QuizService) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    
   this.candidate_password=localStorage.getItem('password');
   console.log(this.candidate_password)
   console.log(this.name)
    //this.role=localStorage.getItem('role');
    this.quizService.getQuestions(this.name,this.candidate_password).subscribe(
      (data: any) => {
        this.role=data.role.role;
        this.noOfQuestion = data.assessment.no_of_qus;
        this.assessmentTiming = data.assessment.time;
        console.log(data, this.noOfQuestion, this.assessmentTiming)
      })

    this.quizService.getSkill(this.name,this.candidate_password).subscribe(
      (data: any) => {
      console.log(data,'data-jhj')
        
        this.skill_count =data.length;
       // alert(this.skill_count)
        if(this.skill_count=="2")
        {
          this.noQuestionperSkill=30
          this.notiming=
          this.timing=2 * 18;
          this.total_length=30+30;
        }
        // else{


        //   //this.timing=60;
 
         
        //   this.timing=18
        // }
        else{
          if(this.skill_count=="1")
          {
            console.log('inside')
            this.noQuestionperSkill=this.noOfQuestion;
            this.total_length=this.noOfQuestion;
            this.timing=this.assessmentTiming;
            this.notiming=this.assessmentTiming
          }
          if(this.skill_count=="3")
          {
            this.noQuestionperSkill=30
            this.total_length=3 * 30;
            this.timing=3*18
            this.notiming=18
          }
          if(this.skill_count=="4")
          {
            this.noQuestionperSkill=30
            this.total_length=4 * 30;
            this.timing=4 *18
            this.notiming=18
          }
          if(this.skill_count=="5")
          {
            this.noQuestionperSkill=30
            this.total_length=5 * 30;
            this.timing=5 *18
            this.notiming=18
          }
   
          
         
    
        }
       
         
        
      })
  }
Show_Hide_Button()
{
  this.router.navigate(['/eee_quiz_start']);
}


}
