import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog, MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA,MatSnackBar } from '@angular/material';
import { BACK_END_URL } from '../shared/app.globals';
import { FRONT_END_URL } from '../shared/app.globals';
import { InterviewScheduleComponent } from '../interview-schedule/interview-schedule.component';
import 'flatpickr/dist/flatpickr.css';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TechnicalIofViewComponent } from '../technical-iof-view/technical-iof-view.component';
import { HrRrfApproveViewComponent } from '../hr-rrf-approve-view/hr-rrf-approve-view.component';
@Component({
  selector: 'app-rrf-interviewer-activity',
  templateUrl: './rrf-interviewer-activity.component.html',
  styleUrls: ['./rrf-interviewer-activity.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})
export class RrfInterviewerActivityComponent implements OnInit {

  @ViewChild('file') file;
  @ViewChild('candidate_paginator') candidate_paginator: MatPaginator;
  @Input() rrf_id: any;
  @Input() view_type: any;
  @Input() rrf_detail: any;
  displayedColumns: string[] = ['candidate_name', 'candidate_phone', 'resume', 'iof_detail','status', 'interview_schedule','action'];
  
  BACK_END_URL = BACK_END_URL;
  FRONT_END_URL = FRONT_END_URL;
  candidate_detail: any;
  maxSize: number = 2097152;
  accepted_file_exetension = ['pdf', 'doc', 'docx'];
  uploaded_candidate_details: any = [];
  duplicate_error_flag: boolean = false;
  panellistbool:any;
  minDate = new Date()
  bands_list: any;

  constructor(private skill_service: EmployeeSkillMappingService, public dialog: MatDialog,private snackBar: MatSnackBar, private bottomSheet: MatBottomSheet ) { }

  ngOnInit() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.panellistbool = jsonObj.is_interviewer;
    console.log("aADASD",this.view_type)
    const filter_candidate = this.rrf_detail['candidate_detail'].filter(e => e.status == 'Uploaded');

    console.log("sdasdd",filter_candidate)

    this.candidate_detail = new MatTableDataSource(filter_candidate.reverse())
    // this.candidate_detail = new MatTableDataSource(this.rrf_detail['candidate_detail'].reverse())
    setTimeout(() => {
      this.candidate_detail.paginator = this.candidate_paginator;
    });
    this.skill_service.getBands()
      .subscribe(
        response => {
          this.bands_list = response["data"];
        }
      )
      console.log(this.candidate_detail);
  }
  
 

  openInterviewSchedule(type, schedule_data, candidate_id) {
    const dialogRef = this.dialog.open(InterviewScheduleComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: { rrf_detail: this.rrf_detail, rrf_id: this.rrf_id, candidate_id, type, schedule_data }, hasBackdrop: true, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.candidate_detail = new MatTableDataSource(result.reverse())
        setTimeout(() => {
          this.candidate_detail.paginator = this.candidate_paginator;
        });
      }
    });
  }
  openIOF(iof_detail, type) {
    if (type == 'hr') {
      this.dialog.open(HrRrfApproveViewComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: iof_detail, hasBackdrop: true, disableClose: true });
    } else {
      this.dialog.open(TechnicalIofViewComponent, { closeOnNavigation: true, width: '80vw', maxWidth: '80vw', maxHeight: '80vh', autoFocus: false, data: iof_detail, hasBackdrop: true, disableClose: true });
    }
  }
  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  profileStatus(status,candidate_id){
   var update_object={};
   update_object["status"]=status;
   update_object["candidate_id"]=candidate_id;
    this.skill_service.updateRRFStatusForCandidate(update_object, this.rrf_id)
    .subscribe(
      response => {
        this.snackBar.open('Technical Screening Selected Successfully', '', {
          duration: 2000,
        });
        const filter_candidate = response["data"].filter(e => e.status == 'Uploaded');

        this.candidate_detail = new MatTableDataSource(filter_candidate.reverse())
        // this.candidate_detail = new MatTableDataSource(response["data"].reverse())
        setTimeout(() => {
          this.candidate_detail.paginator = this.candidate_paginator;
        });
      }
    );
  }

  openDialog(status,candidate_id){
    
      let candidate_obj = new Map();
     
      candidate_obj.set("status",status);
      candidate_obj.set("candidate_id",candidate_id);
      candidate_obj.set("rrf_id",this.rrf_id);
      this.bottomSheet.dismiss();
      const bottomSheetRef = this.bottomSheet.open(RejectPopup, {closeOnNavigation: true, data: candidate_obj, disableClose: true })
        
       bottomSheetRef.afterDismissed().subscribe(result => {
         if(result!='cancel'){
          this.snackBar.open('Technical Screening Rejected Successfully', '', {
            duration: 2000,
          });
          console.log(result["data"]);
          const filter_candidate = result["data"].filter(e => e.status == 'Uploaded');

          this.candidate_detail = new MatTableDataSource(filter_candidate.reverse())
          // this.candidate_detail = new MatTableDataSource(result["data"].reverse())
          setTimeout(() => {
            this.candidate_detail.paginator = this.candidate_paginator;
          });
         }
        
        });
    
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }


}


@Component({
  selector: 'rejection-popup',
  templateUrl: 'rejection-popup.html',
  styleUrls: ['./rrf-interviewer-activity.component.css'],
})
export class RejectPopup {
  public status: any;
  public candidate_id: any;
  public candidate_detail:any;
  public employee_id:any;
  public rrf_id:any;
  form_data: any;
  @ViewChild('candidate_paginator') candidate_paginator: MatPaginator;
  constructor(private location: Location,private router: Router, private skill_service: EmployeeSkillMappingService,private bottomSheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public skill_data: any) { }
 
   ngOnInit() {
    this.status = this.skill_data.get('status');
    this.candidate_id = this.skill_data.get('candidate_id');
    this.rrf_id = this.skill_data.get('rrf_id');
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.employee_id=jsonObj.id;
    this.form_data= new FormGroup({
      reject_reason: new FormControl("", [Validators.required]),
      
    });
   }
  
  profileStatusForRejection(status,candidate_id){
  
   var update_object={};
   update_object["status"]=status;
   update_object["candidate_id"]=candidate_id;
   update_object["rejected_by"]=this.employee_id;
   
   if(this.form_data.valid)
   update_object["reject_reason"]=this.form_data.value.reject_reason;
    this.skill_service.updateRRFStatusForCandidate(update_object, this.rrf_id)
    .subscribe(
      response => {
        this.bottomSheetRef.dismiss(response);
        const filter_candidate = response["data"].filter(e => e.status == 'Uploaded');

        this.candidate_detail = new MatTableDataSource(filter_candidate.reverse())
      }
    );
  }

 
  closeBottomSheet() {
    this.bottomSheetRef.dismiss('cancel');
  }

}