import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable, Subject, timer } from 'rxjs';
import { PASSWORD } from 'node_modules_old/html2canvas/dist/types/dom/replaced-elements/input-element-container';



@Injectable({
  providedIn: 'root'
})
export class QuizService {
  // readonly rootUrl = 'http://35.200.141.90:8000/api/v1/quiz';
  readonly rootUrl = 'https://ht.ihtfast.com:8000/api/v1/quiz';

  readonly rootUrl_2 = 'http://192.168.1.2:8000/api/v1/rrf_details';
  url :string = "https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json";
  qns: any[];
  answer:any = {'0':'a','1':'b','2':'c','3':'d','4':'e'};
  seconds: number;
  timer;
  button:String;
  qnProgress: number;
  userAnswer:number;
  correctAnswerCount: number = 0;
  constructor(private http: HttpClient) { }
  displayTimeElapsed() {
    return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds / 60) + ':' + Math.floor(this.seconds % 60);
  }

  getParticipantName() {
    var participant = JSON.parse(localStorage.getItem('participant'));
    return participant.message.name;
  }


  //---------------- Http Methods---------------

  insertParticipant(name: string, email: string) {
    var body = {
      Name: name,
      Email: email
    }
    return this.http.post(this.rootUrl + '/addparticipant', body);
  }

  getQuestions(candidate_role,candidate_password) {
    var body={
      candidate_role:candidate_role,
      candidate_password:candidate_password
    }
    console.log(body)
    return this.http.post(this.rootUrl + '/api/getquestion',body);
  }
  geteee_Questions(candidate_role,candidate_password) {
    var body={
      candidate_role:candidate_role,
      candidate_password:candidate_password
    }
    console.log(body)
    return this.http.post(this.rootUrl + '/api/geteee_question',body);
  }
  getjoblist(emp_id){
    var body={
      emp_id:emp_id
    }
    return this.http.post(this.rootUrl +'/api/getjoblist',body);

  }
  get_unsavedjobs()
  {
    var body={
      emp_id:'nodata'
      
    }
    console.log(body)
    return this.http.post(this.rootUrl +'/api/getjoblist',body);
  }
  quiz_result(name,password)
  {
    var body={
      name:name,
      password:password
    }

    return this.http.post(this.rootUrl + '/api/result',body);

  }
  savedJobslist(selectedids,emp_id)
  {
    var body={
      selectedids:selectedids,
      emp_id:emp_id
    }
    console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_savedjobs`,body);

  }
  get_checkedjoblist(selectedRole)
  {
    var body ={
      value:selectedRole
    }
    //return this.http.post(this.rootUrl + '/api/get_jobcheckedlist', body);
    return this.http.post(this.rootUrl + '/api/get_jobcheckedlist',body);
  }
  get_joblocation(valueSelected)
  {
    var body ={
      value: valueSelected
    }
    //return this.http.post(this.rootUrl + '/api/get_jobcheckedlist', body);
    return this.http.post(this.rootUrl + '/api/get_joblocationfilter',body);

  }
  getCandidate_password(candidate_password){
    console.log("candidate_password",candidate_password);
    var body = {
      candidate_password:candidate_password,
    }
    return this.http.post(`${this.rootUrl}/api/update_quiz_status`,body)
    }
  get_employeeQA(emp_id) {
    var body={
     employee_id :emp_id
    }
    console.log(body)
    return this.http.post(this.rootUrl + '/api/get_employeeQA',body);
  }
  get_external_employeeQA(emp_id) {
    var body={
     employee_id :emp_id
    }
    console.log(body)
    return this.http.post(this.rootUrl + '/api/get_external_employeeQA',body);
  }
  candidateLogin(email)
  {
    var body = {
      email: email
    }
    console.log('your data has been',email);
    return this.http.post(this.rootUrl + '/api/getcandidate_login',body);
  }
  getAnswers(option,item,name,password,pageno,page_length,topicId, path) {
   // var body = this.qns.map(x => x.QnID);
   console.log('topicId',topicId)
   var body = {
    option: option,
    item: item,
    name:name,
    password:password,
    pageno:pageno,
    page_length:page_length,
    topicId:topicId,
    img_path: path
  }
  console.log(body)
    return this.http.post(this.rootUrl + '/api/Answers', body);
  }
  chatAnswers(name,password) {
    var body = {
     name:name,
     password:password
   }
   console.log(body)
     return this.http.post(this.rootUrl + '/api/staticAnswers', body);
   }
  getpageoption(pageno,candidatename,password,question,topicid)
  {
    var body={
      pageno:pageno,
      candidate_name:candidatename,
      password:password,
      question:question,
      topiceid:topicid

    }
    console.log('youu',body)
    return this.http.post(this.rootUrl + '/api/get_quizoption', body);
  }
  get_quizrrf_codes()
  {
    

    return this.http.get(this.rootUrl + '/api/quiz_getrrf_codes');


    
  }
  get_quizskill_result(rrf_code)
  {
    var body = {
      rrf_code:rrf_code
    }
    return this.http.post(this.rootUrl + '/api/quiz_skill_result',body);
  }
  send_mail()
  {
    
  }
  quiz_sendmail(name,password,count)
  {
    var body = {
      name: name,
      password: password,
      count: count
    }
      return this.http.post(this.rootUrl + '/api/sendquiz_mail', body);
    

  }

  submitScore() {
    var body = JSON.parse(localStorage.getItem('participant'));
    body.Score = this.correctAnswerCount;
    body.TimeSpent = this.seconds;
    return this.http.post(this.rootUrl + "/api/UpdateOutput", body);
  }
  rfGetdata():any
  {
    return this.http.get<any>(`${this.rootUrl_2 }/rrf_details`, {headers : {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }});

  }
  upload(data)
  {
    return this.http.post<any>(`${this.rootUrl_2 }/uploads`,data);

  }
  getRrfnotes(details)
  {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post<any>(`${this.rootUrl_2 }/rrfnotes`,details,{headers, responseType: 'text' as 'json'  });

  }
  rrfSendmail(data)
  {
    return this.http.post<any>(`${this.rootUrl_2 }/rrfnotes/send_rrfmail`,data);
  }
  company_detailsSave(savedata,path)
  {
    return this.http.post<any>(`${this.rootUrl_2 }/rrfnotes/send_companydetails`,savedata,path);

  }
  rrfSave(data)
  {
    return this.http.post<any>(`${this.rootUrl_2 }/rrfnotes/save_rrfdetails`,data);

  }
  getSkill(name,password)
  {
    var body = {
      values: name,
      password: password,
     
     
    }
  //  console.log('you are are a data',body)
   
    
    return this.http.post<any>(`${this.rootUrl}/api/get_skills`,body);

  }
  
  valueChange(data,role,password)
  {

    var body = {
      values: data,
      candidate_role:role,
      password:password
     
    }
    
    return this.http.post<any>(`${this.rootUrl}/api/get_dropdownchange`,body);

  }
  uploadImage(data)
  {
    return this.http.post<any>(`${this.rootUrl_2 }/rrfnotes/save_companylogo`,data);

  }
  allCountries(): Observable<any>{
    return this.http.get(this.url);
  }
  getChartdata(employee_id):Observable<any>
  {
    var body = {employee_id:employee_id}
    //console.log('your data details',body);
    
    return this.http.post<any>(`${this.rootUrl}/api/get_chartdetails`,body);
  }
  update_quizskill_colors(cutoffmarks,skill_name,rrf_code)
  {
    var body={
      cutoffmarks:cutoffmarks,
      skill_name:skill_name,
      rrf_code:rrf_code
  
    }
    return this.http.post(this.rootUrl + '/api/updatecutoffmarks',body);
  }
  get_graphskill_count(rrf_code,)
  {
    var body={
      rrf_code:rrf_code
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_quizgraphskills`,body);

  }
  employee_lookup(employee_number,employee_name,emailid)
  {
    var body={
      employee_number:employee_number,
      employee_name:employee_name,
      email_id:emailid
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/lookup`,body);

  }
  get_resumebuilder()
  {
    
    //console.log('you are selected ids',body);
    return this.http.get<any>(`${this.rootUrl}/api/get_resumedetails`);

  }
  get_dashboarddeat()
  {
    
    //console.log('you are selected ids',body);
    return this.http.get<any>(`${this.rootUrl}/api/quiz_overall_result`);

  }
  getsubpractice(value)
  {
    var body={
      practice:value
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/getsubpractice_details`,body);

  }
  get_practice_graph(subpractice_value,practice_value)
  {
    var body={
      subpractice_value :subpractice_value,
      practice_value:practice_value
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_practicegraphdetails`,body);

  }
  get_rrf_code_quiz(subpractice_value,practice_value)
  {
    var body={
      subpractice_value :subpractice_value,
      practice_value:practice_value
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_graphrrf_details`,body);

  }
  get_quiz_candidate_graph(rrf_code,practice_value,subpractice_value)
  {
    var body={
      rrf_code:rrf_code,
      subpractice_value :subpractice_value,
      practice_value:practice_value
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_quiz_graph_details`,body);

  }
  gettablequiz_rrfdetails(rrf_code_id)
  {
    var body={
      rrf_code_id:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_table_quiz_details`,body);

  }

  getquiz_rrfdetails(rrf_code_id)
  {
    var body={
      rrf_code_id:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_quiz_skill_details`,body);

  }
  getdashboard_graph(rrf_code_id)
  {
    var body={
      rrf_code:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_dashboard_graph`,body);

  }
  get_multipledashborad(rrf_code_id)
  {
    var body={
      rrf_code:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_multiple_userdashboard`,body);

  }
  get_multip_avg(rrf_code_id)
  {
    var body={
      rrf_code:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_multipleaveragedeatials`,body);

  }
  get_multipleskilldashboard_graph(rrf_code_id)
  {
    var body={
      rrf_code:rrf_code_id,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/get_multiple_skilldashbaord`,body);

  }
  getfitmentgraph(subpractice)
  {
    var body={
      subpractice:subpractice,
     
     
      
    }
    //console.log('you are selected ids',body);
    return this.http.post<any>(`${this.rootUrl}/api/getfitmentscoregraph`,body);

  }
  review_questions(candidate_name,candidate_password)
  {
    var body={
    candidate_name:candidate_name,
    candidate_password:candidate_password,
     
     
      
    }
    return this.http.post<any>(`${this.rootUrl}/api/get_reviewsquestion`,body);

  }
  getdatewise_graph(actualdate,subpractice,practice,start_date)
  {
    var body={
      enddate:actualdate,
      subpractice:subpractice,
      practice:practice,
      start_date:start_date
     
       
       
        
      }
      return this.http.post<any>(`${this.rootUrl}/api/get_datewisegraph`,body);
  

  }
  get_datewiserrf_code_quiz(actualdate,subpractice,practice,start_date)
  {
    var body={
      enddate:actualdate,
      subpractice:subpractice,
      practice:practice,
      start_date:start_date
        
        
      }
      return this.http.post<any>(`${this.rootUrl}/api/get_datewiserrf_list`,body);
  

  }
  get_popupdetails(subpractice)
  {
    var body={
      
      subpractice:subpractice,
    }
      return this.http.post<any>(`${this.rootUrl}/api/get_data_popup`,body);
  

  }
  get_data_newdashboard(rrf_master_id)
  {
    var body={
      
      rrf_master_id:rrf_master_id,
    }
      return this.http.post<any>(`${this.rootUrl}/api/getnewdashboardinfo`,body);
  

  }
  newdashboardskills(rrf_master_id)
  {
    var body={
      
      rrf_master_id:rrf_master_id,
    }
      return this.http.post<any>(`${this.rootUrl}/api/getskill_list`,body);
  

  }
  getrrf_role_list(rrf_role)
  {
    var body={
      
      rrf_role:rrf_role,
    }
      return this.http.post<any>(`${this.rootUrl}/api/getskill_roLe_list`,body);
  

  }

  getCountOfAllCandidates(value,score,skillVal) {
    var body= {
      rrf_code:value,
      min_fitment_score:score,
      skill_length:skillVal
    }
    return this.http.post(`${this.rootUrl}/api/dashboard/list/count`,body)
}

}



  