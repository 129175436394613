import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RedirectService } from "../services/redirect";
import { MatPaginator, MatTableDataSource, MatDialog, MatSort, MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-internal-assessment-employee',
  templateUrl: './internal-assessment-employee.component.html',
  styleUrls: ['./internal-assessment-employee.component.css']
})
export class InternalAssessmentEmployeeComponent implements OnInit {
  rrf_list_data: any;
  firstFormGroup: FormGroup;
  units: any;
  roles: any;
  sub_practices: any;
  practices: any;
  bands: any ;
  job_codes:any;
  rrf_id: any = '';
  jsonObj: any;
  view_type:any;
  displayedColumns: string[] = ['rrf_code', 'practice','band','role','fitment_score', 'location', 'job_code','submitted_on', 'status'];
  status_list: any ;
  assessment_type:any;
  business_value: any;
  constructor(private router: Router,private skill_service: EmployeeSkillMappingService,private route: ActivatedRoute,public redirect: RedirectService) { }

  ngOnInit() {
    this.business_value = [{ name: "P1" }, { name: "P2" }, { name: "P3" }, { name: "P4" }];
    this.jsonObj = JSON.parse(localStorage.currentUser);
    this.assessment_type = [{"type":"Regular"},{"type":"Weekly"}];
    if (this.router.url == "/internal_assessment") {
      this.view_type = "internal_employee";
      this.status_list = ['Submitted', 'RM Approved', 'RM Rejected', 'RMG Approved', 'RMG Rejected', 'Recruiter Approved', 'Recruiter Rejected','Internal Fulfillment Approved', 'Internal Fulfillment Rejected', 'Closed'];
     
    }
        
    this.rrfListTableValue()
    this.firstFormGroup = new FormGroup({
      unit: new FormControl("", [Validators.required]),
      practice: new FormControl("", [Validators.required]),
      sub_practice: new FormControl("", [Validators.required]),
      band:new FormControl("", [Validators.required]),
      role:new FormControl("", [Validators.required]),
      job_code:new FormControl("", [Validators.required]),
      business_level: new FormControl("",Validators.required),
      minimum_fitment_score:new FormControl("", [Validators.required]),
      job_title: new FormControl(""),
      assessment_type: new FormControl(""),
      no_of_question: new FormControl("", [Validators.required]),
      assessment_timing: new FormControl("", [Validators.required])
    });
    this.skill_service.getUnit("0")
    .subscribe(
      response => {
        console.log("response",response)
        this.units = response;
      });
      this.firstFormGroup.controls.unit.valueChanges.subscribe(value => {
        this.skill_service.getUnit(value)
          .subscribe(
          
            response => {
              this.firstFormGroup.controls["practice"].setValue(null);
              this.firstFormGroup.controls["sub_practice"].setValue(null);
              this.sub_practices = [];
              this.practices = response;
            }
          )
      });
      this.firstFormGroup.controls.practice.valueChanges.subscribe(value => {
        this.skill_service.getUnit(value)
          .subscribe(
            response => {
              this.firstFormGroup.controls["sub_practice"].setValue(null);
              this.sub_practices = response
            }
          );
      });
      this.skill_service.getBands()
      .subscribe(
        response => {
          console.log("response",response)
          this.bands = response["data"];
        }
      )  
      this.firstFormGroup.controls.band.valueChanges.subscribe(value => {
        if (this.firstFormGroup.controls["unit"].value && this.firstFormGroup.controls["practice"].value && this.firstFormGroup.controls["sub_practice"].value &&this.firstFormGroup.controls["band"].value) {
          this.skill_service.getRoleswithPracticeAndUnit(this.firstFormGroup.controls["unit"].value, this.firstFormGroup.controls["practice"].value, this.firstFormGroup.controls["sub_practice"].value, this.firstFormGroup.controls["band"].value)
          .subscribe(
            response => {
              this.roles = response["data"];
              // this.job_codes = [];
              // this.mapping_detail = [];
            }
          )
        }
        // this.show_table = false;
      });
      this.firstFormGroup.controls.role.valueChanges.subscribe(value => {
        this.skill_service.getJobCodes(this.firstFormGroup.controls["role"].value, this.firstFormGroup.controls["band"].value)
          .subscribe(
            response => {
              this.job_codes = response["data"];
              // this.mapping_detail = [];
            }
          )
      });
  }

  onSubmit() {
    this.firstFormGroup.get('unit').markAsTouched();
    this.firstFormGroup.get('practice').markAsTouched();
    this.firstFormGroup.get('sub_practice').markAsTouched();
    this.firstFormGroup.get('band').markAsTouched();
    this.firstFormGroup.get('role').markAsTouched();
    this.firstFormGroup.get('job_code').markAsTouched();
    this.firstFormGroup.get('business_level').markAsTouched();
    this.firstFormGroup.get('minimum_fitment_score').markAsTouched();
    this.firstFormGroup.get('no_of_question').markAsTouched();
    this.firstFormGroup.get('assessment_timing').markAsTouched();

    if (this.firstFormGroup.status != "INVALID") {
      console.log("data",this.firstFormGroup.value )
        var jsonObj = JSON.parse(localStorage.currentUser);
        var employee_id = jsonObj.id;
        console.log("emp_id",employee_id)
        const formData = new FormData();
        this.firstFormGroup.value["rrf_id"] = this.rrf_id;
        this.firstFormGroup.value["interview_round"] = "2";
        this.firstFormGroup.value["no_of_position"] = "2";
        this.firstFormGroup.value["base_location"] = "SKCL Chennai";
        this.firstFormGroup.value["work_location"] = "Chennai";
        this.firstFormGroup.value["start_date"] = new Date();
        this.firstFormGroup.value["internal_employee_status"] = "Yes";
        this.firstFormGroup.value["external_employee_status"] = "No";
        Object.keys(this.firstFormGroup.value).forEach((key) => {
          console.log("key",key);
          // if (key == "disselected_job_competency")
          //   formData.append(key, JSON.stringify(this.firstFormGroup.value[key]));
          // else
            formData.append(key, this.firstFormGroup.value[key]);
            // formData.append(key, JSON.stringify(this.firstFormGroup.value[key]));
        });
        console.log("formData",formData);
        this.skill_service.creatNewRRF(formData, employee_id)
          .subscribe(
            response => {
              console.log("response",response)
              if(response['status'] == '201'){
                this.rrfListTableValue()
              }
            }
        );
    } else {
      console.log('invalid',this.firstFormGroup)
    }
  }

  rrfListTableValue() {
    var jsonObj = JSON.parse(localStorage.currentUser);
    var rrf_list_value;
    this.skill_service.getRRFList(this.view_type, jsonObj.id)
    .subscribe(
      response => {
        rrf_list_value = response["data"]["rrf_list"];
        console.log("sdfsdfdfgdfg",response["data"]["rrf_list"]);
        console.log('rr_list', rrf_list_value, response["data"]["rrf_list"]);
        this.rrf_list_data = new MatTableDataSource(rrf_list_value);
       }
    );
  }

  redirectPage(page_link, rrf_id) {
    this.redirect.change_page_with_data(page_link, { rrf_id });
  }

}
