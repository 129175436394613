import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-interview-schedule',
  templateUrl: './interview-schedule.component.html',
  styleUrls: ['./interview-schedule.component.css']
})
export class InterviewScheduleComponent implements OnInit {
  displayedColumns: string[] = ['level', 'interviewer_name', 'schedule_time'];
  // displayedColumnsData: string[] = ['level', 'interviewer_name', 'schedule_time'];
  displayedColumnsData: string[] = ['schedule_time'];
  dataSource: any = [];
  todayDate:any = new Date();
  candidate_status : any;
  interview_panel: any = [];
  formdata: any;
  rrf_id: any;
  minDate = new Date();
  type: any;
  schedule_data: any = [];
  panalist_names:any = [];
  hr_name:any;
  panalist_name:any = []
  constructor(private _snackBar: MatSnackBar, private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InterviewScheduleComponent>, ) { };
  ngOnInit() {
    console.log(this.todayDate,'date');
    this.formdata = new FormGroup({
      interview_schedule: new FormArray([]),
      // name: ['', Validators.required],
    });
    var index = 0;
    console.log("this.data",this.data);
    if(this.data['status'] == 'internal_employee'){
      this.candidate_status = this.data['status']
      this.schedule_data = [];
      this.panalist_names = [];
      this.type = 'edit';
    }else{
      this.candidate_status = this.data['status']
      this.schedule_data = this.data['schedule_data'];
      this.panalist_names = this.data['schedule_data'][0];
      console.log("this.panalist_names",this.panalist_names);
      this.type = this.data['type'];
    }
   
    
    // var panal_name = this.data['rrf_detail']["interview_panel"][0]
    // console.log("panal_name",panal_name)
    // panal_name.forEach((data) => {

    // });
    // for (var level_object of this.data['rrf_detail']["interview_panel"]) {
       var employee_names = '';
    //   index++;
    if(this.candidate_status == 'Technical Screen Selected'  || this.candidate_status == 'Scheduled' || this.candidate_status == 'Assessment Taken'){
      for (var employee_object of this.data['rrf_detail']["interview_panel"][0]) {
        employee_names += employee_object["itemName"];
        employee_names += ", ";
      }
      this.interview_panel.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2) });
      this.add_form_control();
      console.log("this.interview_panel",this.interview_panel);
      this.dataSource = new MatTableDataSource(this.interview_panel);
    }else if(this.candidate_status == 'internal_employee'){
      this.interview_panel.push({"level": "Level 0" ,"employee_name": "17753 - Hariharan Achutharaman"})
      this.add_form_control();
      this.dataSource = new MatTableDataSource(this.interview_panel);
    }
    else if(this.candidate_status == 'Assessment Cleared' || this.candidate_status == 'Skipped Assessment' || this.candidate_status == 'Technical Interview 1 Reschedule'){
      for (var employee_object of this.data['rrf_detail']["interview_panel"][0]) {
        console.log("employee_object",employee_object)
        employee_names += employee_object["itemName"];
        employee_names += ", ";
        this.panalist_name.push( employee_object )
      }
      // console.log("employee_names",employee_names.slice(0, -2));
      // this.panalist_name.push({"employee_name": employee_names.slice(0, -2) })
      console.log("this.panalist_name",this.panalist_name);
      this.interview_panel.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2)  });
      this.add_form_control();
      this.dataSource = new MatTableDataSource(this.interview_panel);
    }
    else if(this.candidate_status == 'Technical Interview 1 Scheduled' ){
      for (var employee_object of this.data['rrf_detail']["interview_panel"][0]) {
        console.log("employee_object",employee_object)
        employee_names += employee_object["itemName"];
        employee_names += ", ";
        this.panalist_name.push( employee_object )
      }
      // console.log("employee_names",employee_names.slice(0, -2));
      // this.panalist_name.push({"employee_name": employee_names.slice(0, -2) })
      console.log("this.panalist_name",this.panalist_name);
      this.interview_panel.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2) });
      this.add_form_control();
      this.dataSource = new MatTableDataSource(this.interview_panel);
    }
    else if(this.candidate_status == 'Technical Interview 1 Cleared'|| this.candidate_status =='Skipped Technical Interview 1' || this.candidate_status == 'Technical Interview 2 Reschedule'){
      for (var employee_object of this.data['rrf_detail']["interview_panel"][1]) {
        console.log("employee_object",employee_object)
        employee_names += employee_object["itemName"];
        employee_names += ", ";
        this.panalist_name.push( employee_object )
      }
      // console.log("employee_names",employee_names.slice(0, -2));
      // this.panalist_name.push({"employee_name": employee_names.slice(0, -2) })
      console.log("this.panalist_name",this.panalist_name);
      this.interview_panel.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2)  });
      console.log("asdasdsa",this.interview_panel)
      this.add_form_control();
      this.dataSource = new MatTableDataSource(this.interview_panel);
    } else if(this.candidate_status == 'Technical Interview 2 Scheduled'){
      for (var employee_object of this.data['rrf_detail']["interview_panel"][1]) {
        console.log("employee_object",employee_object)
        employee_names += employee_object["itemName"];
        employee_names += ", ";
        this.panalist_name.push( employee_object )
      }
      // console.log("employee_names",employee_names.slice(0, -2));
      // this.panalist_name.push({"employee_name": employee_names.slice(0, -2) })
      console.log("this.panalist_name",this.panalist_name);
      this.interview_panel.push({ "level": "Level " + index, "employee_name": employee_names.slice(0, -2)  });
      this.add_form_control();
      this.dataSource = new MatTableDataSource(this.interview_panel);
    }
    
  
  }

  add_form_control() {
    this.formdata.setControl('interview_schedule', new FormArray([]));
    let control = <FormArray>this.formdata.controls.interview_schedule;
    this.interview_panel.forEach((value, index) => {
      control.push(this.patchValues(index))
    });
  }
  patchValues(index) {
    var interview_date = '';
    var time_range = '';
    var panalist_name ='';
    if (this.schedule_data.length > 0) {
      interview_date = this.schedule_data[index]['interview_date'];
      time_range = this.schedule_data[index]['time_range'];
    }
    
    return new FormGroup({
      interview_date: new FormControl(interview_date),
      time_range: new FormControl(time_range),
      panalist_name: new FormControl(panalist_name)
    })

    // return new FormGroup({
    //   interview_date: new FormControl(interview_date, Validators.compose([
    //     Validators.required])),
    //   time_range: new FormControl(time_range, Validators.compose([
    //     Validators.required, this.checkDate]))
    // })
  }
  checkDate(formcontrol) {
    if (formcontrol.value && formcontrol.value[0] && formcontrol.value[1]) {
      var date1 = new Date(formcontrol.value[0]);
      var date2 = new Date(formcontrol.value[1]);
      var diff = Math.abs(date1.getTime() - date2.getTime());
      var diffMin = Math.floor(((diff / 1000) / 60));
      if (diffMin < 10) {
        return { "custom_time_check": true };
      }
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  save_date() {
   // if (this.formdata.valid) {
      var jsonObj = JSON.parse(localStorage.currentUser);
      console.log(this.formdata);
      this.formdata.value["scheduled_by"] = jsonObj.id;
      this.formdata.value["status"] = this.data['status'];
      this.formdata.value['interview_schedule'].forEach(function (element, index) {
        var start_time = element['time_range'][0];
        console.log("start_time",start_time);
        var end_time = element['time_range'][1];
        var interview_date = start_time;
        //var interview_date = new Date(element['interview_date']);
        // start_time.setDate(interview_date.getDate());
        // start_time.setMonth(interview_date.getMonth());
        // start_time.setFullYear(interview_date.getFullYear());
        // end_time.setDate(interview_date.getDate());
        // end_time.setMonth(interview_date.getMonth());
        // end_time.setFullYear(interview_date.getFullYear());
        if(!isNaN(interview_date.getTime()) && !isNaN(start_time.getTime()) && !isNaN(end_time.getTime())){
          console.log("sadsfa",start_time.toJSON());
          element['time_range'][0] = start_time.toJSON();
          element['time_range'][1] = end_time.toJSON();
          element['interview_date']=interview_date.toJSON();
          element['level'] = index;
         
        }
        
      });
      console.log(this.formdata);
      this.skill_service.scheduleInterview(this.formdata.value, this.data['rrf_id'], this.data['candidate_id'])
        .subscribe(
          response => {
            this.dialogRef.close(response['data']);
            this.openSnackBar();
          }
        );
   // }
  }

  panalist_save_date(){
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log(this.formdata);
    this.formdata.value["scheduled_by"] = jsonObj.id;
    this.formdata.value['interview_schedule'].forEach(function (element, index) {
      console.log("formdataelement",element)
      var start_time = element['time_range'][0];
      console.log("start_time",start_time);
      var end_time = element['time_range'][1];
      var interview_date = start_time;
      console.log("dasdas",interview_date)
      if(!isNaN(interview_date.getTime()) && !isNaN(start_time.getTime()) && !isNaN(end_time.getTime())){
        console.log("sadsfa",start_time.toJSON());
        element['time_range'][0] = start_time.toJSON();
        element['time_range'][1] = end_time.toJSON();
        element['interview_date']=interview_date.toJSON();
        element['level'] = index;
      }
      
    });
    console.log("formdata",this.formdata.value,this.data['rrf_id'],this.data['candidate_id'])
    this.skill_service.technicalInterviewschedule(this.formdata.value, this.data['rrf_id'], this.data['candidate_id'])
    .subscribe(
      response => {
        this.dialogRef.close(response['data']);
        this.openSnackBar();
      }
    );
  }

  final_interview(){
    var jsonObj = JSON.parse(localStorage.currentUser);
    console.log(this.formdata);
    this.formdata.value["scheduled_by"] = jsonObj.id;
    this.formdata.value['interview_schedule'].forEach(function (element, index) {
      console.log("formdataelement",element)
      var start_time = element['time_range'][0];
      console.log("start_time",start_time);
      var end_time = element['time_range'][1];
      var interview_date = start_time;
      if(!isNaN(interview_date.getTime()) && !isNaN(start_time.getTime()) && !isNaN(end_time.getTime())){
        console.log("sadsfa",start_time.toJSON());
        element['time_range'][0] = start_time.toJSON();
        element['time_range'][1] = end_time.toJSON();
        element['interview_date']=interview_date.toJSON();
        element['level'] = index;
       
      }
      
    });
    console.log("formdata",this.formdata.value,this.data['rrf_id'],this.data['candidate_id'])
    this.skill_service.finalTechnicalInterviewschedule(this.formdata.value, this.data['rrf_id'], this.data['candidate_id'])
    .subscribe(
      response => {
        this.dialogRef.close(response['data']);
        this.openSnackBar();
      }
    );
  }

  // panalist_final_interview

  openSnackBar() {
    this._snackBar.open('Interview Scheduled Successfully', '', {
      duration: 2000,
    });
  }

}
