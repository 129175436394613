import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FileInput, FileValidator } from 'ngx-material-file-input';
import { EmployeeSkillMappingService } from 'src/app/services/employee_skill_mapping.service';


@Component({
  selector: 'app-cem',
  templateUrl: './cem.component.html',
  styleUrls: ['./cem.component.css']
})
export class CemComponent implements OnInit {

  candidate_final_status: any;
  form_data: FormGroup;
  docs_form: FormGroup;
  maxSize: number = 2097152;
  candidate_id:any
  accepted_file_exetension = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg','png', 'jpg', 'jpeg'];

  constructor(private router: Router, private location: Location, private skill_service: EmployeeSkillMappingService,) { }

  ngOnInit() {
    this.candidate_id = JSON.parse(localStorage.getItem("candidate_detail_array"));
    console.log('candidate_id', this.candidate_id)

    if(this.candidate_id.cem_final_candidate_check == null) {
      this.form_data = new FormGroup({
        offer_acceptance_date: new FormControl(""),
        resignation_date: new FormControl(""),   
        resignation_acceptance_date: new FormControl(""),
        last_working_date: new FormControl(""),   
        planned_doj: new FormControl(""),
        actual_doj: new FormControl(""),   
        bgv: new FormControl(""),
        ojf: new FormControl(""),   
        market_status: new FormControl(""),
        cem_remark: new FormControl(""),   
        naukri_update_date: new FormControl(""),
        naukri_last_seen_date: new FormControl(""),    
        rm_connect: new FormControl(""),
        rm_feedback: new FormControl(""),   
        leadership_connect: new FormControl(""),
        on_boarding: new FormControl(""),
        joining_status: new FormControl(""),
        status: new FormControl(""),
        doj_date: new FormControl(""),
        reason_status: new FormControl(""),
        company_name: new FormControl(""),
        remarks: new FormControl("")
      })
      this.docs_form = new FormGroup({
        resignation_mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)]),
        resignation_acceptance_mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)]),
        lwd_mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)]),
        mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)])
  
      })
    } else if(this.candidate_id.cem_final_candidate_check != null ) { 
      this.form_data = new FormGroup({
        offer_acceptance_date: new FormControl((this.candidate_id['cem_final_candidate_check']['offer_acceptance_date'])),
        resignation_date: new FormControl((this.candidate_id['cem_final_candidate_check']['resignation_date'])),   
        resignation_acceptance_date: new FormControl((this.candidate_id['cem_final_candidate_check']['resignation_acceptance_date'])),
        last_working_date: new FormControl((this.candidate_id['cem_final_candidate_check']['last_working_date'])),   
        planned_doj: new FormControl((this.candidate_id['cem_final_candidate_check']['planned_doj'])),
        actual_doj: new FormControl((this.candidate_id['cem_final_candidate_check']['actual_doj'])),   
        bgv: new FormControl(this.candidate_id['cem_final_candidate_check']['bgv']),
        ojf: new FormControl(this.candidate_id['cem_final_candidate_check']['ojf']),   
        market_status: new FormControl(this.candidate_id['cem_final_candidate_check']['market_status']),
        cem_remark: new FormControl(this.candidate_id['cem_final_candidate_check']['cem_remark']),   
        naukri_update_date: new FormControl((this.candidate_id['cem_final_candidate_check']['naukri_update_date'])),
        naukri_last_seen_date: new FormControl((this.candidate_id['cem_final_candidate_check']['naukri_last_seen_date'])),    
        rm_connect: new FormControl(this.candidate_id['cem_final_candidate_check']['rm_connect']),
        rm_feedback: new FormControl(this.candidate_id['cem_final_candidate_check']['rm_feedback']),   
        leadership_connect: new FormControl(this.candidate_id['cem_final_candidate_check']['leadership_connect']),
        on_boarding: new FormControl(this.candidate_id['cem_final_candidate_check']['on_boarding']),
        joining_status: new FormControl(this.candidate_id['cem_final_candidate_check']['joining_status']),
        status: new FormControl(this.candidate_id['cem_final_candidate_check']['status']),
        doj_date: new FormControl((this.candidate_id['cem_final_candidate_check']['doj_date'])),
        reason_status: new FormControl(this.candidate_id['cem_final_candidate_check']['reason_status']),
        company_name: new FormControl(this.candidate_id['cem_final_candidate_check']['company_name']),
        remarks: new FormControl(this.candidate_id['cem_final_candidate_check']['remarks'])
      })

      this.docs_form = new FormGroup({
        resignation_mail_copy: new FormControl(this.candidate_id['cem_final_candidate_check_docs']['resignation_mail_copy']?this.candidate_id['cem_final_candidate_check_docs']['resignation_mail_copy']:""),
        resignation_acceptance_mail_copy: new FormControl(this.candidate_id['cem_final_candidate_check_docs']['resignation_acceptance_mail_copy']?this.candidate_id['cem_final_candidate_check_docs']['resignation_acceptance_mail_copy']:""),
        lwd_mail_copy: new FormControl(this.candidate_id['cem_final_candidate_check_docs']['lwd_mail_copy']? this.candidate_id['cem_final_candidate_check_docs']['lwd_mail_copy'] : ""),
        mail_copy: new FormControl("", [FileValidator.maxContentSize(this.maxSize)])
      })

      // this.statusDropdown();
      this.candidate_final_status = this.candidate_id['cem_final_candidate_check']['status'];

    } 

    this.docs_form.controls.resignation_mail_copy.valueChanges.subscribe(value => {
      console.log("Asdasdasd",value)
      if (value) {
        for (let file of value.files) {
          console.log(file,value,'testfile')
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.docs_form.controls.resignation_mail_copy.setValue(null, { emitEvent: false });
          }
        }
      }
    });

    this.docs_form.controls.resignation_acceptance_mail_copy.valueChanges.subscribe(value => {
      if (value) {
        for (let file of value.files) {
          var uploaded_file_type = file.name.split('.').pop();
          if (!this.accepted_file_exetension.includes(uploaded_file_type)) {
            this.docs_form.controls.resignation_acceptance_mail_copy.setValue(null, { emitEvent: false });
          }
        }
      }
    });

  }

  statusDropdown() {
    
     this.form_data.controls.status.valueChanges.subscribe(value => {
        console.log('setval')
        this.form_data.controls.status.setValue(this.candidate_id['cem_final_candidate_check']['status']);
      });
  }

  onSubmit() {
    // this.form_data.value["candidate_id"] = id;
    console.log(this.form_data.value)
    // candidate status update

    if (this.docs_form.value.resignation_mail_copy != '' && this.docs_form.value.resignation_acceptance_mail_copy == '' && this.docs_form.value.lwd_mail_copy == '') {
          this.docs_form.value.status = 'Resignation Mail Shared';
    } else if ( this.docs_form.value.resignation_acceptance_mail_copy != '' && this.docs_form.value.lwd_mail_copy == '') {
          this.docs_form.value.status = 'Resignation Acceptance Shared';
    } else if (this.docs_form.value.lwd_mail_copy != '') {
          this.docs_form.value.status = 'LWD Acceptance Shared';
    } else {
          this.docs_form.value.status = 'Offer Letter Accepted';
    }

    const formData = new FormData();
   
    Object.keys(this.docs_form.value).forEach((key) => {
      if (this.docs_form.value[key]!='' && this.docs_form.value[key] != undefined){
        if (this.docs_form.value[key]["files"]!=undefined){
          for (var i = 0; i < this.docs_form.value[key]["files"].length; i++) {
            // console.log("asasdewe",key + "[]", this.docs_form.value[key]["files"][i])
            formData.append(key + "[]", this.docs_form.value[key]["files"][i]);
          }
        }else{
          formData.append(key, this.docs_form.value[key]);
          // formData.append("dependent_details", JSON.stringify(this.docs_form.value[key]));
        }
      }
    });
    console.log('formData',formData)

    this.skill_service.cemFinalCandidateCheckDcos(this.candidate_id._id, formData)
    .subscribe(result => {
      console.log(result);    
      this.skill_service.cemFinalCandidateCheck(this.candidate_id._id ,this.form_data.value)
        .subscribe(response => {
          console.log(response);
        this.router.navigate(['/cem-candidate-list']);
      })
    })
    // localStorage.setItem('cem_screen1',JSON.stringify(formData));

  }

  go_back() {
    this.router.navigate(['/cem-candidate-list']);
  }

}
