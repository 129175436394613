import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { QuizService } from '../services/quiz.service';


@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.css']
})
export class QuizResultComponent implements OnInit {
quiz_score:any;
name:string;
count:number;
total_length:number;
movies:[];
skill_count:string;
candidate_password:any;
  constructor(private route:ActivatedRoute,private router:Router,private quizservice:QuizService) { }

  ngOnInit() {
    this.name=localStorage.getItem('name');
    this.candidate_password=localStorage.getItem('password');
    
    this.quizservice.quiz_result(this.name,this.candidate_password).subscribe(
      (data: any) => {
        console.log('has',data)
        this.quiz_score=data;
        let totalAmount = 0;
     });
  
  this.quizservice.getSkill(this.name,this.candidate_password).subscribe(
    (data: any) => {
      
     // console.log('you have height',data)
      this.movies=data
      this.skill_count =data.length;
      if(this.skill_count=="2")
      {
          this.total_length=30+30;
        
      }
      else{
        if(this.skill_count=="1")
        {
          this.total_length=30;
        }
        if(this.skill_count=="3")
        {
          this.total_length=30+30+30;
        }
        if(this.skill_count=="4")
        {
          this.total_length=30+30+30+30;
        }
        if(this.skill_count=="5")
        {
          this.total_length=30+30+30+30+30;
        }
 
        
       
  
      }
       
      
    })
  }
  save_button()
  {
    this.router.navigate(['/quiz_result'], {});
  }
  // send_mail(count)
  // {
   
  //   this.quizservice.quiz_sendmail(this.name,count).subscribe(
  //     (data: any) => {
  //       console.log('hasasss',data)
        
  //     });

  // }

}
