
import { Component, OnInit } from '@angular/core';
import { enableRipple } from '@syncfusion/ej2-base';
import { FormGroup, FormBuilder, FormArray,Validators } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import * as XLSX from 'xlsx';  
import * as FileSaver from 'file-saver'; 
import { QuizService } from '../services/quiz.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rrfemail',
  templateUrl: './rrfemail.component.html',
  styleUrls: ['./rrfemail.component.css']
})
export class RrfemailComponent implements OnInit {

  form:FormGroup;
  upload_file:FormGroup;
  rrf_values:[];
  time  : string = 'HH:mm';
  meridian = true;
  jsonData: any;  
  textData: any;  
  htmlData: any;  
  fileUploaded: File;  
  worksheet: any;  
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string;
  isShown: boolean = false ;
  successMessage: string;
  currentvalue:any= {};
  notemessage:string;
  newdate:string;
  message_list:string
  isDisabled:boolean= true;
  formSubmitAttempt: boolean =false;
  maxDate=new Date();
  minDate=new Date();
  interval:any;
   colorScheme1 = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
      }; 
    showXAxis: boolean = true;
  showYAxis: boolean = true;


  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'values';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'text';
    text = [
      {
        "name": "0-60",
        "value": 59
      },
      {
        "name": "60-80",
        "value": 75
      },
      {
        "name": "80-100",
        "value": 90
      }
  ]

  public formatString: string = 'HH:mm';
 

 
  //starting charting details
 
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public pieChartColors: Array < any > = [{
    //backgroundColor: ['#0fe6e6', '#0ee6e6','#66f3ff','#26bfff','#008cff','#0033e6', '#05238c','#04144c']
    backgroundColor: ['#2ab4d1', '#065687','#8f1c53','#cc4968','#ed7a6f','#fc892c', '#05238c','#04144c']
    // borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
 }];
  public doughnutChartLabels:string[] = [];
  public doughnutChartData:number[] = [];
  single: any = [{}];
  view: any[] = [700, 400];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  data = [
    
      
        {
          name:'Closed',
          value:300
        },
        {
          name:'Submiited',
          value:50
        },
        {
          name:'RM Approved',
          value:10
        },
        {
          name:'Recuritter Approved',
          value:40
        }
        
      ];
   
 

  constructor(public quizService: QuizService, private router: Router,private fb:FormBuilder,private modalService: NgbModal) { }

  ngOnInit() {
    this.setForm();
    this.addItemGroup();
    this.getRf_details();
    this.upload_file = this.fb.group({
      files: ['']
    });
  }
  setForm():void{
    this.form = this.fb.group({
      rrf_codes:['',Validators.required],
      start_date :['',Validators.required],
      start_time :['',Validators.required],
      end_time :['',Validators.required],
      location:['',Validators.required],
      items: this.fb.array([])
    });
  }
  addItemGroup():void{
  
    let items = this.form.get('items') as FormArray;
    items.push(this.getItemGroup());
}
getItemGroup():FormGroup{
  return this.fb.group({
    name: ['',Validators.required],
    email:['',Validators.required]
    
  });
}
removeItemGroup(i:number){
  let items = this.form.get('items') as FormArray;
  items.removeAt(i);
}
getRf_details()
  {
    this.quizService.rfGetdata().subscribe(
       response => {
       this.rrf_values =response[0];
       console.log(response[1])
       response[1].forEach(item => {
        
       this.doughnutChartLabels.push(item._id);
       this.doughnutChartData.push(item.count)
      
      })
     }
    );
  }
  onFileChange(ev) {
     
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
    const data = reader.result;
    workBook = XLSX.read(data, { type: 'binary' });
    jsonData = workBook.SheetNames.reduce((initial, name) => {
    const sheet = workBook.Sheets[name];
    initial[name] = XLSX.utils.sheet_to_json(sheet);
    return initial;
    }, {});
    this.quizService.upload(jsonData).subscribe(res => 
        {
          console.log(res);
          
        });
  }
  reader.readAsBinaryString(file);
  }
  onSubmit(event) {
  

    this.form_settingvalues();
  }
  rrfchange(event)
  {
    this.currentvalue = event.target.value;
    this.quizService.getRrfnotes(this.currentvalue).subscribe(res=>
      {
        console.log(res);
        for(let i=0; i<res.length(); i++) {
          let item = res.getJSONObject(i);  //gets the ith Json object of JSONArray
          this.notemessage = item.getString("Customer_ID");
       }
       
       })
   
  }
  rrf_mailscheduler()
  {
    //starting date formatting
    let ngbDate = this.form.controls['start_date'].value;
    let myDate = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day,);
    var month = myDate.getUTCMonth() + 1; //months from 1-12
    var day = myDate.getUTCDate()+1;
    var year = myDate.getUTCFullYear();
    this.newdate = year + "/" + month + "/" + day;
   
    //starting  time formatting
    let start_pickervalues =this.form.controls['start_time'].value;
    let end_pickervalues =this.form.controls['end_time'].value;
    // updateing form values
    this.form.patchValue({
      start_date: this.newdate,
      start_time:start_pickervalues.toLocaleTimeString('it-IT'),
      end_time:end_pickervalues.toLocaleTimeString('it-IT')
    });
    this.quizService.rrfSendmail(this.form.value).subscribe(res=>
      {
        console.log(res);
        
      })
 
    
  }
  rrf_mailsend(message)
  {
    
    
    //starting date formatting
    let ngbDate = this.form.controls['start_date'].value;
    let myDate = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day,);
    var month = myDate.getUTCMonth() + 1; //months from 1-12
    var day = myDate.getUTCDate()+1;
    var year = myDate.getUTCFullYear();
    this.newdate = year + "/" + month + "/" + day;
   
    // //starting  time formatting
     let start_pickervalues =this.form.controls['start_time'].value;
     let end_pickervalues =this.form.controls['end_time'].value;
     console.log(end_pickervalues.toLocaleTimeString('it-IT'));
    // // updateing form values
    this.form.patchValue({
      start_date: this.newdate,
      start_time:start_pickervalues.toLocaleTimeString('it-IT'),
      end_time:end_pickervalues.toLocaleTimeString('it-IT')
    });
   console.log(this.form.value);
    
    this.quizService.rrfSendmail(this.form.value).subscribe(res=>
      {
        
        console.log('data',res.status);
        if(res.status=200)
        {
          this.message_list="Email had send  successfully"
          this.modalService.open(message);
         
          this.isDisabled= true;


 

        }
            // this.ngOnInit();
        
      })

      
 
    
  }
  form_settingvalues()
  {
    if(this.form.valid)
    {

    
    let ngbDate = this.form.controls['start_date'].value;
    let myDate = new Date(ngbDate.year, ngbDate.month-1, ngbDate.day,);
    var month = myDate.getUTCMonth() + 1; //months from 1-12
    var day = myDate.getUTCDate()+1;
    var year = myDate.getUTCFullYear();
    this.newdate = year + "/" + month + "/" + day;
   
    // //starting  time formatting
     let start_pickervalues =this.form.controls['start_time'].value;
     let end_pickervalues =this.form.controls['end_time'].value;
     this.form.patchValue({
      start_date: this.newdate,
      start_time:start_pickervalues.toLocaleTimeString('it-IT'),
      end_time:end_pickervalues.toLocaleTimeString('it-IT')
    });
    
    this.quizService.rrfSave(this.form.value).subscribe(res =>
      {
          console.log(res)
          
            this.isShown=true;
            this.successMessage='you have been succesfully saved';
    this.upload_file.reset();
   
          
      })
    }
    else{
      this.formSubmitAttempt = true;

    }
  

  }
  // time_change(event)
  // {
  // //  alert(x);
  
  // let start_time =this.form.controls['start_time'].value;
  // let end_time = event.value;
  // let date_format =end_time.toLocaleTimeString('it-IT');
   
  //   if(start_time.toLocaleTimeString('it-IT') < end_time)
  //   {
  //     alert('data has been')
  //   }

  // }
  upload_image(content)
  {
    this.upload_file = this.fb.group({
      files: ['']
    });
    this.modalService.open(content);

    
  }
  form_timing()
  {
    
    if(this.form.valid)
    {
      //alert('has')
      this.isDisabled = false;
    }
  }
  
}