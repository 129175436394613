import { Component, OnInit } from '@angular/core';
import { BACK_END_URL } from '../shared/app.globals';
@Component({
  selector: 'app-reference-documents',
  templateUrl: './reference-documents.component.html',
  styleUrls: ['./reference-documents.component.css']
})
export class ReferenceDocumentsComponent implements OnInit {
  BACK_END_URL = BACK_END_URL;
  constructor() { }

  ngOnInit() {
  }

}
