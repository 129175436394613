import { Component, OnInit,TemplateRef, ViewChild,ElementRef } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MatDialog } from '@angular/material';
import { WebCamComponent } from 'ack-angular-webcam';

import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
@Component({
  selector: 'app-eee-quiz',
  templateUrl: './eee-quiz.component.html',
  styleUrls: ['./eee-quiz.component.css']
})
export class EeeQuizComponent implements OnInit {
  base64
  web_stop:boolean=false
  options = {
    audio: false,
    video: true,
    width: 280,
    height: 210
  };

 
public imageCapture;;
videosrc: string;
  start_login: Boolean;
  email:any;
  count:number;
  candidate_details:string;
  candidate_role:string;
  name:string;
  candidate_password:any;
  web_hide:boolean=false
  

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private quizService : QuizService,private skill_service: EmployeeSkillMappingService,private router : Router,private dialog: MatDialog) {   }

  ngOnInit() {
    this.start_login  = false;
    var video = document.querySelector("#video");  
  
    // Basic settings for the video to get from Webcam  
    const constraints = {  
        audio: false,  
        video: {  
            width: 475, height: 475  
        }  
    };  
  
    // This condition will ask permission to user for Webcam access  
   
    
   // this.startup()
//    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//     console.log("getUserMedia() not supported.");
//     return;
// }
// var btn = document.querySelector('button');

// btn.onclick=function(e) {

// navigator.mediaDevices.getUserMedia({ audio: true, video: true })
//         .then(function(stream) {
//             var video = document.querySelector('video');
//             if ("srcObject" in video) {
//                 video.srcObject = stream;
//             } 
//             video.onloadedmetadata = function(e) {
//                 video.play();
//             };
//        })
//          .catch(function(err) {
//               console.log(err.name + ": " + err.message);
//           });

// };

//this.onGetUserMediaButtonClick();
//this.onTakePhotoButtonClick();

   
  }
  genBase64( webcam:WebCamComponent ){
    this.candidate_password=localStorage.getItem('password');
    console.log("ewrwerwe",this.candidate_password,webcam)
    setInterval(() => {},10000);
    webcam.getBase64()
    .then(base=>{
      console.log("base",base);
      
      this.base64=base
      this.skill_service.sendBaseEncode(this.base64,this.candidate_password).subscribe(
        (data : any) =>{
          console.log("data",data)
        }
      );

    })
    .catch( e=>console.error(e) )
   // console.log(this.base64)
    if(this.base64!='')
    {
      this.web_stop=true;
    }
    
  }
  // genPostData( webcam:WebCamComponent ){
  //   webcam.captureAsFormData({fileName:'file.jpg'})
  //   .then( formData=>console.log(formData) )
  //   .catch( e=>console.error(e) )
  // }
  next_start()
  {
     this.router.navigate(['/eee_quiz_instruction']);
  }



   onGetUserMediaButtonClick() {
    navigator.mediaDevices.getUserMedia({video: true})
    .then(mediaStream => {
      document.querySelector('video').srcObject = mediaStream;
  
      const track = mediaStream.getVideoTracks()[0];
      this.imageCapture = track;
     
    })
    .catch(error => console.log(error));
    
  }
  
  onGrabFrameButtonClick() {
    this.imageCapture.grabFrame()
    .then(imageBitmap => {
      const canvas = document.querySelector('#grabFrameCanvas');
      this.drawCanvas(canvas, imageBitmap);
    })
    .catch(error => console.log(error));
 

  }
   onTakePhotoButtonClick() {
    this.imageCapture.takePhoto()
    .then(blob => createImageBitmap(blob))
    .then(imageBitmap => {
      const canvas = document.querySelector('#takePhotoCanvas');
     this.drawCanvas(canvas, imageBitmap);
    })
    .catch(error => console.log(error));
  }
  
  /* Utils */
  
  drawCanvas(canvas, img) {
    canvas.width = getComputedStyle(canvas).width.split('px')[0];
    canvas.height = getComputedStyle(canvas).height.split('px')[0];
    let ratio  = Math.min(canvas.width / img.width, canvas.height / img.height);
    let x = (canvas.width - img.width * ratio) / 2;
    let y = (canvas.height - img.height * ratio) / 2;
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
        x, y, img.width * ratio, img.height * ratio);
  }
  
  
 
  
  OnSubmit(name:string,email:string){
    this.quizService.insertParticipant(name,email).subscribe(
      (data : any) =>{
        
        localStorage.setItem('participant',JSON.stringify(data));
       
        this.router.navigate(['/eee_quiz_start']);
      }
    );
  }
  changed(event)
  {
    this.start_login = true;
    
  }
  register_login(templateRef : TemplateRef<any>)
  {
    // alert(this.email);
    this.quizService.candidateLogin(this.email).subscribe((data: any) => 
    {
      console.log('has',data)
      if(data!=0 && data.time_count!=0)
      {
       // alert('has')
        this.count=data.count;
      
      this.candidate_details = data.name;
      this.candidate_role =data.role
      this.candidate_password=this.email;
      //alert(this.count)

      //this.candidate_details=data.data.candidate_documents;
    localStorage.setItem('name',this.candidate_details);
    localStorage.setItem('role',this.candidate_role);
    localStorage.setItem('password',this.candidate_password);

    this.name=localStorage.getItem('name');
    
    if(this.count!=0)
    {
      // alert('has')
      this.web_hide=true;
      //this.router.navigate(['/eee_quiz_instruction']);
    }
    else{
      //alert('your ppassword dobt match');
      this.dialog.open(templateRef);
      
    }

      }
      else
      {
        //alert('your password dont match')
        this.dialog.open(templateRef);
      }
      

    

      
     // console.log('you have got data',this.count,this.candidate_details,this.candidate_role);
    })
    
  
  }
 

}

