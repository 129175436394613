import { Component, OnInit } from '@angular/core';
import { QuizService } from '../services/quiz.service';
import {MatDialog, MatDialogRef, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-eeequizreview',
  templateUrl: './eeequizreview.component.html',
  styleUrls: ['./eeequizreview.component.css']
})
export class EeequizreviewComponent implements OnInit {
candidate_name:string;
table_resultquestion=[];
candidate_password:any;
total_length;boolean=false
 
  constructor(public quizService: QuizService,  public dialogRef: MatDialogRef<EeequizreviewComponent>) { }

  ngOnInit() {
    this.candidate_name=localStorage.getItem('name');
 this.candidate_password=localStorage.getItem('password');
 this.quizService.review_questions(this.candidate_name,this.candidate_password).subscribe(
  (data: any) => {
    console.log(data)
    if(data.result.length!=0)
    {
      this.total_length=false;
      this.table_resultquestion=data.result
    }
    else
    {
      this.total_length=true
    }

   
    
  })

  }
  closeDialog() {
    this.dialogRef.close();
  }

}
