import { Component, OnInit } from '@angular/core';
import { EmployeeSkillMappingService } from '../services';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-delivery-structure',
  templateUrl: './delivery-structure.component.html',
  styleUrls: ['./delivery-structure.component.css']
})
export class DeliveryStructureComponent implements OnInit {

  displayedColumns: string[] = ['BU', 'dept', 'leader', 'email', 'phone'];
  spanningColumns = ['BU'];
  dataSource = [];
  spans = [];

  constructor(private skillService: EmployeeSkillMappingService) { 
  }

  ngOnInit() {
    this.skillService.getDeliveryStructure().subscribe((res:any)=>{
      this.dataSource = res.Sheet1;
      this.cacheSpan('BU', d => d.BU);
    })
  }

  cacheSpan(key, accessor) {
    for (let i = 0; i < this.dataSource.length;) {
      let currentValue = accessor(this.dataSource[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.dataSource.length; j++) {        
        if (currentValue != accessor(this.dataSource[j])) {
          break;
        }

        count++;
      } 

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }
}
