import { Component, OnInit,ViewChild, Output } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {MatPaginator, MatDialog, MatTableDataSource } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-organization-fitment-report',
  templateUrl: './organization-fitment-report.component.html',
  styleUrls: ['./organization-fitment-report.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.1, 0.1, 0.1, 0.1)')),
    ]),
  ]
})
export class OrganizationFitmentReportComponent implements OnInit {
 
  roles: any;
  units: any;
  sub_practices: any;
  practices: any;
  filtered_role_list: any = [];
  Object = Object;
  formdata: any;
  emp_id:any;
  mapping_data:any;
  employee_data:any;
  employee_competency_list: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['employee', 'unit', 'band', 'created_on', 'status', 'role', 'fitment_score'];
  total_count:any;
  constructor(private skill_service: EmployeeSkillMappingService, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    
    this.formdata = new FormGroup({
      unit: new FormControl(),
      practice: new FormControl(),
      sub_practice: new FormControl(),
      role: new FormControl()
      // job_code: new FormControl("", Validators.compose([
      //   Validators.required,
      // ]))
    });
    this.formdata.controls.unit.valueChanges.subscribe(value => {
      this.skill_service.getUnit(value)
        .subscribe(
          response => {
           
           this.practices = response;
          }
        )
     
    })
    this.formdata.controls.practice.valueChanges.subscribe(value => {
      this.skill_service.getUnit(value)
        .subscribe(
          response => {
           
            this.sub_practices = response
          }
        );
    });
    var jsonObj = JSON.parse(localStorage.currentUser);
    this.formdata.controls.sub_practice.valueChanges.subscribe(value => {
      this.skill_service.getRoleswithPracticeAndSubPractice(this.formdata.controls["unit"].value,this.formdata.controls["practice"].value,value)
        .subscribe(
          response => {
     this.roles = response["data"]["role_list"];
     //console.log(this.roles)
          }
        )
     
    })
    this.skill_service.getEmployeeFitmentList()
    .subscribe(
      response => {
        this.mapping_data = new MatTableDataSource(response["data"]["employee_competency_list"]);
        this.mapping_data.paginator = this.paginator;
        this.employee_data = response["data"]["employee_detail"];
        this.total_count=response["data"]["employee_competency_list"].length;
      }
    );
    
   
    
  
    this.skill_service.getUnit("0")
      .subscribe(
        response => {
          this.units = response;
        });
  }
  clearAllSelected() {
    this.roles = [];
    this.formdata.controls["role"].setValue(null);
  }

  update_role_list(event) {
    var value = event.target.value;
    const filterValue = value.toLowerCase();
    if (filterValue) {
      this.skill_service.getRoleList(filterValue)
        .subscribe(
          response => {
            this.filtered_role_list = response["data"]["role_list"];
          }
        );
    } else {
      this.filtered_role_list = [];
    }
  }
 
  onSearch(){
    
    this.formdata.value["export"] = false;
    this.skill_service.getEmployeeFitmentListExport(this.formdata.value)
    .subscribe(
      response => {
      //  console.log(response)
      this.mapping_data = new MatTableDataSource(response["data"]["employee_competency_list"]);
      this.mapping_data.paginator = this.paginator;
      this.employee_data = response["data"]["employee_detail"];
      this.total_count=response["data"]["employee_competency_list"].length;
      }
    );
  }
  onExport(){
    
    this.formdata.value["export"] = true;
    this.skill_service.getEmployeeFitmentListExport(this.formdata.value)
    .subscribe(
      response => {
      //  console.log(response)
        FileSaver.saveAs(response, 'fitment_report.xlsx');
      }
    );
  }

    ngOnDestroy() {
    this.dialog.closeAll();
  }


}
