import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-technical-iof',
  templateUrl: './technical-iof.component.html',
  styleUrls: ['./technical-iof.component.css']
})
export class TechnicalIofComponent implements OnInit {
  formdata: any;
  candidate_status:any;
  interview_round:any;
  overallRating: any = 0;
  total_experience:any;
  constructor(private router: Router, private _snackBar: MatSnackBar, private skill_service: EmployeeSkillMappingService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TechnicalIofComponent>) { }

  ngOnInit() {
    console.log("csdsadsa",this.data)
     this.candidate_status = this.data['candidate_detail']['status']
    this.interview_round = this.data['interview_round']
    this.formdata = new FormGroup({
      skill_detail: new FormGroup({ 
          primary_skill: new FormControl(""), 
          overall_experience: new FormControl(""), 
          relevant_experience: new FormControl("",[(control: AbstractControl) => Validators.max(this.total_experience)(control)]) 
        }),
       // new FormGroup({ primary_skill: new FormControl(""), overall_experience: new FormControl(""), relevant_experience: new FormControl("") })
      qualification_detail: new FormGroup({ highest_qualification: new FormControl(""), year_of_passing: new FormControl("") }),
      interview_performance: new FormGroup({
        primary_skill_and_proficiency: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        secondary_skill_and_proficiency: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        clarity_of_technical_concept: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        communication: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        project_management: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        team_management: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        problem_solving_skill: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        creativity_and_innovation: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        tool_or_written_test_taken: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        fitment_to_job_description: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
        others: new FormGroup({ comments: new FormControl(""), rating: new FormControl("") }),
      }),
      evaluation_result_and_remarks: new FormGroup({
        evaluation_remarks: new FormControl(""),
        evaluation_result: new FormControl("", [Validators.required]),
        image_upload:new FormControl("",[Validators.required])
      }),
      training_requirements: new FormControl(""),
    });
    console.log("this.data",this.data);
  }
  closeDialog() {
    this.dialogRef.close();
  }
  keyPress(event: any) {
    const pattern = /[1-5]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isNumberForTotalExp(event) {
    const pattern = /^\d*\.?\d*$/ ;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  keyPress1(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  calculate_overall_rating() {
    const rating_object = this.formdata.value.interview_performance;
    var overall_array = Object.keys(rating_object);
    var current_rating = 0;
    var length = 0;
    for (var i = 0; i < overall_array.length; i++) {
      if (rating_object[overall_array[i]]['rating']) {
        length++;
        current_rating = Number(current_rating) + Number(rating_object[overall_array[i]]['rating'])
      }
    }
    this.overallRating = current_rating / length;
    if(this.overallRating>=3){
      this.formdata.controls.evaluation_result_and_remarks.get('evaluation_result').setValue('Selected');
     // this.formdata.controls.evaluation_result_and_remarks.get('evaluation_result').disable();
    }else{
      this.formdata.controls.evaluation_result_and_remarks.get('evaluation_result').setValue('Rejected');
     // this.formdata.controls.evaluation_result_and_remarks.get('evaluation_result').disable();
    }
  }
  save_iof() {
    console.log("sdss",this.formdata.value.evaluation_result_and_remarks)
    this.formdata.controls.evaluation_result_and_remarks.get('image_upload').markAsTouched();
    if (this.formdata.valid) {
      console.log("sdfsdfsdfs")
      this.formdata.getRawValue();
      var jsonObj = JSON.parse(localStorage.currentUser);
      var employee_id = jsonObj.id;
      this.formdata.value["submitted_by"] = employee_id;
      const formData = new FormData();
      formData.append("file", this.formdata.value.evaluation_result_and_remarks.image_upload.files[0]);
      delete this.formdata.value.evaluation_result_and_remarks.image_upload;
      Object.keys(this.formdata.value).forEach((key) => {
        // if(key!='employee_reference'){
        //   formData.append(key, this.form_data.value[key]);
        // }else{
          formData.append(key, JSON.stringify(this.formdata.value[key]));
        // }
        
      });
      // this.formdata.value['file'] = formData
      // delete this.formdata.value.image_upload;status: "HR Interview Scheduled"
      if (this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Selected') {
        console.log("inside select")
        if(this.data['interview_round'] == '1' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview Cleared';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview 1')){
          var status = 'Technical Interview 1 Cleared';
          console.log("inside tech interview 1")
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 2 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview Cleared';
          console.log("inside tech interview 2")
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['final_interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '1' && (this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round')){
          var status = 'HR Round Cleared';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' &&(this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round')){
          var status = 'HR Round Cleared';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        // if (Number(this.data['candidate_detail']['interview_schedule']['level']) == (Number(this.data['interview_round']) - 1)) {
        //   var status = 'Technical Round Cleared';
        // } else {
        //   var level = Number(this.data['candidate_detail']['interview_schedule']['level']) + 1;
        //   var status = 'Level-' + level + ' Cleared';
        // }
      } else if(this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Rejected'){
       // var status = 'Rejected'
        if(this.data['interview_round'] == '1' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview Rejected';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview 1')){
          var status = 'Technical Interview 1 Rejected';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 2 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview Rejected';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['final_interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '1' &&(this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round')){
          var status = 'HR Round Rejected';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round')){
          var status = 'HR Round Rejected';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
      }else if(this.formdata.value['evaluation_result_and_remarks']['evaluation_result'] == 'Reschedule'){
       console.log("asdsad",this.data['interview_round'],this.candidate_status)
        if(this.data['interview_round'] == '1' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview 1 Reschedule'
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 1 Scheduled' || this.candidate_status == 'Skipped Technical Interview 1')){
          var status = 'Technical Interview 1 Reschedule';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'Technical Interview 2 Scheduled' || this.candidate_status == 'Skipped Technical Interview')){
          var status = 'Technical Interview 2 Reschedule';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['final_interview_schedule']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '1' && this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round'){
          var status = 'Reschedule HR Round';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        else if(this.data['interview_round'] == '2' && (this.candidate_status == 'HR Interview Scheduled' || this.candidate_status == 'Skipped HR Round')){
          var status = 'Reschedule HR Round';
          this.skill_service.saveIOFDetail(formData, this.data['candidate_detail']['rrf_master_id'], this.data['candidate_detail']['_id'], this.data['candidate_detail']['interview_schedule_hr']['_id'], status)
        .subscribe(
          response => {
            this.dialogRef.close();
            this.router.navigateByUrl('/employee_skill_view', { skipLocationChange: true }).then(() => {
              this.router.navigate(["iof_list"]);
            });
            this.openSnackBar();
          }
        );
        }
        
      }
      
    }
    else{
      this._snackBar.open('please fill mandatory fields', '', {
        duration: 2000,
      });
    }
  }
  openSnackBar() {
    this._snackBar.open('Interview Observation Form Saved Successfully', '', {
      duration: 2000,
    });
  }

}
