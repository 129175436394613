import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeSkillMappingService } from "../services/employee_skill_mapping.service";
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { EmployeeViewPopupComponent } from '../employee-view-popup/employee-view-popup';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.css']
})
export class EmployeeSearchComponent implements OnInit {
  mapping_data: any;
  employee_data: any;
  formdata: any;
  filtered_employee_list: any = {};
  Object = Object;
  skill_data: any = [];
  filtered_skill_list: any = [];
  filtered_role_list: any = [];
  units: any;
  sub_practices: any;
  practices: any;
  competency_data:any=[];
  filtered_competency_list: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['employee_name', 'unit', 'band', 'action'];
  total_count:any;
  constructor( private skill_service: EmployeeSkillMappingService, public dialog: MatDialog) {
  }
  ngOnInit() {
    this.skill_service.getEmployeeSkillMappingList({})
      .subscribe(
        response => {
          this.mapping_data = new MatTableDataSource(response["data"]["employee_skill_data"]);
          this.mapping_data.paginator = this.paginator;
          this.employee_data = response["data"]["employee_detail"];
         this.total_count=response["data"]["employee_skill_data"].length;
        }
      );
    this.skill_service.getSkillList()
      .subscribe(
        response => {
          this.skill_data = response["data"]["skill_proficiency_array"];
          this.filtered_skill_list = this.skill_data;
        }
      );
      this.skill_service.getCompetencyList()
      .subscribe(
        response => {
          this.competency_data = response["data"]["competency_list"];
          this.filtered_competency_list = this.competency_data;
        }
      );
      this.skill_service.getUnit("0")
      .subscribe(
        response => {
          this.units = response;
        });
  
    this.formdata = new FormGroup({
      employee: new FormControl(),
      skill: new FormControl(),
      skill_search: new FormControl(),
      role: new FormControl(),
      unit: new FormControl(),
      practice: new FormControl(),
      sub_practice: new FormControl(),
      competency: new FormControl(),
      competency_search: new FormControl()
    });
    this.formdata.controls["skill"].setValue([]);
    this.formdata.controls.skill_search.valueChanges
      .subscribe(() => {
        this.update_skill();
      });
      this.formdata.controls["competency"].setValue([]);
      this.formdata.controls.competency_search.valueChanges
      .subscribe(() => {
        this.update_competency();
      });
      this.formdata.controls.unit.valueChanges.subscribe(value => {
        this.skill_service.getUnit(value)
          .subscribe(
            response => {
             
             this.practices = response;
            }
          )
       
      })
      this.formdata.controls.practice.valueChanges.subscribe(value => {
        this.skill_service.getUnit(value)
          .subscribe(
            response => {
             
              this.sub_practices = response
            }
          );
      });
  }


  openEmployeeSkill(employee_id) {
    this.dialog.closeAll();
    this.dialog.open(EmployeeViewPopupComponent, { closeOnNavigation: true, width: '90vw', height: '80vh', maxWidth: '90vw', maxHeight: '80vh', autoFocus: false, data: employee_id, hasBackdrop: true, disableClose: true });
  }
  update_employee_list(event) {
    var value = event.target.value;
    const filterValue = value.toLowerCase();
    if (filterValue) {
      this.skill_service.getFilteredEmployeeList(filterValue)
        .subscribe(
          response => {
            this.filtered_employee_list = response["data"];
          }
        );
    } else {
      this.filtered_employee_list = {};
    }
    // this.filtered_employee_list = Object.keys(this.employee_data).filter(employee_id => {
    //   return this.employee_data[employee_id].employee_name.toLowerCase().includes(filterValue)
    // }).map(filteredEmployeeId => this.employee_data[filteredEmployeeId]);
  }

  displayFn(employee): string | undefined {
    return employee ? employee.employee_name : undefined;
  }
  update_skill() {
    var skill = this.formdata.controls.skill_search.value
    const filterValue = skill.toLowerCase();
    this.filtered_skill_list = this.skill_data.filter(skill => {
      return skill.skill_name.toLowerCase().includes(filterValue)
    })
  }
  update_competency(){
    var competency = this.formdata.controls.competency_search.value
    const filterValue = competency.toLowerCase();
    this.filtered_competency_list = this.competency_data.filter(competency => {
      return competency.competency_name.toLowerCase().includes(filterValue)
    })
  }
  update_competency_export(){
    console.log(this.formdata.value)
    // let data = this.formdata.value
    // let result = data.employee['id']
    // console.log(data.employee['employee_name'], final)
    this.skill_service.getCompetencyMappingExport()
    .subscribe(
      response => {
        console.log("response",response);
        FileSaver.saveAs(response, 'Employee_Skill_Dump.xlsx');
      }
  );
  }
  filter_employees() {
    if (this.formdata.value.employee || this.formdata.value.skill || this.formdata.value.role) {
      this.skill_service.getEmployeeSkillMappingList(this.formdata.value)
        .subscribe(
          response => {
            this.mapping_data = new MatTableDataSource(response["data"]["employee_skill_data"]);
            this.mapping_data.paginator = this.paginator;
            this.total_count=response["data"]["employee_skill_data"].length;
          }
        );
    } else {
      this.skill_service.getEmployeeSkillMappingList({})
        .subscribe(
          response => {
            this.mapping_data = new MatTableDataSource(response["data"]["employee_skill_data"]);
            this.mapping_data.paginator = this.paginator;
            this.total_count=response["data"]["employee_skill_data"].length;
          }
        );
    }
  }
  ngOnDestroy() {
    this.dialog.closeAll();
  }
  update_role_list(event) {
    var value = event.target.value;
    const filterValue = value.toLowerCase();
    if (filterValue) {
      this.skill_service.getRoleList(filterValue)
        .subscribe(
          response => {
            this.filtered_role_list = response["data"]["role_list"];
          }
        );
    } else {
      this.filtered_role_list = [];
    }
  }

  displayRole(role): string | undefined {
    return role ? role : undefined;
  }
 

}
